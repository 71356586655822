<!-- Button to recenter position -->
<button class="userPosition d-flex align-items-center justify-content-center" (click)="recenter()">
    <span class="material-icons grey">gps_fixed</span>
</button>

<div>
    <div class="gradient"></div>
    <agm-map
        [minZoom]="minZoom"
        [zoom]="zoom"
        [maxZoom]="maxZoom"
        [latitude]="lat"
        [longitude]="lng"
        [clickableIcons]="false"
        [disableDefaultUI]="true"
        [gestureHandling]="'greedy'"
        (mapReady)="onMapReady($event)"
        (mapClick)="onMapClick()"
    >
        <agm-fullscreen-control></agm-fullscreen-control>
    </agm-map>    
</div>