<div class="main">
    <!-- Navigation -->
    <GlobalNavBar [COMPONENT]="'UserManagement'"></GlobalNavBar>

    <div *ngIf="!DATA_IS_LOADED" class="component text-center my-5">
        <Message MESSAGE="Data is loading..."></Message>
    </div>

    <div *ngIf="DATA_IS_LOADED" class="col-10 mx-auto my-4">
        <h1 class="blue">USER MODIFICATION - {{user.username}}</h1>
        <div class="row">
            <!-- User reacap -->
            <div class="col-5">
                <div class="card">
                    <div class="card-header"><strong>USER INFORMATIONS</strong></div>
                    <div class="card-body">
                        <p class="card-text"><span class="text-nowrap">Username</span><span class="float-right">{{user.username}}</span></p>
                        <p class="card-text"><span class="text-nowrap">Email</span><span class="float-right">{{user.email}}</span></p>
                        <p class="card-text"><span class="text-nowrap">Status</span><span class="float-right">{{user.userStatus}}</span></p>
                        <p class="card-text"><span class="text-nowrap">Creation date</span><span class="float-right">{{user.userCreateDate | date:'medium'}}</span></p>
                        <p class="card-text">
                            <span class="text-nowrap">Role</span>
                            <span class="float-right">
                                <ng-container *ngIf="user?.group === 'SiteAdministrators'">Site Admin</ng-container>
                                <ng-container *ngIf="user?.group === 'GlobalAdministrators'">Global Admin</ng-container>
                            </span>
                        </p>
                        <div *ngIf="user?.group === 'SiteAdministrators'">
                            <p class="card-text">
                                <span class="text-nowrap">Sites</span>
                                <span class="col-10 float-right m-0 p-0 text-right">{{user.sites.join(', ')}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Role -->
            <div class="col-4">
                <div class="card">
                    <div class="card-header"><strong>UPDATE ROLE</strong></div>
                    <div class="card-body">
                        <div class="form-group">
                            <p class="labelLike">Role</p>
                            <select class="form-select" class="form-control" aria-label="Default select example" [(ngModel)]="user.group">
                                <option *ngFor="let group of groups" [ngValue]="group.Value">{{group.Label}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Role is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Update sites-->
                <div class="card mt-2" *ngIf="user?.group === 'SiteAdministrators' ">
                    <div class="card-header"><strong>UPDATE SITES</strong></div>
                    <div class="card-body">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Selected Sites</mat-label>
                            <mat-chip-list #chipList aria-label="Site selection">
                                <mat-chip *ngFor="let site of sites" [selectable]="selectable" [removable]="removable" (removed)="remove(site)">
                                    {{site}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="New site..." #siteInput [formControl]="siteCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event, siteInput)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let site of filteredSites | async" [value]="site">
                                    {{site}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <!-- Modify user -->
                <div class="d-flex justify-content-center">
                    <button type="button" class="mt-2 btn btn-global-type btn-type2" (click)="modifyUser()">Update User</button>
                </div>
            </div>
            <!-- User actions -->
            <div class="col-3">
                <div class="card">
                    <div class="card-header"><strong>USER ACTIONS</strong></div>
                    <div class="card-body">
                        <ul class="list-group border-none text-center">
                            <div *ngIf="user.userStatus === 'FORCE_CHANGE_PASSWORD'">
                                <li class="list-group-item"><button type="button" class="btn btn-global-type btn-warning" (click)="resendTemporaryPassword()"> Resend password </button></li>
                            </div>
                            <li class="list-group-item"><button type="button" class="btn btn-global-type btn-type2" (click)="passwordForget()">Forgot password</button></li>
                            <li class="list-group-item"><button type="button" class="btn btn-global-type btn-danger" (click)="removeUser()">Remove User</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>