import { Component, OnInit, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'alertmessage',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  @Input() ALERT_MESSAGE: any;
  @Input() callback: any;

  header:string;

  inputType : string = "password";
  buttonText : string ="Show";

  constructor() { }

  ngOnInit(): void {
    $('#modalMessage').modal('show');
    console.log("AlertMessage: ", this.ALERT_MESSAGE);

    /** We check the type of the ALERT_MESSAGE, in order to set the header text */
    if(this.ALERT_MESSAGE.TYPE_OF_MESSAGE === 'errorModal')
      this.header = "An error has occured";
    else if(this.ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal')
      this.header = 'Success !';
    else if(this.ALERT_MESSAGE.TYPE_OF_MESSAGE === 'informativeModal')
      this.header = 'Information';
    else if(this.ALERT_MESSAGE.TYPE_OF_MESSAGE === 'actionModal')
      this.header = '';
    else if(this.ALERT_MESSAGE.TYPE_OF_MESSAGE === 'displaypassword')
      this.header ='New Temporary password'
  }

  /** When we accept the modal, we call action callback to continue the process */
  actionModal(){
    if(this.ALERT_MESSAGE.TYPE_OF_MESSAGE === 'actionModal'){
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'generate'){
        this.callback.callbackActionModal_generate();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'save'){
        this.callback.callbackActionModal_save();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'downloadData'){
        this.callback.callbackActionModal_downloadData();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'remove'){
        this.callback.callbackActionModal_remove();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'modifyQuestion'){
        this.callback.callbackActionModal_modifyQuestion();
      }
      // if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'changeDisease'){
      //   this.callback.callbackActionModal_changeDisease();
      // }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'changeSecondaryLanguage'){
        this.callback.callbackActionModal_changeSecondaryLanguage();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'changePrimaryLanguage'){
        this.callback.callbackActionModal_changePrimaryLanguage();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'resendTemporaryPassword'){
        this.callback.callbackActionModal_resendTemporaryPassword();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'resetPassword'){
        this.callback.callbackActionModal_resetPassword();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === 'modifyUser'){
        this.callback.callbackActionModal_modifyUser();
      }
      if(this.ALERT_MESSAGE.TYPE_OF_ACTION === null){
        console.debug("TypeOfAction is null");
      }
    } else {
      this.closeModal();
      return
    }
  }

  /** Modal is closed (not accepted) */
  closeModal(){
    this.callback.callbackResetModal(this.ALERT_MESSAGE);
  }

  hidepassword(){
    if(this.inputType== "text")
      {
        this.inputType= "password";
        this.buttonText="Show"
      }
    else if(this.inputType=="password")
      {
        this.inputType= "text";
        this.buttonText="Hide"
      }
  }
}