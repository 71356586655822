<form [formGroup]="parentForm">

    <!-- Password Modal Title -->
    <div class="form-group">
        <p class="labelLike">Title</p>
        <input type="text" id="passwordModalTitle_{{type}}" class="form-control"
            [formControlName]="'passwordModalTitle_' + type" />
    </div>

    <!--  Password Modal Content -->
    <div class="form-group">
        <p class="labelLike">Content</p>
        <ckeditor [editor]="Editor" [config]="editorConfig" id="passwordModalContent_{{type}}" [formControlName]="'passwordModalContent_' + type"></ckeditor>
    </div>
</form>