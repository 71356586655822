import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { ROUTES } from 'src/app/core/classes/Routes';
import { RoutesService } from 'src/app/core/services/routes.service';
import { SitesService } from 'src/app/core/services/sites.service';
import { SurveysService } from 'src/app/core/services/surveys.service';

@Component({
  selector: 'aac-main',
  templateUrl: './main.component.html',
  styleUrls: [
    './main.component.scss',
    '../survey.scss',
    '../../local-back-office.scss',
    '../../../private.scss'
  ]
})
export class MainComponent implements OnInit {
  
  message: string = null;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null
  };

  ROUTES : ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null
  };
  SITE: any = {};
  SITE_NAME: string = '';
  SITE_FORMS;

  SITE_IS_DEACTIVATED: boolean = false;
  DATA_IS_LOADING: boolean = false;

  numberMaxOfForms: number = 10;

  constructor(
    private router: Router,
    private titleService: Title,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _surveysService: SurveysService
  ) {
    this.titleService.setTitle("Elefight - Form Management");

    this.DATA_IS_LOADING = true;
    this.message = "Data is loading...";

    /** Get routes information then get site information*/
    this._routesService.currentRoutes
    .subscribe(routes => {
      this.ROUTES = routes;
      if(routes.PARAMS){
        if(routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
      }
    });

    /** Get information about the site */
    this._sitesService.getSite(this.SITE_NAME).then(res => {
      this.SITE = JSON.parse(JSON.stringify(res));
      //Check if site exist and if it's activated
      if (Object.keys(this.SITE).length <= 0 || this.SITE.OPTIONS.ACTIVATED == 'false') {
        console.error("Site doesn't exist or has been deactivated");
        this.message = "This site/page is no longer  \n available or has been deactivated";
        this.SITE_IS_DEACTIVATED = true;
        return;
      }
      this.getForms();
    })
    .catch(err => this.callbackHTTPClient(err));
  }

  ngOnInit(): void {}

  /** Get list of forms of the current site */
  getForms(){
    this._surveysService.getForms(this.SITE_NAME)
      .then((res) => {
        this.SITE_FORMS = JSON.parse(JSON.stringify(res));
        //Sort array by creation_date
        this.SITE_FORMS.sort((a,b) => (a.CREATION_DATE < b.CREATION_DATE) ? 1 : ((b.CREATION_DATE < a.CREATION_DATE) ? -1 : 0));
        this.DATA_IS_LOADING = false;
      })
      .catch(err => this.callbackHTTPClient(err));
  }

  /**
   * Remove a form
   * @param id id of the form
  */
  removeForm(id: string){
    this._surveysService.removeForm(this.SITE_NAME, id)
      .then(() => {
        this.getForms();
      })
      .catch(err => this.callbackHTTPClient(err));
  }

  /**
   * Navigate through component
   * @param route route to reach
   * @param id id of the form (not mandatory)
   */
  navigate(route:string, id?: number){
    if(id) var route = `/admin/${this.SITE_NAME}/${route}/${id}`;
    else var route = `/admin/${this.SITE_NAME}/${route}`;
    this.router.navigate([route]);
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE){
    this.resetModal();
  }

  resetModal(){
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(error) {
    this.message = error['message'];
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.MESSAGE = error['message'+'errr'];
  }
}
