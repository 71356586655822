<div class="container">
    <div class="col">
        <!--
        <div class="row justify-content-md-center product-head">
             <img src="../../../../assets/img/information.png"> 
            <span class="material-icons md-48">sentiment_very_dissatisfied</span>
        </div>
            <h1>Oops! Something went wrong!666</h1>
        </div>
    -->
        <div  class="component text-center my-5">
            <Message MESSAGE="Oops! Something went wrong!"></Message>
        </div>
        <!-- <div class="row justify-content-md-center product-head">
            <button type="button" class="btn btn-secondary" (click)="navigateToHome()">Return to home</button>
        </div> -->
    </div>