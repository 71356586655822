import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/core/services/routes.service';

/** Not Found Component for routes that are foreign for the Routing Module */
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  // DISEASE: string = "";
  SITE_NAME: string = "";

  /** Constructor */
  constructor(
    public router: Router,
    private _routesService: RoutesService
  ) {
    //Get routes objects in RoutesService
    this._routesService.currentRoutes
    .subscribe(routes => {
      if(routes.PARAMS){
        console.log(routes.PARAMS);
        // if(routes.PARAMS['disease'] != undefined) var disease = routes.PARAMS['disease'];
        if(routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        // (disease) ? this.DISEASE = disease.toUpperCase() : this.DISEASE = '';
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
        console.log(this.SITE_NAME);
      }
    });
  }

  /** Function for a button that redirect the user on the Home Page */
  navigateToHome() {
    this.router.navigate(['/home']);
  }
}
