import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListSitesComponent } from './list-sites/list-sites.component';
import { SitesGeneratorComponent } from './sites-generator/sites-generator.component';
import { SiteManagementComponent } from './site-management/site-management.component';
import { SiteModificationComponent } from './site-modification/site-modification.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [ListSitesComponent, SitesGeneratorComponent, SiteManagementComponent, SiteModificationComponent],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    FormsModule, ReactiveFormsModule, CKEditorModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule, CKEditorModule
  ]
})
export class SitesGeneratorModule { }
