import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/core/classes/Routes';
import { RoutesService } from 'src/app/core/services/routes.service';
import { SitesService } from 'src/app/core/services/sites.service';
import { environment } from 'src/environments/environment';
import { FileManagementService } from 'src/app/core/services/file-management.service';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { SurveysService } from 'src/app/core/services/surveys.service';
import { extensionFile } from '../../../../core/validators/extensionFile';
import { maximumFileSize } from '../../../../core/validators/maximumFileSize';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EDITOR_CONFIG from "../../../../../assets/constants/editorConfigSurvey";
import { fileName } from 'src/app/core/validators/fileName';
import {listOfImagesExtensions} from 'src/app/core/classes/ImagesExtensions';
import { generateUUID } from 'src/app/core/helpers/generate-uuid';
@Component({
  selector: 'aac-modify',
  templateUrl: './modify.component.html',
  styleUrls: [
    './modify.component.scss',
    '../survey.scss',
    '../../local-back-office.scss',
    '../../../private.scss'
  ]
})
export class ModifyComponent implements OnInit {

  message: string = null;

  inputPath: string = environment.inputPath;

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null
  };
  SITE: any = {};
  SITE_NAME: string = '';

  SITE_IS_DEACTIVATED: boolean = false;
  DATA_IS_LOADING: boolean = false;

  newForm: FormGroup;
  submitted: boolean = false;
  isActivatedChecked: boolean;

  imageURL: string;
  file_to_display: Array<String> = [];
  file_to_export: Array<File> = [];

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null
  };
  temporaryModifyQuestionID: number = null;

  FORM_ID: string = null;
  FORM = {};
  FORM_QUESTIONS: Array<string> = [];
  FORM_QUESTIONS_IMAGES: Array<string> = [];
  ARCHIVED_QUESTIONS = [];
  formIsLoaded: boolean = false;
  errorMessage: string;
  
  public Editor = ClassicEditor;
  public editorConfig;

  constructor(
    private router: Router,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _surveysService: SurveysService,
    private _fileManagement: FileManagementService
  ) {
    this.titleService.setTitle("Elefight - Modify Form");
    this.editorConfig = EDITOR_CONFIG;

    /** Get routes information then get site information*/
    this._routesService.currentRoutes
    .subscribe(routes => {
      this.ROUTES = routes;
      console.log(routes);
      if(routes.PARAMS){
        console.log(routes.PARAMS);
        if(routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        if(routes.PARAMS['id'] != undefined) var id = routes.PARAMS['id'];
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
        (id) ? this.FORM_ID = id : this.FORM_ID = null;
      }
    });

    this._sitesService.getSite(this.SITE_NAME)
      .then((value) => {
        this.SITE = JSON.parse(JSON.stringify(value));
        console.log(this.SITE);

        //Check if site exist and if it's activated
        if (Object.keys(this.SITE).length <= 0 || this.SITE.OPTIONS.ACTIVATED == 'false') {
          console.error("Site doesn't exist or has been deactivated");
          this.message = "This site/page is no longer  \n available or has been deactivated";
          this.SITE_IS_DEACTIVATED = true;
          return;
        }
        
      })
      .catch(err => this.callbackHTTPClient(err));
  }

  ngOnInit() {
    this._surveysService.getForm(this.SITE_NAME, this.FORM_ID)
      .then((value) => {
        console.info(value);
        this.FORM = JSON.parse(JSON.stringify(value));
        if(Object.keys(this.FORM).length <= 0 ){
          console.error("Form is empty");
          return;
        }
        this.formIsLoaded = true;
        this.initForms();
      })
      .catch(err => this.callbackHTTPClient(err));
  }

  /** Init different forms */
  initForms(){
    this.newForm = this.formBuilder.group({
      uuid: [this.FORM['uuid'], [Validators.required]],
      site: [this.SITE_NAME, [Validators.required]],
      title: [this.FORM['title'], [Validators.required]],
      description: [this.FORM['description']],
      end_message: [this.FORM['end_message']],
      creation_date: [this.FORM['creation_date'], [Validators.required]],
      questions: new FormArray([])
    });
    //For each question we disabled it in the HMI
    //Then we push question and images in specifics array to retrieve after, even if we add new questions to form
    this.FORM['questions'].forEach(question => {
      question['disabled'] = true;
      this.addQuestion(question);
      this.FORM_QUESTIONS.push(question.uuid);
      this.FORM_QUESTIONS_IMAGES.push(question.uuid);
      this.file_to_display.push();
    });
    //If the status of the question is ARCHIVED, we remove it of the Reactive Form and store it in a secure array
    for(let i=0; i<this.questions().controls.length; i++){
      if(this.questions().controls[i]['value']['status'] === "ARCHIVED"){
        this.removeQuestion(i);
      }
    }
  }

  get nF() { return this.newForm.controls; }

  /** ---- Question Management ---- */
  questions(): FormArray { return this.newForm.get("questions") as FormArray }
  addQuestion(question?: any) {
    var STATUS:boolean = true;
    question? STATUS = (question?.status === 'ACTIVATED') ? true : false : STATUS = true;
    const newQuestion: FormGroup = this.formBuilder.group({
      uuid: [question?.uuid || generateUUID(), [Validators.required]],
      title: [question?.title || null, [Validators.required]],
      image_file: ['', [ maximumFileSize(`image_file_${this.questions().length}`, 2000000), extensionFile(`image_file_${this.questions().length}`, listOfImagesExtensions), fileName(`image_file_${this.questions().length}`) ] ],
      image: [question?.image || ''],
      status: [question?.status || "ACTIVATED", [Validators.required]],
      status_toogle: [STATUS, [Validators.required]],
      type: [question?.type || null, [Validators.required]],
      disabled: [question?.disabled || false, [Validators.required]],
      answers_options: new FormArray([])
    });
    this.questions().push(newQuestion);
    if (question !== undefined) {
      question?.answers_options.forEach(option => {
        this.addOption(this.questions().length - 1, option);
      });
    } else this.addOption(this.questions().length - 1);
  }
  removeQuestion(i:number) {
    //We don't remove the question, we archived it => STATUS = ARCHIVED
    //If the question is an old one (already in DynamoDB) we change the status of the question and move to a secure array 
    //before to remove it from the Reactive Form
    if(this.FORM_QUESTIONS.includes(this.questions().controls[i]['value']['uuid'])){
      console.log(this.questions());
      console.log(this.questions().controls[i]);
      this.questions().controls[i].patchValue({
        status: "ARCHIVED"
      });
      this.ARCHIVED_QUESTIONS.push(this.questions().controls[i]['value']);
    }
    this.questions().removeAt(i);
    console.log(this.ARCHIVED_QUESTIONS);
  }
  imageFileAt(i: number): FormControl {
    return (this.questions().at(i) as FormGroup).controls.image_file as FormControl;
  }
  
  /** ---- Question Management ---- */
  /** ---- Options Management ---- */
  options(index: number): FormArray { return this.newForm.get("questions")['controls'][index].get("answers_options") as FormArray }
  addOption(index: number, option?: any) {
    const newOption: FormGroup = this.formBuilder.group({
      uuid: [option?.uuid || generateUUID(), [Validators.required]],
      label: [option?.label, [Validators.required]]
    });
    this.options(index).push(newOption);
  }
  removeOption(index:number, i:number) {
    this.options(index).removeAt(i);
  }
  /** ---- Options Management ---- */

  /** onSubmit function */
  onSubmit() {
    this.submitted = true;
    console.log(this.newForm);

    // stop here if form is invalid
    if (this.newForm.invalid) {
      return;
    }

    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "save";
    this.ALERT_MESSAGE.MESSAGE = 
    `<p>Are you sure you want to modify this form ?</p><br/>`;

    console.log("Form is valid : ", this.newForm.value);
  }

  /** Callback to send value of form to API */
  callbackActionModal_save(){
    while(this.ALERT_MESSAGE.IS_DISPLAYED)
      this.callbackResetModal(this.ALERT_MESSAGE);

    //Set the variable submitted to true
    this.submitted = true;

    var newFormValue = this.newForm.value;
    console.log(this.newForm);
    console.log(JSON.parse(JSON.stringify(newFormValue)));

    // stop here if form is invalid
    if (this.newForm.invalid) {
      return;
    }

    //We replace " characters by &quot;
    newFormValue.description = newFormValue.description.replace(/[\\"]/g, '&quot;').replace(/\u0000/g, '\\0');

    //-------------------------------------------------------------------
    //We replace " characters by &quot;
    newFormValue.end_message = newFormValue.end_message.replace(/[\\"]/g, '&quot;').replace(/\u0000/g, '\\0');
    //-------------------------------------------------------------------

    //For each file in file_to_export, we upload the file in S3 bucket
    for(let i=0; i < this.file_to_export.length ; i++){
      if(this.file_to_export[i] != undefined){
        let extension = this.file_to_export[i].name.substring(this.file_to_export[i].name.lastIndexOf('.') + 1);
        this._fileManagement.uploadFile(this.file_to_export[i], null, extension, this.SITE_NAME, this.file_to_export[i].name).then(result => {
          console.log(result);
        })
      }
    }

    //For each question we get value of Switch (boolean) and write ACTIVATED or DEACTIVATED instead
    for(let i=0; i<newFormValue.questions.length; i++){
      if(newFormValue.questions[i]['status'] != "ARCHIVED")
        newFormValue.questions[i]['status_toogle'] ? newFormValue.questions[i]['status'] = "ACTIVATED" : newFormValue.questions[i]['status'] = "DEACTIVATED";
      delete newFormValue.questions[i]["status_toogle"];
    }

    //We re-add question ARCHIVED in the body to keep them in DynamoDB
    console.log(this.ARCHIVED_QUESTIONS);
    if(this.ARCHIVED_QUESTIONS.length > 0){
      this.ARCHIVED_QUESTIONS.forEach(question => {
        this.addQuestion(question);
      });
    }

    //We remove fields not necessary in API body
    delete newFormValue.creation_date;
    delete newFormValue.site;
    
    newFormValue['questions'].forEach(question => {
      delete question.image_file;
      delete question.disabled;
    });

    console.log(JSON.parse(JSON.stringify(newFormValue)));
    console.log('Form is ok');

    //We post form with body by calling API
    this._surveysService.modifyForm(this.SITE_NAME, this.FORM_ID, newFormValue)
      .then((res) => {
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
        this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
        this.ALERT_MESSAGE.MESSAGE = `Form modified successfully`;
        this.submitted = false;
      })
      .catch(err => this.callbackHTTPClient(err));
  }

  /**
   * move a question from a position on array to another
   * @param from position of object in array
   * @param to destination of object in array
   */
  moveItem(from, to) {
    var f = this.questions().value.splice(from, 1)[0];
    this.questions().value.splice(to, 0, f);
    var t = this.questions().controls.splice(from, 1)[0];
    this.questions().controls.splice(to, 0, t);

    //switch files
    var file1=this.file_to_display[from];
    this.file_to_display[from]=this.file_to_display[to];
    this.file_to_display[to]=file1; 
    
  }

  /**
   * event when a new file is put in input type file
   * @param event The file
   * @param index Index of the question
   */
  onFileChange(event, index) {
    if (event.target.files.length > 0) {
      this.FORM_QUESTIONS_IMAGES.splice(index, 1);
      const file = (event.target as HTMLInputElement).files[0];
      this.questions().at(index).patchValue({
        image: file.name
      });
      this.file_to_export[index] = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.file_to_display[index] = reader.result as string;
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * User ask to modify question
   * @param i id of the question
   */
  modifyQuestion(i:number){
    this.temporaryModifyQuestionID = i;
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "modifyQuestion";
    this.ALERT_MESSAGE.MESSAGE = `
    <span>Are you sure ? You are about to modify this question. It may negatively impact the results. Note that you can also deactivate this question and create a new one.</span><br/>`;
  }

  /** Callback if user accept to modify question */
  callbackActionModal_modifyQuestion(){
    this.questions()['controls'][this.temporaryModifyQuestionID]['controls']['disabled'].setValue(false);
    this.callbackResetModal(this.ALERT_MESSAGE);
  }

  /**
   * Navigate through component
   * @param route route to reach
   * @param id id of the form (not mandatory)
   */
  navigate(route:string, id?: number){
    if(id) var route = `/admin/${this.SITE_NAME}/${route}/${id}`;
    else var route = `/admin/${this.SITE_NAME}/${route}`;
    console.log(route);
    this.router.navigate([route]);
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(error) {
    console.log(error);
    this.errorMessage = error['message'];
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.MESSAGE = error['message'];
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE){
    if(ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal'){
      this.navigate('survey');
    }
    this.resetModal();
  }

  resetModal(){
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }
}