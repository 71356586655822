import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'results-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  @Input() DATA: any;

  BACKGROUND_COLOR = [
    '#FF5050',
    '#70FF50',
    '#5072FF',
    "#FAFF50"
  ]

  constructor() { }

  ngOnInit(): void {
    this.DATA = JSON.parse(JSON.stringify(this.DATA));
  }

}
