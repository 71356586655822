<div class="main">
    <!-- Navigation -->
    <GlobalNavBar [COMPONENT]="'UserManagement'"></GlobalNavBar>

    <div class="col-6 mx-auto my-4">
        <h1 class="blue">USER CREATION</h1>
        <!-- Form Creation -->
        <form (ngSubmit)="createUser()" [formGroup]="createUserForm">
            <!-- Username -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10">
                    <p class="labelLike">Username</p>
                    <input type="text" placeholder="Username" id="form_title" formControlName="username" class="form-control ng-pristine ng-invalid ng-touched" ng-reflect-name="username" [ngClass]="{'is-invalid' : submitted && createUserForm.controls.username.errors}" ng-reflect-ng-class="[object Object]" oninput="this.value = this.value.replace(' ', '_').replace(/[^A-Za-z0-9-_]/gi, '')" required>
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors?.pattern">Username must includes only letters or numbers, without 'space'</div>
                            <div *ngIf="f.username.errors.forbiddenUser">This username already exists</div>
                        <div *ngIf="f.username.errors?.required">Username is required</div>
                    </div>
                </div>
            </div>
            <!-- Email -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10">
                    <p class="labelLike">Email</p>
                    <input type="text" placeholder="email@sanofi.com" id="form_title" formControlName="email" [ngClass]="{'is-invalid' : submitted && createUserForm.controls.email.errors}" class="form-control ng-pristine ng-invalid ng-touched" ng-reflect-name="email" ng-reflect-ng-class="[object Object]" required>
                    <div class="invalid-feedback">
                        <div *ngIf="f.email.errors?.required">Email is required</div>
                        <div *ngIf="f.email.errors?.forbiddenMail">This email already exists</div>
                        <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
                    </div>
                </div>
            </div>
            <!-- Group / Role -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10">
                    <p class="labelLike">Role</p>
                    <select class="form-select" class="form-control" aria-label="Default select example" formControlName="role" required [ngClass]="{'is-invalid' : submitted && f.role.errors}">
                        <option value="" disabled selected>Role</option>
                        <option *ngFor="let group of groups" [ngValue]="group.Value">{{group.Label}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>Role type is required</div>
                    </div>
                </div>
            </div>
            <!-- Sites for SiteAdministrators group -->
            <div *ngIf="createUserForm.value.role === 'SiteAdministrators'" class="form-row row justify-content-md-center">
                <div class="form-group col-10">
                    <p class="labelLike">Sites</p>
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Selected Sites</mat-label>
                        <mat-chip-list #chipList aria-label="Site selection">
                        <mat-chip
                            *ngFor="let site of sites"
                            [selectable]="selectable"
                            [removable]="removable"
                            (removed)="remove(site)">
                            {{site}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input
                            placeholder="New site..."
                            #siteInput
                            [formControl]="siteCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event, siteInput)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let site of filteredSites | async" [value]="site">
                            {{site}}
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <!-- Create user -->
            <div class="row justify-content-md-end">
                <button class="btn btn-global-type btn-type2 mb-3" type="submit">Create user</button>
            </div>
        </form>
    </div>
</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>