<div
    class="box {{type === 'POSITIVE' ? 'box_positive' : type === 'ACCEPTED' ? 'box_accepted' : type === 'REFUSED' ? 'box_refused' : 'box_negative' }}">
    <div class="figure">
        <div class="row text">
            {{text}}
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div
                class="col-4 d-flex align-items-center justify-content-center main-figure {{totalFigure === null ? 'p-1' : 'p-0'}}">
                {{figure}}
            </div>
            <div *ngIf="totalFigure != null" class="col-4">
                <div class="row total total-text">
                    TOTAL
                </div>
                <div class="row total total-figure">
                    / {{totalFigure}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="infoText != null" class="info">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
            <path
                d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z" />
        </svg>
    </div>
    <div *ngIf="infoText != null" class="info_bulle">
        <div class="info_text">
            {{infoText}}
        </div>
    </div>
</div>