import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'FullImageForm',
  templateUrl: './full-image.component.html',
  styleUrls: [
    './full-image.component.scss',
    '../../content-management.scss',
    '../../../local-back-office.scss',
    '../../../../private.scss'
  ]
})
export class FullImageComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;
  @Input() option: string;
  @Input() id: number;
  @Output() files: EventEmitter<{ file: any, id: number, type: string }> = new EventEmitter<{ file: '', id: -1, type: '' }>();

  currentFiles: Array<File> = [];

  constructor(
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log()
  }

  /**
   * get fields of pages :index
   * @param index index of the pages
   * @returns return FormArray of fields
   */
  pageImageFields(index: number): FormGroup {
    return this.parentForm.get(`pages_${this.type}`)['controls'][index].get("pageImageFields") as FormGroup
  }

  /**
   * onChangeFile Event
   * @param event file of the event
   */
  onChangeFile(event) {
    if (event.target.files.length > 0) {
      const imgPath: FormControl = this.pageImageFields(this.id).controls["imagePath"] as FormControl;

      //fetch only the file name from the html
      const file = event.target.files.item(0);
      const myRenamedFile = new File([file], `${file.name.split('.').shift()}_${this.datePipe.transform(new Date(), 'dd-MM-yyyy_hh-mm-ss')}.${file.name.split('.').pop()}`);
      imgPath.setValue(myRenamedFile.name);
      //add the files to the files
      this.currentFiles[0] = myRenamedFile;
      this.files.emit({ file: this.currentFiles, id: this.id, type: this.type });
    }
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(error) {
    console.log(error);
  }
}
