import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { QRCodePasswordService } from 'src/app/core/services/qrcode-password.service';

@Component({
    selector: 'password-modal',
    templateUrl: './password-modal.component.html',
    styleUrls: [
        './password-modal.scss',
        '../update.component.scss',
        '../../qr-code-management.scss',
        '../../../local-back-office.scss',
        '../../../../private.scss'
    ]
})

export class PasswordModalComponent implements OnInit {

    form: FormGroup;
    selectedQRCodes: any[] = [];
    hidePassword: boolean = true;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<PasswordModalComponent>,
        private _qrcodePasswordService: QRCodePasswordService,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        this.selectedQRCodes = data.selectedQRcodes;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            password: ["", []],
            passwordActivated: [true, []]
        });
    }

    async save() {
        //hashPassword
        if (this.form.value.passwordActivated) {
            let hashedPassword = await this._qrcodePasswordService.hashPassword(this.form.value.password);
            this.form.value.password = hashedPassword;
        }

        let response = {
            "formCompleted": true,
            "form": this.form.value
        }
        
        this.dialogRef.close(response);
    }
}