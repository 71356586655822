import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoutesService } from 'src/app/core/services/routes.service';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { SITE } from 'src/app/core/classes/Site';
import { Title } from '@angular/platform-browser';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SitesService } from 'src/app/core/services/sites.service';
import { HttpclientService } from 'src/app/core/services/httpclient.service';
import { v4 as uuid } from 'uuid';
import { FileManagementService } from 'src/app/core/services/file-management.service';
import Auth from '@aws-amplify/auth';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EDITOR_CONFIG from "../../../../../assets/constants/editorConfigSurvey";
import { HttpClient } from "@angular/common/http";
import { mapOfLanguages, languages_array } from 'src/app/core/classes/Languages';
import { generateUUID } from 'src/app/core/helpers/generate-uuid';

declare var $: any;

@Component({
  selector: 'site-modification',
  templateUrl: './site-modification.component.html',
  styleUrls: [
    './site-modification.component.scss',
    '../sites-generator.scss',
    '../../global-back-office.scss',
    '../../../private.scss'
  ],
  providers: [DatePipe]
})
export class SiteModificationComponent implements OnInit, OnDestroy {

  DATA_IS_LOADED: boolean = false;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null
  };

  numberMaxOfPages: number = 10;
  arrayNumberMaxOfPages: Array<Number> = [];

  site_name_route: string = '';
  currentRoutesParamsSuscribe: Subscription;

  modifyForm: FormGroup;
  formIsLoaded: boolean = false;
  submitted: boolean = false;
  doNotModified: boolean = true;
  languages_array: Array<any> = languages_array
  listOfLanguages = mapOfLanguages;

  errorMessage: string;

  SITE: SITE;
  currentActivationStatus: boolean = null;
  translations: Array<any> = [];

  bsConfig: Partial<BsDatepickerConfig>;
  minDate: Date;

  public Editor = ClassicEditor;
  public editorConfig;
  public countryLists;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private datePipe: DatePipe,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _fileManagementService: FileManagementService,
    private _httpClient: HttpclientService,
    private httpClient: HttpClient,

  ) {
    this.titleService.setTitle("Elefight - Site Modification");
    this.editorConfig = EDITOR_CONFIG;

    //We generate array from numberMaxOfPages in order to loop on it to display choice of number of pages
    this.arrayNumberMaxOfPages = Array(this.numberMaxOfPages).fill(0).map((x, i) => i + 1);

    //Get params in the URL
    this.currentRoutesParamsSuscribe = this._routesService.currentRoutes
      .subscribe(routes => {
        if (routes.PARAMS) {
          console.info(routes.PARAMS);
          if (routes.PARAMS['site'] != undefined) {
            var site = routes.PARAMS['site'];
            this.site_name_route = site.toUpperCase();
          }
        }
      });
    //Configuration for calendar
    this.bsConfig = Object.assign({}, {
      adaptivePosition: true,
      isAnimated: true,
      containerClass: 'theme-sanofi',
      dateInputFormat: 'DD/MM/YYYY'
    });
    this.minDate = new Date();
  }

  /** ngOnInit function */
  async ngOnInit() {
    this.DATA_IS_LOADED = false;

    await this._sitesService.getSite(this.site_name_route)
      .then((value) => {
        this.SITE = JSON.parse(JSON.stringify(value));
        this.currentActivationStatus = (this.SITE["OPTIONS"]["ACTIVATED"] == 'true');
        console.log(this.languages_array);
        //We get the translations for each languages and store it in translations variable
        Promise.all(this.languages_array.map(language => this.loadTranslation(this.SITE.SITE.toUpperCase(), language.short_language))).then(success => {
          this.translations = JSON.parse(JSON.stringify(success));
          //We init the forms if there is a result from getSite()
          if (!this.isEmpty(this.SITE)) {
            this.formIsLoaded = true;
            this.initForms();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Init different forms */
  initForms() {
    this.SITE["CREATION_DATE"] = this.datePipe.transform(new Date(parseInt(this.SITE["CREATION_DATE"])), 'dd/MM/yyyy');
    if (this.SITE["LAST_ACTIVATION_DATE"] === '0')
      this.SITE["LAST_ACTIVATION_DATE"] = 'Never activated';
    else
      this.SITE["LAST_ACTIVATION_DATE"] = this.datePipe.transform(new Date(parseInt(this.SITE["LAST_ACTIVATION_DATE"])), 'dd/MM/yyyy');
    if (this.SITE["EXPIRATION_DATE"] === '0')
      this.SITE["EXPIRATION_DATE"] = '';
    else
      this.SITE["EXPIRATION_DATE"] = this.datePipe.transform(new Date(parseInt(this.SITE["EXPIRATION_DATE"])), 'dd/MM/yyyy');

    this.modifyForm = this.formBuilder.group({
      site: [this.SITE["SITE"], [
        Validators.required
      ]],
      languages: this.formBuilder.array([]),
      mapEnabled: [this.SITE['OPTIONS']['MAP_ENABLED'] === "true" ? 'Yes' : 'No', Validators.required],
      maxPages: [this.SITE['OPTIONS']['MAX_PAGES'] ? this.SITE['OPTIONS']['MAX_PAGES'] : "1", Validators.required],
      activated: [this.currentActivationStatus, [Validators.required]],
      creationDate: [this.SITE["CREATION_DATE"], [Validators.required]],
      lastActivationDate: [this.SITE["LAST_ACTIVATION_DATE"], [Validators.required]],
      expirationDate: [this.SITE["EXPIRATION_DATE"]],
      privacy_policies: this.formBuilder.array([]),
      legal_informations: this.formBuilder.array([]),
      campaignDateAnalytics: [this.SITE["MISC"]["CAMPAIGN_DATE_ANALYTICS"]],
      contentBlockingModalActivated: [this.SITE["MISC"]["CONTENT_BLOCKING_MODAL_ACTIVATED"]]
    });
    for (const language of this.SITE['LANGUAGES']) {
      this.addLanguage(language);
    }
    console.info("modifyForm: ", this.modifyForm);
    this.DATA_IS_LOADED = true;
  }

  get mF() { return this.modifyForm.controls; }

  /** ---- Language Management ---- */
  languages(): FormArray { return this.modifyForm.get("languages") as FormArray }
  privacy_policies(): FormArray { return this.modifyForm.get("privacy_policies") as FormArray }
  legal_informations(): FormArray { return this.modifyForm.get("legal_informations") as FormArray }

  addLanguage(language?: string) {
    const newLanguage: FormGroup = this.formBuilder.group({
      language: [language || '', [
        Validators.required,
        RxwebValidators.unique()
      ]]
    });
    this.languages().push(newLanguage);

    if (language) this.addLegalNotice(language);
  }

  addLegalNotice(inputLanguage: string) {
    if (this.privacy_policies().value.some(privacy_policy => privacy_policy.language === inputLanguage) || this.legal_informations().value.some(legal_information => legal_information.language === inputLanguage)) {
      this.errorMessage = "One language has been selected several times.";
      console.warn(this.errorMessage);
      $(".toast-warning").toast({ autohide: false });
      $('.toast-warning').toast('show');
      return;
    }

    //We store the translations for the language that we want to add
    let translationForLanguage = this.translations.filter(translation => translation.language === inputLanguage)[0]['translation'];

    console.log(this.translations);
    console.log(translationForLanguage);

    const newPrivacyPolicy: FormGroup = this.formBuilder.group({
      text: [translationForLanguage.legalNotices.privacyPolicy || ''],
      language: [inputLanguage || '']
    });
    this.privacy_policies().push(newPrivacyPolicy);

    const newLegalInformation: FormGroup = this.formBuilder.group({
      text: [translationForLanguage.legalNotices.legalInformation || ''],
      language: [inputLanguage || '']
    });
    this.legal_informations().push(newLegalInformation);

  }


  removeLanguage(i: number) {
    if (this.languages().length - 1 === 0) {
      this.errorMessage = "Error : You can not have less than one language.";
      $(".toast-alert").toast({ autohide: false });
      $('.toast-alert').toast('show');
    }
    else {
      //If we have more than one language, we remove all legal informations and privacy policies with this language
      //then we remove the language
      (this.privacy_policies().value.map((elm, idx) => elm.language === this.languages().value[i]['language'] ? idx : '').filter(String)).reverse().forEach(index => {
        this.privacy_policies().removeAt(index);
      });
      (this.legal_informations().value.map((elm, idx) => elm.language === this.languages().value[i]['language'] ? idx : '').filter(String)).reverse().forEach(index => {
        this.legal_informations().removeAt(index);
      });
      this.languages().removeAt(i);
    }
  }
  /** ---- Language Management ---- */

  indexOfAll(array, searchItem) {
    var i = array.findIndex(function (privacy_policy) {
      return privacy_policy.language === searchItem;
    }),
      indexes = [];
    while (i !== -1) {
      indexes.push(i);
      i = array.findIndex(function (privacy_policy) {
        return privacy_policy.language === searchItem;
      }, ++i);
    }
    return indexes;
  }

  /** onSubmit */
  onSubmit() {
    this.submitted = true;
    console.info(this.modifyForm);

    // stop here if form is invalid
    if (this.modifyForm.invalid) {
      return;
    }

    const languages = this.modifyForm.value.languages.map(language => this.listOfLanguages[language.language]).join(", ");
    const activated = this.modifyForm.value.activated ? "Yes" : "No";

    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "save";
    this.ALERT_MESSAGE.MESSAGE =
      `<p>Are you sure you want to modify this site ?</p><br/>
    <span>Site name : ${this.modifyForm.value.site.toUpperCase()}</span><br/>
    <span>Activated : ${activated}</span><br/> 
    <span>Languages : ${languages}</span><br/>
    <span>Map enabled : ${this.modifyForm.value.mapEnabled}</span><br/>
    <span>Number of pages : ${this.modifyForm.value.maxPages}</span><br/>`;

    console.info("Form is valid : ", this.modifyForm.value);
  }


  /** Callback after alert modal for save */
  callbackActionModal_save() {
    while (this.ALERT_MESSAGE.IS_DISPLAYED)
      this.callbackResetModal(this.ALERT_MESSAGE);

    //Set the variable submitted to true
    this.submitted = true;

    //Stop here if the form is invalid
    if (this.modifyForm.invalid) {
      return;
    }

    //On gère la date de création
    if (this.modifyForm.value.creationDate) {
      const splitCreationDate = this.modifyForm.value.creationDate.split('/');
      this.modifyForm.value.creationDate = (new Date(splitCreationDate[2], splitCreationDate[1] - 1, splitCreationDate[0])).getTime();
    } else {
      console.error("No creation date.");
      $('.toast-wait').toast('hide');
      this.errorMessage = "No creation date.";
      $(".toast-alert").toast({ autohide: false });
      $('.toast-alert').toast('show');
      return;
    }

    //Si on viens d'activer le site, on change la date d'activation
    if (this.currentActivationStatus === false && this.modifyForm.value.activated === true) {
      const currentDate = this.datePipe.transform(Date.now(), 'dd/MM/yyyy');
      const splitLastActivationDate: any = currentDate.split('/');
      this.modifyForm.value.lastActivationDate = (new Date(splitLastActivationDate[2], splitLastActivationDate[1] - 1, splitLastActivationDate[0])).getTime();
    } else {
      //On gére la date de la dernière activation
      if (this.modifyForm.value.lastActivationDate) {
        if (this.modifyForm.value.lastActivationDate === "Never activated") {
          this.modifyForm.value.lastActivationDate = 0;
        } else {
          const splitLastActivationDate = this.modifyForm.value.lastActivationDate.split('/');
          this.modifyForm.value.lastActivationDate = (new Date(splitLastActivationDate[2], splitLastActivationDate[1] - 1, splitLastActivationDate[0])).getTime();
        }
      } else {
        console.error("No last activation date.");
        $('.toast-wait').toast('hide');
        this.errorMessage = "No last activation date.";
        $(".toast-alert").toast({ autohide: false });
        $('.toast-alert').toast('show');
        return;
      }
    }

    //On gére la date d'expiration
    if (this.modifyForm.value.expirationDate === "" || this.modifyForm.value.expirationDate === null) {
      this.modifyForm.value.expirationDate = 0
    } else {
      if (typeof this.modifyForm.value.expirationDate === 'object') {
        this.modifyForm.value.expirationDate = Date.parse(this.modifyForm.value.expirationDate);
      } else if (typeof this.modifyForm.value.expirationDate === 'string') {
        const splitExpirationDate = this.modifyForm.value.expirationDate.split('/');
        this.modifyForm.value.expirationDate = (new Date(splitExpirationDate[2], splitExpirationDate[1] - 1, splitExpirationDate[0])).getTime();
      } else {
        console.error("No type");
      }
    }

    //On change le nom du site en upperCase
    this.modifyForm.value.site = this.modifyForm.value.site.toUpperCase();

    //On map les languages pour s'adapter à l'API
    this.modifyForm.value.languages = this.modifyForm.value.languages.map(l => l.language.toString());
    this.modifyForm.value.mapEnabled = this.modifyForm.value.mapEnabled == 'Yes' ? true : false;
    this.modifyForm.value.maxPages = parseInt(this.modifyForm.value.maxPages);

    for (let legal_info of this.modifyForm.value.legal_informations) {
      for (var i = 0; i < this.translations.length; i++) {
        //verifier la langue du champ récupérer et le champ modifier 
        if (this.translations[i].language == legal_info.language) {
          this.translations[i]["translation"].legalNotices.legalInformation = legal_info.text;
        }
      }
    }

    for (let privacy of this.modifyForm.value.privacy_policies) {
      for (var i = 0; i < this.translations.length; i++) {
        //verifier la langue du champs récupérer et le champs modifier 
        if (this.translations[i].language == privacy.language) {
          this.translations[i]["translation"].legalNotices.privacyPolicy = privacy.text;
        }
      }
    }

    delete this.modifyForm.value.legal_informations;
    delete this.modifyForm.value.privacy_policies;

    //On créer un nouveau site '-PREVIEW' pour envoyer à l'API
    let modifyFormPreview = JSON.parse(JSON.stringify(this.modifyForm.value));

    //On change le nom du site en rajoutant -PREVIEW et on active le site à chaque fois
    modifyFormPreview.site = (modifyFormPreview.site + '-PREVIEW').toUpperCase();
    modifyFormPreview.activated = true;

    console.info("Modify site : ");
    console.info(modifyFormPreview);
    console.info(this.modifyForm.value);

    //On stocke dans un tableau les promises retourné par les fonction modifySite
    let promises = [];
    promises.push(this._sitesService.modifySite(this.modifyForm.value));
    promises.push(this._sitesService.modifySite(modifyFormPreview));

    //Quand les promises sont récupérés, on affiche le résultat : error ou success
    Promise.all(promises).then((values) => {
      if (values[0].error) {
        $('.toast-wait').toast('hide');
        this.errorMessage = values[0].message;
        $(".toast-alert").toast({ autohide: false });
        $('.toast-alert').toast('show');
        return;
      }
      if (values[1].error) {
        $('.toast-wait').toast('hide');
        this.errorMessage = values[1].message;
        $(".toast-alert").toast({ autohide: false });
        $('.toast-alert').toast('show');
        return;
      }

      let allPages = [];

      //On calcul le nombre de pages que l'on souhaite ajouter
      const defaultNumberOfPages: number = this.translations[0]['translation'].pages.length - 1;
      const numberOfPages: number = this.modifyForm.value.maxPages;
      const pagesToGenerate: number = numberOfPages - defaultNumberOfPages;
      //Si on doit ajouter des pages, on les génères et les ajoutes aux traductions
      if (pagesToGenerate > 0) {
        allPages = this.generateArrayPages(pagesToGenerate, defaultNumberOfPages);
        for (let i = 0; i < this.translations.length; i++) {
          this.translations[i]['translation'].pages = [...this.translations[i]['translation'].pages, ...allPages];
        }
      }
      //Sinon on passe directement à l'upload des JSONs
      let promises_uploadJSON = [];

      this.translations = this.translations.map(translation => translation.translation);

      for (let i = 0; i < this.languages_array.length; i++) {
        //We remove general data for each languages
        delete this.translations[i]['GENERAL'];
        delete this.translations[i]['LANGUAGE'];
        
        //Then we push the new translation
        promises_uploadJSON.push(this._httpClient.postTranslation(`${this.modifyForm.value.site.toUpperCase()}-PREVIEW`, this.translations[i]));
      }

      //Once each translation are updated on preview site, we publish modification and display modal information
      Promise.all(promises_uploadJSON).then((UploadFileRes) => {
        this._fileManagementService.publishModification(`${this.modifyForm.value.site.toUpperCase()}`).then(value => {
          this.ALERT_MESSAGE.IS_DISPLAYED = true;
          this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
          this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
          this.ALERT_MESSAGE.MESSAGE = `Site modified successfully`;
          $('.toast-wait').toast('hide');
          this.submitted = false;
        });
      });
    });
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    if (ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal') {
      this.navigate('sites/sites-management', this.modifyForm.value.site.toLowerCase());
      this.resetModal();
    }
    this.resetModal();
  }

  private async loadTranslation(siteName: string, language: string) {
    const translation = await this._httpClient.getPreviewTranslation(siteName, language);
    return { language: language, translation: translation };
  }

  /**
   * Check if obj is empty or not
   * @param obj object to test
   * @returns return boolean if empty or not
   */
  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  /**
   * Return an array of pages for the new site
   * @param pagesToGenerate number of pages that we want to generate
   * @returns return array of pages
   */
  generateArrayPages(pagesToGenerate: number, pageNumber: number) {
    let pages = [];
    for (let i = 0; i < pagesToGenerate; i++) {
      pages.push({
        id: generateUUID(),
        name: `Content Page ${(pageNumber + (i + 1))}`,
        type: 'FULL_IMAGE',
        color: '#264F6F',
        activated: false,
        callToAction: {
          callToActionDocument: {
            documentPath: ''
          },
          callToActionExternalLink: {
            url: ''
          },
          callToActionSurvey: {
            survey: ''
          },
          callToActionMultiCta: {
            ctaList: []
          },
          id: '',
          label: '',
          type: 'EXTERNAL'
        },
        pageCompositeFields: [
          {
            imagePath: "Image1.png",
            text: "<p>This layout allows you to upload illustrations and provide your user with relevant information.</p>"
          },
          {
            imagePath: "Image2.png",
            text: "<p>This layout allows you to upload illustrations and provide your user with relevant information.</p>"
          },
          {
            imagePath: "Image3.png",
            text: "<p>This layout allows you to upload illustrations and provide your user with relevant information.</p>"
          }
        ],
        pageImageFields: {
          imagePath: "Picture_Homepage_template.png"
        },
        pageTextFields: {
          text: ""
        }
      });
    }
    return pages;
  }

  resetModal() {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /**
   * Navigate though components
   * @param route route to reach
   * @param site name of the site
   */
  navigate(route: string, site?: string) {
    this.destroySubscription();
    if (site) this.router.navigate([`/admin/${route}/${site}`])
    else this.router.navigate([`/admin/${route}`]);
  }

  /** Destroy all subscription in the component */
  destroySubscription(): void {
    this.currentRoutesParamsSuscribe.unsubscribe();
  }

  ngOnDestroy(): void {
    this.destroySubscription();
  }

  signOut() {
    Auth.signOut();
  }
}