<form [formGroup]="parentForm">
    <!-- Header Logo -->
    <div class="form-group mb-3">
        <p><span class="labelLike">Header Logo </span><span class="aspect-indication">(Aspect Ratio 32:9 - Size Max. 2MB)</span></p>
        <div class="row">
            <div class="col-md-6 px-0">
                <input type="file" class="custom-file-input" id="headerLogo_10_{{type}}" [formControlName]="'headerLogo_10_' + type" accept="image/*" (change)="onChangeLogo('headerLogo_10', $event)">
                <label class="custom-file-label" for="headerLogo_10_{{type}}">Upload</label>
                <div *ngIf="parentForm.get('headerLogo_10_' + type).invalid && (parentForm.get('headerLogo_10_' + type).dirty || parentForm.get('headerLogo_10_' + type).touched)" class="alert alert-danger">
                    <div *ngIf="parentForm.get('headerLogo_10_' + type).errors.invalidFileSize">
                        This file is too large. It must be less than 2MB.
                    </div>
                    <div *ngIf="parentForm.get('headerLogo_10_' + type).errors.extension">
                        File format not allowed. It must be an image.
                    </div>
                    <div *ngIf="parentForm.get('headerLogo_10_' + type).errors?.unauthorizedFileName">
                        File name not allowed. It must not contains any special characters other than "-" and "_" .
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <div *ngIf="parentForm.value['headerLogo_10_filename_' + type]; else no_value_image">
                    <p  class="labelLike">{{parentForm.value['headerLogo_10_filename_' + type]}}</p>
                    <small class="removeLogo" (click)="removeLogo('headerLogo_10_filename_' + type, 'headerLogo_10_' + type)">remove logo</small>
                </div>
                
                <ng-template #no_value_image>
                    <p class="labelLike">No file</p>
                </ng-template>
            </div>
        </div>
    </div>

    <!-- Menu logo -->
    <div class="form-group mb-3">
        <p> <span class="labelLike">Menu: Top Logo </span><span class="aspect-indication">(Aspect Ratio 32:9 - Size Max. 2MB)</span></p>
        <div class="row">
            <div class="col-md-6 px-0">
                <input type="file" class="custom-file-input" id="menuLogo_09_{{type}}" [formControlName]="'menuLogo_09_' + type" accept="image/*" (change)="onChangeLogo('menuLogo_09', $event)">
                <label class="custom-file-label" for="menuLogo_09_{{type}}">Upload</label>
                <div *ngIf="parentForm.get('menuLogo_09_' + type).invalid && (parentForm.get('menuLogo_09_' + type).dirty || parentForm.get('menuLogo_09_' + type).touched)" class="alert alert-danger">
                    <div *ngIf="parentForm.get('menuLogo_09_' + type).errors.invalidFileSize">
                        This file is too large. It must be less than 2MB.
                    </div>
                    <div *ngIf="parentForm.get('menuLogo_09_' + type).errors.extension">
                        File format not allowed. It must be an image.
                    </div>
                    <div *ngIf="parentForm.get('headerLogo_19_' + type).errors?.unauthorizedFileName">
                        File name not allowed. It must not contains any special characters other than "-" and "_" .
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <div *ngIf="parentForm.value['menuLogo_09_filename_' + type]; else no_value_image">
                    <p class="labelLike">{{parentForm.value['menuLogo_09_filename_' + type]}}</p>
                    <small class="removeLogo" (click)="removeLogo('menuLogo_09_filename_' + type, 'menuLogo_09_' + type)">remove logo</small>
                </div>
                <ng-template #no_value_image>
                    <p class="labelLike">No file</p>
                </ng-template>
            </div>
        </div>
    </div>

    <!-- Sanofi logo -->
    <div class="form-group mb-3">
        <p><span class="labelLike">Menu: Bottom Logo </span><span class="aspect-indication">(Aspect Ratio 32:9 - Size Max. 2MB)</span></p>
        <div class="row">
            <div class="col-md-6 px-0">
                <input type="file" class="custom-file-input" id="secondaryLogo_09_{{type}}" [formControlName]="'secondaryLogo_09_' + type" accept="image/*" (change)="onChangeLogo('secondaryLogo_09', $event)">
                <label class="custom-file-label" for="secondaryLogo_09_{{type}}">Upload</label>
                <div *ngIf="parentForm.get('secondaryLogo_09_' + type).invalid && (parentForm.get('secondaryLogo_09_' + type).dirty || parentForm.get('secondaryLogo_09_' + type).touched)" class="alert alert-danger">
                    <div *ngIf="parentForm.get('secondaryLogo_09_' + type).errors.invalidFileSize">
                        This file is too large. It must be less than 2MB.
                    </div>
                    <div *ngIf="parentForm.get('secondaryLogo_09_' + type).errors.extension">
                        File format not allowed. It must be an image.
                    </div>
                    <div *ngIf="parentForm.get('secondaryLogo_09_' + type).errors?.unauthorizedFileName">
                        File name not allowed. It must not contains any special characters other than "-" and "_" .
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <div *ngIf="parentForm.value['secondaryLogo_09_filename_' + type]; else no_value_image">
                    <p class="labelLike">{{parentForm.value['secondaryLogo_09_filename_' + type]}}</p>
                    <small class="removeLogo" (click)="removeLogo('secondaryLogo_09_filename_' + type, 'secondaryLogo_09_' + type)">remove logo</small>
                </div>
                <ng-template #no_value_image>
                    <p class="labelLike">No file</p>
                </ng-template>
            </div>
        </div>
    </div>
</form>