import FRANCE from "./France.json";
import ROMANIA from "./Romania.json";
import SPAIN from "./Spain.json";

const regions = {
    ROMANIA,
    FRANCE,
    SPAIN,
};

export default regions;
