import { Injectable } from '@angular/core';
import bcrypt from 'bcryptjs';

@Injectable({
    providedIn: 'root'
  })

export class QRCodePasswordService {

    private saltRounds : number;
    constructor() {
        this.saltRounds = 10;
    }


    async hashPassword (password : string)  {
        let salt = await bcrypt.genSalt(this.saltRounds)
        return bcrypt.hash(password,salt);
    }

    async validatePassword (password: string, hash: string){
        return await bcrypt.compare(password,hash);
    }
  
}