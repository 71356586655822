import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { UsersService } from 'src/app/core/services/users.service';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { SITE } from 'src/app/core/classes/Site';
import { SitesService } from 'src/app/core/services/sites.service';
import { Observable } from 'rxjs-compat/Observable';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import Auth from '@aws-amplify/auth';

@Component({
  selector: 'aac-user-generator',
  templateUrl: './user-generator.component.html',
  styleUrls: [
    './user-generator.component.scss',
    '../users-generator.scss',
    '../../global-back-office.scss',
    '../../../private.scss'
  ]
})

export class UserGeneratorComponent implements OnInit {

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  siteCtrl = new FormControl();
  filteredsites: Observable<string[]>;

  forbiddenUserNames: string[] = [];
  forbiddenEmails: string[] = [];

  sites: string[] = [];
  allSites: string[] = [];
  filteredSites: Observable<string[]>;

  submitted: boolean = false;

  groups = [
    {
      "Label": "Site Admin",
      "Value": "SiteAdministrators"
    },
    {
      "Label": "Global Admin",
      "Value": "GlobalAdministrators"
    }
  ]

  createUserForm: FormGroup;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null
  };

  @ViewChild('siteInput') siteInput: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private titleService: Title,
    private _sitesService: SitesService,
    private _usersService: UsersService,
    private formBuilder: FormBuilder,
  ) {
    this.titleService.setTitle("Elefight - User Management");
    this._sitesService.getSites().then((value) => {
      this.allSites = value['sites'].map((site: SITE) => site.SITE);
      //We remove sites ending by "-PREVIEW"
      this.allSites = this.allSites.filter((item) => {
        return !item.includes("-PREVIEW");
      });
    });

    this._usersService.getUsers().then((users: Array<string>) => {
      console.log("Users list: ", users);
      this.forbiddenUserNames = users.map(user => user['username'].toUpperCase());
      this.forbiddenEmails = users.map(email => email['email'].toUpperCase());
      this.initForms();
    });

    this.filteredsites = this.siteCtrl.valueChanges.map((site: string | null) => {
      return site ? this._filter(site) : this.sites.slice()
    });
    this.filteredSites = this.siteCtrl.valueChanges.pipe(
      startWith(null),
      map((site: string | null) => (site ? this._filter(site) : this.allSites.slice())),
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.createUserForm.controls; }

  ngOnInit(): void {
    this.initForms();
  }

  initForms() {
    this.createUserForm = this.formBuilder.group({
      username:
        ['', [
          Validators.required,
          (control: AbstractControl) => {
            return this.forbiddenUserNames.indexOf(control.value.toUpperCase()) === -1 ? null : { 'forbiddenUser': true };
          }
        ]
        ],
      email:
        ['', [
          Validators.required,
          Validators.email,
          (control: AbstractControl) => {
            return this.forbiddenEmails.indexOf(control.value.toUpperCase()) === -1 ? null : { 'forbiddenMail': true };
          }
        ]
        ],
      role: new FormControl('', Validators.required),
      sites: new FormControl([])
    });
  }

  add(event: MatChipInputEvent, inputName): void {
    const value = (event.value || '').trim();

    // Add our site
    if (value) this.sites.push(value);

    // Clear the input value
    inputName.value = "";
    this.siteCtrl.setValue(null);
  }

  remove(site: string): void {
    const index = this.sites.indexOf(site);
    if (index >= 0) this.sites.splice(index, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.sites.push(event.option.viewValue);
    this.siteInput.nativeElement.value = '';
    this.siteCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allSites.filter(site => site.toLowerCase().includes(filterValue));
  }

  /** Create a new user */
  createUser() {
    this.submitted = true;
    this.createUserForm.patchValue({ "sites": [...this.sites] });
    console.log(this.createUserForm.value);

    if (this.createUserForm.invalid)
      return;

    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "generate";
    this.ALERT_MESSAGE.MESSAGE =
      `<p>Are you sure you want to create user ${this.createUserForm.value.username} ?</p>`;
  }

  /**
   * Callback of createUser() action modal
   */
  callbackActionModal_generate() {

    // stop here if form is invalid
    if (this.createUserForm.invalid) {
      return;
    }

    let body = {
      username: this.createUserForm.value.username,
      email: this.createUserForm.value.email,
      group: this.createUserForm.value.role,
      sites: this.createUserForm.value.sites.map(site => site.toUpperCase())
    }

    console.log("Body sent to API: ", body);

    this._usersService.postUser(body)
      .then((response) => {
        this.navigate("users-management/user-modification", this.createUserForm.value.username);
      }).catch((error) => {
        console.error(error);
      });
  }

  /**
  * Navigate through components
  * @param route route we want to reach
  * @param username username of the user, if we want to go to user-modification component
  */
  navigate(route: string, username?: string) {
    if (username) this.router.navigate([`/admin/${route}/${username}`])
    else this.router.navigate([`/admin/${route}`]);
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  signOut(){
    Auth.signOut();
  }
}