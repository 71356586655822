<div class="main">
    <!-- Navigation -->
    <LocalNavBar [COMPONENT]="'QRCodesComponent'"></LocalNavBar>

    <div *ngIf="DATA_IS_LOADING || SITE_IS_DEACTIVATED || !DATA_IS_LOADED; else elseBlock"
        class="component text-center my-5">
        <Message [MESSAGE]="message"></Message>
    </div>

    <ng-template #elseBlock>
        <!-- QR Codes menu -->
        <div class="row mt-2">
            <div class="col-md-12 pl-2 pb-2 pt-2 d-flex">
                <div class="bottom_row_buttons">
                    <div class="btn-toolbar ml-1" role="toolbar">
                        <div class="mr-2" role="group">
                            <button type="button" class="btn btn-global-type btn-type3 d-flex align-items-center"
                                (click)="navigate('qr-code-management/update')">
                                <span class="material-icons icons_button">
                                    list
                                </span>
                                <span class="ml-2">List of QR Codes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Main component -->
        <div class="container_global mt-2">
            <div class="container_qr_settings">
                <!-- Configuration of QR Codes -->
                <div class="qr_section_title d-flex align-items-end font-weight-bold">
                    <h2>Set up your QR Codes</h2>
                </div>
                <div class="container_qr_settings_form">
                    <div class="container_scrollable">
                        <div class="form_group form_group_qr_size">
                            <span class="labelLike">Main options</span>
                            <div class="label_section">
                                <!-- <div class="sub_section">
                                    <span>Size (px) :</span>
                                    <mat-slider max=1000 min=100 step=1 thumbLabel="true"
                                        [(ngModel)]="QR_CODES_CONFIG.SIZE" (ngModelChange)="updateQrCode()">
                                    </mat-slider>
                                    <span>{{QR_CODES_CONFIG.SIZE}} x {{QR_CODES_CONFIG.SIZE}}</span>
                                </div>
                                <div class="sub_section">
                                    <span>Margin</span>
                                    <mat-slider max=150 min=0 step=10 thumbLabel="true"
                                        [(ngModel)]="QR_CODES_CONFIG.MARGIN" (ngModelChange)="updateQrCode()">
                                    </mat-slider>
                                    <span>{{QR_CODES_CONFIG.MARGIN}}</span>
                                </div> -->
                                <div class="sub_section">
                                    <span>Error Correction Level</span>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Error Correction Level</mat-label>
                                        <mat-select [(ngModel)]="QR_CODES_CONFIG.ERROR_CORRECTION_LEVEL"
                                            name="qr_error_correction_levels" (ngModelChange)="updateQrCode()">
                                            <mat-option
                                                *ngFor="let qr_error_correction_level of QR_ERROR_CORRECTION_LEVELS_LIST"
                                                [value]="qr_error_correction_level">
                                                {{qr_error_correction_level}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form_group form_group_color">
                            <span class="labelLike">Background color</span>
                            <div class="label_section">
                                <div class="sub_section">
                                    <span>Color type</span>
                                    <mat-radio-group aria-label="Select a color type"
                                        [(ngModel)]="this.QR_CODES_CONFIG.COLOR_TYPE" (change)="updateQrCode()">
                                        <mat-radio-button [value]=1>Single color</mat-radio-button>
                                        <mat-radio-button [value]=2>Color gradient</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="QR_CODES_CONFIG.COLOR_TYPE===2" class="sub_section">
                                    <span>Gradient type</span>
                                    <mat-radio-group aria-label="Select a gradient type"
                                        [(ngModel)]="QR_CODES_CONFIG.GRADIENT_OPTION.TYPE" (change)="updateQrCode()">
                                        <mat-radio-button [value]="'radial'">Radial</mat-radio-button>
                                        <mat-radio-button [value]="'linear'">Linear</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="QR_CODES_CONFIG.COLOR_TYPE===2 && QR_CODES_CONFIG.GRADIENT_OPTION.TYPE === 'linear'"
                                    class="sub_sub_section">
                                    <span>Gradient rotation</span>
                                    <mat-slider max=360 min=0 step=1 thumbLabel="true"
                                        [(ngModel)]="QR_CODES_CONFIG.GRADIENT_OPTION.ROTATION"
                                        (ngModelChange)="updateQrCode()">
                                    </mat-slider>
                                </div>
                                <div class="sub_section">
                                    <span>Background color</span>
                                    <div class="color_preview_control">
                                        <input type="button" class="inputFormControl" 
                                            [value]="QR_CODES_CONFIG.COLOR"
                                            (click)="togglePicker('backGroundPicker')" 
                                            [ngStyle]="{'background-color': QR_CODES_CONFIG.COLOR, 'color': isLightColor(QR_CODES_CONFIG.COLOR) ? '#000000' : '#FFFFFF'}"
                                            />
                                        <input *ngIf="QR_CODES_CONFIG.COLOR_TYPE===2" type="button"
                                            class="inputFormControl"
                                            [value]="QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].COLOR"
                                            [ngStyle]="{'background-color': QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].COLOR, 'color': isLightColor(QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].COLOR) ? '#000000' : '#FFFFFF'}"
                                            (click)="togglePicker('backGroundGradientPicker')"  
                                            />

                                    </div>
                                </div>
                                <span *ngIf="toggle.backGroundPicker"
                                    [cpToggle]="true"
                                    [cpDialogDisplay]="'inline'"
                                    [(colorPicker)]="QR_CODES_CONFIG.COLOR"
                                    (colorPickerChange)="updateQrCode()" >
                                </span>
                                <span *ngIf="toggle.backGroundGradientPicker"
                                    [cpToggle]="true"
                                    [cpDialogDisplay]="'inline'"
                                    [(colorPicker)]="QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].COLOR"
                                    (colorPickerChange)="updateQrCode()" >
                                </span>
                            </div>
                        </div>
                        <div class="form_group form_group_dots">
                            <span class="labelLike">Dots option</span>
                            <div class="label_section dots_preview_control">
                                <div class="sub_section">
                                    <span>Dots style</span>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Dots style</mat-label>
                                        <mat-select [(ngModel)]="this.QR_CODES_CONFIG.DOTS.STYLE" name="dots_style"
                                            (ngModelChange)="updateQrCode()">
                                            <mat-option *ngFor="let dots_style of DOTS_STYLES_LIST"
                                                [value]="dots_style">
                                                {{dots_style}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="sub_section">
                                    <span>Color type</span>
                                    <mat-radio-group aria-label="Select a color type"
                                        [(ngModel)]="this.QR_CODES_CONFIG.DOTS.COLOR_TYPE" (change)="updateQrCode()">
                                        <mat-radio-button [value]=1>Single color</mat-radio-button>
                                        <mat-radio-button [value]=2>Color gradient</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="QR_CODES_CONFIG.DOTS.COLOR_TYPE===2" class="sub_section">
                                    <span>Gradient type</span>
                                    <mat-radio-group aria-label="Select a gradient type"
                                        [(ngModel)]="QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.TYPE"
                                        (change)="updateQrCode()">
                                        <mat-radio-button [value]="'radial'">Radial</mat-radio-button>
                                        <mat-radio-button [value]="'linear'">Linear</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="QR_CODES_CONFIG.DOTS.COLOR_TYPE===2 && QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.TYPE === 'linear'"
                                    class="sub_sub_section">
                                    <span>Gradient rotation</span>
                                    <mat-slider max=360 min=0 step=1 thumbLabel="true"
                                        [(ngModel)]="QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.ROTATION"
                                        (ngModelChange)="updateQrCode()">
                                    </mat-slider>
                                </div>
                                <div class="sub_section">
                                    <span>Dots color</span>
                                    <div class="color_preview_control">
                                        <input type="button" class="inputFormControl"
                                            [value]="QR_CODES_CONFIG.DOTS.COLOR" 
                                            [ngStyle]="{'background-color': QR_CODES_CONFIG.DOTS.COLOR, 'color': isLightColor(QR_CODES_CONFIG.DOTS.COLOR) ? '#000000' : '#FFFFFF'}"
                                            (click)="togglePicker('dotPicker')" />
                                        <input *ngIf="QR_CODES_CONFIG.DOTS.COLOR_TYPE===2" type="button"
                                            class="inputFormControl"
                                            [value]="QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR"
                                            [ngStyle]="{'background-color': QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR, 'color': isLightColor(QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR) ? '#000000' : '#FFFFFF'}"
                                            (click)="togglePicker('dotGradientPicker')" />
                                    </div>
                                </div>
                                <span *ngIf="toggle.dotPicker"
                                    [cpToggle]="true" 
                                    [cpDialogDisplay]="'inline'"
                                    [(colorPicker)]="QR_CODES_CONFIG.DOTS.COLOR" 
                                    (colorPickerChange)="updateQrCode()">
                                </span>
                                <span *ngIf="toggle.dotGradientPicker"
                                    [cpToggle]="true" 
                                    [cpDialogDisplay]="'inline'"
                                    [(colorPicker)]="QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR" 
                                    (colorPickerChange)="updateQrCode()">
                                </span>

                            </div>
                        </div>
                        <div class="form_group form_group_corner_squares">
                            <span class="labelLike">Corner squares option</span>
                            <div class="label_section corner_squares_preview_control">
                                <div class="sub_section">
                                    <span>Corner square style</span>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Corner square style</mat-label>
                                        <mat-select [(ngModel)]="this.QR_CODES_CONFIG.CORNERS_SQUARE.STYLE"
                                            name="corners_square_style" (ngModelChange)="updateQrCode()">
                                            <mat-option *ngFor="let corners_square_style of CORNERS_SQUARE_STYLES_LIST"
                                                [value]="corners_square_style">
                                                {{corners_square_style}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="sub_section">
                                    <span>Color type</span>
                                    <mat-radio-group aria-label="Select a color type"
                                        [(ngModel)]="this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR_TYPE"
                                        (change)="updateQrCode()">
                                        <mat-radio-button [value]=1>Single color</mat-radio-button>
                                        <mat-radio-button [value]=2>Color gradient</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="QR_CODES_CONFIG.CORNERS_SQUARE.COLOR_TYPE===2" class="sub_section">
                                    <span>Gradient type</span>
                                    <mat-radio-group aria-label="Select a gradient type"
                                        [(ngModel)]="QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.TYPE"
                                        (change)="updateQrCode()">
                                        <mat-radio-button [value]="'radial'">Radial</mat-radio-button>
                                        <mat-radio-button [value]="'linear'">Linear</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="QR_CODES_CONFIG.CORNERS_SQUARE.COLOR_TYPE===2 && QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.TYPE === 'linear'"
                                    class="sub_sub_section">
                                    <span>Gradient rotation</span>
                                    <mat-slider max=360 min=0 step=1 thumbLabel="true"
                                        [(ngModel)]="QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.ROTATION"
                                        (ngModelChange)="updateQrCode()">
                                    </mat-slider>
                                </div>
                                <div class="sub_section">
                                    <span>Corners square color</span>
                                    <div class="color_preview_control">
                                        <input type="button" class="inputFormControl"
                                            [value]="QR_CODES_CONFIG.CORNERS_SQUARE.COLOR"
                                            [ngStyle]="{'background-color': QR_CODES_CONFIG.CORNERS_SQUARE.COLOR, 'color': isLightColor(QR_CODES_CONFIG.CORNERS_SQUARE.COLOR) ? '#000000' : '#FFFFFF'}"
                                            (click) = "togglePicker('cornerSquarePicker')" />
                                        <input *ngIf="QR_CODES_CONFIG.CORNERS_SQUARE.COLOR_TYPE===2" type="button"
                                            class="inputFormControl"
                                            [(colorPicker)]="QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR"
                                            [value]="QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR"
                                            [ngStyle]="{'background-color': QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR, 'color': isLightColor(QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR) ? '#000000' : '#FFFFFF'}"
                                            (click) = "togglePicker('cornerSquareGradientPicker')" />
                                    </div>
                                </div>
                                <span *ngIf="toggle.cornerSquarePicker"
                                    [cpToggle]="true" 
                                    [cpDialogDisplay]="'inline'"
                                    [(colorPicker)]="QR_CODES_CONFIG.CORNERS_SQUARE.COLOR"
                                    (colorPickerChange)="updateQrCode()">
                                </span>
                                <span *ngIf="toggle.cornerSquareGradientPicker"
                                    [cpToggle]="true" 
                                    [cpDialogDisplay]="'inline'"
                                    [(colorPicker)]="QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR"
                                    (colorPickerChange)="updateQrCode()">
                                </span>
                            </div>
                        </div>
                        <div class="form_group form_group_corner_dots">
                            <span class="labelLike">Corner dots option</span>
                            <div class="label_section corner_dots_preview_control">
                                <div class="sub_section">
                                    <span>Corner dots style</span>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Corner dots style</mat-label>
                                        <mat-select [(ngModel)]="this.QR_CODES_CONFIG.CORNERS_DOTS.STYLE"
                                            name="corners_dots_style" (ngModelChange)="updateQrCode()">
                                            <mat-option *ngFor="let corners_dots_style of CORNERS_DOTS_STYLES_LIST"
                                                [value]="corners_dots_style">
                                                {{corners_dots_style}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="sub_section">
                                    <span>Color type</span>
                                    <mat-radio-group aria-label="Select a color type"
                                        [(ngModel)]="this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR_TYPE"
                                        (change)="updateQrCode()">
                                        <mat-radio-button [value]=1>Single color</mat-radio-button>
                                        <mat-radio-button [value]=2>Color gradient</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="QR_CODES_CONFIG.CORNERS_DOTS.COLOR_TYPE===2" class="sub_section">
                                    <span>Gradient type</span>
                                    <mat-radio-group aria-label="Select a gradient type"
                                        [(ngModel)]="QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.TYPE"
                                        (change)="updateQrCode()">
                                        <mat-radio-button [value]="'radial'">Radial</mat-radio-button>
                                        <mat-radio-button [value]="'linear'">Linear</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="QR_CODES_CONFIG.CORNERS_DOTS.COLOR_TYPE===2 && QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.TYPE === 'linear'"
                                    class="sub_sub_section">
                                    <span>Gradient rotation</span>
                                    <mat-slider max=360 min=0 step=1 thumbLabel="true"
                                        [(ngModel)]="QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.ROTATION"
                                        (ngModelChange)="updateQrCode()">
                                    </mat-slider>
                                </div>
                                <div class="sub_section">
                                    <span>Corners square color</span>
                                    <div class="color_preview_control">
                                        <input type="button" class="inputFormControl"
                                            [value]="QR_CODES_CONFIG.CORNERS_DOTS.COLOR"
                                            [ngStyle]="{'background-color': QR_CODES_CONFIG.CORNERS_DOTS.COLOR, 'color': isLightColor(QR_CODES_CONFIG.CORNERS_DOTS.COLOR) ? '#000000' : '#FFFFFF'}"
                                            (click)="togglePicker('cornerDotPicker')"/>
                                        <input *ngIf="QR_CODES_CONFIG.CORNERS_DOTS.COLOR_TYPE===2" type="button"
                                            class="inputFormControl"
                                            [value]="QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR"
                                            [ngStyle]="{'background-color': QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR, 'color': isLightColor(QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR) ? '#000000' : '#FFFFFF'}"
                                            (click)="togglePicker('cornerDotGradientPicker')" />
                                    </div>
                                </div>
                                <span *ngIf="toggle.cornerDotPicker"
                                    [cpToggle]="true" 
                                    [cpDialogDisplay]="'inline'"
                                    [(colorPicker)]="QR_CODES_CONFIG.CORNERS_DOTS.COLOR"
                                    (colorPickerChange)="updateQrCode()">
                                </span>
                                <span *ngIf="toggle.cornerDotGradientPicker"
                                    [cpToggle]="true" 
                                    [cpDialogDisplay]="'inline'"
                                    [(colorPicker)]="QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR"
                                    (colorPickerChange)="updateQrCode()">
                                </span>
                            </div>
                        </div>
                        <div class="form_group form_group_image">
                            <div class="image_title">
                                <span class="labelLike">Image </span><span class="aspect-indication">(Size Max.
                                    2MB)</span>
                            </div>
                            <div class="label_section">
                                <div class="sub_section">
                                    <div class="row container_upload">
                                        <div class="col-md-6 col-sm-12 container_input mb-2 px-0">
                                            <input type="file" class="custom-file-input" accept="image/*"
                                                id="IMG_FILE_QR" (change)="getFiles($event)">
                                            <label class="custom-file-label" for="imagePath">Upload</label>
                                            <div *ngIf="imageQRCodeError" class="alert alert-danger">
                                                <div>{{imageQRCodeError}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12 container_img_title d-flex align-items-center">
                                            <p *ngIf="IMG_FILE_QR" class="labelLike">{{IMG_FILE_QR.name}}</p>
                                            <p *ngIf="!IMG_FILE_QR && this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME != 'null'"
                                                class="labelLike">{{this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME}}</p>
                                            <p *ngIf="(!IMG_FILE_QR && this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME == 'null') || !IMG_FILE_QR && !this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME"
                                                class="labelLike">No File</p>
                                            <small
                                                *ngIf="IMG_FILE_QR || (this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME && this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME != 'null')"
                                                class="removeLogo" (click)="removeQRCodeImage('IMG_FILE_QR')">remove
                                                logo</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub_section">
                                    <span>Hide Background Dots</span>
                                    <mat-checkbox [(ngModel)]="QR_CODES_CONFIG.IMAGE.IS_BACK_DOTS_HIDDEN"
                                        (ngModelChange)="updateQrCode()"></mat-checkbox>
                                </div>
                                <div class="sub_section">
                                    <span>Size</span>
                                    <mat-slider max=1 min=0.1 step=0.1 thumbLabel="true"
                                        [(ngModel)]="QR_CODES_CONFIG.IMAGE.IMAGE_SIZE" (ngModelChange)="updateQrCode()">
                                    </mat-slider>
                                </div>
                                <div class="sub_section">
                                    <span>Margin</span>
                                    <mat-slider max=100 min=0 step=1 thumbLabel="true"
                                        [(ngModel)]="QR_CODES_CONFIG.IMAGE.MARGIN" (ngModelChange)="updateQrCode()">
                                    </mat-slider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container_btn_save_changes d-flex justify-content-end">
                        <button type="button" class="btn btn-global-type btn-type2" (click)="saveConfiguration()">
                            <span class="material-icons icons_button">
                                save
                            </span>
                            <span class="ml-2">Save Changes</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="container_qr_overview">
                <div class="qr_section_title">
                    <h2>QR Code Overview</h2>
                    <span class="ml-1 mt-1" matTooltip="Scan the preview before saving to ensure QR Code readability"
                        matTooltipPosition="right"
                        aria-label="Scan the preview before saving to ensure QR Code readability" style="cursor: help;">
                        <span class="material-icons">info</span>
                    </span>
                </div>
                <div class="qr_overview">
                    <div #canvas></div>
                </div>
            </div>
            <div class="container_selected_qr">
                <div class="qr_section_title d-flex align-items-end font-weight-bold">
                    <h2> Your selected QR Codes ({{this.selectedQRCodes.length}})</h2>
                </div>
                <div class="container_selected_qr_table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Infos</th>
                                <th>Last Configuration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let code of this.selectedQRCodes; let i = index">
                                <td class="align-middle">
                                    <p style="color: rgb(26, 26, 26);">
                                        {{code.loc1}}</p>
                                    <p style="color: rgb(26, 26, 26);">
                                        {{code.loc2}}</p>
                                    <p *ngIf="code.landingPage === 'externalUrl'" style="color: rgb(26, 26, 26);">
                                        {{code.externalUrl}}</p>
                                    <p *ngIf="code.landingPage !== 'externalUrl'" style="color: rgb(26, 26, 26);">
                                        {{code.landingPage}}</p>
                                </td>
                                <td>
                                    <div id="canvasLastConfig_{{i}}" #canvasLastConfig></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>