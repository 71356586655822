import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/core/services/routes.service';
import { ROUTES } from 'src/app/core/classes/Routes';
import Auth from '@aws-amplify/auth';

@Component({
  selector: 'AdminNavBar',
  templateUrl: './adminnavbar.component.html',
  styleUrls: ['./adminnavbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {

  @Input() COMPONENT: string;

  USER: string = '';

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null
  };

  constructor(
    private router: Router,
    private _routesService: RoutesService,

  ) {
    /** Get routes information then get site information*/
    this._routesService.currentRoutes.subscribe(routes => {
      this.ROUTES = routes;
      if (routes.PARAMS) {
        if(routes.PARAMS['user'] != undefined) var user = routes.PARAMS['user'];
        (user) ? this.USER = user: this.USER = '';
      }
    });
  }

  ngOnInit(): void { }

  /**Function to navigate through components */
  navigate(route: string) {
    console.log(route);
    this.router.navigate([`/admin/user/${this.USER}/${route}`]);
  }

  signOut() {
    Auth.signOut();
  }

}
