<table>
    <thead>
        <tr>
            <th class="dbox">
                    <div class="dheading_tr">Option</div>
                    <div class="dheading_bl">Week Number</div>
            </th>
            <th class="truncate" *ngFor="let ANSWER of DATA.DATA">
                <span data-toggle="tooltip" data-placement="top" title="{{ANSWER.LABEL}}">{{ANSWER.LABEL}}</span>
            </th>
            <th>Total</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let TABLE_DATA of DATA_FOR_TABLE; let i = index">
            <th><span *ngIf="!weekIsNotANumber(TABLE_DATA.WEEK)">W</span>{{TABLE_DATA.WEEK}}</th>
            <td *ngFor="let ANSWER of TABLE_DATA.DATA">{{ANSWER}}</td>
        </tr>
    </tbody>
</table>