import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * Angular form validator checking the maximum file size of a file input.
 * 
 * @param fileInputId DOM identifier of a file input
 * @param maxFileSize The maximum accepted file size
 * @returns An synchronous Angular form validator
 */
export const extensionFile = (fileInputId: string, extensions: string[]): ValidatorFn => {

  return (control: AbstractControl): ValidationErrors | null => {

    // Get "file input" HTML element
    const fileInput: HTMLInputElement = <HTMLInputElement>document.getElementById(fileInputId);
    if (!fileInput) return null;

    // Get file list linked to the "file input"
    const files: FileList = fileInput.files;
    if (!files.length) return null;

    // Check wether the selected file has the required size
    if (!extensions.includes(files.item(0).name.split('.').pop())) {
      return {
        extension: {
          'message': "",
          'refValues': [
            files.item(0).name.split('.').pop(),
            extensions
          ]
        }
      };
    }

    return null;
  }
}