/** List of mandatory params for each public routes */
export var OPTIONS_ROUTES = [
    {
        ROUTES: 'home',
        OPTIONS: [
            'site',
            //'disease'
        ]
    },
    {
        ROUTES: 'home-map',
        OPTIONS: [
            'site',
            //'disease'
        ]
    },
    {
        ROUTES: 'legal',
        OPTIONS: [
            'site',
            //'disease'
        ]
    },
    {
        ROUTES: 'cookie',
        OPTIONS: [
            'site',
            //'disease'
        ]
    },
    {
        ROUTES: 'survey',
        OPTIONS: [
            'site',
            'id'
        ]
    },
    {
        ROUTES: 'more-information',
        OPTIONS: [
            'site',
            //'disease',
            'id'
        ]
    }
]