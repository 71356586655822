import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * Angular form validator checking the file name so it does not contain special unauthorized characters.
 * 
 * @returns An synchronous Angular form validator
 */
export const fileName = (fileInputId: string): ValidatorFn => {

    return (control: AbstractControl): ValidationErrors | null => {

        // Get "file input" HTML element
        const fileInput: HTMLInputElement = <HTMLInputElement>document.getElementById(fileInputId);
        if (!fileInput) return null;

        // Get file list linked to the "file input"
        const files: FileList = fileInput.files;
        if (!files.length) return null;

        const fileName = files.item(0).name.split(".").shift();

        //authorized : digits , min mAJ,  _ -, 
        //if the file name have any char that is not part of the authorized chars
        const hasSpecialChars = /[^a-zA-Z_\-0-9\s]/.test(fileName);

        if (hasSpecialChars) console.info("File name invalid");
        return hasSpecialChars ? { unauthorizedFileName: true } : null;

    }
}