// Generated on Fri Feb 28 12:58:36 UTC 2025

export const environment = {
  production: false,
  envName: "dev",

  apiKeyGoogleMaps: "AIzaSyCxYTvKfyUN5CTRzAREsdCJGGy26e1sNio",
  gaTrackingId: "G-EGHMZ0SQWS",
  bucketS3: "sanofi-elefight-input-dev",

  apiGatewayId: "foe18ao83j",
  apiUrl: "https://dev.elefight.com/v1",
  redirectUrl: "https://dev.elefight.com/v1/qrcode/access",
  inputPath: "/public/",
  i18nPath: "https://sanofi-elefight-input-dev.s3-eu-west-1.amazonaws.com/public/assets/i18n/",

  dataPath: "COUNTRY/FRANCE/2020/",
  csvFilename: "Real_Data_2020_France",

  identityPoolId: "eu-west-1:283e074d-c85a-4316-93da-a254f3ec4a9a",
  region: "eu-west-1",
  userPoolId: "eu-west-1_pguEIFvcR",
  userPoolWebClientId: "11h8oros8apf6sn67djjmcmq64",

  cognito_idp_endpoint: ""
};
