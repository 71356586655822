<div class="main">
    <!-- Navigation -->
    <GlobalNavBar [COMPONENT]="'SiteManagement'"></GlobalNavBar>
    
    <div *ngIf="!DATA_IS_LOADED" class="component text-center my-5">
        <Message MESSAGE="Data is loading..."></Message>
    </div>

    <div *ngIf="DATA_IS_LOADED" class="col-8 mx-auto my-4">
        <h1 class="blue" style="overflow-wrap: anywhere;">SITE MANAGEMENT - {{SITE['SITE']}}</h1>
        <div class="row">
            <!-- Site information -->
            <div class="col p-0">
                <div class="card">
                    <div class="card-header">
                        <span class="card_header_middle"><strong>SITE INFORMATION</strong></span>
                        <span class="float-right">
                          <i class="material-icons blue clickable" (click)="navigateToModification('sites/sites-management',SITE['SITE'])" data-toggle="tooltip" data-placement="top" title="Modify">
                            create
                          </i>
                        </span>
                    </div>
                    <div class="card-body">
                        <p class="card-text">Site name<span class="float-right" style="overflow-wrap: anywhere;">{{SITE['SITE']}}</span></p>
                        <p class="card-text">Languages<span class="float-right">{{SITE_LANGUAGES}}</span></p>
                        <p class="card-text">Activation Status
                            <span *ngIf="SITE['OPTIONS']['ACTIVATED'] == 'true'" class="float-right">Activated</span>
                            <span *ngIf="SITE['OPTIONS']['ACTIVATED'] == 'false'" class="float-right">Deactivated</span>
                        </p>
                        <p class="card-text">Creation date<span class="float-right">{{SITE['CREATION_DATE']}}</span></p>
                        <p class="card-text">Last activation date<span class="float-right">{{SITE['LAST_ACTIVATION_DATE']}}</span></p>
                        <p class="card-text">Expiration date<span class="float-right">{{SITE['EXPIRATION_DATE']}}</span></p>
                        <p class="card-text">Map enabled<span class="float-right">{{SITE["OPTIONS"]["MAP_ENABLED"]}}</span></p>
                        <p class="card-text">Number of pages<span class="float-right">{{SITE["OPTIONS"]["MAX_PAGES"]}}</span></p>
                    </div>
                </div>
            </div>
            <!-- Site reacap -->
            <div class="col-12 col-lg-auto">
                <div class="card">
                    <div class="card-header"><strong>SITE ACTIONS</strong></div>
                    <div class="card-body">
                        <ul class="list-group border-none text-center">
                            <li class="list-group-item"><button type="button" class="btn btn-global-type btn-type2" (click)="navigate('content-management',SITE['SITE'])">Access the BackOffice</button></li>
                            <li class="list-group-item" *ngIf="SITE['OPTIONS']['ACTIVATED'] == 'false'"><button type="button" class="btn btn-global-type btn-success" (click)="activateSite(SITE['SITE'])">Activate Site</button></li>
                            <li class="list-group-item" *ngIf="SITE['OPTIONS']['ACTIVATED'] == 'true'"><button type="button" class="btn btn-global-type btn-warning" (click)="deactivateSite(SITE['SITE'])">Deactivate Site</button></li>
                            <li class="list-group-item"><button type="button" class="btn btn-global-type btn-danger" (click)="removeSite(SITE['SITE'])">Remove Site</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>