import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EDITOR_CONFIG from "../../../../../assets/constants/editorConfig";

@Component({
  selector: 'form-content-blocking-modal',
  templateUrl: './content-blocking-modal.component.html',
  styleUrls: ['./content-blocking-modal.component.scss']
})
export class ContentBlockingModalComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;

  public Editor = ClassicEditor;
  public editorConfig;

  constructor() {
    this.editorConfig = EDITOR_CONFIG;
  }

  ngOnInit(): void {
  }

}
