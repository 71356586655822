<div>
    <!-- Navigation -->
    <div class="row row_main_menu sticky-top">
        <div class="col-md-12 pl-2 pb-2 pt-2">
            <div class="row bottom_row_buttons">
                <div class="btn-toolbar ml-1 d-flex align-items-center" role="toolbar">
                    <div role="group">
                        <img src="../../../../../assets/img/elefight_icon.svg" height="40px" width="40px">
                    </div>
                    <div class="mx-4" role="group">
                        <h1 class="elefight_title_mobile m-0">E L E F I G H T BackOffice</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center mt-4 px-2">
        <h1 class="blue">Check & Pair</h1>
    </div>

    <!-- Menu to search one QR Code -->
    <div *ngIf="!generated" class="row justify-content-center mt4 px-2">
        <button type="button" class="btn btn-global-type btn-type2" (click)="activateCamera()">
            <span *ngIf="!isCameraActivated && !scannerEnabled"
                class="material-icons icons_button">qr_code_scanner</span>
            <span *ngIf="!isCameraActivated && !scannerEnabled"> SCAN</span>
            <span *ngIf="isCameraActivated && scannerEnabled"> Shut down camera</span>
        </button>

    </div>
    <!-- Menu to do a new search -->
    <div *ngIf="generated" class="row justify-content-center mt-5">
        <button class="btn btn-global-type btn-type2" type="reset" (click)="onReset()">New search</button>
    </div>

    <!--Afficher le message si la camera est bloquée  -->
    <ng-container *ngIf="hasPermission === false && isCameraActivated">
        <h2 style="text-align: center; margin-top: 50px;">You denied the camera permission, we can't scan anything
            without it. 😪</h2>
    </ng-container>

    <!--Afficher le message si l'apareil n'a pas de camera -->
    <ng-container *ngIf="hasDevices === false && !generated">
        <h2>No devices were found.</h2>
        <blockquote>
            I believe your device has no media devices attached to.
        </blockquote>
    </ng-container>

    <!-- Camera -->
    <div *ngIf="isCameraActivated" class="scanner">
        <zxing-scanner [enable]="scannerEnabled" [torch]="torchEnabled" [(device)]="currentDevice"
            (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled" [tryHarder]="tryHarder"
            (permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)"
            (torchCompatible)="onTorchCompatible($event)" previewFitMode="cover">
        </zxing-scanner>
    </div>

    <!-- Display the QR Code searched -->
    <form [formGroup]="dynamicForm" class="my-2">
        <div *ngFor="let code of qrcodes().controls; let i = index" class="list-group list-group-flush form_qrcode">
            <div class="list-group-item w-100">
                <div [formGroup]="code" class="form-row ">

                    <div class="form-group col-lg-2 col-xs-12">
                        <label class="labelLike">Date of Creation</label>
                        <p class="table_uuid_value" style="color: rgb(26, 26, 26);">
                            {{timeConverter(code.value.creationDate)}}</p>
                    </div>

                    <div class="form-group col-lg-2 col-xs-12">
                        <label class="labelLike">Location: Level 1 <br> </label>
                        <input type="text" formControlName="loc1" class="form-control" />
                    </div>

                    <div class="form-group col-lg-2 col-xs-12">
                        <label class="labelLike">Location: Level 2</label>
                        <input type="text" formControlName="loc2" class="form-control" />
                    </div>

                    <div class="form-group col-lg-2 col-xs-12">
                        <label class="labelLike">Landing Page</label>
                        <select #selectPage class="form-control" formControlName="landingPage"
                            [ngClass]="{ 'is-invalid': submitted && code.controls.landingPage.errors }"
                            (change)="landingPageChanged(selectPage.value,i)">
                            <option [value]="landing_page.Value" *ngFor="let landing_page of landing_page_array">
                                {{landing_page.Key}}</option>
                            <option value="externalUrl">External URL</option>
                        </select>
                        <div *ngIf="submitted && code.controls.landingPage.errors" class="invalid-feedback">
                            <div *ngIf="code.controls.landingPage.errors.required">Landing page is required</div>
                        </div>
                    </div>

                    <div *ngIf="code.get('externalUrl').enabled" class="form-group col-lg-2 col-xs-12">
                        <label class="labelLike">External URL</label>
                        <input type="text" formControlName="externalUrl" class="form-control" />
                    </div>
                    <div class="form-group col-lg-1 col-xs-12 d-flex justify-content-center align-self-end">
                        <span class="material-icons red clickable mr-1" (click)="removeQRCode(search.Items[i])">
                            highlight_off
                        </span>
                        <span class="material-icons blue clickable ml-1" (click)="printQRCode(search.Items[i])">
                            cloud_download
                        </span>
                    </div>
                    <div *ngIf="code.errors" class="invalid-feedback form-group col-lg-2 col-xs-12"
                        style="display: block; margin-top: auto;">
                        <td>
                            <div *ngIf="code.errors?.loc1Andloc2DuplicateError">
                                <span class="material-icons">
                                    warning
                                </span>
                                <span> This qrCode already exists</span>
                            </div>
                            <div *ngIf="code.errors?.loc1Andloc2RequiredError">
                                <span class="material-icons">
                                    warning
                                </span>
                                <span> Location : level 1 and level 2 are required</span>
                            </div>
                        </td>
                    </div>
                </div>
            </div>
            <div class="qrPreview">
                <div #canvasConfig></div>
            </div>

        </div>
    </form>

    <!-- Save button -->
    <div *ngIf="generated" class="text-center">
        <button class="btn btn-global-type btn-type2 mr-1" (click)="onSubmit()">Save</button>
    </div>
</div>


<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>