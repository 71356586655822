export var mapOfLanguages = {
    "en" : "English",
    "fr" : "French",
    "it" : "Italiano",
    "es" : "Spanish"
}

export var languages_array: Array<any> = [
    {
      short_language: "en",
      long_language: "English",
    },
    {
      short_language: "fr",
      long_language: "Français",
    },
    {
      short_language: "es",
      long_language: "Español",
    },
    {
      short_language: "it",
      long_language: "Italiano",
    }
];