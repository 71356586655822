import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateComponent } from './create/create.component';
import { ModifyComponent } from './modify/modify.component';
import { ResultsComponent } from './results/results.component';
import { MainComponent } from './main/main.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LegendComponent } from './results/legend/legend.component';
import { ChartComponent } from './results/chart/chart.component';
import { TableComponent } from './results/table/table.component';
import { NgChartjsModule } from 'ng-chartjs';

@NgModule({
  declarations: [CreateComponent, ModifyComponent, ResultsComponent, MainComponent, LegendComponent, ChartComponent, TableComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    MatSlideToggleModule,
    NgChartjsModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    MatSlideToggleModule
  ]
})
export class SurveyModule { }