import { OnInit, Component, ElementRef, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { ROUTES } from "src/app/core/classes/Routes";
import { HttpclientService } from "src/app/core/services/httpclient.service";
import { QRCodeGenerationService } from "src/app/core/services/qrcode-generation.service";
import { RoutesService } from "src/app/core/services/routes.service";
import { ShareDataService } from "src/app/core/services/share-data.service";
import { SitesService } from "src/app/core/services/sites.service";
import { FileManagementService } from "src/app/core/services/file-management.service";

@Component({
  selector: "aac-history",
  templateUrl: "./history.component.html",
  styleUrls: [
    "./history.component.scss",
    "../qr-code-management.scss",
    "../../local-back-office.scss",
    "../../../private.scss",
  ],
})
export class QRHistoryComponent implements OnInit {
  canvasConfig: ElementRef;

  @ViewChild("canvasConfig", { static: false }) set contentConfig(
    contentConfig: ElementRef
  ) {
    if (contentConfig) {
      this.canvasConfig = contentConfig;
      for (let i = 0; i < this.QR_CODE_HISTORY.length; i++) {
        this.generateCanvasForOneQRCode(i);
      }
    }
  }

  DATA_IS_LOADING: boolean = true;
  SITE_IS_DEACTIVATED: boolean = false;
  IS_ERROR: boolean = false;
  message: string = null;

  SITE: any = {};
  SITE_NAME: string = "";
  QR_UUID: string = "";
  QR_CODE_HISTORY: any = [];

  landing_page_array = [];

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };

  /**ALERT_MESSAGE object to feed the alertMessage component child */
  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  constructor(
    private _httpClientService: HttpclientService,
    private _fileManagementService: FileManagementService,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _shareData: ShareDataService,
    private _qrCodeGeneration: QRCodeGenerationService,
    private titleService: Title,
    private router: Router
  ) {
    this.titleService.setTitle("Elefight - QR Code History");
    this.message = "Data is loading...";

    /** Get routes information then get site information*/
    this._routesService.currentRoutes.subscribe((routes) => {
      this.ROUTES = routes;
      if (routes.PARAMS) {
        if (routes.PARAMS["site"] != undefined)
          var site = routes.PARAMS["site"];
        if (routes.PARAMS["uuid"] != undefined)
          var uuid = routes.PARAMS["uuid"];
        site ? (this.SITE_NAME = site.toUpperCase()) : (this.SITE_NAME = "");
        uuid ? (this.QR_UUID = uuid) : (this.QR_UUID = "");
      }
    });
  }

  ngOnInit(): void {
    this.DATA_IS_LOADING = true;
    this.landing_page_array.length = 0;
    this._sitesService
      .getSite(this.SITE_NAME)
      .then(async (value) => {
        this.SITE = JSON.parse(JSON.stringify(value));
        console.debug("SITE = ", this.SITE);
        //Check if site exist and if it's activated
        if (
          Object.keys(this.SITE).length <= 0 ||
          this.SITE.OPTIONS.ACTIVATED == "false"
        ) {
          console.error("Site doesn't exist or has been deactivated");
          this.message =
            "This site/page is no longer  \n available or has been deactivated";
          this.SITE_IS_DEACTIVATED = true;
          this.DATA_IS_LOADING = false;
          return;
        }
        this._httpClientService
          .getQRCodeHistory(this.SITE_NAME, this.QR_UUID)
          .then(async (value) => {
            if(value["Items"].length === 0){
              this.DATA_IS_LOADING = false;
              this.IS_ERROR = true;
              this.message = "No history found";
              return;
            }
            this.QR_CODE_HISTORY = this.sortData(
              JSON.parse(JSON.stringify(value["Items"][0]["HISTORY"]["L"]))
            );
            console.debug("QR_CODE_HISTORY = ", this.QR_CODE_HISTORY);
            this.getTranslation(this.SITE.SITE, this.SITE.LANGUAGES);
          })
          .catch((error) => this.callbackHTTPClient(error));
      })
      .catch((error) => this.callbackHTTPClient(error));
  }

  /**
   * Async function to consume service httpClientService and its function getTranslation
   * @param siteName Name of the site that we are consulting
   * @param language Language for which we want to get the translation
   */
  private async loadTranslation(siteName: string, language: string) {
    const translation = await this._httpClientService.getTranslation(
      siteName,
      language
    );
    return [language, translation];
  }

  /**
   * Get all translations for all languages in the order to fill the list of pages generated and activated
   * @param siteName Name of the site that we are consulting
   * @param languages Language(s) of the site
   */
  public getTranslation(siteName: string, languages: Array<string>) {
    Promise.all(languages.map((l) => this.loadTranslation(siteName, l))).then(
      (languages) => {
        let pageIDAlreadyAdded: Array<string> = [];
        let landing_page_array = [];
        /** We loop on each languages to check if at least one page is activated.
         *  We add each page activated for each languages
         *  Before to add, we check if the pages is not already added to the array with pageIDAlreadyAdded batch array
         */
        for (let i = 0; i < languages.length; i++) {
          languages[i][1]["pages"].forEach((page) => {
            if (page.activated && !pageIDAlreadyAdded.includes(page.id)) {
              landing_page_array.push({
                Key: `${page.name}`,
                Value:
                  page.id === "home"
                    ? `/home/${this.SITE_NAME.toLowerCase()}`
                    : `/more-information/${this.SITE.SITE}/${page.id}`,
              });
              pageIDAlreadyAdded.push(page.id); // We use this batch array to check if the page is already in the array or not
            }
          });
          if (i === languages.length - 1) {
            //if last loop we add pages to landing_pages_array
            this.landing_page_array = [
              ...landing_page_array,
              ...this.landing_page_array,
            ];
          }
        }
        this.DATA_IS_LOADING = false;
      }
    );
  }

  generateCanvasForOneQRCode(index: number) {
    const qrcode = this.QR_CODE_HISTORY[index]["M"];

    let text = `${environment.redirectUrl}/${this.SITE_NAME}/${qrcode.ID.S}`;
    let srcImgRenderQr = null;

    if (
      qrcode.QR_CODES_CONFIG.M.image.M.image_name &&
      qrcode.QR_CODES_CONFIG.M.image.M.image_name.S !== ""
    ) {
      if (
        qrcode.QR_CODES_CONFIG.M.image.M.image_name.S === "Logo_Elefight.png"
      ) {
        srcImgRenderQr = "../../../../../assets/img/Logo_Elefight.png";
      } else {
        srcImgRenderQr = `${environment.inputPath}SITES/${this.SITE_NAME}/IMG/${qrcode.QR_CODES_CONFIG.M.image.M.image_name.S}`;
      }
    }

    // Créer une nouvelle instance de QRCodeStyling avec ou sans image
    const current_qr_code_preview = this._qrCodeGeneration.getQRCodeData(
      text,
      srcImgRenderQr,
      100,
      qrcode.QR_CODES_CONFIG
    );

    const canvasElement = document.getElementById(
      `canvasConfig_${index}`
    ) as HTMLCanvasElement;

    const child = canvasElement?.firstChild;

    if (child) {
      canvasElement?.removeChild(child);
    }
    current_qr_code_preview.append(canvasElement);
  }

  downloadHistory() {
    this._httpClientService.getQRCodeHistoryFile(this.SITE_NAME, {qrCodes: [this.QR_UUID]}).then((response) => {
      this._fileManagementService.downloadFile(response['URL'], "QRCodeHistory.xlsx");

      this.ALERT_MESSAGE.IS_DISPLAYED = false;
      this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
      this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
      this.ALERT_MESSAGE.MESSAGE = null;

    }).catch(err => { console.log(err); });
  }

  /** Function to navigate through components */
  navigate(route: string) {
    this.router.navigate([`/admin/${this.SITE_NAME}/${route}`]);
  }

  /** Timestamp Converter */
  timeConverter(epochtime) {
    if (epochtime != "INVALID") {
      var a = new Date(parseInt(epochtime));
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours().toString();
      if (hour.length === 1) {
        hour = "0" + hour;
      }
      var min = a.getMinutes().toString();
      if (min.length === 1) {
        min = "0" + min;
      }
      var time = date + " " + month + " " + year + " " + hour + ":" + min;
      return time;
    }
    return epochtime;
  }
  /** Sort by date */
  sortData(myArray) {
    return myArray.sort((a, b) => {
      return (
        <any>new Date(parseInt(b.M.UPDATE_DATE.N)) -
        <any>new Date(parseInt(a.M.UPDATE_DATE.N))
      );
    });
  }

  findPageinLandingPageArray(pageId: string) {
    const page = this.landing_page_array.find((page) => page.Value === pageId);
    return page ? page.Key : "Page not found";
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(message: string) {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = `Error: ${message}`;
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal, sent to {@link AlertMessage} */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
    this._shareData.changeZIPCodes(null);
  }
}
