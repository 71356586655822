import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './local-back-office/content-management/content-management-main/admin.component';
import { QRUpdateComponent } from './local-back-office/qr-code-management/update/update.component';
import { QRCheckPairComponent } from './local-back-office/qr-code-management/check-pair/check-pair.component';
import { CreateComponent } from './local-back-office/survey/create/create.component';
import { MainComponent } from './local-back-office/survey/main/main.component';
import { ModifyComponent } from './local-back-office/survey/modify/modify.component';
import { ResultsComponent } from './local-back-office/survey/results/results.component';
import { LocalReportingComponent } from './local-back-office/reporting/reporting.component';
import { ListSitesComponent } from './global-back-office/sites-generator/list-sites/list-sites.component';
import { SitesGeneratorComponent } from './global-back-office/sites-generator/sites-generator/sites-generator.component';
import { SiteManagementComponent } from './global-back-office/sites-generator/site-management/site-management.component';
import { SiteModificationComponent } from './global-back-office/sites-generator/site-modification/site-modification.component';
import { GlobalReportingComponent } from './global-back-office/reporting/reporting.component';
import { AdminReportingComponent } from './admin-back-office/reporting/reporting.component';
import { ListUsersComponent } from './global-back-office/users-generator/list-users/list-users.component';
import {  UserGeneratorComponent } from './global-back-office/users-generator/user-generator/user-generator.component';
import { UserModificationComponent } from './global-back-office/users-generator/user-modification/user-modification.component';
import { QRConfigComponent } from './local-back-office/qr-code-management/config/config.component';
import { QRHistoryComponent } from './local-back-office/qr-code-management/history/history.component';


const APP_ROUTES_PRIVATE: Routes = [
  { path: 'admin/:site', redirectTo: '/admin/:site/content-management', pathMatch: 'full' },
  { path: 'admin/:site/content-management', component: AdminComponent },
  { path: 'admin/:site/qr-code-management',   redirectTo: '/admin/:site/qr-code-management/update', pathMatch: 'full' },
  { path: 'admin/:site/qr-code-management/update', component: QRUpdateComponent },
  { path: 'admin/:site/qr-code-management/check-and-pair', component: QRCheckPairComponent },
  { path: 'admin/:site/qr-code-management/config', component: QRConfigComponent },
  { path: 'admin/:site/qr-code-management/history/:uuid', component: QRHistoryComponent},
  { path: 'admin/:site/survey', component: MainComponent},
  { path: 'admin/:site/survey/create', component: CreateComponent},
  { path: 'admin/:site/survey/modify/:id', component: ModifyComponent},
  { path: 'admin/:site/survey/results/:id', component: ResultsComponent},
  { path: 'admin/:site/reporting', component: LocalReportingComponent },

  { path: 'admin/sites/sites-generator', component: SitesGeneratorComponent },
  { path: 'admin/sites/sites-management', component: ListSitesComponent },
  { path: 'admin/sites/sites-management/:site', component: SiteManagementComponent},
  { path: 'admin/sites/sites-management/:site/modify', component: SiteModificationComponent},
  { path: 'admin/sites/sites-reporting', component: GlobalReportingComponent },
  
  { path: 'admin/users-management/list-users', component: ListUsersComponent },
  { path: 'admin/users-management/user-generator', component: UserGeneratorComponent },
  { path: 'admin/users-management/user-modification/:user', component: UserModificationComponent },

  { path: 'admin/user/:user/reporting', component: AdminReportingComponent },
];

@NgModule({
  imports: [RouterModule.forChild(APP_ROUTES_PRIVATE)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }