export class LocationsDependency {

    public static readonly SELECT_VALUE = '>SELECT<';

    public static locationLinks(items: any[], reducer: LocationReducer, reporting: string): Map<string, string[]> {
        let locationLinks: Map<string, string[]> = items.reduce(reducer, new Map<string, string[]>());
        locationLinks.set(this.SELECT_VALUE, this.uniqueValuesFrom(locationLinks));
        Array.from(locationLinks.values())
            .forEach((linkedLocations: string[]) => linkedLocations.sort());
        if(reporting === 'RESULTS'){
            Array.from(locationLinks.values())
                .forEach((linkedLocations: string[]) => linkedLocations.unshift(this.SELECT_VALUE));
        }
        return locationLinks;
    }

    public static location1Reducer(accumulator: Map<string, string[]>, item: any): Map<string, string[]> {
        return locationReducer(accumulator, item, 'LOC1', 'LOC2');
    }

    public static location2Reducer(accumulator: Map<string, string[]>, item: any): Map<string, string[]> {
        return locationReducer(accumulator, item, 'LOC2', 'LOC1');
    }

    public static sourceReducer(accumulator: Map<string, string[]>, item: any): Map<string, string[]> {
        return sourceMediumReducer(accumulator, item, true);
    }

    public static mediumReducer(accumulator: Map<string, string[]>, item: any): Map<string, string[]> {
        return sourceMediumReducer(accumulator, item, false);
    }

    private static uniqueValuesFrom(locationLinks: Map<string, string[]>): string[] {
        return [...new Set<string>([].concat.apply([], Array.from(locationLinks.values())))]
    }
}

interface LocationReducer {
    (accumulator: Map<string, string[]>, item: any): Map<string, string[]>;
}

const locationReducer = (accumulator: Map<string, string[]>, item: any, locationKey: string, locationValue: string): Map<string, string[]> => {

    const key = item[locationKey].S;
    const value = item[locationValue].S;

    if (accumulator.has(key)) {
        if (!accumulator.get(key).includes(value)) {
            accumulator.get(key).push(value);
        }
    }
    else {
        accumulator.set(key, [value]);
    }

    return accumulator;
}

const sourceMediumReducer = (accumulator: Map<string, string[]>, item: any, groupBySource: boolean): Map<string, string[]> => {

    const sourceMedium: string = item.dimensions;
    const [source, medium] = sourceMedium.split(' / ');

    const key = groupBySource ? source : medium;
    const value = groupBySource ?  medium : source;

    if (accumulator.has(key)) {
        if (!accumulator.get(key).includes(value)) {
            accumulator.get(key).push(value);
        }
    }
    else {
        accumulator.set(key, [value]);
    }

    return accumulator;
}
