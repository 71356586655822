<div class="container">
    <!-- Text with disease and weeks selected -->
    <div class="row textFluContainer mt-3">
        <span class="textFlu">{{'map.title' | translate }}</span>
        <span data-toggle="popover" data-container="body" data-placement="left" data-html="true">
            <span class="material-icons yellow icons-about-data">
                info
            </span>
        </span>
        <div id="popover-content" class="d-none">
            <p>{{'map.informationAboutData' | translate }}</p>
        </div>
        <span class="ml-auto displayedDate">{{WEEK_START | date : 'dd MMM' : undefined : datePipeLocale}} - {{WEEK_END | date : 'dd MMM yyyy' : undefined : datePipeLocale}}</span>
    </div>

    <!-- Number of cases total -->
    <div class="row mt-3">
        <div class="col-9">
            <p class="pl-2 pr-2 textPotentialCases text-uppercase">
                {{'map.legend1' | translate }}
            </p>
        </div>
        <div class="col-3">
            <span class="textCasesNumber">
                {{CURRENT_CASES | number : undefined : currentLang}}
            </span>
        </div>
    </div>

    <!-- Progress bar total -->
    <div class='progress-mask'>
        <div class="progress">
            <div class="progress-bar progressCases" role="progressbar" [style.width]="CURRENT_CASES_PERCENTAGE + '%'" attr.aria-valuenow="{{CURRENT_CASES_PERCENTAGE}}" aria-valuemin="0" attr.aria-valuemax="{{TOTAL_CASES_PERCENTAGE}}"></div>
        </div>
    </div>

    <!-- Number of cases for the week selected -->
    <div class="row mt-3">
        <div class="col-9">
            <p class="pl-2 pr-2 textPotentialCases text-uppercase">
                {{'map.legend2' | translate }}
            </p>
        </div>
        <div class="col-3">
            <span class="textCasesNumber">
                {{WEEK_CASES  | number : undefined : currentLang}}
            </span>
        </div>
    </div>

    <!-- Chart bar -->
    <div id="chartWrapper" class="chartWrapper" (scroll)="scrollEvent($event)" (touchstart)="hideGif()">
        <div class="chartAreaWrapper">
          <canvas id="myChart" width="700" height="150" #mychart></canvas>
          <img id="timelineGif" class="timelineGif" src="../../../../../assets/img/Timeline.gif" alt="timelineGif"/>
        </div>
    </div>

</div>