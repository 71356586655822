<div class="main">
    <!-- Navigation -->
    <GlobalNavBar [COMPONENT]="'UserManagement'"></GlobalNavBar>

	<div *ngIf="!DATA_IS_LOADED" class="component text-center my-5">
		<Message MESSAGE="Data is loading..."></Message>
    </div>

    <!-- Button to create new user -->
    <div *ngIf="DATA_IS_LOADED" role="group" class="m-3">
        <button type="button" class="btn btn-global-type btn-type3 d-flex align-items-center" (click)="navigate('users-management/user-generator')">
            <span class="material-icons icons_button">
                add_circle
            </span>
            <span class="ml-2">Create new user</span>
        </button>
    </div>

    <!-- Main Component -->
    <div *ngIf="DATA_IS_LOADED" class="row">
        <div class="col">
            <!-- List of users -->
			<table class="table mt-3">
				<thead>
					<tr>
						<th scope="col"></th>
						<th scope="col">Username</th>
						<th scope="col">Email</th>
						<th scope="col">Status</th>
						<th scope="col">Role</th>
						<th scope="col">Sites</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let user of this.USERS">
						<th scope="row">
							<i class="material-icons blue clickable" (click)="navigate('users-management/user-modification', user.username)" data-toggle="tooltip" data-placement="top" title="View more">
								more_horiz
							</i>
						</th>
						<td>{{user.username}}</td>
						<td>{{user.email}}</td>
						<td>{{user.userStatus}}</td>
						<td>
							<ng-container *ngIf="user?.group === 'SiteAdministrators'">Site Admin</ng-container>
							<ng-container *ngIf="user?.group === 'GlobalAdministrators'">Global Admin</ng-container>
						</td>
						<div *ngIf=" user.group=='SiteAdministrators' && user.sites!==undefined ;then thenBlock else elseBlock"></div>
						<ng-template #thenBlock>
							<td>{{user.sites}}</td>
						</ng-template>
						<ng-template #elseBlock>
							<td></td>
						</ng-template>
						<th scope="row">
							<span class="material-icons red clickable" (click)=removeUser(user.username)>highlight_off</span>
						</th>
					</tr>
				</tbody>
			</table>
        </div>
    </div>
</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>
