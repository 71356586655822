import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EDITOR_CONFIG from "../../../../../assets/constants/editorConfig";

@Component({
  selector: 'form-dashboard-bis',
  templateUrl: './dashboard-bis.component.html',
  styleUrls: [
    './dashboard-bis.component.scss',
    '../content-management.scss',
    '../../local-back-office.scss',
    '../../../private.scss'
  ]
})
export class DashboardBisComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;

  public Editor = ClassicEditor;
  public editorConfig;
  
  constructor() {
    this.editorConfig = EDITOR_CONFIG;
  }

  ngOnInit(): void { }

}
