<form [formGroup]="parentForm">

    <!-- Upload and Download Data File -->
    <div class="row">
        <!-- Download Previous Data File -->
        <div class="col-3 pl-0">
            <p class="col pl-0 labelLike">Download Previous Data File</p>
            <button type="button" class="btn btn-global-type btn-type2" (click)="downloadFile('csv')">
                <span>DOWNLOAD</span>
            </button>
        </div>
        <!-- Upload New Data File -->
        <div class="col-9">
            <p><span class="labelLike">Upload New Data File </span> <span class="aspect-indication">(Size Max. 4MB)</span></p>
            <div class="custom-file">
                <input id="mapData_04_{{type}}" type="file" class="custom-file-input" style="display:none" [formControlName]="'mapData_04_' + type" accept=".csv" (change)="onFileChange($event)">
                <label class="custom-file-label" for="mapData_04_{{type}}">{{parentForm.value.mapData_04_filename_primary}}</label>
            </div>
            <div *ngIf="parentForm.get('mapData_04_primary').invalid && (parentForm.get('mapData_04_primary').dirty || parentForm.get('mapData_04_primary').touched)" class="alert alert-danger">
                <div *ngIf="parentForm.get('mapData_04_primary').errors.invalidFileFormat">
                    The file doesn't hold map data in the expected CSV format.
                </div>
                <div *ngIf="parentForm.get('mapData_04_primary').errors.invalidFileSize">
                    This file is too large. It must be less than 4MB.
                </div>
                <div *ngIf="parentForm.get('mapData_04_primary').errors.extension">
                    File format not allowed. It must be a CSV file.
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="py-3">
            <div class="col-xs-12 col-12 col-md-4 form-group">
                <p class="col pl-0 labelLike">Change campaign date</p>
                <input type="text" [formControlName]="'campaignDate_04_' + type" id="campaignDate_04_{{type}}" class="form-control" bsDaterangepicker [bsConfig]="bsConfig" [bsValue]="bsInlineRangeValue">
            </div>
        </div>
    </div>
</form>