import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  /** URL de l'API */
  apiURL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  /**
   * Get list of forms created on the current site
   * @param site Name of the site
   * @returns return promise with results
   */
  getForms(site: string){
    return this.httpClient.get(`${this.apiURL}/sites/${site.toUpperCase()}/surveys`).toPromise();
  }

  /**
   * Create a new form for the current site
   * @param site Name of the site
   * @param body Body with information of the form
   * @returns return promise with results
   */
  postForm(site: string, body) {
    return this.httpClient.post(`${this.apiURL}/sites/${site.toUpperCase()}/surveys`, body).toPromise();
  }

  /**
   * Get content of a form
   * @param site Name of the site
   * @param uuid ID of the form
   * @returns return promise with results
   */
  getForm(site: string, uuid: string) {
    return this.httpClient.get(`${this.apiURL}/sites/${site.toUpperCase()}/surveys/${uuid}`).toPromise();
  }

  /**
   * Modify an existing form
   * @param site Name of the site
   * @param uuid ID of the form
   * @param body Body with information of the form
   * @returns return promise with results
   */
  modifyForm(site: string, uuid: string, body) {
    return this.httpClient.put(`${this.apiURL}/sites/${site.toUpperCase()}/surveys/${uuid}`, body).toPromise();
  }

  /**
   * Remove an existing form
   * @param site Name of the site
   * @param uuid ID of the form
   * @returns return promise with results
   */
  removeForm(site: string, uuid: string) {
    return this.httpClient.delete(`${this.apiURL}/sites/${site.toUpperCase()}/surveys/${uuid}`).toPromise();
  }

  /**
   * Post a new answer on a form
   * @param site Name of the site
   * @param uuid ID of the form
   * @param body Body with information of the form
   * @returns return promise with results
   */
  postAnswer(site: string, uuid: string, body) {
    return this.httpClient.post(`${this.apiURL}/sites/${site.toUpperCase()}/surveys/${uuid}/answer`, body).toPromise();
  }

  /**
   * Get results for one form
   * @param site Name of the site
   * @param uuid ID of the form
   * @param queryParams filters
   * @returns return promise with results
   */
  getAnalysis(site: string, uuid: string, queryParams) {
    var URL: string;
    if (queryParams != null && queryParams.length > 0) {
      let params = new HttpParams();
      queryParams.forEach(param => {
        params = params.set(param.label, param.value);
      });
      URL = `${this.apiURL}/sites/${site.toUpperCase()}/surveys/${uuid}/analysis?` + params.toString();
    } else {
      URL = `${this.apiURL}/sites/${site.toUpperCase()}/surveys/${uuid}/analysis`;
    }

    console.log(`GET ${URL}`);
    return this.httpClient.get(URL).toPromise();
  }

  /**
   * Get exportable results for one form
   * @param site Name of the site
   * @param uuid ID of the form
   * @returns return promise with results
   */
  getExport(site: string, uuid: string) {
    return this.httpClient.get(`${this.apiURL}/sites/${site.toUpperCase()}/surveys/${uuid}/export`).toPromise();
  }
}
