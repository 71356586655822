import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { NgxCsvParser } from "ngx-csv-parser";

/**
 * Angular form validator checking the epidemic map format validity for a file input.
 * 
 * @param fileInputId DOM identifier of a file input
 * @returns An asynchronous Angular form validator
 */
 export const epidemicMapDataFile = (fileInputId: string): AsyncValidatorFn => {
  
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
  
      // Get "file input" HTML element
      const fileInput: HTMLInputElement = <HTMLInputElement>document.getElementById(fileInputId);
      if (!fileInput) return null;
  
      // Get file list linked to the "file input"
      const files: FileList = fileInput.files;
      if (!files.length) return null;
  
      // Check wether the selected file is a valid CSV file
      try {
        const fileLines: any[] =
          await new NgxCsvParser().parse(files.item(0), { header: false, delimiter: ';' }).toPromise() as any[];
        
        if (fileLines[0][0] !== 'Region_Code'
         || fileLines[0][1] !== 'Region_Name'
         || fileLines[0][2] !== 'SubRegion_Code'
         || fileLines[0][3] !== 'SubRegion_Name') {
          console.log("pb parsing csv");
          throw new Error();
        }
      }
      catch (error) {
        return {
          invalidFileFormat: {
            value: files.item(0)
          }
        };
      }
  
      return null;
    }
  }