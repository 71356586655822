import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FileManagementService } from 'src/app/core/services/file-management.service';
import { RoutesService } from 'src/app/core/services/routes.service';
import EDITOR_CONFIG from "../../../../../assets/constants/editorConfig";

@Component({
  selector: 'form-logos',
  templateUrl: './logos.component.html',
  styleUrls: [
    './logos.component.scss',
    '../content-management.scss',
    '../../local-back-office.scss',
    '../../../private.scss'
  ]
})
export class LogosComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;

  public Editor = ClassicEditor;
  public editorConfig;

  SITE_NAME: string = '';

  files: Map<string, File> = new Map;

  constructor(
    private datePipe: DatePipe,
    private _fileManagement: FileManagementService,
    private _routesService: RoutesService
  ) {
    /** Get routes information then get site information*/
    this._routesService.currentRoutes.subscribe(routes => {
      if (routes.PARAMS) {
        if (routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
      }
    });
    this.editorConfig = EDITOR_CONFIG;
  }

  ngOnInit(): void { }

  onChangeLogo(logoId: string, $event){
    const file = $event.target.files.item(0);
    const myRenamedFile = new File([file], `${file.name.split('.').shift()}_${this.datePipe.transform(new Date(), 'dd-MM-yyyy_hh-mm-ss')}.${file.name.split('.').pop()}`);
    this.parentForm.controls[`${logoId}_filename_${this.type}`].setValue(myRenamedFile.name);
    this.files.set(`${logoId}_${this.type}`, myRenamedFile);
  }

  removeLogo(inputFormControl: string, inputId: string) {
    this.parentForm.controls[inputFormControl].setValue("");
    this.parentForm.controls[inputFormControl].setErrors(null);
    this.parentForm.controls[inputId].setErrors(null);
    (<HTMLInputElement>document.getElementById(inputId)).value = "";
  }

  /**
   * When sending form, get and send files to S3 bucket
   * @param SITE_NAME name of the site
   * @param type type of the language (primary or secondary)
   * @returns returns a promise
   */
  getAndSendFiles(SITE_NAME: string, type: string) {
    return new Promise(resolve => {
      let promises = [];

      //PRIMARY CONTENT
      if (type === "primary") {
        if ((<HTMLInputElement>document.getElementById('headerLogo_10_primary')).files[0]) {
          let file = this.files.get('headerLogo_10_primary');
          let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
          promises.push(this._fileManagement.uploadFile(file, this.language, extension, SITE_NAME, file.name));
        }
        if ((<HTMLInputElement>document.getElementById('menuLogo_09_primary')).files[0]) {
          let file = this.files.get('menuLogo_09_primary');
          let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
          promises.push(this._fileManagement.uploadFile(file, this.language, extension, SITE_NAME, file.name));
        }
        if ((<HTMLInputElement>document.getElementById('secondaryLogo_09_primary')).files[0]) {
          let file = this.files.get('secondaryLogo_09_primary');
          let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
          promises.push(this._fileManagement.uploadFile(file, this.language, extension, SITE_NAME, file.name));
        }
      }

      //SECONDARY CONTENT
      if (type === "secondary") {
        if ((<HTMLInputElement>document.getElementById('headerLogo_10_secondary')).files[0]) {
          let file = this.files.get('headerLogo_10_secondary');
          let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
          promises.push(this._fileManagement.uploadFile(file, this.language, extension, SITE_NAME, file.name));
        }
        if ((<HTMLInputElement>document.getElementById('menuLogo_09_secondary')).files[0]) {
          let file = this.files.get('menuLogo_09_secondary');
          let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
          promises.push(this._fileManagement.uploadFile(file, this.language, extension, SITE_NAME, file.name));
        }
        if ((<HTMLInputElement>document.getElementById('secondaryLogo_09_secondary')).files[0]) {
          let file = this.files.get('secondaryLogo_09_secondary');
          let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
          promises.push(this._fileManagement.uploadFile(file, this.language, extension, SITE_NAME, file.name));
        }
      }

      Promise.all(promises).then((res) => {
        console.info(res);
        resolve(res);
      });
    });
  }
}
