<div class="main">

    <!-- Toast success when save site data -->
    <div class="toast toast-info text-white bg-success" role="alert" aria-live="assertive" aria-atomic="true"
        style="right: 1% !important; top: 5% !important; z-index: 10000 !important;">
        <div class="toast-header">
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body">
            Content successfuly saved
        </div>
    </div>

    <!-- Display form when data is loaded -->
    <div *ngIf="SITE_ACCESS">
        <div class="sticky-top">
            <LocalNavBar [COMPONENT]="'ContentManagementComponent'"></LocalNavBar>
            <div *ngIf="FORM_STATUS.FORM_LOADED" class="row justify-content-between align-items-end headerSelection">
                <div class="col col-md-3">
                </div>
                <!-- Languages select -->
                <div class="col col-md-9">
                    <div *ngIf="SITE.LANGUAGES.length > 1;else one_language" class="row justify-content-center my-2">
                        <div class="col col-md-6 d-flex justify-content-center">
                            <span class="material-icons-outlined">
                                chat
                            </span>
                            <select class="selectLanguages" [(ngModel)]="selectedPrimaryLanguage"
                                (change)="changePrimaryLanguage()">
                                <option *ngFor="let language of SITE.LANGUAGES" value="{{language}}">
                                    <span>{{LANGUAGES[language]['long_language']}}</span>
                                </option>
                            </select>
                        </div>
                        <div class="col col-md-6 d-flex justify-content-center">
                            <span class="material-icons-outlined">
                                chat
                            </span>
                            <select class="selectLanguages" [(ngModel)]="selectedSecondaryLanguage"
                                (change)="changeSecondaryLanguage()">
                                <option *ngFor="let language of SITE.LANGUAGES" value="{{language}}">
                                    <span>{{LANGUAGES[language]['long_language']}}</span>
                                </option>
                            </select>
                        </div>
                    </div>
                    <ng-template #one_language>
                        <div class="row justify-content-center my-2">
                            <div class="col-auto">
                                <span
                                    class="selectLanguages">{{LANGUAGES[listOfCurrentLanguages[0]]['long_language']}}</span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

        <mat-vertical-stepper *ngIf="FORM_STATUS.FORM_LOADED" linear="false" #stepper>

            <!-- Logos & Colors -->
            <mat-step>
                <ng-template matStepLabel class="component_title">Logos</ng-template>
                <div id="MoreInfo" class="row">
                    <div class="col col-md-3 first-col">
                        <img class="preview_image" src="../../../../assets/img/previews/Logos.png" />
                    </div>
                    <div class="col col-md-9 second_col">
                        <div *ngIf="SITE.LANGUAGES.length > 1;else one_language_form_more_info" class="row">
                            <div class="col col-md-6">
                                <form [formGroup]="parentForm">
                                    <form-logos #MoreInfoComponentPrimary [parentForm]="parentForm"
                                        [language]=listOfCurrentLanguages[0] [type]="'primary'"></form-logos>
                                </form>
                            </div>
                            <div class="col col-md-6">
                                <form [formGroup]="parentForm">
                                    <form-logos #MoreInfoComponentSecondary [parentForm]="parentForm"
                                        [language]=listOfCurrentLanguages[1]
                                        [type]="'secondary'"></form-logos>
                                </form>
                            </div>
                        </div>
                        <ng-template #one_language_form_more_info>
                            <div class="row">
                                <div class="col">
                                    <form [formGroup]="parentForm">
                                        <form-logos #MoreInfoComponentPrimary [parentForm]="parentForm"
                                            [language]=listOfCurrentLanguages[0]
                                            [type]="'primary'"></form-logos>
                                    </form>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
                        <div role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </mat-step>

            <!-- Multi Pages -->
            <mat-step>
                <ng-template matStepLabel class="component_title">Page Edition</ng-template>
                <div id="MultiPages" class="row">
                    <div class="col col-md-3 first-col">
                        <img class="preview_image" src="../../../../assets/img/previews/Home.jpg" />
                    </div>
                    <div class="col col-md-9 second_col">
                        <div class="row">
                            <div class="col">
                                <form [formGroup]="parentForm">
                                    <form-multi-pages [NumberMaxOfPages]="SITE.OPTIONS.MAX_PAGES"
                                        [parentForm]="parentForm" [languages]="listOfCurrentLanguages" [types]="types"
                                        [siteName]="SITE_NAME"></form-multi-pages>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button
                                matStepperPrevious>Back</button>
                        </div>
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </mat-step>

            <!-- Menu -->
            <mat-step>
                <ng-template matStepLabel class="component_title">Menu</ng-template>
                <div id="Menu" class="row" *ngIf="menuVisible">
                    <div class="col col-md-3 first-col">
                        <img class="preview_image" src="../../../../assets/img/previews/Menu.png" />
                    </div>
                    <div class="col col-md-9 second_col">
                        <div *ngIf="SITE.LANGUAGES.length > 1;else one_language_form_menu" class="row">
                            <div class="col col-md-6">
                                <form [formGroup]="parentForm">
                                    <form-menu #MenuComponentPrimary [parentForm]="parentForm"
                                        [language]=listOfCurrentLanguages[0] [type]="'primary'"></form-menu>
                                </form>
                            </div>
                            <div class="col col-md-6">
                                <form [formGroup]="parentForm">
                                    <form-menu #MenuComponentSecondary [parentForm]="parentForm"
                                        [language]=listOfCurrentLanguages[1] [type]="'secondary'"></form-menu>
                                </form>
                            </div>
                        </div>
                        <ng-template #one_language_form_menu>
                            <div class="row">
                                <div class="col">
                                    <form [formGroup]="parentForm">
                                        <form-menu #MenuComponentPrimary [parentForm]="parentForm"
                                            [language]=listOfCurrentLanguages[0] [type]="'primary'"></form-menu>
                                    </form>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button
                                matStepperPrevious>Back</button>
                        </div>
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </mat-step>


            <!-- REPORTING METADATA -->
            <mat-step>
                <ng-template matStepLabel class="component_title">Reporting</ng-template>
                <div id="Reporting" class="row">
                    <div class="col col-md-3 first-col">
                        <img class="preview_image" src="../../../../assets/img/Elefight_WithBaby_Text.svg" />
                    </div>
                    <div class="col col-md-9 second_col">
                        <div *ngIf="FORM_STATUS.FORM_LOADED" class="row my-2">
                            <div class="px-0 mx-4">
                                <p class="labelLike">Campaign Information</p>
                                <input type="text" class="form-control" placeholder="Insert campaign start date"
                                    [(ngModel)]="campaignDateAnalytics">
                                <small id="link" class="form-text text-muted">The content of this field is not published
                                    on the website.</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button
                                matStepperPrevious>Back</button>
                        </div>
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </mat-step>

            <!-- Content Blocking Modal -->
            <mat-step>
                <form [formGroup]="parentForm">
                    <ng-template matStepLabel class="component_title">
                        Content Blocking Modal
                        <mat-slide-toggle dragStopPropagation class="mat-slide-toggle-long ml-3"
                            formControlName="contentBlockingModalActivated">
                        </mat-slide-toggle>
                    </ng-template>
                </form>
                <div id="ContentBlockingModal" class="row">
                    <div class="col col-md-3 first-col">
                        <img class="preview_image" src="../../../../assets/img/Elefight_WithBaby_Text.svg" />
                    </div>
                    <div class="col col-md-9 second_col">
                        <div *ngIf="SITE.LANGUAGES.length > 1;else one_language_form_content_blocking_modal"
                            class="row">
                            <div class="col col-md-6">
                                <form [formGroup]="parentForm">
                                    <form-content-blocking-modal #ContentBlockingModalComponentPrimary
                                        [parentForm]="parentForm" [language]=listOfCurrentLanguages[0]
                                        [type]="'primary'"></form-content-blocking-modal>
                                </form>
                            </div>
                            <div class="col col-md-6">
                                <form [formGroup]="parentForm">
                                    <form-content-blocking-modal #ContentBlockingModalComponentSecondary
                                        [parentForm]="parentForm" [language]=listOfCurrentLanguages[1]
                                        [type]="'secondary'"></form-content-blocking-modal>
                                </form>
                            </div>
                        </div>
                        <ng-template #one_language_form_content_blocking_modal>
                            <div class="row">
                                <div class="col">
                                    <form [formGroup]="parentForm">
                                        <form-content-blocking-modal #ContentBlockingModalComponentPrimary
                                            [parentForm]="parentForm" [language]=listOfCurrentLanguages[0]
                                            [type]="'primary'"></form-content-blocking-modal>
                                    </form>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button
                                matStepperPrevious>Back</button>
                        </div>
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </mat-step>

            <!-- Password Modal -->
            <mat-step>
                <form [formGroup]="parentForm">
                    <ng-template matStepLabel class="component_title">
                        Password Modal
                        <mat-slide-toggle dragStopPropagation class="mat-slide-toggle-long ml-3"
                            formControlName="passwordActivated">
                        </mat-slide-toggle>
                    </ng-template>
                    <div id="PasswordModal" class="row">
                        <div class="col col-md-3 first-col">
                            <img class="preview_image" src="../../../../assets/img/Elefight_WithBaby_Text.svg" />
                        </div>
                        <div class="col col-md-9 second_col">
                            <div *ngIf="SITE.LANGUAGES.length > 1;else one_language_form_password_modal" class="row">
                                <div class="col col-md-6">
                                    <form [formGroup]="parentForm">
                                        <form-password-modal #PasswordModalComponentPrimary [parentForm]="parentForm"
                                            [language]=listOfCurrentLanguages[0]
                                            [type]="'primary'"></form-password-modal>
                                    </form>
                                </div>
                                <div class="col col-md-6">
                                    <form [formGroup]="parentForm">
                                        <form-password-modal #PasswordModalComponentSecondary [parentForm]="parentForm"
                                            [language]=listOfCurrentLanguages[1]
                                            [type]="'secondary'"></form-password-modal>
                                    </form>
                                </div>
                            </div>
                            <ng-template #one_language_form_password_modal>
                                <div class="row">
                                    <div class="col">
                                        <form [formGroup]="parentForm">
                                            <form-password-modal #PasswordModalComponentPrimary
                                                [parentForm]="parentForm" [language]=listOfCurrentLanguages[0]
                                                [type]="'primary'"></form-password-modal>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-center">
                    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button
                                matStepperPrevious>Back</button>
                        </div>
                        <div *ngIf="isMapEnabled" class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </mat-step>

            <!-- Map -->
            <mat-step *ngIf="isMapEnabled">
                <form [formGroup]="parentForm">
                    <ng-template matStepLabel class="component_title">Epidemic Map <mat-slide-toggle dragStopPropagation
                            class="mat-slide-toggle-long ml-3"
                            formControlName="activateDashboardCheck_04_primary"></mat-slide-toggle>
                    </ng-template>
                </form>
                <div id="EpidemicMap" class="row">
                    <div class="col col-md-3 first-col">
                        <img class="preview_image" src="../../../../assets/img/previews/Map.png" />
                    </div>
                    <div class="col col-md-9 second_col">
                        <div class="row">
                            <div class="col">
                                <form [formGroup]="parentForm">
                                    <form-dashboard [parentForm]="parentForm" [language]=listOfCurrentLanguages[0]
                                        [type]="'primary'"></form-dashboard>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="SITE.LANGUAGES.length > 1;else one_language_form_dashboard_bis" class="row">
                            <div class="col col-md-6">
                                <form [formGroup]="parentForm">
                                    <form-dashboard-bis [parentForm]="parentForm" [language]=listOfCurrentLanguages[0]
                                        [type]="'primary'"></form-dashboard-bis>
                                </form>
                            </div>
                            <div class="col col-md-6">
                                <form [formGroup]="parentForm">
                                    <form-dashboard-bis [parentForm]="parentForm" [language]=listOfCurrentLanguages[1]
                                        [type]="'secondary'"></form-dashboard-bis>
                                </form>
                            </div>
                        </div>
                        <ng-template #one_language_form_dashboard_bis>
                            <div class="row">
                                <div class="col">
                                    <form [formGroup]="parentForm">
                                        <form-dashboard-bis [parentForm]="parentForm"
                                            [language]=listOfCurrentLanguages[0]
                                            [type]="'primary'"></form-dashboard-bis>
                                    </form>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
                        <div class="mx-2" role="group">
                            <button class="btn btn-global-type btn-type1 px-4" mat-button
                                matStepperPrevious>Back</button>
                        </div>
                    </div>
                </div>
            </mat-step>

        </mat-vertical-stepper>

        <!-- Button to save form -->
        <div class="row">
            <div class="col">
                <!-- Save and Publish buttons -->
                <div class="row bottom_row_buttons d-flex justify-content-center">
                    <div *ngIf="parentForm" class="btn-toolbar ml-3 mr-2 mt-2 d-flex align-items-center" role="toolbar">
                        <div *ngIf="parentForm.invalid" class="btn-group mr-2" role="group">
                            <label class="text-danger">Some files are invalid</label>
                        </div>
                        <div class="btn-group mr-2 mb-2" role="group">
                            <button type="button" class="btn btn-global-type btn-type2" (click)="onDownloadAllData()"
                                [disabled]="!FORM_STATUS.FORM_LOADED || parentForm.invalid">
                                <span class="material-icons icons_button" style="width: 18px; height: 18px">
                                    save_alt
                                </span>
                                <span class="ml-2">Download all content</span>
                            </button>
                        </div>
                        <div class="btn-group mr-2 mb-2" role="group">
                            <button type="button" class="btn btn-global-type btn-type2" (click)="save()"
                                [disabled]="!FORM_STATUS.FORM_LOADED || parentForm.invalid">
                                <span class="material-icons icons_button" style="width: 18px; height: 18px">
                                    save
                                </span>
                                <span class="ml-2">Save data</span>
                            </button>
                        </div>
                        <div class="btn-group mb-2" role="group">
                            <button type="button" class="btn btn-global-type btn-type2" (click)="save('preview')"
                                [disabled]="!FORM_STATUS.FORM_LOADED || parentForm.invalid">
                                <span class="material-icons icons_button" style="width: 18px; height: 18px">
                                    publish
                                </span>
                                <span class="ml-2">Publish</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Display message when data is loading -->
    <div *ngIf="!FORM_STATUS.FORM_LOADED || !SITE_ACCESS" class="component text-center my-5">
        <Message [MESSAGE]="message"></Message>
    </div>

</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>

<!--Display Modal Preview-->
<div class="modal fade" id="modalPreview" tabindex="-1" role="dialog" data-backdrop="static"
    aria-labelledby="modalPreview" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <span class="modal-title blue" style="font-weight: 500;">PREVIEW OF {{SITE_NAME}}</span>
            </div>

            <div class="modal-body">
                <iframe width="100%" height="100%" frameBorder="0" [src]="this.previewSafeUrl | safeUrl"></iframe>
            </div>

            <div class="modal-footer">
                <div class="btn-group mb-2" role="group">
                    <button type="button" class="btn btn-global-type btn-type1" (click)="onClosePreview()">
                        <span class="material-icons icons_button">
                            arrow_back_ios
                        </span>
                        <span class="ml-2">Back</span>
                    </button>
                </div>


                <div class="btn-group mb-2" role="group">
                    <button type="button" class="btn btn-global-type btn-type2" (click)="onPublish()"
                        [disabled]="!FORM_STATUS.FORM_LOADED || parentForm.invalid">
                        <span class="material-icons icons_button">
                            publish
                        </span>
                        <span class="ml-2">Save & Publish</span>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>