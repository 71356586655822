import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';

@Component({
  selector: 'GlobalNavBar',
  templateUrl: './globalnavbar.component.html',
  styleUrls: ['./globalnavbar.component.scss']
})
export class GlobalNavbarComponent implements OnInit {

  @Input() COMPONENT: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void { }

  /**Function to navigate through components */
  navigate(route:string){
    this.router.navigate([`/admin/${route}`]);
  }

  signOut(){
    Auth.signOut();
  }
}
