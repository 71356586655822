import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'Message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input() MESSAGE: string;

  constructor() {
  }

  ngOnInit(): void { }

}
