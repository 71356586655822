import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdminReportingComponent } from './reporting/reporting.component';

@NgModule({
  declarations: [AdminReportingComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, ReactiveFormsModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule
  ]
})
export class AdminBackOfficeModule { }
