import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from '../public/main-page/main-page.component'
import { LegaleInformationComponent } from './legale-information/legale-information.component';
import { CookieInformationComponent } from './cookie-information/cookie-information.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SurveyComponent } from './survey/survey.component';
import { MultiPagesComponent } from './multi-pages/multi-pages.component';

const APP_ROUTES_PUBLIC: Routes = [
  { path: 'home/:site', component: MainPageComponent },
  { path: 'home/:site/:disease', component: MainPageComponent },
  { path: 'home-map/:site', component: DashboardComponent },
  { path: 'home-map/:site/:disease', component: DashboardComponent },
  { path: 'legal/:site', component: LegaleInformationComponent },
  { path: 'legal/:site/', component: LegaleInformationComponent },
  { path: 'cookie/:site', component: CookieInformationComponent },
  { path: 'cookie/:site/', component: CookieInformationComponent },
  { path: 'survey/:site/:id', component: SurveyComponent},
  { path: 'more-information/:site/:id', component: MultiPagesComponent },

  // { path: '/:site/home', component: MainPageComponent},
  // { path: '/:site/home/:disease', component: MainPageComponent},
  // { path: '/:site/home-map', component: DashboardComponent},
  // { path: '/:site/home-map/:disease', component: DashboardComponent},
  // { path: '/:site/legal', component: LegaleInformationComponent},
  // { path: '/:site/legal/:disease', component: LegaleInformationComponent},
  // { path: '/:site/cookie', component: CookieInformationComponent},
  // { path: '/:site/cookie/:disease', component: CookieInformationComponent},
  // { path: '/:site/survey/:id', component: SurveyComponent},
  // { path: '/:site/more-information/:disease/:id', component: MultiPagesComponent}
  // { path: '/:site/:page-name/:disease', component: MultiPagesComponent}
];

@NgModule({
  imports: [RouterModule.forChild(APP_ROUTES_PUBLIC)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
