import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContentManagementModule } from './content-management/content-management.module';
import { LocalReportingComponent } from './reporting/reporting.component';
import { SurveyModule } from './survey/survey.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  declarations: [LocalReportingComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    CKEditorModule,
    ZXingScannerModule,
    ColorPickerModule,
    SurveyModule,
    ContentManagementModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    CKEditorModule,
    ZXingScannerModule,
    ColorPickerModule
  ]
})
export class LocalBackOfficeModule { }
