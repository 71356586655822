<!-- Map is activated -->
<div *ngIf="DASHBOARD_IS_ACTIVATED;else is_deactivated">
    <MapComponent [DATA]="DATA" [SITE]="SITE"></MapComponent>
    <ChartComponent *ngIf="DATA_STATE === 'READY'" [DATA]="DATA" [SITE]="SITE" [campaignDate]="campaignDate"></ChartComponent>
    <!-- Map is unavailable -->
    <div *ngIf="DATA_STATE === 'UNAVAILABLE'" class="container">
        <div class="row mt-3 justify-content-center">
            <h1>No epidemic data available</h1>
        </div>
    </div>
</div>

<!-- Call to action -->
<call-to-action [colorHeader]="colorHeader" [CTA_LINK]="CTA_LINK" [CTA_TYPE]="CTA_TYPE" [CTA_LIST]="CTA_LIST" [CTA_LABEL]="CTA_LABEL" [ROUTES]="ROUTES" [language]="currentLanguage" page="home"></call-to-action>

<!-- Map is deactivated -->
<ng-template #is_deactivated>
    <h1>Map is deactivated</h1>
</ng-template>