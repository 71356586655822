import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ROUTES } from 'src/app/core/classes/Routes';
import { BeginsWithPipe } from 'src/app/shared/pipes/begins-with.pipe';
import { OPTIONS_ROUTES } from 'src/app/core/classes/RoutesOptions';
import { environment } from 'src/environments/environment';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null
  };

  //Array to separate Public and Private routes
  arrayRoutesPublic = ["/home", "/legal", "/cookie", "/survey", "/more-information"];
  arrayRoutesPrivate = ["/admin"];

  private routes = new BehaviorSubject(this.ROUTES);
  currentRoutes = this.routes.asObservable();

  constructor(
    private router: Router,
    private _beginsWithPipe: BeginsWithPipe
  ) {
    this.router.events.subscribe(event => {
      this.ROUTES = {
        IS_PUBLIC: false,
        IS_PRIVATE: false,
        IS_GLOBAL: false,
        ROUTE: null,
        PARAMS: null,
        MANDATORY_PARAMS: [],
        QUERY_PARAMS: null
      };
      if (event instanceof RoutesRecognized) {
        console.info("Event RoutesRecognized: ", event);
        this.ROUTES.PARAMS = event.state.root.children[0].params;
        this.ROUTES.QUERY_PARAMS = event.state.root.children[0].queryParams;

        //We set sessionStorage for campaign, source and medium
        if (this.ROUTES.QUERY_PARAMS['utm_source']) sessionStorage.setItem("GA_SOURCE", this.ROUTES.QUERY_PARAMS['utm_source']);
        if (this.ROUTES.QUERY_PARAMS['utm_medium']) sessionStorage.setItem("GA_MEDIUM", this.ROUTES.QUERY_PARAMS['utm_medium']);
        if (this.ROUTES.QUERY_PARAMS['utm_campaign']) sessionStorage.setItem("GA_CAMPAIGN", this.ROUTES.QUERY_PARAMS['utm_campaign']);

        this.ROUTES.ROUTE = event.urlAfterRedirects;
        console.log(this.ROUTES);
        //We check if routes is a public routes
        for (let i = 0; i < this.arrayRoutesPublic.length; i++) {
          if (this._beginsWithPipe.transform(event.urlAfterRedirects, this.arrayRoutesPublic[i])) {
            this.ROUTES.IS_PUBLIC = true;
            var current_route = event.state.root.children[0].url[0].path;
            console.log(current_route);
            var current_options_route = OPTIONS_ROUTES
              .filter(option_route => option_route.ROUTES === current_route)
              .map(obj => obj.OPTIONS);
            current_options_route[0].forEach(option => {
              this.ROUTES.MANDATORY_PARAMS.push(option);
            });
          }
        }
        //We check if routes is a private routes
        for (let i = 0; i < this.arrayRoutesPrivate.length; i++) {
          if (this._beginsWithPipe.transform(event.urlAfterRedirects, this.arrayRoutesPrivate[i])) {
            this.ROUTES.IS_PRIVATE = true;
          }
        }
        //If routes is not public nor private, we set it as not found
        if (!this.ROUTES.IS_PUBLIC && !this.ROUTES.IS_PRIVATE)
          this.ROUTES.IS_GLOBAL = true;
        this.sendRoutesToGoogleAnalytics(event.urlAfterRedirects);
        this.changeRoutes(this.ROUTES);
      }
    });
  }

  /**
   * Emit a new value for routes
   * @param routes value of the new route
   */
  changeRoutes(routes: ROUTES) {
    this.routes.next(routes);
  }

  /** Add Google Analytics info */
  sendRoutesToGoogleAnalytics(urlAfterRedirects) {
    gtag('config', environment.gaTrackingId, { 'page_path': urlAfterRedirects });
  }
}