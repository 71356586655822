import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  /** URL de l'API */
  apiURL = environment.apiUrl;
  API_path = 'users';

  constructor(private httpClient: HttpClient) { }

  /**
   * Get list of users
   * @returns return a promise with JSON
   */
  getUsers() {
    console.debug(`GET ${this.apiURL}/${this.API_path}`);
    return this.httpClient.get(`${this.apiURL}/${this.API_path}`).toPromise();
  }


  /**
   * Get information about one user
   * @param user Name of the user
   * @returns return a promise with JSON
   */
  getUser(user: string) {
    console.debug(`GET ${this.apiURL}/${this.API_path}/${user}`);
    return this.httpClient.get(`${this.apiURL}/${this.API_path}/${user}`).toPromise();
  }


  /**
   * Create a new user
   * @param body body with content of the new user
   * @returns return a promise with achievement or not of the API call
   */
  postUser(body) {
    console.debug(`POST ${this.apiURL}/${this.API_path}`);
    return this.httpClient.post(`${this.apiURL}/${this.API_path}`, body).toPromise();
  }


  /**
   * Modify an existing user
   * @param body body with content of the user
   * @returns return a promise with achievement or not of the API call
   */
  modifyUser(body) {
    console.debug(`POST ${this.apiURL}/${this.API_path}/${body.username}/modify`);
    return this.httpClient.post(`${this.apiURL}/${this.API_path}/${body.username}/modify`, body).toPromise();
  }

  /**
   * Remove an existing user
   * @param user Name of the user
   * @returns return a promise with achievement or not of the API call
   */
  deleteUser(user: string) {
    console.debug(`DELETE ${this.apiURL}/${this.API_path}/${user}/resetpassword`);
    return this.httpClient.delete(`${this.apiURL}/${this.API_path}/${user}`).toPromise();
  }


  /**
   * reset the temporary password
   * @param user Name of the user
   * @returns return a promise with achievement or not of the API call
   */
  resendTemporaryPassword(user: string) {
    console.debug(`POST ${this.apiURL}/${this.API_path}/${user}/resendtemporarypassword`);
    return this.httpClient.post(`${this.apiURL}/${this.API_path}/${user}/resendtemporarypassword `, null).toPromise();
  }

  /**
   * reset the  password
   * @param user Name of the user
   * @returns return a promise with achievement or not of the API call
   */
  passwordForget(user: string, temporaryPass: string) {
    console.debug(`POST ${this.apiURL}/${this.API_path}/${user}/resetpassword`);

    //construct the custom headers
    let head = new HttpHeaders({ 'password': temporaryPass });
    let options = { headers: head };

    return this.httpClient.post(`${this.apiURL}/${this.API_path}/${user}/resetpassword`, null, options).toPromise();
  }
}