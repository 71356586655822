import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms'
import { AdminFormService } from 'src/app/core/services/admin-form.service';
import { MenuComponent } from '../menu/menu.component';
import { LogosComponent } from '../logos/logos.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MainMultiPagesComponent } from '../multi-pages/multi-pages-main/multi-pages-main.component';
import { Title } from '@angular/platform-browser';
import { RoutesService } from 'src/app/core/services/routes.service';
import { ROUTES } from 'src/app/core/classes/Routes';
import { SitesService } from 'src/app/core/services/sites.service';
import { HttpclientService } from 'src/app/core/services/httpclient.service';
import { FileManagementService } from 'src/app/core/services/file-management.service';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserLoginService } from 'src/app/core/services/user-login.service';
import { listOfImagesExtensions } from 'src/app/core/classes/ImagesExtensions';

declare var $: any;

@Component({
    selector: 'aac-admin',
    templateUrl: './admin.component.html',
    styleUrls: [
        './admin.component.scss',
        '../content-management.scss',
        '../../local-back-office.scss',
        '../../../private.scss'
    ]
})
export class AdminComponent implements OnInit, AfterViewChecked {

    @ViewChild("MoreInfoComponentPrimary") MoreInfoComponentPrimary: LogosComponent;
    @ViewChild("MoreInfoComponentSecondary") MoreInfoComponentSecondary: LogosComponent;
    @ViewChild("MenuComponentPrimary") MenuComponentPrimary: MenuComponent;
    @ViewChild("MenuComponentSecondary") MenuComponentSecondary: MenuComponent;
    @ViewChild(MainMultiPagesComponent) MultiPagesComponent: MainMultiPagesComponent;
    @ViewChild(DashboardComponent) DashboardComponent: DashboardComponent;
    @Output() pageIdChanged: EventEmitter<any> = new EventEmitter();

    message: string = null;
    imgpath: any;

    campaignDateAnalytics: string = '';

    ROUTES: ROUTES = {
        IS_PUBLIC: false,
        IS_PRIVATE: false,
        IS_GLOBAL: false,
        ROUTE: null,
        PARAMS: null,
        MANDATORY_PARAMS: [],
        QUERY_PARAMS: null
    };
    SITE: any = {};
    SITE_NAME: string = '';
    SITE_IS_DEACTIVATED: boolean = false;

    PREVIEW_DISPLAYED: boolean = true;

    parentForm: FormGroup;
    FORM_STATUS = {
        FORM_LOADED: false,
        FORM_SAVE: false,
    }

    SITE_ACCESS: boolean = false;

    isMapEnabled: boolean = false;

    listOfCurrentLanguages: string[] = [];
    selectedSecondaryLanguage: string = null;
    selectedPrimaryLanguage: string = null;

    types: string[] = [];

    LANGUAGES = {
        "en": { "long_language": "English" },
        "fr": { "long_language": "Français" },
        "es": { "long_language": "Español" },
        "it": { "long_language": "Italiano" }
    }

    ALERT_MESSAGE: ALERT_MESSAGE = {
        TYPE_OF_MESSAGE: null,
        TYPE_OF_ACTION: null,
        MESSAGE: null,
        IS_DISPLAYED: null
    };

    formReady = false;
    previewSafeUrl: string;

    constructor(
        private router: Router,
        private titleService: Title,
        private _adminFormService: AdminFormService,
        private _fileManagementService: FileManagementService,
        private _routesService: RoutesService,
        private _sitesService: SitesService,
        private _httpService: HttpclientService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private _userLoginService: UserLoginService
    ) {
        this.titleService.setTitle("Elefight - Content Management");

        this.message = "Data is loading...";

        //Get routes information then get site information
        this._routesService.currentRoutes.subscribe(routes => {
            this.ROUTES = routes;
            console.debug(routes);
            if (routes.PARAMS) {
                if (routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
                (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
            }
        });
    }

    ngOnInit(): void {
        //check if the account is allowed on this site
        this._userLoginService.getTokenJwtClaims().subscribe((x) => {
            if (x["cognito:groups"][0] == "GlobalAdministrators") {
                this.SITE_ACCESS = true;
            } else if (x["cognito:groups"][0] == "SiteAdministrators" && x["custom:sites"].split(',').includes(this.SITE_NAME)) {
                this.SITE_ACCESS = true;
            }
        });
        this.getData();
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Async function to consume service httpClientService and its function getTranslation
     * @param siteName Name of the site that we are consulting
     * @param language Language for which we want to get the translation
     */
    private async loadTranslation(siteName: string, language: string) {
        const translation = await this._httpService.getTranslation(siteName, language);
        return [language, translation];
    }

    /**
     * Get data to build Reactive Form
     */
    getData() {
        let promisesGetSite = [];
        promisesGetSite.push(this._sitesService.getSite(this.SITE_NAME));

        Promise.all(promisesGetSite).then((res) => {
            this.SITE = JSON.parse(JSON.stringify(res[0]));
            console.log("Site:", this.SITE);
            //Check if site exist and if it's activated
            if (Object.keys(this.SITE).length <= 0 || this.SITE.OPTIONS.ACTIVATED == 'false') {
                console.error("Site doesn't exist or has been deactivated");
                this.message = "This site/page is no longer  \n available or has been deactivated";
                this.SITE_IS_DEACTIVATED = true;
                return;
            }
            this.isMapEnabled = this.SITE.OPTIONS.MAP_ENABLED == 'true' ? true : false;
            this.campaignDateAnalytics = this.SITE.MISC.CAMPAIGN_DATE_ANALYTICS;
            //Store primary language
            this.listOfCurrentLanguages.push(this.SITE.LANGUAGES[0]);
            this.types.push("primary");
            //Store secondary language - if present
            if (this.SITE.LANGUAGES.length > 1) {
                this.listOfCurrentLanguages.push(this.SITE.LANGUAGES[1]);
                this.selectedPrimaryLanguage = this.SITE.LANGUAGES[0];
                this.selectedSecondaryLanguage = this.SITE.LANGUAGES[1];
                this.types.push("secondary");
            }

            //Execute function to get data on server side
            this._adminFormService.getPreviewTranslation(this.SITE_NAME, this.SITE.LANGUAGES);

            //Listen when translation changes
            this._adminFormService.currentData.subscribe(receiveData => {
                if (receiveData) {
                    this._adminFormService.currentTranslationData.subscribe(data => {
                        console.log("Current languages: ", this.listOfCurrentLanguages);
                        this.parentForm = this._adminFormService.initForm(this.parentForm, this.listOfCurrentLanguages[0], this.listOfCurrentLanguages[1]);
                        this.FORM_STATUS.FORM_LOADED = receiveData; //moved here for async reasons 
                    });
                }
                this.FORM_STATUS.FORM_SAVE = this.SITE.OPTIONS.PUBLISHED == 'true' ? false : true;
                //this.refreshMenu();
            });


        });
    }

    /**
     * On Download all data function
     */
    onDownloadAllData() {
        var zip = new JSZip();
        var urls = [];
        var filenames: string[] = [];
        var languages = [];

        //We store all translations for all languages
        Promise.all(this.SITE.LANGUAGES.map(l => this.loadTranslation(this.SITE_NAME, l))).then(success => {
            //We loop on all languages
            success.forEach(language => {
                console.log(language);
                languages.push(language[1]);
                filenames = findGeneralLink(language[1]['graphics'], filenames);
                if (language[1]['map']['activated']) {
                    filenames = findDashboardLink(language[1]['map']['dataPath'], filenames);
                }
                filenames = findPagesLink(language[1]['pages'], filenames);
            });
            console.log("List of files before filters : ", filenames);

            filenames = filterArray(filenames);
            filenames = removeEmptyElements(filenames);
            urls = generateUrls(filenames, urls);

            console.log("List of files after filters", filenames);
            console.log("List of urls after filters", urls);

            //For each urls, we create a blop and store it in a zip
            urls.forEach((url) => {
                const myHeaders = new Headers();

                const myRequest = new Request(url.url, {
                    method: 'GET',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default',
                });

                const blobPromise = fetch(myRequest).then(r => {
                    if (!r.ok) {
                        throw new Error('Network response was not OK');
                    }
                    if (r.status === 200) return r.blob()
                    return Promise.reject(new Error(r.statusText))
                });
                zip.file(url.filename, blobPromise);
            });

            //For each language, we create a blob and store it in a zip
            for (let i = 0; i < languages.length; i++) {
                const blob = new Blob([JSON.stringify(languages[i])], {
                    type: "application/json;charset=utf-8"
                });
                const url = URL.createObjectURL(blob);
                const blobPromise = fetch(url).then(r => {
                    if (r.status === 200) return r.blob()
                    return Promise.reject(new Error(r.statusText))
                });
                zip.file(languages[i].LANGUAGE + ".json", blobPromise);
            }

            //We generate the zip file
            zip.generateAsync({ type: "blob" })
                .then(blob => FileSaver.saveAs(blob, `Content_${this.SITE_NAME}`))
                .catch(e => console.error(e));

        });

        //Find general link (header, company, menu logos)
        const findGeneralLink = (data, filenames) => {
            Object.values(data).forEach(filename => {
                filenames.push(filename);
            })
            return filenames;
        };

        //Find map csv link
        const findDashboardLink = (data, filenames) => {
            filenames.push(data['FILE_NAME']);
            return filenames;
        };

        //Find links in pages (pages content and call to action)
        const findPagesLink = (data, filenames) => {
            data.forEach(page => {
                if (page['activated']) {
                    switch (page['type']) {
                        case 'FULL_IMAGE':
                            filenames.push(page['pageImageFields']['imagePath']);
                            break;
                        case 'IMAGE_AND_TEXT':
                            page['pageCompositeFields'].forEach(line => {
                                filenames.push(line['imagePath']);
                            });
                            break;
                        default:
                            break;
                    }
                    switch (page['callToAction']['type']) {
                        case 'FILE':
                            filenames.push(page['callToAction']['callToActionDocument']['documentPath']);
                            break;
                        default:
                            break;
                    }

                }
            });
            return filenames;
        };

        //Remove empty element in array
        const removeEmptyElements = (array) => {
            return array.filter((element) => element != '' && element != null && element != null);
        };

        //Filter duplicate
        const filterArray = (array) => {
            return array.filter(function (ele, pos) {
                return array.indexOf(ele) == pos;
            });
        };

        //Generate array of urls from array of filenames
        const generateUrls = (filenames, urls) => {
            filenames.forEach(filename => {
                urls.push({ 'filename': filename, 'url': createUrlFromFilename(filename) });
            });
            return urls;
        };

        //Create an url from a file name
        const createUrlFromFilename = (filename) => {
            const inputPath = `${environment.inputPath}SITES/${this.SITE_NAME}` + "-PREVIEW";
            if (filename != null && filename != 'null') {

                console.log("fichier", filename.substr(filename.lastIndexOf('.') + 1));
                if (listOfImagesExtensions.includes(filename.substr(filename.lastIndexOf('.') + 1))) {
                    return `${inputPath}/IMG/${filename}`
                } else if (filename.substr(filename.lastIndexOf('.') + 1) === 'pdf') {
                    return `${inputPath}/DATA/pdf/${filename}`;
                } else if (filename.substr(filename.lastIndexOf('.') + 1) === 'csv') {
                    return `${inputPath}/DATA/csv/${filename}`;
                } else return;
            }
        };
    }

    /**
     * Save site data and display preview or publish depending on callback given ("preview" / "display")
     */
    save(callback?: string) {
        console.log("SAVE : ", this.parentForm.value)
        const SITE_NAME = `${this.SITE_NAME}-PREVIEW`;
        this._adminFormService.sendForm(this.parentForm, `${SITE_NAME}`, this.listOfCurrentLanguages, this.SITE.LANGUAGES)
            .then((value) => {
                console.log(value);
                if (this.listOfCurrentLanguages[0]) {
                    this.MoreInfoComponentPrimary.getAndSendFiles(SITE_NAME, 'primary')
                        .then(value => {
                            console.log(value);
                            this.MultiPagesComponent.getAndSendFiles(SITE_NAME, 'primary')
                                .then(value => {
                                    console.log(value);
                                    if (this.isMapEnabled) {
                                        this.DashboardComponent.getAndSendFiles(SITE_NAME)
                                            .then(value => {
                                                console.log(value);
                                            });
                                    }
                                    this._fileManagementService.previewModification(`${SITE_NAME}`, { campaignDateAnalytics: this.campaignDateAnalytics, contentBlockingModalActivated: this.parentForm.value.contentBlockingModalActivated, passwordActivated: this.parentForm.value.passwordActivated })
                                        .then(() => {
                                            $(".toast-info").toast({ autohide: true, delay: 5000 });
                                            $('.toast-info').toast('show');
                                            this.FORM_STATUS.FORM_SAVE = true;
                                            if (callback == "preview") {
                                                this.displayPreview();
                                            } else if (callback == "publish") {
                                                this.publish();
                                            }
                                        });
                                })
                        });
                }
                if (this.listOfCurrentLanguages[1]) {
                    this.MoreInfoComponentSecondary.getAndSendFiles(SITE_NAME, 'secondary')
                        .then(value => {
                            console.log(value);
                            this.MultiPagesComponent.getAndSendFiles(SITE_NAME, 'secondary')
                                .then(value => {
                                    console.log(value);
                                    if (this.isMapEnabled) {
                                        this.DashboardComponent.getAndSendFiles(SITE_NAME)
                                            .then(value => {
                                                console.log(value);
                                            });
                                    }
                                    this._fileManagementService.previewModification(`${SITE_NAME}`, { campaignDateAnalytics: this.campaignDateAnalytics, contentBlockingModalActivated: this.parentForm.value.contentBlockingModalActivated, passwordActivated: this.parentForm.value.passwordActivated })
                                        .then(() => {
                                            this.FORM_STATUS.FORM_SAVE = true;
                                            $(".toast-info").toast({ autohide: true, delay: 5000 });
                                            $('.toast-info').toast('show');
                                            if (callback == "preview") {
                                                this.displayPreview();
                                            } else if (callback == "publish") {
                                                this.publish();
                                            }
                                        });
                                })
                        });
                }
            }, error => {
                console.error("Can't save : " + error);
            });
    }

    onClosePreview() {
        $('#modalPreview').modal('hide');
    }

    /**
     * Display site preview (open modal with iframe inside containing preview url)
     */
    displayPreview() {
        const url = `/home/${this.SITE_NAME}-PREVIEW/`;
        let newRelativeUrl = this.router.createUrlTree([url]);
        let baseUrl = window.location.href.replace(this.router.url, '');
        this.previewSafeUrl = baseUrl + newRelativeUrl;
        $('iframe').attr('src', $('iframe').attr('src')); //reload src attribute of iframe
        $('#modalPreview').modal('show');
    }

    /**
     * On publish function
     */
    onPublish() {
        $('#modalPreview').modal('hide');
        //Before publishing, we check if form is already saved in PREVIEW and we also check if there are modification between PREVIEW and CURRENT FORM
        // if the CURRENT FORM has been saved in PREVIEW AND is not different with the one saved PREVIEW, we publish directly otherwise we save and publish
        if (this.FORM_STATUS.FORM_SAVE && !this.checkFormHasChanged(this._adminFormService.parentFormSave, this.parentForm.value)) {
            this._fileManagementService.publishModification(`${this.SITE_NAME}`)
                .then(value => {
                    console.log(value);
                    this.publishSuccess();
                });
        } else {
            this.save("publish");
        }
    }

    /**
     * Callback for publish
     */
    publish() {
        this._fileManagementService.publishModification(`${this.SITE_NAME}`)
            .then(value => {
                console.log(value);
                this.publishSuccess();
            });
    }

    /**
     * Publish success
     */
    publishSuccess() {
        this.FORM_STATUS.FORM_SAVE = false;
        console.log(this.FORM_STATUS);
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
        this.ALERT_MESSAGE.MESSAGE = `Site ${this.SITE_NAME} was published successfully`;
    }

    navigate(route: string) {
        this.router.navigate([`/admin/${this.SITE_NAME}/${route}`]);
    }

    scrollToElement(viewId): void {
        var x = document.getElementById(viewId);
        x.scrollIntoView();
    }

    changeSecondaryLanguage(): void {
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
        this.ALERT_MESSAGE.TYPE_OF_ACTION = "changeSecondaryLanguage";
        this.ALERT_MESSAGE.MESSAGE = `
    <span>You didn't save the data before changing the language.</span><br/>
    <span>Do you want to continue ?</span><br/>
    `;
    }

    callbackActionModal_changeSecondaryLanguage() {
        this.FORM_STATUS.FORM_LOADED = false;
        this.listOfCurrentLanguages[1] = this.selectedSecondaryLanguage;
        this._adminFormService.getPreviewTranslation(this.SITE_NAME, this.SITE.LANGUAGES);
        while (this.ALERT_MESSAGE.IS_DISPLAYED)
            this.callbackResetModal(this.ALERT_MESSAGE);
    }

    changePrimaryLanguage(): void {
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
        this.ALERT_MESSAGE.TYPE_OF_ACTION = "changePrimaryLanguage";
        this.ALERT_MESSAGE.MESSAGE = `
    <span>You didn't save the data before changing the language.</span><br/>
    <span>Do you want to continue ?</span><br/>
    `;
    }

    callbackActionModal_changePrimaryLanguage() {
        this.FORM_STATUS.FORM_LOADED = false;
        this.listOfCurrentLanguages[0] = this.selectedPrimaryLanguage;
        this._adminFormService.getPreviewTranslation(this.SITE_NAME, this.SITE.LANGUAGES);
        while (this.ALERT_MESSAGE.IS_DISPLAYED)
            this.callbackResetModal(this.ALERT_MESSAGE);
    }

    checkFormHasChanged(oldForm, newForm): boolean {
        if (!(JSON.stringify(newForm) == JSON.stringify(oldForm))) return true;
        else return false;
    }

    /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
    callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
        this.ALERT_MESSAGE.IS_DISPLAYED = false;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
        this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
        this.ALERT_MESSAGE.MESSAGE = null;

        this.selectedSecondaryLanguage = this.listOfCurrentLanguages[1];
        this.selectedPrimaryLanguage = this.listOfCurrentLanguages[0];
    }

    menuVisible: boolean = true;
    //  old fix now is useless
    // refreshMenu() {  
    //     console.log("FROM ADMIN FORM",this.parentForm);

    //     

    //     //  this.menuVisible = false;
    //     //  let fun=()=>{this.menuVisible=true;}
    //     //  setTimeout(fun);

    // }

    changePageId() {
        this.pageIdChanged.emit();
    }

}