import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'results-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() DATA: any;

  DATA_FOR_TABLE = [];

  constructor() { }

  ngOnInit(): void {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    this.DATA = JSON.parse(JSON.stringify(this.DATA));
    for(let i=0; i < this.DATA.WEEK.length; i++){
      this.DATA_FOR_TABLE.push({
        WEEK: this.DATA.WEEK[i],
        DATA: []
      })
      for(let j=0; j < this.DATA.DATA.length; j++){
        this.DATA_FOR_TABLE[i].DATA.push(this.DATA.DATA[j].DATA[i]);
      }
      this.DATA_FOR_TABLE[i].DATA.push(this.DATA_FOR_TABLE[i].DATA.reduce(reducer));
    }
    this.DATA_FOR_TABLE.push({
      WEEK: "TOTAL",
      DATA: []
    })
    this.DATA.DATA.forEach(element => {
      this.DATA_FOR_TABLE[this.DATA_FOR_TABLE.length-1].DATA.push(element.DATA.reduce(reducer));
    });
    this.DATA_FOR_TABLE[this.DATA_FOR_TABLE.length-1].DATA.push(this.DATA_FOR_TABLE[this.DATA_FOR_TABLE.length-1].DATA.reduce(reducer));
  }

  /**
   * Test on week if it's a number or not
   * @param week week
   * @returns return boolean
   */
  weekIsNotANumber(week: any){
    if (isNaN(week)) {
      return true;
    } else {
      return false;
    }
  }
}
