import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import EDITOR_CONFIG from "../../../../../../../../assets/constants/editorConfigMultiCta";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'multi-call-to-action-modal',
  templateUrl: './multi-call-to-action-modal.component.html',
  styleUrls: [
    './multi-call-to-action-modal.component.scss',
    '../../../../content-management.scss',
    '../../../../../local-back-office.scss',
    '../../../../../../private.scss']
})
export class MultiCallToActionModalComponent implements OnInit {

  @Output() filesEvent: EventEmitter<{ file: any, section: string }> = new EventEmitter<{ file: '', section: '' }>();
  currentEditedCta: FormGroup | null = null;
  currentEditedCtaIndex: number = null;

  public Editor = ClassicEditor;
  public editorConfig;

  SITE_NAME: string = '';
  ENVIRONMENT_PATH: string = '';
  SURVEYS_LIST: [] = [];

  toggle = {
    "textColorEditorDisplayed": false,
    "backgroundColorEditorDisplayed": false
  };

  togglePicker(option: string) {
    Object.keys(this.toggle)
      .map((picker) => {
        if (picker == option)
          this.toggle[picker] = !this.toggle[picker];
        else this.toggle[picker] = false;
      })
  }

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<MultiCallToActionModalComponent>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.editorConfig = EDITOR_CONFIG;

    let cta = data.currentEditedCta;
    this.currentEditedCtaIndex = data.ctaIndex;

    this.SITE_NAME = data.siteName;
    this.ENVIRONMENT_PATH = data.environmentPath;
    this.SURVEYS_LIST = data.surveysList;

    this.currentEditedCta = this.formBuilder.group({
      size: cta.size,
      text: cta.text,
      textColor: cta.textColor,
      bgColor: cta.bgColor,
      logo: cta.logo,
      currentUploadedLogo: cta.currentUploadedLogo,
      action: this.formBuilder.group({
        type: cta.action.type,
        documentPath: cta.action.documentPath,
        url: cta.action.url,
        survey: cta.action.survey
      })
    });

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close({ event: this.currentEditedCta });
    })
  }

  ngOnInit(): void {
    if(this.currentEditedCta.value.currentUploadedLogo){
      this.changeDetectorRef.detectChanges();
      const outputImage = document.getElementById('outputImage') as HTMLImageElement;
      outputImage.src = URL.createObjectURL(this.currentEditedCta.value.currentUploadedLogo);  
    }
  }

  isLightColor(color: string) {
    const hex = color.replace('#', '');
    const rgb = hex.match(/.{1,2}/g)?.map(val => parseInt(val, 16));
    if (rgb) {
      const yiq = ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000;
      return yiq > 214; // valeur de luminance pour déterminer si la couleur est claire
    }
    return false;
  }

  updateCurrentEditedCta() {
    if (this.currentEditedCta) {
      this.currentEditedCta.patchValue({
        size: this.currentEditedCta.value.size,
        text: this.currentEditedCta.value.text,
        textColor: this.currentEditedCta.value.textColor,
        bgColor: this.currentEditedCta.value.bgColor,
        logo: this.currentEditedCta.value.logo,
        currentUploadedLogo: this.currentEditedCta.value.currentUploadedLogo,
        action: {
          type: this.currentEditedCta.value.action.type,
          documentPath: this.currentEditedCta.value.action.documentPath,
          url: this.currentEditedCta.value.action.url,
          survey: this.currentEditedCta.value.action.survey
        }
      });
    }
  }

  onChangeFile(event, formLevel) {
    const file = event.target.files.item(0);
    const myRenamedFile = new File([file], `${file.name.split('.').shift()}_${this.datePipe.transform(new Date(), 'dd-MM-yyyy_hh-mm-ss')}.${file.name.split('.').pop()}`);

    if (formLevel === "callToActionMultiCta") {
      this.currentEditedCta.value.action.documentPath = myRenamedFile.name;
    } else if (formLevel === "callToActionLogo") {
      this.currentEditedCta.patchValue({
        logo: myRenamedFile.name,
        currentUploadedLogo: myRenamedFile
      });
      this.changeDetectorRef.detectChanges();
      const outputImage = document.getElementById('outputImage') as HTMLImageElement;
      outputImage.src = URL.createObjectURL(myRenamedFile);
    }
    this.filesEvent.emit({ file: myRenamedFile, section: formLevel });
  }
}
