/** How to use it :
 * <button (click)="SendAddToCartEvent()">Add To Cart</button>
 * ----------------------------------------------------------------
 * import{GoogleAnalyticsService} from './google-analytics.service';
 * ...
 * 
 * SendAddToCartEvent(){ 
    this
     .googleAnalyticsService
     .eventEmitter("add_to_cart", "shop", "cart", "click", 10);
   } 
 *  
 */

import { Injectable } from "@angular/core";

declare let gtag: Function;

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor() {}

  /**
   * Emit a new event on Google Analytics
   * @param eventName
   * @param eventCategory
   * @param eventAction
   * @param eventLabel
   * @param eventValue
   */
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    console.log("Emitting event to GA : ", eventName, eventCategory, eventAction, eventLabel, eventValue);
    gtag("event", eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    });
  }
}
