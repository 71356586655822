import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/core/services/httpclient.service';
import allRegions from "../../../../assets/constants/export";
import { COUNTRYBOUNDS } from "src/assets/constants/Countries";
import { ShareDataService } from 'src/app/core/services/share-data.service';
import { Subscription } from 'rxjs';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { ISubscription } from 'rxjs/Subscription';
declare const google: any;

const pinIcon = require("src/assets/img/icon_pin.svg") as string;
const COLORS = [
    "#FFFFFF",
    "#ECF3F8",
    "#D9E6F1",
    "#C6DAEA",
    "#B3CEE3",
    "#A0C2DC",
    "#8CB5D5",
    "#79A9CE",
    "#669DC7",
    "#5390C0",
    "#4084B9",
    "#3A77A7",
];
let currentCountyIndex;
let regions = [];
let polygons = [];
// let circles = [];

@Component({
  selector: 'MapComponent',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  @Input() DATA;
  @Input() SITE: any;

  lat = 46.227638;
  lng = 2.213749;
  UserLat = 46.227638;
  UserLng = 2.213749;
  zoom = 5;
  minZoom = 1;
  maxZoom = 10;

  currentRegion: string = '';
  currentWeek: number;
  currentWeekSubscription: Subscription;
  currentWeekNumberSubscription: ISubscription;

  coordinates = {
    latitude:  46.227638,
    longitude: 2.213749
  };

  map: GoogleMapsAPIWrapper;
  marker: google.maps.Marker = null;

  constructor(
    private _httpClientService: HttpclientService,
    private _shareDataService: ShareDataService,
    private ngZone: NgZone
  ) {
    //We execute the subscribe of the observable currentWeekNumber
    //When we change the week in the chart component (scroll bar chart) we change the week selected in the map
    this.currentWeekNumberSubscription = this._shareDataService.currentWeekNumber.subscribe(receivedWeek => {
      this.currentWeek = receivedWeek;
      this.changeColors();
    });
  }

  ngOnInit() {
    this.setCurrentLocation();
  }

  /**
   * When the map is ready, we add data on it
   * @param map map
   */
  onMapReady(map: GoogleMapsAPIWrapper) {
    this.map = map;
    if (allRegions[this.SITE.REGION] !== regions) var redraw = true;
    regions = allRegions[this.SITE.REGION] || [];
    this.currentRegion = this.SITE.REGION;
    this.setPolygons(regions);
    this.drawPolygons(this.map);
    this.markUserLocation({coords: {latitude: this.coordinates.latitude, longitude: this.coordinates.longitude}}, this.map);
  }

  /**
   * Set one polygons for one region in polygons array
   * @param regions regions present in the country
   */
  setPolygons(regions) {
    for (const region in regions) {
        const regionObject = regions[region];
        const [regionName] = Object.keys(regions[region]);
        const { code } = regions[region];
        const regionBorderPoints = regionObject[regionName];
        if (
            regionBorderPoints[0] &&
            typeof regionBorderPoints[0].lat === "number"
        ) {
            polygons.push({
                regionName: region,
                points: regionBorderPoints,
                index: polygons.length,
                id: regionObject.id,
                code,
                zOrder: 1
            });
          //   circles.push({
          //     regionName: region,
          //     points: regionBorderPoints,
          //     index: circles.length,
          //     id: regionObject.id,
          //     code,
          //     zOrder: 1
          // });
        } else {
            for (const miniRegion in regionBorderPoints) {
                const miniRegionBorderPoints =
                    regionBorderPoints[miniRegion];
                polygons.push({
                    regionName: region,
                    points: miniRegionBorderPoints,
                    index: polygons.length,
                    id: regionObject.id,
                    code,
                    zOrder: 1
                });
                // circles.push({
                //   regionName: region,
                //   points: miniRegionBorderPoints,
                //   index: circles.length,
                //   id: regionObject.id,
                //   code,
                //   zOrder: 1
                // });
            }
        }
    }
  }

  /**
   * For each polygons, draw a polygon on the map
   * @param map map
   */
  drawPolygons(map) {
    for (const polygon of polygons) {
        this.newPolygon(polygon, map);
    }
  }

  /**
   * Function to draw the polygon
   * @param param0 polygon parameters
   * @param map map
   */
  newPolygon({ points, regionName, index, code }, map) {
    const color = this.getColorForRegion(code);

    polygons[index].ref = new window.google.maps.Polygon({
        paths: points,
        strokeColor: "#000000",
        strokeOpacity: 0.5,
        strokeWeight: 0.2,
        fillColor: color,
        fillOpacity: 0.35
    });
    
    polygons[index].ref.setMap(map);
    polygons[index].ref.addListener(
      "click",
      event => {
          event.stop();
          this.onPolyClicked(regionName, index);
      },
      true
    );

    // circles[index].ref = new window.google.maps.Circle({
    //   strokeColor: "#FF0000",
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,
    //   fillColor: "#FF0000",
    //   fillOpacity: 0.35,
    //   map,
    //   center: this.getpolygoneCenter(points),
    //   radius: Math.sqrt(this.getraduis(color)) * 4000,
    // }); 

    // circles[index].ref.setMap(map);
    // circles[index].ref.addListener(
    //   "click",
    //   event => {
    //       event.stop();
    //       this.onPolyClicked(regionName, index);
    //   },
    //   true
    // );

  }

  //centre of polygone
  getpolygoneCenter(points){
    var bounds = new google.maps.LatLngBounds();
    var i;
    for (i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }
    return bounds.getCenter();
  }

  //getraduis
  getraduis(col){
    return ((COLORS.indexOf(col))*100/COLORS.length);
  }

  /** When the week is changed, we change the colors of the map with the data of the selected week */
  changeColors() {
    for (const polygon of polygons) {
        const { index, code } = polygon;
        const color = this.getColorForRegion(code);
        polygons[index].ref.setOptions({ fillColor: color });
        // circles[index].ref.setOptions({ radius: Math.sqrt(this.getraduis(color)) * 4000 });
    }
  }

  /**
   * generate a percentage following the epidemic data, and call a function to convert the percentage into color
   * @param code code of the region
   * @returns return a color
   */
  getColorForRegion(code){
    code = parseInt(code);
    let procente = -1;

    if (this.DATA) {
        const currentWeekFluData = this.DATA[this.currentWeek];
        
        if (currentWeekFluData) {
          const region = currentWeekFluData.subReports.filter(
              ({ location: { subRegion } }) => {
                  return parseInt(subRegion.code) === code;
              }
          )[0];
          if (region) {
            procente = (region.cases * 100) / currentWeekFluData.maxLocationCases;
          }
        } else {
            procente = 0;
        }
    }
    return this.getFillColor(procente);
  }

  /**
   * Convert a percentage into a color
   * @param percentage percentage
   * @returns return a color
   */
  getFillColor(percentage) {
    if (percentage < 0 || Number.isNaN(percentage)) {
        return COLORS[0];
    }
    const colorIndex = Math.floor(((COLORS.length - 1) * percentage) / 100);

    return COLORS[colorIndex];
  }

  /**
   * When we click on a polygon present on the map
   * @param regionName name of the region
   * @param index index of the region
   */
  onPolyClicked(regionName, index){
    if (currentCountyIndex === index) return this.onMapClick();
    let options = {
        strokeOpacity: 0.5,
        strokeWeight: 0.2,
    };
    polygons[
        currentCountyIndex >= 0 ? currentCountyIndex : index
    ].ref.setOptions(options);

    currentCountyIndex = index;
    options = {
        strokeOpacity: 1,
        strokeWeight: 2,
    };
    polygons[currentCountyIndex].ref.setOptions(options);
    this.ngZone.run( () => {
      this.currentRegion = (regions[regionName].code ? `${regions[regionName].code} - ` : "") + regions[regionName].name;
    });
    this.autoCenter([{"points":polygons[currentCountyIndex]['points']}] || [], "SUB_REGION");
    this._shareDataService.changeSubRegionNumber(regions[regionName].code);
  };

  /** on map click event */
  onMapClick(){
    this.autoCenter(COUNTRYBOUNDS[this.SITE.REGION] || [], "COUNTRY");
    this._shareDataService.changeSubRegionNumber('-1');
    if (currentCountyIndex >= 0) {
        let options;
        options = {
            strokeOpacity: 0.5,
            strokeWeight: 0.2,
        };
        polygons[currentCountyIndex].ref.setOptions(options);
    }
    this.ngZone.run( () => {
      this.currentRegion = this.SITE.REGION;
    });
    currentCountyIndex = -1;
  };

  /** Autocenter the view of the map on the region (region or country) */
  autoCenter(region, span) {
    const bounds = this.getBounds(region);
    this.map.fitBounds(bounds);
    this.map.panToBounds(bounds);
    if(span == "COUNTRY") this.recenter();
  }

  /** Get bound of a region */
  getBounds(points) {
    const bounds = new google.maps.LatLngBounds();
    let positions;
    for (const polygon of points) {
      for (const position of polygon.points) {
          positions = new google.maps.LatLng(position.lat, position.lng);
          bounds.extend(positions);
      }
    }
    return bounds;
  }

  /**
   * Put marker on the map with the position of the user
   * @param param0 coordinates
   * @param map map
   */
  markUserLocation = ({ coords: { latitude, longitude } }, map) => {
    if(map){
      if(this.marker != null) this.marker.setMap(null);
      this.marker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map,
        title: "Your position!",
        icon: pinIcon,
      });
      this.marker.setMap(map);
    }
  };

  /** Set current location of the user */
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        this.coordinates = {
          latitude:  position.coords.latitude,
          longitude: position.coords.longitude
        };
        this.markUserLocation({coords: {latitude: this.coordinates.latitude, longitude: this.coordinates.longitude}}, this.map);
      });
    }
  }

  /** Recenter the map on the user's position */
  recenter() {
    this.map.setCenter({
      lat: this.marker.getPosition().lat(),
      lng: this.marker.getPosition().lng()});
    this.map.setZoom(5);
  }

  ngOnDestroy(): void{
    this.currentWeekNumberSubscription.unsubscribe();
  }
}