<form [formGroup]="parentForm">
    <!-- Text for subtitle -->
    <div class="form-group">
        <p class="labelLike">Title</p>
        <input type="text" id="subtitle_04_{{type}}" class="form-control" [formControlName]="'subtitle_04_' + type"/>
    </div>

    <!-- Text for Section #1 - Small Label -->
    <div class="form-group">
        <p class="labelLike">Legend 1</p>
        <input type="text" id="label1_04_{{type}}" class="form-control" [formControlName]="'label1_04_' + type"/>
    </div>

    <!-- Text for Section #2 - Small Label -->
    <div class="form-group">
        <p class="labelLike">Legend 2</p>
        <input type="text" id="label2_04_{{type}}" class="form-control" [formControlName]="'label2_04_' + type"/>
    </div>
    
    <!-- Text for Section #2 - Popup content -->
    <div class="form-group">
        <p class="labelLike">Information about the data</p>
        <textarea class="form-control" id="information_data_content_04_{{type}}" rows="3" [formControlName]="'information_data_content_04_' + type"></textarea>
    </div>
</form>