import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { FileManagementService } from './file-management.service';
import { BehaviorSubject } from 'rxjs';
import { JsonPrimaryLanguageService } from '../helpers/json-primary-language.service';
import { JsonSecondaryLanguageService } from '../helpers/json-secondary-language.service';
import { JsonMultiLanguageService } from '../helpers/json-multi-language.service';
import { HttpclientService } from './httpclient.service';
import { v4 as uuid } from 'uuid';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { maximumFileSize } from '../validators/maximumFileSize';
import { epidemicMapDataFile } from '../validators/epidemicMapDataFile';
import { environment } from 'src/environments/environment';
import { listOfImagesExtensions } from '../classes/ImagesExtensions';
import { fileName } from '../validators/fileName';
import { SitesService } from './sites.service';

@Injectable({
  providedIn: 'root'
})
export class AdminFormService {

  /** Variable declaration for event on get translations */
  private dataSource = new BehaviorSubject(false);
  currentData = this.dataSource.asObservable();

  /** Variable declaration for data from get translations */
  dataTranslationSource = new BehaviorSubject('');
  currentTranslationData = this.dataTranslationSource.asObservable();

  /** Save of the parentForm */
  parentFormSave: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpclientService,
    private fileManagement: FileManagementService,
    private _json_primary_language_service: JsonPrimaryLanguageService,
    private _json_secondary_language_service: JsonSecondaryLanguageService,
    private _json_multi_language_service: JsonMultiLanguageService,
    private _httpClient: HttpclientService,
    private _siteService: SitesService
  ) { }

  /**
   * Get preview translation in S3 and return in an array
   * @param siteName Name of the site
   * @param language Language of the translation we want to get back
   * @returns array with language, and translation of the language
   */
  private async loadTranslation(siteName: string, language: string) {
    const translation = await this.httpService.getPreviewTranslation(siteName, language);
    return [language, translation];
  }

  /**
   * Get all translations
   * @param siteName Name of the site
   * @param languages Language we are modifying in admin component
   */
  public getPreviewTranslation(siteName: string, languages: Array<string>) {
    console.debug(`Get translations for default COVID for now and languages ${languages[0]} and ${languages[1]}`);
    //After all translations get from loadTranslation function, we emit a new value in dataSource and dataTranslationSource variable
    Promise.all([...languages.map(l => this.loadTranslation(siteName, l)), this._siteService.getSite(siteName+"-PREVIEW")]).then(success => {
      this.changeDataTranslations(success);
      this.changeData(true);
    });
  }

  /**
   * Init the parentForm that contains all childForms
   * @param parentForm Main Reactive Form of the Admin Component
   * @param primaryLanguage First current language
   * @param secondaryLanguage Second current languahe (if present)
   * @returns Return the main Reactive Form
   */
  public initForm(parentForm: FormGroup, primaryLanguage: string, secondaryLanguage: string): FormGroup {
    console.info("Init form ...");
    console.info("ParentForm : ", parentForm);
    console.info("PrimaryLanguage = " + primaryLanguage + " & SecondaryLanguage = " + secondaryLanguage);

    let value_PRIMARY;
    let value_SECONDARY;
    let value_METADATA;

    this.currentTranslationData.subscribe((receiveData: any) => {
      value_METADATA = receiveData[receiveData.length - 1];
      value_PRIMARY = receiveData.filter(item => item[0] === primaryLanguage)[0];
      if (secondaryLanguage) value_SECONDARY = receiveData.filter(item => item[0] === secondaryLanguage)[0];
    });

    console.log("VALUE_PRIMARY = ", value_PRIMARY);
    console.log("VALUE_SECONDARY = ", value_SECONDARY);
    console.log("value_METADATA = ", value_METADATA);

    let controlGroup: any = {};

    //We build the Reactive Forms for primary language
    if (primaryLanguage === '' || primaryLanguage === undefined) {
      console.error("Erreur : No primary language");
    } else {
      controlGroup = {
        menuLogo_09_primary: ['', [maximumFileSize('menuLogo_09_primary', 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName('menuLogo_09_primary')]],
        menuLogo_09_filename_primary: [value_PRIMARY[1].graphics.menuTopLogoPath],//LOGO_MENU
        secondaryLogo_09_primary: ['', [maximumFileSize('secondaryLogo_09_primary', 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName('secondaryLogo_09_primary')]],
        secondaryLogo_09_filename_primary: [value_PRIMARY[1].graphics.menuBottomLogoPath],//LOGO_SANOFI
        internalUsage_09_primary: [value_PRIMARY[1].menu.bottomText], //INTERNAL_USE
        headerColor_09_primary: [value_PRIMARY[1].pages[0].color], //HEADER_COLOR
        headerLogo_10_primary: ['', [maximumFileSize('headerLogo_10_primary', 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName('headerLogo_10_primary')]],
        headerLogo_10_filename_primary: [value_PRIMARY[1].graphics.headerLogoPath], //LOGO_HEADER
        regionMap_04_primary: [''], //REGION
        mapData_04_primary: ['', [maximumFileSize('mapData_04_primary', 4000000), RxwebValidators.extension({ extensions: ['csv'] })], epidemicMapDataFile('mapData_04_primary')],
        mapData_04_filename_primary: [environment.csvFilename], //FILE_NAME
        activateDashboardCheck_04_primary: [value_PRIMARY[1].map.activated], //ACTIVATED
        campaignDate_04_primary: [
          [
            new Date(value_PRIMARY[1].map.startDateCampaign), //CAMPAIGN_DATE_START
            new Date(value_PRIMARY[1].map.endDateCampaign) //CAMPAIGN_DATE_END
          ]
        ],
        subtitle_04_primary: [value_PRIMARY[1].map.title],
        label1_04_primary: [value_PRIMARY[1].map.legend1],
        label2_04_primary: [value_PRIMARY[1].map.legend2],
        information_data_content_04_primary: [value_PRIMARY[1].map.informationAboutData],
        pages_primary: this.pagesFormArrayFor(value_PRIMARY[1].pages),
        links_menu_primary: this.menulinksFormArrayFor(value_PRIMARY[1].menu.menuLinks),
        contentBlockingModalTitle_primary: value_PRIMARY[1].contentBlockingModal ? [value_PRIMARY[1].contentBlockingModal.title] : [''], //Content Blocking Modal Title
        contentBlockingModalContent_primary: value_PRIMARY[1].contentBlockingModal ? [value_PRIMARY[1].contentBlockingModal.content] : [''], //Content Blocking Modal Content
        contentBlockingModalAccept_primary: value_PRIMARY[1].contentBlockingModal ? [value_PRIMARY[1].contentBlockingModal.accept] : [''], //Content Blocking Modal Accept
        contentBlockingModalDecline_primary: value_PRIMARY[1].contentBlockingModal ? [value_PRIMARY[1].contentBlockingModal.decline] : [''], //Content Blocking Modal Decline
        contentBlockingModalActivated: value_METADATA.MISC.CONTENT_BLOCKING_MODAL_ACTIVATED ? value_METADATA.MISC.CONTENT_BLOCKING_MODAL_ACTIVATED : false,
        passwordModalTitle_primary: value_PRIMARY[1].passwordModal ? value_PRIMARY[1].passwordModal.title : "",
        passwordModalContent_primary :value_PRIMARY[1].passwordModal ? value_PRIMARY[1].passwordModal.content : "" ,
        passwordActivated : value_METADATA.MISC.PASSWORD_ACTIVATED ? value_METADATA.MISC.PASSWORD_ACTIVATED : false

      }
    }
    //Then if there is a secondary language, we add the controlGroup to the Reactive Form
    if (secondaryLanguage === '' || secondaryLanguage === undefined) {
      console.error("Erreur : No secondary language");
    } else {
      controlGroup.menuLogo_09_secondary = ['', [maximumFileSize('menuLogo_09_secondary', 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName('menuLogo_09_secondary')]];
      controlGroup.menuLogo_09_filename_secondary = value_SECONDARY[1].graphics.menuTopLogoPath;
      controlGroup.secondaryLogo_09_secondary = ['', [maximumFileSize('secondaryLogo_09_secondary', 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName('secondaryLogo_09_secondary')]];
      controlGroup.secondaryLogo_09_filename_secondary = value_SECONDARY[1].graphics.menuBottomLogoPath;
      controlGroup.internalUsage_09_secondary = [value_SECONDARY[1].menu.bottomText];
      controlGroup.headerColor_09_secondary = [value_SECONDARY[1].pages[0].color];
      controlGroup.headerLogo_10_secondary = ['', [maximumFileSize('headerLogo_10_secondary', 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName('headerLogo_10_secondary')]];
      controlGroup.headerLogo_10_filename_secondary = [value_SECONDARY[1].graphics.headerLogoPath];
      controlGroup.subtitle_04_secondary = [value_SECONDARY[1].map.title];
      controlGroup.label1_04_secondary = [value_SECONDARY[1].map.legend1];
      controlGroup.label2_04_secondary = [value_SECONDARY[1].map.legend2];
      controlGroup.information_data_content_04_secondary = [value_SECONDARY[1].map.informationAboutData];
      controlGroup.pages_secondary = this.pagesFormArrayFor(value_SECONDARY[1].pages);
      controlGroup.links_menu_secondary = this.menulinksFormArrayFor(value_SECONDARY[1].menu.menuLinks);
      controlGroup.contentBlockingModalTitle_secondary = value_SECONDARY[1].contentBlockingModal ? [value_SECONDARY[1].contentBlockingModal.title] : ""; //Content Blocking Modal Title
      controlGroup.contentBlockingModalContent_secondary = value_SECONDARY[1].contentBlockingModal ? [value_SECONDARY[1].contentBlockingModal.content] : ""; //Content Blocking Modal Content
      controlGroup.contentBlockingModalAccept_secondary = value_SECONDARY[1].contentBlockingModal ? [value_SECONDARY[1].contentBlockingModal.accept] : ""; //Content Blocking Modal Accept
      controlGroup.contentBlockingModalDecline_secondary = value_SECONDARY[1].contentBlockingModal ? [value_SECONDARY[1].contentBlockingModal.decline] : ""; //Content Blocking Modal Decline
      controlGroup.passwordModalTitle_secondary = value_SECONDARY[1].passwordModal ? value_SECONDARY[1].passwordModal.title : ""; 
      controlGroup.passwordModalContent_secondary =  value_SECONDARY[1].passwordModal ? value_SECONDARY[1].passwordModal.content : "";
    }
    parentForm = this.formBuilder.group(controlGroup);
    this.parentFormSave = JSON.parse(JSON.stringify(parentForm.value));
    console.info("ParentForm : ", parentForm);
    console.info("Form is init !");
    return parentForm;
  }

  /**
   * Function use to set value of menu in the main Reactive Form
   * @param menuLinksModel JSON with data of menu links
   * @returns return the array of menu links in FormGroup format
   */
  menulinksFormArrayFor(menuLinksModel): FormArray {

    const addMenuLinkTypeAppPage = (typeAppPage) => {
      var formMenuLinkTypeAppPage: FormGroup = this.formBuilder.group({
        pageId: typeAppPage.pageId
      });
      return formMenuLinkTypeAppPage;
    };

    const addMenuLinkTypeExternalLink = (typeExternalLink) => {
      var formMenuLinkTypeExternalLink: FormGroup = this.formBuilder.group({
        url: typeExternalLink.url
      });
      return formMenuLinkTypeExternalLink;
    };

    let menuLinks: FormArray = this.formBuilder.array([]);
    if (menuLinksModel.length > 0) {
      //For each menu links in JSON translation we add it to menuLinks FormArray
      menuLinksModel.forEach(link => {
        const newMenuLink: FormGroup = this.formBuilder.group({
          id: [link.id],
          label: [link.label],
          type: [link.type],
          typeAppPage: addMenuLinkTypeAppPage(link.typeAppPage),
          typeExternalLink: addMenuLinkTypeExternalLink(link.typeExternalLink)
        });
        menuLinks.push(newMenuLink);
      });
    } else {
      //If JSON translation has no menu links, we create a new one
      menuLinks.push(this.formBuilder.group({
        id: [uuid()],
        label: ['New link'],
        type: ['null'],
        typeAppPage: addMenuLinkTypeAppPage({ pageId: 'null' }),
        typeExternalLink: addMenuLinkTypeExternalLink({ url: "" })
      }));
    }
    return menuLinks;
  }

  pagesFormArrayFor(pagesModel): FormArray {

    const addPageCompositeFields = (pageCompositeFields, id) => {
      var arrayPageCompositeFields: FormArray = this.formBuilder.array([]);
      for (let i = 0; i < pageCompositeFields.length; i++) {
        var formCompositeField: FormGroup = this.formBuilder.group({
          imagePath: [pageCompositeFields[i].imagePath, [fileName('arrayPageCompositeFields_' + id + '_formCompositeField_imagePath_' + i), maximumFileSize('arrayPageCompositeFields_' + id + '_formCompositeField_imagePath_' + i, 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions })]],
          text: pageCompositeFields[i].text
        });
        arrayPageCompositeFields.push(formCompositeField);
      }
      return arrayPageCompositeFields;
    }

    const addPageImageFields = (pageImageFields, id) => {

      var formPageImageFields: FormGroup = this.formBuilder.group({
        imagePath: [pageImageFields.imagePath, [maximumFileSize("formPageImageFields_imagePath_" + id, 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName("formPageImageFields_imagePath_" + id)]]
      });
      return formPageImageFields;
    };

    const addPageTextFields = (pageTextFields) => {
      var formPageTextFields: FormGroup = this.formBuilder.group({
        text: pageTextFields.text
      });
      return formPageTextFields;
    };

    let pages: FormArray = this.formBuilder.array([]);
    if (pagesModel.length > 0) {
      //For each menu links in JSON translation we add it to menuLinks FormArray
      for (var i = 0; i < pagesModel.length; i++) {

        var page = pagesModel.at(i);
        if (page.type != "FULL_IMAGE" && page.type != "FULL_TEXT" && page.type != "IMAGE_AND_TEXT") {
          console.error(`Error : type ${page.type} is not authorized`);
          return;
        }
        const newPage: FormGroup = this.formBuilder.group({
          id: [page.id],
          name: [page.name],
          type: [page.type],
          color: [page.color],
          activated: [page.activated],
          callToAction: this.addCallToAction(page.callToAction, i),
          pageCompositeFields: addPageCompositeFields(page.pageCompositeFields, i),
          pageImageFields: addPageImageFields(page.pageImageFields, i),
          pageTextFields: addPageTextFields(page.pageTextFields)
        });
        pages.push(newPage);
      }
    } else {
      console.error("No pages found");
    }
    return pages;
  }

  addCallToAction(callToAction, id) {

    const addCallToActionExternalLink = (callToActionExternalLink) => {
      var formCallToActionExternalLink: FormGroup = this.formBuilder.group({
        url: callToActionExternalLink.url
      });
      return formCallToActionExternalLink;
    };

    const addcallToActionDocument = (callToActionDocument) => {
      var formCallToActionDocument: FormGroup = this.formBuilder.group({
        documentPath: [callToActionDocument.documentPath, [fileName("formCallToActionDocument_documentPath_" + id), RxwebValidators.extension({ extensions: listOfImagesExtensions.concat('pdf').concat('ics') }), maximumFileSize("formCallToActionDocument_documentPath_" + id, 2000000)]]
      });
      return formCallToActionDocument;
    };

    const addcallToActionSurvey = (callToActionSurvey) => {
      var formCallToActionSurvey: FormGroup = this.formBuilder.group({
        survey: callToActionSurvey.survey
      });
      return formCallToActionSurvey;
    };

    const addCallToActionMultiCta = (callToActionMultiCta) => {
      let formCtaList = [];

      if (callToActionMultiCta && callToActionMultiCta.ctaList) {
        for (let j = 0; j < callToActionMultiCta.ctaList.length; j++) {
          const cta = callToActionMultiCta.ctaList[j];

          let formCta = this.formBuilder.group({
            size: cta.size,
            text: cta.text,
            textColor: cta.textColor,
            bgColor: cta.bgColor,
            logo: [cta.logo, [maximumFileSize("ctaLogo_" + j, 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName("ctaLogo_" + j)]],
            currentUploadedLogo: '',
            action: this.formBuilder.group({
              type: cta.action.type,
              documentPath: [cta.action.documentPath, [fileName("multi_cta_documentpath_" + j), RxwebValidators.extension({ extensions: listOfImagesExtensions.concat('pdf').concat('ics') }), maximumFileSize("multi_cta_documentpath_" + j, 2000000)]],
              url: cta.action.url,
              survey: cta.action.survey
            })
          });

          formCtaList.push(formCta);
        }
      }

      var formCallToActionMultiCta = this.formBuilder.group({
        ctaList: this.formBuilder.array(formCtaList)
      });

      return formCallToActionMultiCta;
    };

    if (!callToAction.type || callToAction.type === "") {
      let formCtaList = [];
      formCtaList.push(this.formBuilder.group({
        size: "Medium",
        text: "",
        textColor: "#2B2B38",
        bgColor: "FFFFFF",
        logo: ["", [maximumFileSize("ctaLogo_0", 2000000), RxwebValidators.extension({ extensions: listOfImagesExtensions }), fileName("ctaLogo_0")]],
        currentUploadedLogo: '',
        action: this.formBuilder.group({
          type: "OpenFile",
          documentPath: ["", [fileName("multi_cta_documentpath_0"), RxwebValidators.extension({ extensions: listOfImagesExtensions.concat('pdf').concat('ics') }), maximumFileSize("multi_cta_documentpath_0", 2000000)]],
          url: "",
          survey: ""
        })
      }));
      var formCallToAction: FormGroup = this.formBuilder.group({
        callToActionDocument: this.formBuilder.group({
          documentPath: ["", [fileName("formCallToActionDocument_documentPath_0"), RxwebValidators.extension({ extensions: listOfImagesExtensions.concat('pdf').concat('ics') }), maximumFileSize("formCallToActionDocument_documentPath_0", 2000000)]]
        }),
        callToActionExternalLink: this.formBuilder.group({
          url: ""
        }),
        callToActionSurvey: this.formBuilder.group({
          survey: ""
        }),
        callToActionMultiCta: this.formBuilder.group({
          ctaList: this.formBuilder.array(formCtaList)
        }),
        id: "",
        label: "",
        type: 'EXTERNAL'
      });
    } else {
      var formCallToAction: FormGroup = this.formBuilder.group({
        id: callToAction.id,
        label: callToAction.label,
        type: callToAction.type,
        callToActionDocument: addcallToActionDocument(callToAction.callToActionDocument),
        callToActionExternalLink: addCallToActionExternalLink(callToAction.callToActionExternalLink),
        callToActionSurvey: addcallToActionSurvey(callToAction.callToActionSurvey),
        callToActionMultiCta: addCallToActionMultiCta(callToAction.callToActionMultiCta),
      });
    }
    return formCallToAction;
  }

  /**
   * Submit parentForm
   * @param parentForm Main Reactive Form
   * @param site Site Name
   * @param currentLanguages Current languages
   * @param siteLanguages All the site languages
   * @returns Return a boolean if save is done or not
   */
  async sendForm(parentForm, site: string, currentLanguages: Array<string>, siteLanguages: Array<string>) {
    return new Promise(resolve => {
      console.info("Send form ...");
      console.info("ParentForm = ", parentForm);
      console.info("Site = " + site);
      console.info("CurrentLanguages = " + currentLanguages);
      console.info("SiteLanguages = " + siteLanguages);

      const formValue = parentForm.value;

      let VALUE_TRANSLATIONS;
      let VALUE_PRIMARY;
      let VALUE_SECONDARY;

      //Get translations value from Observable currentTranslationData
      this.currentTranslationData.subscribe(receiveData => {
        VALUE_TRANSLATIONS = receiveData;
      });

      //For each translation we store it in private variable
      for (let i = 0; i < VALUE_TRANSLATIONS.length; i++) {
        if (VALUE_TRANSLATIONS[i][0] === currentLanguages[0]) VALUE_PRIMARY = VALUE_TRANSLATIONS[i];
        if (VALUE_TRANSLATIONS[i][0] === currentLanguages[1]) VALUE_SECONDARY = VALUE_TRANSLATIONS[i];
      }

      //Check if the current site has more than two languages and get it
      let mutlipleLanguages = siteLanguages.filter(x => !currentLanguages.includes(x));
      console.log("mutlipleLanguages : ", mutlipleLanguages);

      console.info("VALUE_PRIMARY = ", VALUE_PRIMARY);
      console.info("VALUE_SECONDARY = ", VALUE_SECONDARY);
      console.info("VALUE_TRANSLATIONS = ", VALUE_TRANSLATIONS);


      if (currentLanguages[1] === '' || currentLanguages[1] === undefined) {
        //If there is only one languages, we get JSON for this languages and send it to S3 Bucket
        Promise.all([
          this._json_primary_language_service.getJSON(formValue, VALUE_PRIMARY[1]), //remplace by COVID , will change with new model 
        ]).then((getJSONres) => {
          console.info("Translation : ", getJSONres);
          console.info(`Push translation 0 to dynamo on site ${site}`);
          getJSONres[0] = this.checkLink(getJSONres[0]);
          this._httpClient.postTranslation(site, getJSONres[0]);
          console.info("Form sent !");
          resolve(getJSONres);
        });
      } else {
        //Else we get JSON for all languages and send it to S3 bucket
        //We also get JSON for Multi Languages ans send it to S3 bucket
        let getJSONPromises = [];
        getJSONPromises.push(this._json_primary_language_service.getJSON(formValue, VALUE_PRIMARY[1]));
        getJSONPromises.push(this._json_secondary_language_service.getJSON(formValue, VALUE_SECONDARY[1]));

        //if site has more than the one/two languages edited, we update others languages for common translations
        if (mutlipleLanguages.length > 0) {
          for (let i = 0; i < mutlipleLanguages.length; i++) {
            let VALUE = VALUE_TRANSLATIONS.filter(item => item[0] === mutlipleLanguages[i]);
            console.log(VALUE[0][1]);
            getJSONPromises.push(this._json_multi_language_service.getJSON(formValue, VALUE[0][1]))
          }
        }

        Promise.all(getJSONPromises).then((getJSONres) => {
          console.info("Translation : ", JSON.parse(JSON.stringify(getJSONres)));
          let promises_uploadJSON = [];
          for (let i = 0; i < getJSONres.length; i++) {
            //check urls / mails before sending form
            getJSONres[i] = this.checkLink(getJSONres[i]);

            console.info(`Push translation ${i} to dynamo on site ${site}`);
            console.log(JSON.parse(JSON.stringify(getJSONres[i])));
            promises_uploadJSON.push(this._httpClient.postTranslation(site, getJSONres[i]));
          }
          Promise.all(promises_uploadJSON).then((UploadFileRes) => {
            console.info(UploadFileRes);
            resolve(true);
          });
        });
      }
    });
  }

   /**
 * Function to test if the URL is an email or not
 * @param {string} url url to be tested
 * @returns boolean
 */
   isEmail = function (url) {
    return /^(mailto:)?(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(url.toLowerCase());
  };

  /**
   * This function allow to verify all sites links to reformat them: begin with protocol if url or mailto: in case of mail;
   * The type for call to action is EXTERNAL
   * The type for menu links is EXTERNAL_LINK
   * @param translation is the site content language object containing the form state value
   * @returns a new translation with new formated links
   */
  checkLink(translation) {
    let menuLinks = translation.menu.menuLinks;
    let pages = translation.pages;

    //loop on callToAction
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].callToAction != null) {
        if (pages[i].callToAction.type === "EXTERNAL") { //if callToAction is external
          if (pages[i].callToAction.callToActionExternalLink != null && pages[i].callToAction.callToActionExternalLink.url != "") {
            let url = pages[i].callToAction.callToActionExternalLink.url;
            if (!this.isEmail(url)) {
              let finalUrl = this.getValidUrl(url);
              pages[i].callToAction.callToActionExternalLink.url = finalUrl;
            } else if (this.isEmail(url)) {
              let finalMailto = this.getValidMailto(url);
              pages[i].callToAction.callToActionExternalLink.url = finalMailto;
            }
          }
        } else if (pages[i].callToAction.type === "MULTI_CTA") { //if callToAction is multiCta
          pages[i].callToAction.callToActionMultiCta.ctaList.forEach(cta => {
            if (cta.action.type === "Redirect") {
              let url = cta.action.url;
              if (!this.isEmail(url)) {
                let finalUrl = this.getValidUrl(url);
                cta.action.url = finalUrl;
              } else if (this.isEmail(url)) {
                let finalMailto = this.getValidMailto(url);
                cta.action.url = finalMailto;
              }
            }
          });
        }
      }
    }
    //loop on menu links
    for (let i = 0; i < menuLinks.length; i++) {
      if (menuLinks[i].type === "EXTERNAL_LINK" && menuLinks[i].typeExternalLink.url && menuLinks[i].typeExternalLink.url != "") {
        let url = menuLinks[i].typeExternalLink.url;
        if (!this.isEmail(url)) {
          let finalUrl = this.getValidUrl(url);
          translation.menu.menuLinks[i].typeExternalLink.url = finalUrl;
        } else if (this.isEmail(url)) {
          let finalMailto = this.getValidMailto(url);
          translation.menu.menuLinks[i].typeExternalLink.url = finalMailto;
        }
      }
    }
    return translation;
  }

  getValidUrl = (url = "") => {
    let listProtocol = ["http", "https", "ftp", "sftp", "ftps"];
    //decode URL with special char
    let newUrl = url.trim();

    //if URL with protocol
    if (newUrl.includes("://")) {

      //get protocol and rest of string
      let [prefix, ...rest] = newUrl.split('://');
      let subfix = rest.join('://');

      console.log("PREFIX : ", prefix);
      console.log("SUBFIX : ", subfix);

      if (subfix != "") {
        //If multiple protocol in sufix remove them
        subfix = subfix.replace(/http:\/\/|https:\/\/|ftp:\/\/|ftps:\/\/|sftp:\/\//g, "");
        if (subfix != "") {
          //If prefix is in list of valid protocol
          if (prefix != "") {
            if (listProtocol.includes(prefix)) {
              newUrl = prefix + "://" + subfix;
            }
          } else {
            newUrl = `https://${subfix}`;
          }
        } else {
          newUrl = "";
        }
      } else {
        newUrl = "";
      }
    } else {
      newUrl = `https://${newUrl}`;
    }
    console.log("New Url :", newUrl);
    return newUrl;
  };

  getValidMailto = (mail = "") => {
    //remove splace from url
    let newMail = mail.trim().replace(/\s/g, "");
    //if multiple "mailto:mailto:" 
    newMail = newMail.replace(/mailto:/g, "");

    if (!/^mailto:/i.test(newMail)) {
      return `mailto:${newMail}`;
    }
    return newMail;
  };

  /** Function use by getTranslation() to update boolean Observable when getTranslation is over*/
  changeData(isReady: boolean) {
    this.dataSource.next(isReady);
  }
  /** Function use by getTranslation() to update translation Observable */
  changeDataTranslations(data: any) {
    this.dataTranslationSource.next(data);
  }

}