import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'innerHTML',
  templateUrl: './inner-html.component.html',
  styleUrls: ['./inner-html.component.scss']
})
export class InnerHTMLComponent implements OnInit {

  @Input() HTMLcontent: string;

  constructor() { }
  ngOnInit(): void { }

}
