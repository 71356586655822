import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdminComponent } from './content-management-main/admin.component';
import { LogosComponent } from './logos/logos.component';
import { MenuComponent } from './menu/menu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardBisComponent } from './dashboard-bis/dashboard-bis.component';
import { MainMultiPagesCommonComponent } from './multi-pages/multi-pages-common/multi-pages-common.component';
import { FullImageComponent } from './multi-pages/full-image/full-image.component';
import { FullTextComponent } from './multi-pages/full-text/full-text.component';
import { ImageAndTextComponent } from './multi-pages/image-and-text/image-and-text.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxKjuaModule } from 'ngx-kjua';
import { ColorPickerModule } from 'ngx-color-picker';
import { MaterialModule } from '../../../material-module';
import { MainMultiPagesComponent } from './multi-pages/multi-pages-main/multi-pages-main.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ContentBlockingModalComponent } from './content-blocking-modal/content-blocking-modal.component';
import { PasswordModalComponent } from './password-modal/password-modal.component';
import { MultiCallToActionModalComponent } from './multi-pages/multi-pages-common/multi-call-to-action/multi-call-to-action-modal/multi-call-to-action-modal.component';

@NgModule({
  declarations: [AdminComponent, LogosComponent, MenuComponent, DashboardComponent, DashboardBisComponent, MainMultiPagesComponent, FullImageComponent, FullTextComponent, ImageAndTextComponent, MainMultiPagesCommonComponent, ContentBlockingModalComponent, MultiCallToActionModalComponent, PasswordModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, ReactiveFormsModule, RxReactiveFormsModule,
    CKEditorModule,
    ZXingScannerModule,
    NgxKjuaModule,
    ColorPickerModule,
    MaterialModule,
    DragDropModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    CKEditorModule,
    ZXingScannerModule,
    NgxKjuaModule,
    ColorPickerModule,
    MaterialModule
  ]
})
export class ContentManagementModule { }
