<h2 mat-dialog-title class="text-center">{{this.title}}</h2>

<mat-dialog-content [formGroup]="form">
    <div>
        <div [innerHTML]="content"></div>
        <mat-form-field class="pass_input">
            <mat-label>password</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password"
                (keydown.enter)="login()">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                {{ hidePassword ? 'visibility' : 'visibility_off' }}
            </mat-icon>
        </mat-form-field>
        <div *ngIf="wrongPassword">
            <p style="color: red;">invalid password</p>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions class="d-flex align-items-center justify-content-center">
    <button type="button" class="btn btn-primary d-flex align-items-center btn-type2 btn-global-type" (click)="login()">
        <span class="material-icons icons_button">
            login
        </span>
    </button>
</mat-dialog-actions>