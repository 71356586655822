import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { UsersService } from 'src/app/core/services/users.service';
import { SitesService } from 'src/app/core/services/sites.service';
import { RoutesService } from 'src/app/core/services/routes.service';
import { ROUTES } from 'src/app/core/classes/Routes';
import { Observable } from 'rxjs';
import { JwtClaims, UserLoginService } from 'src/app/core/services/user-login.service';


@Component({
  selector: 'aac-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: [
    './reporting.component.scss',
    '../admin-back-office.scss',
    '../../private.scss'
  ]
})
export class AdminReportingComponent {

  message: string = null;

  userToken$: Observable<JwtClaims>;

  SITES: any = [];
  allSites: string[] = [];

  USER_NAME: string = '';
  user: any = {
    username: '',
    email: '',
    group: '',
    sites: []
  };

  DATA_IS_LOADED: boolean = false;

  ROUTES: ROUTES;

  constructor(
    private router: Router,
    private titleService: Title,
    private _usersService: UsersService,
    private _sitesService: SitesService,
    private _routesService: RoutesService,
    private _userLoginService: UserLoginService
  ) {
    this.DATA_IS_LOADED = false;
    this.titleService.setTitle("Elefight - Reporting");
    this.userToken$ = this._userLoginService.getTokenJwtClaims();
    this._routesService.currentRoutes.subscribe(routes => {
      this.ROUTES = routes;
      console.log(routes);
      if (routes.PARAMS) {
        console.log(routes.PARAMS);
        if (routes.PARAMS['user'] != undefined) var user = routes.PARAMS['user'];
        (user) ? this.USER_NAME = user : this.USER_NAME = '';
      }
    });

    this._usersService.getUser(this.USER_NAME).then((user: any) => {
      this.userToken$.subscribe((jwtClaims: JwtClaims) => {
        console.log("jwtClaims : ", jwtClaims);
        if (this.USER_NAME === jwtClaims["cognito:username"]) {
          this.user = JSON.parse(JSON.stringify(user));
          if (user.sites?.length > 0) {
            const siteNames = user.sites.split(",");
            const sitePromises = siteNames.map(siteName => this._sitesService.getSite(siteName));
            Promise.all(sitePromises)
              .then(sites => {
                this.SITES = sites;
                console.log("SITES: ", this.SITES); // Afficher le tableau SITES mis à jour
                console.log("user : ", this.user);
                this.message = null;
                this.DATA_IS_LOADED = true;
              })
              .catch(error => {
                console.error("Error fetching sites: ", error);
                this.message = "These sites/pages are no longer available";
                this.DATA_IS_LOADED = false;
                return;
              });
          } else {
            console.error("No sites found for this user");
            this.message = "No sites found for this user";
            this.DATA_IS_LOADED = false;
            return;
          }
        } else {
          console.error("User name " + jwtClaims["cognito:username"] + " does not match " + this.USER_NAME);
          this.message = "You are not authorized to access this resource";
          this.DATA_IS_LOADED = false;
          return;
        }
      });
    }).catch(error => {
      console.error("Error fetching user: ", error);
      this.message = "User not found";
      this.DATA_IS_LOADED = false;
      return;
    });


  }

  /** Navigate though components */
  navigate(route: string) {
    this.router.navigate([`/admin/${this.USER_NAME}/${route}`]);
  }

  signOut() {
    Auth.signOut();
  }

}
