import { Component, NgZone, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "./core/services/google-analytics.service";
import { environment } from "../environments/environment";
import { BeginsWithPipe } from "src/app/shared/pipes/begins-with.pipe";
import { Auth } from "@aws-amplify/auth";
import {
  AuthState,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
  onAuthUIStateChange,
} from "@aws-amplify/ui-components";
import { Hub } from "@aws-amplify/core";
import { RoutesService } from "./core/services/routes.service";
import { ROUTES } from "./core/classes/Routes";
import { SitesService } from "./core/services/sites.service";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { UserLoginService } from "./core/services/user-login.service";
import { take } from "rxjs/operators";
import { HttpclientService } from "./core/services/httpclient.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PasswordModalComponent } from "./shared/components/password-modal/password-modal.component";
import "rxjs/add/operator/toPromise";
declare var $: any;
declare let gtag: Function;

@Component({
  selector: "aac-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [BeginsWithPipe],
})
export class AppComponent implements OnInit {
  message: string = null;

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };

  LOGO_HEADER: string;
  LOGO_COMPANY: string;
  LOGO_MENU: string;

  CURRENT_LANGUAGE: string;
  LIST_OF_LANGUAGES: Array<string> = ["fr", "en", "it", "es"];
  LANGUAGES = {
    en: { long_language: "English", change_language: "Change language" },
    fr: { long_language: "Français", change_language: "Changer de langue" },
    es: { long_language: "Español", change_language: "Cambiar idioma" },
    it: { long_language: "Italiano", change_language: "Cambia lingua" },
  };

  inputPath: string = environment.inputPath;

  SITE_NAME: string = "";
  SITE: any = {};
  SITE_IS_LOADED: boolean = false;
  TRANSLATION_IS_LOADED: boolean = false;
  CONTENT_BLOCKING_MODAL_IS_LOADED: boolean = false;
  UUID: string = null;

  siteStructureSubjet = new BehaviorSubject<string>(null);
  menuLinks$: Observable<any>;

  googleAnalyticsId = environment.gaTrackingId;
  cookieAcceptanceIsDisplayed: boolean = null;
  cookiesAreAccepted: boolean = null;

  blockingModalContent = {
    title: "",
    content: "",
    accept: "",
    decline: "",
  };

  passwordModal = {
    title: "Default title",
    content: "Default content",
  };

  BLOCKING_MODAL_ACCEPTED: boolean = false;
  PASSWORD_MODAL_ACTIVATED: boolean = false;
  PASSWORD_ACCEPTED: boolean = false;

  colorHeader: string = "#264F6F";

  constructor(
    private router: Router,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private _cookieService: CookieService,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _httpClientService: HttpclientService,
    private ngZone: NgZone,
    private _userLoginService: UserLoginService,
    private _passwordDialog: MatDialog
  ) {
    /** Add list of languages to i18n languages */
    this.translate.addLangs(this.LIST_OF_LANGUAGES);

    /** On récupère les informations de la route actuelle */
    this._routesService.currentRoutes
      .pipe(take(2))
      .toPromise()
      .then((routes) => {
        if (routes.IS_PRIVATE) {
          this._userLoginService.refreshToken();
        }

        this.ROUTES = routes;
        console.info("ROUTES: ", routes);
        //Si nous avons des params de route
        if (routes.PARAMS) {
          if (routes.PARAMS["site"] != undefined)
            var site = routes.PARAMS["site"];
          this.siteStructureSubjet.next("language");
          site ? (this.SITE_NAME = site.toUpperCase()) : (this.SITE_NAME = "");
          //Si la route récupérée est publique, on va récupérée le site voulu
          if (this.ROUTES.IS_PUBLIC) {
            //on récupère l'uuid du QrCode
            this.UUID =
              routes.QUERY_PARAMS["uuid"] != undefined
                ? routes.QUERY_PARAMS["uuid"]
                : null;
            //get site Data
            this._sitesService.getSite(this.SITE_NAME).then((value) => {
              this.SITE = JSON.parse(JSON.stringify(value));
              this.checkAdminAcess().then((admin_acess) => {
                if (this.SITE.MISC.PASSWORD_ACTIVATED && !admin_acess) {
                  if (this.UUID != null) {
                    this.getQrCode()
                      .then((res) => {
                        console.info(res);
                        this.loadSite();
                      })
                      .catch((e) => {
                        console.error(e);
                        this.setRouteGlobal();
                        console.error("Unable to access the website");
                        this.router.navigate(["no-uuid"]);
                      });
                  } else {
                    //if no UUID in the url can not access the website
                    this.setRouteGlobal();
                    console.error(
                      "Unable to access the website, no uuid provided"
                    );
                    this.router.navigate(["no-uuid"]);
                  }
                } else this.loadSite();
              });
            });
          }
        }
      });
  }

  ngOnInit(): void {
    //function qui permet de passer l'étape de vérification du compte Cognito
    onAuthUIStateChange(async (authState: string, authData: any) => {
      if (authState === AuthState.VerifyContact) {
        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: AUTH_STATE_CHANGE_EVENT,
          message: AuthState.SignedIn,
          data: await Auth.currentAuthenticatedUser(),
        });
      }
      if (authState === AuthState.SignedIn) {
        const jwt = authData.signInUserSession.idToken.payload;
      }
    });

    onAuthUIStateChange((authState: string, authData: any) => {
      console.info("authState: ", authState);
      console.info("authData: ", authData);
      if (authState === AuthState.SignedIn) {
        this.ngZone.run(() => this.router.navigate([this.ROUTES.ROUTE]));
      }
    });
  }

  async getQrCode() {
    return new Promise((resolve, reject) => {
      this._httpClientService
        .getQRCode(this.SITE_NAME, this.UUID)
        .then((res) => {
          this.PASSWORD_MODAL_ACTIVATED =
            res["Items"][0].PASSWORD_ACTIVATED.BOOL;
          if (!this.PASSWORD_MODAL_ACTIVATED) resolve("No Password needed");
          else {
            this.translate.use(this.SITE.LANGUAGES[0]);
            this.translate
              .get("passwordModal")
              .subscribe((passwordModalTrad) => {
                if (passwordModalTrad.title && passwordModalTrad.content) {
                  this.passwordModal = {
                    title: passwordModalTrad.title,
                    content: passwordModalTrad.content,
                  };
                }
                let hashedPassword = res["Items"][0].PASSWORD.S;
                this.handlePassword(this.passwordModal, hashedPassword)
                  .then((data) => {
                    if (data.status == "LOGGEDIN") {
                      resolve("User Logged in");
                    } else reject("Unable to log in");
                  })
                  .catch((error) => reject(error));
              });
          }
        });
    });
  }

  async checkAdminAcess() {
    return new Promise((resolve, reject) => {
      this._userLoginService.getTokenJwtClaims().subscribe(
        (x) => {
          if (x["cognito:groups"][0] == "GlobalAdministrators") {
            resolve(true);
          } else if (
            x["cognito:groups"][0] == "SiteAdministrators" &&
            x["custom:sites"].split(",").includes(this.SITE_NAME)
          ) {
            resolve(true);
          } else resolve(false);
        },
        (err) => {
          console.error(err);
          resolve(false);
        }
      );
    });
  }

  setRouteGlobal() {
    this.ROUTES.IS_PRIVATE = false;
    this.ROUTES.IS_PUBLIC = false;
    this.ROUTES.IS_GLOBAL = true;
  }

  async handlePassword(modal: any, password: string) {
    const dialogConfig = new MatDialogConfig(); // to rename
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: modal.title,
      content: modal.content,
      key: password,
    };

    const dialogRef = this._passwordDialog.open(
      PasswordModalComponent,
      dialogConfig
    );

    return dialogRef.afterClosed().toPromise();
  }

  //On charge le site suite à la vérification du mots de passe avec le QrCode
  loadSite() {
    this.SITE_IS_LOADED = true;
    //Si on récupère un objet vide, il y a une erreur
    if (Object.keys(this.SITE).length <= 0) {
      console.error("Site doesn't exist");
      this.message =
        "This site/page is no longer \n available or has been deactivated";
      return;
    }
    if (!this.SITE.MISC.CONTENT_BLOCKING_MODAL_ACTIVATED)
      this.blockingModalAccepted();
    else {
      //On utilise comme langue par défaut la première langue du site
      this.translate.use(this.SITE.LANGUAGES[0]);
      //On récupère "contentBlockingModal" et on setup le contenu de la modal
      this.translate
        .get("contentBlockingModal")
        .subscribe((contentBlockingModal) => {
          //Si il manque un des contenus, on reste sur les trad par défaut
          if (
            contentBlockingModal.title &&
            contentBlockingModal.content &&
            contentBlockingModal.accept &&
            contentBlockingModal.decline
          ) {
            this.blockingModalContent = {
              title: contentBlockingModal.title,
              content: contentBlockingModal.content,
              accept: contentBlockingModal.accept,
              decline: contentBlockingModal.decline,
            };
          } else {
            this.blockingModalContent = {
              title: "Default title",
              content: "Default content",
              accept: "Default accept",
              decline: "Default decline",
            };
          }
          this.CONTENT_BLOCKING_MODAL_IS_LOADED = true;
        });
    }
    let analytics = sessionStorage.getItem("analytics_sent");
    if (analytics == null) {
      try {
        this._sitesService.putAnalytics(this.SITE_NAME, this.UUID);
        sessionStorage.setItem("analytics_sent", "true");
      } catch (e) {
        console.error(e);
      }
    }
  }

  setSiteContent() {
    this.observeMenuLinks();

    // by default, disable Google analytics default cookies
    window[`ga-disable-${this.googleAnalyticsId}`] = true;

    this.cookiesAreAccepted =
      this._cookieService.get("cookie-acceptance") === "true";
    if (this.cookiesAreAccepted) {
      // if cookies was accepted, we reactive the GA
      window[`ga-disable-${this.googleAnalyticsId}`] = false;
    } else {
      //else we keep blocked and removes google analytics cookies
      window[`ga-disable-${this.googleAnalyticsId}`] = true;
      this._cookieService.delete("_ga");
      this._cookieService.delete("_gid");
      this._cookieService.delete(`_gat_gtag_${this.googleAnalyticsId}`);
    }

    //On set la langue par défaut avec la première langue du tableau
    this.translate.setDefaultLang(this.SITE.LANGUAGES[0]);
    //On stock le status du site dans la variable ACTIVATED
    this.SITE["OPTIONS"]["ACTIVATED"] =
      this.SITE["OPTIONS"]["ACTIVATED"] == "true";
    //On set les cookies pour le domain atteint
    this.setCookie();
    //On récupère les traduction du site voulu
    this.setTranslation();
  }

  setCookie() {
    //On vérifie si l'acceptance des cookie a été faites
    if (this._cookieService.check("cookie-acceptance")) {
      this.cookieAcceptanceIsDisplayed = false;
    } else {
      this.cookieAcceptanceIsDisplayed = true;
    }

    //Si les cookie n'ont pas été accepté, on set la langue à la première langue du tableau de langues
    if (!this.cookiesAreAccepted) {
      console.info("Cookie not accepted");
      this.CURRENT_LANGUAGE = this.SITE.LANGUAGES[0];
      return;
    }

    //Si il n'y a pas de cookie 'language', on set la langue à la première langue du tableau de langues et on set le cookie
    if (!this._cookieService.check("language")) {
      console.info("No cookie 'language' detected");
      this.CURRENT_LANGUAGE = this.SITE.LANGUAGES[0];
      this._cookieService.set("language", this.CURRENT_LANGUAGE, 1, "/");
      return;
    }

    //Si la langue récupéré dans le cookie n'est pas dans le tableau de langues du site,
    //on set la langue à la première langue du tableau de langues et on set le cookie
    if (!this.SITE.LANGUAGES.includes(this._cookieService.get("language"))) {
      console.info("Cookie 'language' not detected in site's metadata");
      this.CURRENT_LANGUAGE = this.SITE.LANGUAGES[0];
      this._cookieService.set("language", this.CURRENT_LANGUAGE, 1, "/");
      return;
    }

    //Sinon, on récupère la langue du cookie comme langue par défaut
    this.CURRENT_LANGUAGE = this._cookieService.get("language");
  }

  setTranslation() {
    //On utilise la langue courrante via translate
    this.translate.use(this.CURRENT_LANGUAGE);
    console.debug("Translation language:", this.translate.currentLang);
    this.siteStructureSubjet.next("pages");

    //get logo values for display
    this.translate.get("graphics").subscribe((graphics) => {
      this.LOGO_COMPANY = graphics.menuBottomLogoPath;
      this.LOGO_HEADER = graphics.headerLogoPath;
      this.LOGO_MENU = graphics.menuTopLogoPath;
    });

    //On récupère les traductions pour les pages courrantes
    this.translate.get("pages").subscribe((pages) => {
      this.TRANSLATION_IS_LOADED = true;
      //on set la couleur de l'application à celle de la page courante
      if (this.ROUTES.IS_PUBLIC) {
        let page = pages.find((page) => this.ROUTES.ROUTE.includes(page.id));
        if (page != undefined && page["color"] != null) {
          this.colorHeader = page["color"];
        } else if (sessionStorage.getItem("colorHeader") != undefined) {
          this.colorHeader = sessionStorage.getItem("colorHeader");
        } else {
          this.colorHeader = "#264F6F";
        }
      }
      sessionStorage.setItem("colorHeader", this.colorHeader);
    });
  }

  /** Callback for Cookie Acceptance: triggered when user click on "Accept cookies" */
  cookiesAccepted() {
    //Store language selected as cookie
    this._cookieService.set("language", this.CURRENT_LANGUAGE, 1, "/");
    //Active google analytics cookies
    window[`ga-disable-${this.googleAnalyticsId}`] = false;
    //Push Traffic Sources
    gtag("config", this.googleAnalyticsId, {
      campaign: {
        source: sessionStorage.getItem("GA_SOURCE"),
        medium: sessionStorage.getItem("GA_MEDIUM"),
        name: sessionStorage.getItem("GA_CAMPAIGN"),
      },
    });
  }

  blockingModalAccepted() {
    this.BLOCKING_MODAL_ACCEPTED = true;
    this.setSiteContent();
  }

  /** Function to switch the language of the application */
  switchLanguage(language: string) {
    $("#languagesModal").modal("hide");
    this.CURRENT_LANGUAGE = language;
    if (this.CURRENT_LANGUAGE === null) return false;
    else {
      if (this.cookiesAreAccepted)
        this._cookieService.set("language", this.CURRENT_LANGUAGE, 1, "/");
      var route = "home" + "/" + this.SITE_NAME;
      this.router.navigate([route]);
      this.setTranslation();
    }
  }

  /** Send GA event when act button is pressed */
  sendActionEvent(eventName: string) {
    this.googleAnalyticsService.eventEmitter(
      eventName,
      "act",
      "survey",
      "click",
      10
    );
  }

  isLightColor(color: string) {
    const hex = color.replace("#", "");
    const rgb = hex.match(/.{1,2}/g)?.map((val) => parseInt(val, 16));
    if (rgb) {
      const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
      return yiq > 214; // valeur de luminance pour déterminer si la couleur est claire
    }
    return false;
  }

  closeModal() {
    $("#modalMessage").modal("hide");
  }

  signOut() {
    Auth.signOut();
  }

  private observeMenuLinks(): void {
    this.menuLinks$ = this.siteStructureSubjet.asObservable().pipe(
      switchMap(() => this.translate.get("menu.menuLinks")),
      tap((menuLinks: any) => console.debug("Menu links:", menuLinks))
    );
  }

  navigateToHome() {
    this.router.navigate(["home/" + this.SITE_NAME]);
  }
}
