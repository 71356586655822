import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileManagementService } from 'src/app/core/services/file-management.service';

@Component({
  selector: 'form-multi-pages',
  templateUrl: './multi-pages-main.component.html',
  styleUrls: [
    './multi-pages-main.component.scss',
    '../../content-management.scss',
    '../../../local-back-office.scss',
    '../../../../private.scss'
  ]
})
export class MainMultiPagesComponent implements OnInit, AfterViewChecked {

  @Input() siteName: string;
  @Input() parentForm: FormGroup;
  @Input() languages: any;
  @Input() NumberMaxOfPages: number;
  @Input() types: string[];
  @Output() pageChanged: EventEmitter<any> = new EventEmitter();

  files: { [type: string]: { [section: string]: File[][] } } = {
    primary: {
      page_edition: [],
      callToAction: [],
      callToActionLogo: [],
      callToActionMultiCta: []
    },
    secondary: {
      page_edition: [],
      callToAction: [],
      callToActionLogo: [],
      callToActionMultiCta: []
    }
  };

  selectedPage: string = 'null';

  constructor(
    private changeDetector: ChangeDetectorRef,
    private _fileManagement: FileManagementService,
  ) { }

  ngOnInit(): void { }

  ngAfterViewChecked() { this.changeDetector.detectChanges(); }

  onChangePages(event) {
    this.selectedPage = event;
    this.changePageId();
  }

  changePageId() {
    this.pageChanged.emit();
  }

  addFiles(event) {
    const { type, section, idPage, file } = event;

    if (!this.files[type]) {
      this.files[type] = {};
    }

    if (!this.files[type][section]) {
      this.files[type][section] = [];
    }

    if (!this.files[type][section][idPage]) {
      this.files[type][section][idPage] = [];
    }

    this.files[type][section][idPage].push(file);
  }

  /**
   * When sending form, get and send files to S3 bucket
   * @param SITE_NAME name of the site
   * @param type type of the language (primary or secondary)
   * @returns returns a promise
   */
  getAndSendFiles(SITE_NAME: string, type: string) {
    console.log(SITE_NAME);
    console.log(type);
    console.log(this.files);

    return new Promise(resolve => {
      let promises = [];
      if (this.files[type]) {
        for (const section of Object.keys(this.files[type])) {
          this.files[type][section].forEach(page => {
            page.forEach(file => {
              if (file) {
                let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
                promises.push(this._fileManagement.uploadFile(file, this.languages[0], extension, SITE_NAME, file.name));
              }
            });
          });
        }
      }

      Promise.all(promises).then((res) => {
        console.info(res);
        resolve(res);
      });
    });
  }

}
