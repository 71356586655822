import { NgModule } from '@angular/core';
import { PublicRoutingModule } from './public-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MainPageComponent } from './main-page/main-page.component';
import { CookieInformationComponent } from './cookie-information/cookie-information.component';
import { LegaleInformationComponent } from './legale-information/legale-information.component';
import { MapComponent } from './dashboard/map/map.component';
import { AgmCoreModule } from '@agm/core';
import { InnerHTMLComponent } from '../shared/components/inner-html/inner-html.component';
import { NotFoundModule } from './not-found/not-found.module';
import { ChartComponent } from './dashboard/chart/chart.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SurveyComponent } from './survey/survey.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiPagesComponent } from './multi-pages/multi-pages.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [MainPageComponent, CookieInformationComponent, LegaleInformationComponent, MapComponent, InnerHTMLComponent, ChartComponent, DashboardComponent, SurveyComponent, MultiPagesComponent],
  imports: [
    SharedModule,
    PublicRoutingModule,
    FormsModule, ReactiveFormsModule,
    AgmCoreModule,
    NotFoundModule,
    MatRadioModule,
    MatCheckboxModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule
  ]
})
export class PublicModule { }
