import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { SitesService } from 'src/app/core/services/sites.service';
import { COUNTERS } from 'src/app/core/classes/Counters';
import { SITE } from 'src/app/core/classes/Site';
import Auth from '@aws-amplify/auth';

declare var $: any;

@Component({
  selector: 'list-sites',
  templateUrl: './list-sites.component.html',
  styleUrls: [
    './list-sites.component.scss',
    '../sites-generator.scss',
    '../../global-back-office.scss',
    '../../../private.scss'
  ],
  providers: [DatePipe]
})
export class ListSitesComponent implements OnInit {

  DATA_IS_LOADED: boolean = false;

  filterForm: FormGroup;

  SITES: SITE[] = [];
  SAVE_SITES: SITE[] = [];
  filteredArray: any;
  //filteredArrayDiseases: any;
  filteredArrayLanguages: any;
  inputSearch_activated: string = "";

  COUNTERS: COUNTERS = {
    COUNTER_SITES_GENERATED: 0,
    COUNTER_SITE_ACTIVATED: 0,
    COUNTER_SITE_DEACTIVATED: 0
  };

  LANGUAGES = {
    "en": {"long_language": "English"},
    "fr": {"long_language": "Français"},
    "es": {"long_language": "Español"},
    "it": {"long_language": "Italiano"}
  }

  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private router: Router,
    private titleService: Title,
    private datePipe: DatePipe,
    private _localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    private _sitesService: SitesService
  ) {
    this.titleService.setTitle("Elefight - Site Management");

    this.DATA_IS_LOADED = false;

    //Get list of sites
    this._sitesService.getSites().then((value) => {
      console.log(value);
      this.SITES = value['sites'];

      //We remove sites ending by "-PREVIEW"
      this.SITES = this.SITES.filter((item) => {
        return !item.SITE.includes("-PREVIEW");
      });

      //For each site we set information with correct standards
      for(let i=0; i < this.SITES.length; i++){
        this.SITES[i]["CREATION_DATE"] = this.datePipe.transform(new Date(parseInt(this.SITES[i]["CREATION_DATE"])), 'dd/MM/yyyy');
        if(this.SITES[i]["LAST_ACTIVATION_DATE"] === '0')
          this.SITES[i]["LAST_ACTIVATION_DATE"] = 'Never activated';
        else
          this.SITES[i]["LAST_ACTIVATION_DATE"] = this.datePipe.transform(new Date(parseInt(this.SITES[i]["LAST_ACTIVATION_DATE"])), 'dd/MM/yyyy');
        if(this.SITES[i]["EXPIRATION_DATE"] === '0')
          this.SITES[i]["EXPIRATION_DATE"] = 'N/A';
        else
          this.SITES[i]["EXPIRATION_DATE"] = this.datePipe.transform(new Date(parseInt(this.SITES[i]["EXPIRATION_DATE"])), 'dd/MM/yyyy');

        this.SITES[i]['LANGUAGES_STRING'] = this.SITES[i]['LANGUAGES'].map(function(elem){
          let LANGUAGES = {
            "en": {"long_language": "English"},
            "fr": {"long_language": "Français"},
            "es": {"long_language": "Español"},
            "it": {"long_language": "Italiano"}
          }
          return LANGUAGES[elem].long_language;
        }).join(", ");
      }

      this.SAVE_SITES = JSON.parse(JSON.stringify(this.SITES));
      this.setCounters();

      /** Loop to get all cases for filter for Activated options : true or false */
      let array = [];
      for (let i = 0; i < Object.keys(this.SITES).length; i++)
        array.push(this.SITES[i]['OPTIONS']['ACTIVATED']);
      this.filteredArray = array.filter(function(item, pos){
        return array.indexOf(item) == pos;
      });
      /** End of loop */

      /** Loop to store all available languages in the array of sites */
      array = [];
      for (let i = 0; i < Object.keys(this.SITES).length; i++){
        this.SITES[i]['LANGUAGES'].forEach(element => {
          array.push(element);
        });
      }
      this.filteredArrayLanguages = array.filter(function(item, pos){
        return array.indexOf(item) == pos;
      });

      /** End of loop */

      //Function to sort alphabetically array of sites
      this.SITES.sort(function(a, b){
        if(a.SITE < b.SITE) { return -1; }
        if(a.SITE > b.SITE) { return 1; }
        return 0;
      })

      //Configuration for calendar
      this.bsConfig = Object.assign({}, {
        adaptivePosition: true,
        isAnimated: true,
        containerClass: 'theme-sanofi',
        dateInputFormat: 'DD/MM/YYYY'
      });
      this._localeService.use('fr');
      this.DATA_IS_LOADED = true;
    })
    .catch((error) => {
      console.log(error);
    });
  }

  ngOnInit(): void {
    this.initForms();
    $('.dropdown.keep-open').on({
      "shown.bs.dropdown": function() { this.closable = false; },
      "click":             function() { this.closable = true; },
      "hide.bs.dropdown":  function() { return this.closable; }
    });
  }

  /**
   * Init forms filters
   */
  initForms(){
    this.filterForm = this.formBuilder.group({
      SITE: [''],
      LANGUAGES: [''],
      DISEASES: [''],
      ACTIVATED: [''],
      CREATION_DATE: [null],
      LAST_ACTIVATION_DATE: [null],
      EXPIRATION_DATE: [null]
    });
  }

  onClick($event:Event){
    $event.stopPropagation();
  }

  /**
   * Navigate though components
   * @param route route to reach
   * @param site name of the site
   */
  navigate(route: string, site?: string){
    if(site) this.router.navigate([`/admin/${route}/${site}`])
    else this.router.navigate([`/admin/${route}`]);
  }

  /**
   * Redirect to modification page
   * @param site name of the site
   */
  modifySite(site: string){
    this.router.navigate([`/admin/sites/sites-management/${site.toLowerCase()}`]);
  }

  /**
   * Function to filter search
   */
  onFilter(){
    $('.dropdown-toggle').dropdown('hide');

    const formValue = this.filterForm.value;
    this.SITES = JSON.parse(JSON.stringify(this.SAVE_SITES));
    // console.log(this.datePipe.transform(new Date(Date.parse(formValue['CREATION_DATE'])), 'dd/MM/yyyy'));
    console.log(formValue);
    console.log(this.SITES);
    if (formValue['SITE'])
      this.SITES = this.SITES.filter(item => item["SITE"].includes(formValue['SITE']));
      //this.SITES = this.SITES.filter(item => item["SITE"].startsWith(formValue['SITE']));
    if (formValue['LANGUAGES'])
      this.SITES = this.SITES.filter(item => item["LANGUAGES"].includes(formValue['LANGUAGES']));
    if (formValue['DISEASES'])
      this.SITES = this.SITES.filter(item => item["DISEASES"].includes(formValue['DISEASES']));
    if (formValue['ACTIVATED']) {
      if(formValue['ACTIVATED'] === 'false')
        this.SITES = this.SITES.filter(item => item["OPTIONS"]["ACTIVATED"] === "false");
      if(formValue['ACTIVATED'] === 'true')
        this.SITES = this.SITES.filter(item => item["OPTIONS"]["ACTIVATED"] === "true");
    }
    if(formValue['CREATION_DATE'])
      this.SITES = this.SITES.filter(item => item["CREATION_DATE"] === this.datePipe.transform(new Date(Date.parse(formValue['CREATION_DATE'])), 'dd/MM/yyyy'));
    if(formValue['LAST_ACTIVATION_DATE'])
      this.SITES = this.SITES.filter(item => item["LAST_ACTIVATION_DATE"] === this.datePipe.transform(new Date(Date.parse(formValue['LAST_ACTIVATION_DATE'])), 'dd/MM/yyyy'));
    if(formValue['EXPIRATION_DATE'])
      this.SITES = this.SITES.filter(item => item["EXPIRATION_DATE"] === this.datePipe.transform(new Date(Date.parse(formValue['EXPIRATION_DATE'])), 'dd/MM/yyyy'));
    console.log(this.SITES);

    this.setCounters();
  }

  /**
   * Remove all filters and restore list
   */
  removeFilters() {
    this.initForms();
    this.SITES = JSON.parse(JSON.stringify(this.SAVE_SITES));
    this.setCounters();
  }

  /**
   * Close filters dropdown
   */
  closeDropdown(){
    $('dropdown-toggle').dropdown('toggle');
  }

  /**
   * Set counters for sites recap
   */
  setCounters(){
    this.resetCounters();
    this.COUNTERS.COUNTER_SITES_GENERATED = this.SITES.length;
    this.SITES.forEach(item => {
      switch (item["OPTIONS"]["ACTIVATED"]) {
        case 'true':
          this.COUNTERS.COUNTER_SITE_ACTIVATED++;
          break;
        case 'false':
          this.COUNTERS.COUNTER_SITE_DEACTIVATED++;
          break;
        default:
          console.error("Error");
      }
    })
  }

  /**
   * Reset counters
   */
  resetCounters(){
    this.COUNTERS = {
      COUNTER_SITES_GENERATED: 0,
      COUNTER_SITE_ACTIVATED: 0,
      COUNTER_SITE_DEACTIVATED: 0
    };
  }

  signOut(){
    Auth.signOut();
  }
}
