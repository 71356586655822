<div class="callToActionMultiCta">
    <div class="multi_cta_container">
        <div class="scroll_container">
            <div class="cta_container">
                <div *ngFor="let CTA of CTA_LIST; let j = index" id="cta_item_{{j}}" class="cta_item"
                    [ngClass]="{
                    'small-cta': CTA.size === 'Small',
                    'medium-cta': CTA.size === 'Medium',
                    'large-cta': CTA.size === 'Large'
                    }" [ngStyle]="{'background': CTA.bgColor ? CTA.bgColor : 'rgb(228 228 228 / 90%)'}">
                    <div *ngIf="CTA.logo !== ''" class="cta_logo">
                        <img [src]="ENVIRONMENT_PATH + 'SITES/' + SITE_NAME + '/IMG/' + CTA.logo"
                            width="50px" height="50px" alt="Logo" />
                    </div>
                    <div *ngIf="CTA.text !== ''" class="cta_text_container">
                        <p class="labelLike"
                            [ngStyle]="{'color': CTA.textColor ? CTA.textColor : '#2b2b38'}"
                            [innerHTML]="CTA.text">
                        </p>
                    </div>
                    <div class="info_bulle">
                        <div class="figures">
                            <div class="figure-container">
                                <div class="row text">
                                    CLICKS
                                </div>
                                <div class="row figure">
                                    <div class="col-4 d-flex align-items-center justify-content-center p-0">
                                        {{CTA.metrics.totalEvents.value}} - {{CTA.metrics.totalEvents.percent.toFixed(2)}}%
                                    </div>
                                </div>
                            </div>
                            <div class="figure-container">
                                <div class="row text">
                                    USERS
                                </div>
                                <div class="row figure">
                                    <div class="col-4 d-flex align-items-center justify-content-center p-0">
                                        <!-- {{CTA.metrics.uniqueEvents.value}} - {{CTA.metrics.uniqueEvents.percent.toFixed(2)}}% -->
                                        {{CTA.metrics.uniqueEvents.value}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>