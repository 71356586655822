import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { Title } from '@angular/platform-browser';
import { SitesService } from 'src/app/core/services/sites.service';
import { v4 as uuid } from 'uuid';
import Auth from '@aws-amplify/auth';
import { languages_array } from 'src/app/core/classes/Languages';
import { generateUUID } from 'src/app/core/helpers/generate-uuid';
declare var $: any;

@Component({
  selector: 'sites-generator',
  templateUrl: './sites-generator.component.html',
  styleUrls: [
    './sites-generator.component.scss',
    '../sites-generator.scss',
    '../../global-back-office.scss',
    '../../../private.scss'
  ]
})
export class SitesGeneratorComponent implements OnInit {

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null
  };

  numberMaxOfPages: number = 10;
  arrayNumberMaxOfPages: Array<Number> = [];

  generateForm: FormGroup;
  submitted: boolean = false;
  languages_array: Array<any> = languages_array;
  
  forbbidenSiteNames = [];

  errorMessage: string;
  buttons = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private _sitesService: SitesService
  ) {
    this.titleService.setTitle("Elefight - Site Generator");

    //We generate array from numberMaxOfPages in order to loop on it to display choice of number of pages
    this.arrayNumberMaxOfPages = Array(this.numberMaxOfPages).fill(0).map((x,i)=>i+1);

    //Get list of sites names that already exists in DynamoDB
    this._sitesService.getSitesNames()
      .then((response: Array<string>) => {
        response = response.filter((item) => {
          return !item.includes("-PREVIEW");
        });
        console.log(response);
        this.forbbidenSiteNames = response;
      });
  }

  ngOnInit(): void {
    this.initForms();
    for (let i=1; i<=5; i++) {
      this.buttons.push({
        id: i, show: false
      });
    }
  }

  /** Init different forms */
  initForms(){
    this.generateForm = this.formBuilder.group({
      site: ['', [
        Validators.required,
        (control: AbstractControl) => {
          return this.forbbidenSiteNames.indexOf(control.value.toUpperCase()) === -1 ? null : {'forbiddenValue': true};
        },
        (control: AbstractControl) => {
          return control.value.toUpperCase().endsWith("-PREVIEW")  ? {'forbiddenName': true} : null;
        }
      ]],
      languages: this.formBuilder.array([]),
      mapEnabled: ['No', Validators.required],
      maxPages: ['1', Validators.required],
      activated: [false],
      creationDate: [Date.now()],
      lastActivationDate: [0],
      expirationDate: [0],
      uuidArray: [],
    });
    this.addLanguage();
  }

  get gF() { return this.generateForm.controls; }

  /** ---- Language Management ---- */
  languages() : FormArray { return this.generateForm.get("languages") as FormArray }
  addLanguage() {
    const newLanguage : FormGroup = this.formBuilder.group({
      language: ['', [
        Validators.required,
        RxwebValidators.unique()
      ]]
    });
    this.languages().push(newLanguage);
  }
  removeLanguage(i:number) {
    this.languages().removeAt(i);
  }
  /** ---- Language Management ---- */

  /** onSubmit function */
  onSubmit() {
    this.submitted = true;
    console.log(this.generateForm);

    // stop here if form is invalid
    if (this.generateForm.invalid) {
      return;
    }

    const languages = this.generateForm.value.languages.map(function(elem){
      let LANGUAGES = {
        "en": {"long_language": "English"},
        "fr": {"long_language": "Français"},
        "es": {"long_language": "Español"},
        "it": {"long_language": "Italiano"}
      }
      return LANGUAGES[elem.language].long_language;
    }).join(", ");

    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "save";
    this.ALERT_MESSAGE.MESSAGE = 
    `<p>Are you sure you want to create this site ?</p><br/>
    <span>Site name : ${this.generateForm.value.site.toUpperCase()}</span><br/>
    <span>Languages : ${languages}</span><br/>
    <span>Map enabled : ${this.generateForm.value.mapEnabled}</span><br/>
    <span>Number of pages : ${this.generateForm.value.maxPages}</span><br/>`;

    console.log("Form is valid : ", this.generateForm.value);
  }

  /** Callback after alert modal for save */
  callbackActionModal_save(){
    while(this.ALERT_MESSAGE.IS_DISPLAYED)
      this.callbackResetModal(this.ALERT_MESSAGE);

    //Set the variable submitted to true
    this.submitted = true;

    $(".toast-wait").toast({autohide: false});
    $('.toast-wait').toast('show');

    //Stop here if the form is invalid
    if(this.generateForm.invalid) {
        return;
    }
    
    this.generateForm.value.activated = false;
    this.generateForm.value.site = this.generateForm.value.site.toUpperCase();
    this.generateForm.value.languages = this.generateForm.value.languages.map(l => l.language.toString());
    this.generateForm.value.mapEnabled = this.generateForm.value.mapEnabled == 'Yes' ? true : false;
    this.generateForm.value.maxPages = parseInt(this.generateForm.value.maxPages);

    console.log("Create site : ");

    let promises = [];
    let uuidArray = [];

    for(let i=0; i<this.generateForm.value.maxPages; i++){
      uuidArray.push(generateUUID()) ;
    }
    this.generateForm.value.uuidArray = uuidArray;

    console.log(this.generateForm.value);

    promises.push(this._sitesService.postSite(this.generateForm.value));
    
    Promise.all(promises).then((values) => {
      console.log(values);
      if(values[0].error){
        $('.toast-wait').toast('hide');
        this.errorMessage = values[0].message;
        $(".toast-alert").toast({autohide: false});
        $('.toast-alert').toast('show');
        return;
      }
      this.ALERT_MESSAGE.IS_DISPLAYED = true;
      this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
      this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
      this.ALERT_MESSAGE.MESSAGE = `Site generated successfully`;
      $('.toast-wait').toast('hide');
      this.submitted = false;
    });
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE){
    if(ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal'){
      this.navigate('sites/sites-management',this.generateForm.value.site.toLowerCase());
      this.resetModal();
    }
    this.resetModal();
  }

  /** Reset Modal */
  resetModal(){
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Navigate though components */
  navigate(route: string, site?: string){
    if(site) this.router.navigate([`/admin/${route}/${site}`])
    else this.router.navigate([`/admin/${route}`]);
  }

  signOut(){
    Auth.signOut();
  }
}
