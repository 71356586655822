<form [formGroup]="parentForm">
    <div [formGroupName]="'pages_' + type">
        <div [formGroupName]="id">
            <div formGroupName="pageImageFields" class="form-group">
                <div class="row my-2">
                    <p> <span class="labelLike">Image </span> <span class="aspect-indication">(Aspect Ratio 9:16 - Size Max. 2MB)</span></p>
                </div>
                <div class="row my-2">
                    <div class="col-md-6 px-0">
                        <input type="file" class="custom-file-input" accept="image/*" formControlName="imagePath" [id]="'formPageImageFields_imagePath_'+id" (change)="onChangeFile($event)">
                        <label class="custom-file-label" for="imagePath">Upload</label>
                        <div *ngIf="pageImageFields(id).get('imagePath').invalid" class="alert alert-danger">
                            <div *ngIf="pageImageFields(id).get('imagePath').errors.invalidFileSize">
                                This file is too large. It must be less than 2MB.
                            </div>
                            <div *ngIf="pageImageFields(id).get('imagePath').errors.extension">
                                File format not allowed. It must be an image.
                            </div>
                            <div *ngIf="pageImageFields(id).get('imagePath').errors.unauthorizedFileName">
                                File name not allowed. It must not contains any special characters other than "-" and "_" .
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <p *ngIf="pageImageFields(id).value.imagePath; else no_value_image" class="labelLike">{{pageImageFields(id).value.imagePath}}</p>
                        <ng-template #no_value_image>
                            <p class="labelLike">No file</p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>