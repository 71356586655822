export const COUNTRYBOUNDS = {
    "FRANCE": [
        {
            "points": [
                { "lat": 40.38830890363259, "lng": -3.7878880906631593 },
                { "lat": 50.83384253438784, "lng": 2.202494085000012 },
                { "lat": 48.91124190123776, "lng": 7.6025390625 },
                { "lat": 44.043699407461176, "lng": 7.734375 },
                { "lat": 41.57436130598913, "lng": 9.404296875 },
                { "lat": 42.47566841495366, "lng": 2.5927734375 },
                { "lat": 43.37663183351989, "lng": -1.1865234375 },
                { "lat": 46.7883315503137, "lng": -1.4501953125 },
                { "lat": 47.97845561559518, "lng": -3.8232421875 }
            ]
        }
    ],
    "ROMANIA": [
        {
            "points": [
                { "lat": 45.96038021061398, "lng": 20.837211814685134 },
                { "lat": 45.03953495017037, "lng": 28.67488136605766 },
                { "lat": 47.72646676738365, "lng": 25.956614074273375 },
                { "lat": 44.07108670558748, "lng": 24.959916603727606 }
            ]
        }
    ],
    "SPAIN": [
        {
            "points": [
                { "lat": 43.251064088021664, "lng": -8.162040993346931 },
                { "lat": 43.31504630500917, "lng": -6.40422849334693 },
                { "lat": 43.05871376093895, "lng": -3.5917284933469307 },
                { "lat": 42.575190369969306, "lng": 0.6709668191530493 },
                { "lat": 41.76092493509889, "lng": 2.5166699441530493 },
                { "lat": 39.6626510176978, "lng": 3.0440136941530493 },
                { "lat": 36.973878546574845, "lng": -2.5370409933469307 },
                { "lat": 36.90362948131664, "lng": -5.78899411834693 },
                { "lat": 39.119257051684905, "lng": -6.79973630584693 },
                { "lat": 42.52359387011524, "lng": -9.22222222 }
            ]
        }
    ]
}