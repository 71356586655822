import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/** Material import */
import { MatButtonModule } from  '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule  } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { PrivateModule } from './private/private.module';
import { PublicModule } from './public/public.module';
import { SharedModule } from './shared/shared.module';
import { TranslationService } from './core/services/translation.service';

/** Translate Service */
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
/** Cookie Service */
import { CookieService } from 'ngx-cookie-service';
/** Google Maps Service */
import { AgmCoreModule } from '@agm/core';
//Google Analytics Service
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
/** Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { HttpInterceptorProviders} from './core/interceptors/interceptor-provider';
/** Scanner for QR Code */
import { ZXingScannerModule } from '@zxing/ngx-scanner';
/** Import QR Code Creation module */
import { NgxKjuaModule } from 'ngx-kjua';
/** Reactive Forms Service */
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
/** Color Picker Service */
import { ColorPickerModule } from 'ngx-color-picker';
/** Datatable Service */
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
/** CSV Parser Service */
import { NgxCsvParserModule } from 'ngx-csv-parser';

/** Set value for Datepicker */
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
defineLocale('fr', frLocale);

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
/** the second parameter 'fr' is optional */
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    PrivateModule,
    PublicModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    DragDropModule,
    FormsModule, ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKeyGoogleMaps,
      libraries: ['places', 'drawing', 'geometry']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient]
      }
    }),
    AmplifyUIAngularModule, /* Add Amplify module */
    ZXingScannerModule,
    NgxKjuaModule,
    RxReactiveFormsModule,
    ColorPickerModule,
    NgxDatatableModule,
    NgxCsvParserModule
  ],
  providers: [
    CookieService,
    GoogleAnalyticsService,
    HttpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }