<form [formGroup]="parentForm">

    <!-- Content Blocking Modal Title -->
    <div class="form-group">
        <p class="labelLike">Title</p>
        <input type="text" id="contentBlockingModalTitle_{{type}}" class="form-control"
            [formControlName]="'contentBlockingModalTitle_' + type" />
    </div>

    <!-- Content Blocking Modal Content -->
    <div class="form-group">
        <p class="labelLike">Content</p>
        <ckeditor [editor]="Editor" [config]="editorConfig" id="contentBlockingModalContent_{{type}}" [formControlName]="'contentBlockingModalContent_' + type"></ckeditor>
    </div>

    <!-- Content Blocking Modal Accept -->
    <div class="form-group">
        <p class="labelLike">"Accept" button</p>
        <input type="text" id="contentBlockingModalAccept_{{type}}" class="form-control"
            [formControlName]="'contentBlockingModalAccept_' + type" />
    </div>

    <!-- Content Blocking Modal Decline -->
    <div class="form-group">
        <p class="labelLike">"Decline" button</p>
        <input type="text" id="contentBlockingModalDecline_{{type}}" class="form-control"
            [formControlName]="'contentBlockingModalDecline_' + type" />
    </div>
</form>