import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingComponent } from './reporting/reporting.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBootstrapModule } from '../../modules/ngx-bootstrap.module';
import { MainFiguresComponent } from './main-figures/main-figures.component';
import { CellContentComponent } from './cell-content/cell-content.component';
import { CallToActionsReportingComponent } from './call-to-actions-reporting/call-to-actions-reporting.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxKjuaModule } from 'ngx-kjua';
import { ColorPickerModule } from 'ngx-color-picker';
import { MaterialModule } from 'src/app/material-module';

@NgModule({
  declarations: [ReportingComponent, MainFiguresComponent, CellContentComponent, CallToActionsReportingComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgxBootstrapModule,
    NgxDatatableModule,
    CKEditorModule,
    ZXingScannerModule,
    NgxKjuaModule,
    ColorPickerModule,
    MaterialModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    NgxBootstrapModule,
    NgxDatatableModule,
    CKEditorModule,
    ZXingScannerModule,
    NgxKjuaModule,
    ColorPickerModule,
    ReportingComponent,
    MaterialModule
  ]
})
export class ReportingModule { }
