import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitesGeneratorModule } from './sites-generator/sites-generator.module';
import { GlobalReportingComponent } from './reporting/reporting.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxKjuaModule } from 'ngx-kjua';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatOptionModule } from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
  declarations: [GlobalReportingComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    CKEditorModule,
    ZXingScannerModule,
    NgxKjuaModule,
    SitesGeneratorModule,
    MatOptionModule,
    MatFormFieldModule,
    MatChipsModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    CKEditorModule,
    ZXingScannerModule,
    NgxKjuaModule,
    ColorPickerModule,
    MatOptionModule
  ],
  

})
export class GlobalBackOfficeModule { }
