import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { request } from 'http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAPIService {

  /** URL de l'API */
  apiURL = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  /**
   * Get data from Google Anlaytics by calling API /analyticsreporting/reportbatchget
   * @param date Range of dates
   * @param dimensionFilterExpressions Filters
   * @param dimensionFilterClausesSelectOption Filters without campaign, source, medium
   * @returns returns a promise with results
   */
  getData(date, dimensionFilterExpressions, dimensionFilterClausesSelectOption, siteName, requestedSites: Array<string> = []) {
    var body = this.generateBodyRequest(date, dimensionFilterExpressions, dimensionFilterClausesSelectOption, siteName, requestedSites);
    return this.httpClient.post(`${this.apiURL}/analyticsreporting/reportbatchget`, body).toPromise();
  }

 /**
  * Get data from Google Anlaytics by calling API /analyticsreporting/reportbatchget
  * @returns returns the file path
  */
  generateExcelFile(clientUUID, siteName) {
    return this.httpClient.get(`${this.apiURL}/analyticsreporting/reportsbatchdownload?clientUUID=${clientUUID}&siteName=${siteName}`).toPromise();
  }

  /**
  * Format attendu par l'API :
  * {
  *   dateRanges: [
  *     {
  *       startDate: string,
  *       endDate: string
  *     }
  *   ],
  *   dimensionFilterClauses: [
  *     {
  *       operator: string,
  *       filters: [
  *         {
  *           dimensionName: string,
  *           operator: string,
  *           expressions: [
  *             string
  *           ]
  *         }
  *       ]
  *     }
  *   ],
  *   dimensionFilterClausesSelectOption: [
  *     {
  *       operator: string,
  *       filters: [
  *         {
  *           dimensionName: string,
  *           operator: string,
  *           expressions: [
  *             string
  *           ]
  *         }
  *       ]
  *     }
  *   ]
  * }
  */
  /**
   * Generate the body of the request
    * @param date Range of dates
    * @param dimensionFilterExpressions Filters
    * @param dimensionFilterClausesSelectOption Filters without campaign, source, medium
   * @returns return a body request
   */
  generateBodyRequest(date, dimensionFilterExpressions, dimensionFilterClausesSelectOption, siteName, requestedSites: Array<string> = []) {
    var body = {
      dateRanges: [date],
      siteName: siteName,
      requestedSites: requestedSites,
    };

    if (dimensionFilterExpressions.length > 0) {
      body['dimensionFilterClauses'] = {
        "andGroup": {
          "expressions": dimensionFilterExpressions,
        }
      };
    }

    if (dimensionFilterClausesSelectOption.length > 0) {
      body['dimensionFilterClausesSelectOption'] = {
        "andGroup": {
          "expressions": dimensionFilterClausesSelectOption,
        }
      };
    }

    return body;
  }

  /**
   * Get reporting information about current site in DynamoDB (Forms, QR Codes, etc...)
   * @param queryParams Filters
   * @returns return a JSON of results
   */
  old_getReportingData(queryParams: any) {
    var URL: string = `${this.apiURL}/sites/report`;

    if (queryParams != null && queryParams.length > 0) {
      let params = new HttpParams();
      queryParams.forEach(param => {
        params = params.set(param.label, param.value);
      });
      URL = `${URL}?${params.toString()}`;
    }

    console.debug(URL);

    return this.httpClient.get(URL).toPromise();
  }

  getReportingData(body) {
    console.debug(`POST ${this.apiURL}/sites/report`);
    return this.httpClient.post(`${this.apiURL}/sites/report`, body,).toPromise();
  }
}
