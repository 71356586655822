import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormArray, Validators, FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { Clipboard } from "@angular/cdk/clipboard";

import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import { RxwebValidators } from "@rxweb/reactive-form-validators";

import { jsPDF } from "jspdf";
import * as JSZip from "jszip";
import * as FileSaver from "file-saver";

import { environment } from "src/environments/environment";

import { HttpclientService } from "src/app/core/services/httpclient.service";
import { ShareDataService } from "src/app/core/services/share-data.service";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { RoutesService } from "src/app/core/services/routes.service";
import { SitesService } from "src/app/core/services/sites.service";
import { ROUTES } from "src/app/core/classes/Routes";
import { QR_CODES_CONFIG } from "src/app/core/classes/QRCodesConfig";
import { AdminFormService } from "src/app/core/services/admin-form.service";
import { QRCodeGenerationService } from "src/app/core/services/qrcode-generation.service";
import { PasswordModalComponent } from "./password-modal/password-modal.component";
import { fileName } from "src/app/core/validators/fileName";
import { maximumFileSize } from "src/app/core/validators/maximumFileSize";
import { listOfImagesExtensions } from "src/app/core/classes/ImagesExtensions";
import { FileManagementService } from "src/app/core/services/file-management.service";
import { generateUUID } from "src/app/core/helpers/generate-uuid";
import { getCurrentRoute } from "src/app/core/helpers/window-location";

declare var $: any;

@Component({
  selector: "aac-update",
  templateUrl: "./update.component.html",
  styleUrls: [
    "./update.component.scss",
    "../qr-code-management.scss",
    "../../local-back-office.scss",
    "../../../private.scss",
  ],
})
export class QRUpdateComponent implements OnInit {
  canvasConfig: ElementRef;
  canvasCheckAndPair: ElementRef;

  @ViewChild("canvasConfig", { static: false }) set contentConfig(
    contentConfig: ElementRef
  ) {
    if (contentConfig) {
      this.canvasConfig = contentConfig;
      for (let i = 0; i < this.qrcodes().length; i++) {
        this.generateCanvasForOneQRCode(i);
      }
    }
  }

  @ViewChild("canvasCheckAndPair", { static: false }) set contentCheckAndPair(
    contentCheckAndPair: ElementRef
  ) {
    if (contentCheckAndPair && this.QRCodeCheckAndPairURL) {
      this.canvasCheckAndPair = contentCheckAndPair;
      this.QRCodeCheckAndPairURL.append(this.canvasCheckAndPair.nativeElement);
    }
  }

  qrCodeSettingsInitialized = false;

  message: string = null;
  oneQRCodeIsCorrupted: boolean = false;

  isMobile: boolean = false;
  isEpidMapActivated: boolean = false;

  allchecked = false;

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };
  SITE: any = {};
  SITE_NAME: string = "";

  SITE_IS_DEACTIVATED: boolean = false;
  DATA_IS_LOADING: boolean = false;

  QR_CODES_CONFIG: QR_CODES_CONFIG = {
    COLOR: "#FFFFFF",
    SIZE: 300,
    MARGIN: 0,
    TYPE_NUMBER: 0,
    MODE: "Byte",
    ERROR_CORRECTION_LEVEL: "Q",
    COLOR_TYPE: 1,
    GRADIENT_OPTION: {
      TYPE: "linear",
      ROTATION: 0,
      COLOR_STOPS: [
        {
          OFFSET: 0,
          COLOR: "#FFFFFF",
        },
        {
          OFFSET: 1,
          COLOR: "#FFFFFF",
        },
      ],
    },
    IMAGE: {
      IMAGE_NAME: "Logo_Elefight.png",
      IS_BACK_DOTS_HIDDEN: true,
      IMAGE_SIZE: 0.3,
      MARGIN: 0,
    },
    DOTS: {
      STYLE: "rounded",
      COLOR_TYPE: 1,
      COLOR: "#000000",
      DOTS_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
    CORNERS_SQUARE: {
      COLOR: "#000000",
      STYLE: "extra-rounded",
      COLOR_TYPE: 1,
      CORNERS_SQUARE_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
    CORNERS_DOTS: {
      COLOR: "#000000",
      STYLE: "dot",
      COLOR_TYPE: 1,
      CORNERS_DOTS_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
  };

  /**ALERT_MESSAGE object to feed the alertMessage component child */
  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  search: any;
  SAVE_search: any;
  numberOfQRCodes: number;
  dynamicForm: FormGroup;

  filter = {
    searchType: "contains",
    searchText: null,
    filtersActivated: false,
  };

  imageQRCode: HTMLImageElement = new Image();

  ITEM_TO_ZIP: any;

  landing_page_array = [];

  checkAndPairURL: string = "";
  QRCodeCheckAndPairURL;

  QRCodesGeneration = {
    numberOfQRCodesToGenerate: 1,
    message: "",
  };

  typesOfQRCodes = ["png", "jpeg", "svg", "pdf"];

  constructor(
    private _httpClientService: HttpclientService,
    private _qrCodeGeneration: QRCodeGenerationService,
    private _shareData: ShareDataService,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _httpService: HttpclientService,
    private _adminFormService: AdminFormService,
    private _fileManagementService: FileManagementService,
    private router: Router,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private clipboard: Clipboard,
    private passwordDialog: MatDialog,
    private datePipe: DatePipe
  ) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.titleService.setTitle("Elefight - QR Code Management");
    this.message = "Data is loading...";
    this.imageQRCode.crossOrigin = "Anonymous";
    this.imageQRCode.src = "../../../../../assets/img/Logo_Elefight.png";

    /** Get routes information then get site information*/
    this._routesService.currentRoutes.subscribe((routes) => {
      this.ROUTES = routes;
      if (routes.PARAMS) {
        if (routes.PARAMS["site"] != undefined)
          var site = routes.PARAMS["site"];
        site ? (this.SITE_NAME = site.toUpperCase()) : (this.SITE_NAME = "");
        this.checkAndPairURL = `${getCurrentRoute()}/admin/${this.SITE_NAME.toLowerCase()}/qr-code-management/check-and-pair`;
        this.QRCodeCheckAndPairURL = this._qrCodeGeneration.getQRCodeData(
          this.checkAndPairURL,
          "../../../../../assets/img/Logo_Elefight.png",
          250,
          this.QR_CODES_CONFIG
        );
      }
    });
  }

  /** on init, we get the site information and the translations for the site */
  ngOnInit(): void {
    this.DATA_IS_LOADING = true;
    this.landing_page_array.length = 0;
    this._sitesService
      .getSite(this.SITE_NAME)
      .then((value) => {
        this.SITE = JSON.parse(JSON.stringify(value));
        console.debug("SITE = ", this.SITE);

        //Check if site exist and if it's activated
        if (
          Object.keys(this.SITE).length <= 0 ||
          this.SITE.OPTIONS.ACTIVATED == "false"
        ) {
          console.error("Site doesn't exist or has been deactivated");
          this.message =
            "This site/page is no longer  \n available or has been deactivated";
          this.SITE_IS_DEACTIVATED = true;
          this.DATA_IS_LOADING = false;
          return;
        }

        //for each language of the site, get the site translations then check the activation of the epidemic map
        var promises = [];
        this.SITE.LANGUAGES.forEach((langue) => {
          promises.push(
            this._httpClientService.getTranslation(this.SITE_NAME, langue)
          );
        });
        Promise.all(promises).then((value) => {
          for (let i = 0; i < value.length; i++) {
            if (!this.isEpidMapActivated) {
              this.isEpidMapActivated = value[i]["map"]["activated"];
            }
          }
          let isMapEnabled =
            this.SITE.OPTIONS.MAP_ENABLED === "true" ? true : false;
          if (isMapEnabled && this.isEpidMapActivated) {
            this.landing_page_array.push({
              Key: "Epidemic Map",
              Value: `/home-map/${this.SITE_NAME.toLowerCase()}`,
            });
          }
          this.getTranslation(this.SITE.SITE, this.SITE.LANGUAGES);
          this.initForms();
          this.getAllQRCodes();
        });
      })
      .catch((error) => this.callbackHTTPClient(error));
  }

  /**
   * Async function to consume service httpClientService and its function getTranslation
   * @param siteName Name of the site that we are consulting
   * @param language Language for which we want to get the translation
   */
  private async loadTranslation(siteName: string, language: string) {
    const translation = await this._httpService.getTranslation(
      siteName,
      language
    );
    return [language, translation];
  }

  /**
   * Get all translations for all languages in the order to fill the list of pages generated and activated
   * @param siteName Name of the site that we are consulting
   * @param languages Language(s) of the site
   */
  public getTranslation(siteName: string, languages: Array<string>) {
    Promise.all(languages.map((l) => this.loadTranslation(siteName, l))).then(
      (languages) => {
        let pageIDAlreadyAdded: Array<string> = [];
        let landing_page_array = [];
        /** We loop on each languages to check if at least one page is activated.
         *  We add each page activated for each languages
         *  Before to add, we check if the pages is not already added to the array with pageIDAlreadyAdded batch array
         */
        for (let i = 0; i < languages.length; i++) {
          languages[i][1]["pages"].forEach((page) => {
            if (page.activated && !pageIDAlreadyAdded.includes(page.id)) {
              landing_page_array.push({
                Key: `${page.name}`,
                Value:
                  page.id === "home"
                    ? `/home/${this.SITE_NAME.toLowerCase()}`
                    : `/more-information/${this.SITE.SITE}/${page.id}`,
              });
              pageIDAlreadyAdded.push(page.id); // We use this batch array to check if the page is already in the array or not
            }
          });
          if (i === languages.length - 1) {
            //if last loop we add pages to landing_pages_array
            this.landing_page_array = [
              ...landing_page_array,
              ...this.landing_page_array,
            ];
          }
        }
      }
    );
  }

  /** Function to init the form */
  initForms() {
    this.dynamicForm = this.formBuilder.group({
      qrcodes: new FormArray([]),
    });
  }

  /** Getters for easy access to form fields */
  qrcodes(): FormArray {
    return this.dynamicForm.get("qrcodes") as FormArray;
  }

  /** Function getAllQRCodes to get all QR Codes from the API, then run the display generation */
  getAllQRCodes() {
    this.onReset();
    this._httpClientService
      .getQRCodes(this.SITE_NAME)
      .then((data) => {
        this.search = data;
        this.SAVE_search = data;
        this.qrcodes().clear();
        this.generateFormToDisplayQRCodes();
        console.debug("All QR Codes have been loaded !");
      })
      .catch((err) => this.callbackHTTPClient(err));
  }

  /** validators for loc1 and loc2 duplicate or required */
  loc1Andloc2() {
    return (form: FormGroup): { [key: string]: any } => {
      if (form.value.loc1 != "" && form.value.loc2 != "") {
        if (
          this.qrcodes().value.filter(
            (qrcode) =>
              qrcode.loc1 == form.value.loc1 &&
              qrcode.loc2 == form.value.loc2 &&
              qrcode.uuid != form.value.uuid
          ).length > 0
        ) {
          return { loc1Andloc2DuplicateError: true };
        } else {
          return null;
        }
      } else if (form.value.loc1 == "" || form.value.loc2 == "") {
        return { loc1Andloc2RequiredError: true };
      } else {
        return null;
      }
    };
  }

  /** Generate form to display QR Codes in the table */
  generateFormToDisplayQRCodes() {
    this.onReset();
    const items = this.sortData(this.search.Items);

    for (let i = 0; i < items.length; i++) {
      const qrcode = items[i];
      if (qrcode.SITE?.S === this.SITE_NAME)
        this.qrcodes().push(this.generateOneQRCodeFormControl(qrcode, i));
      else this.oneQRCodeIsCorrupted = true;
    }

    this.DATA_IS_LOADING = false;
    console.debug("Form is generated ! List of qrcodes : ", this.qrcodes());
  }

  /** Generate one form group for one QR Code */
  generateOneQRCodeFormControl(qrcode: any, index: number): FormGroup {
    let text = `${environment.redirectUrl}/${this.SITE_NAME}/${qrcode.ID.S}`;

    // Get a new config if not exist
    if (!qrcode.QR_CODES_CONFIG) {
      const qrConfig = this._qrCodeGeneration.getQrCodeDefaultConfig();
      qrcode.QR_CODES_CONFIG = qrConfig;
    }

    let srcImgRenderQr = null;

    if (
      qrcode.QR_CODES_CONFIG.M.image.M.image_name &&
      qrcode.QR_CODES_CONFIG.M.image.M.image_name.S !== ""
    ) {
      if (
        qrcode.QR_CODES_CONFIG.M.image.M.image_name.S === "Logo_Elefight.png"
      ) {
        srcImgRenderQr = "../../../../../assets/img/Logo_Elefight.png";
      } else {
        srcImgRenderQr = `${environment.inputPath}SITES/${this.SITE_NAME}/IMG/${qrcode.QR_CODES_CONFIG.M.image.M.image_name.S}`;
      }
    }

    return this.formBuilder.group(
      {
        uuid: [qrcode.ID.S, Validators.required],
        site: [qrcode.SITE.S, [Validators.required]],
        loc1: [qrcode.LOC1.S, Validators.required],
        loc2: [qrcode.LOC2.S, Validators.required],
        landingPage: [qrcode.LANDING_PAGE.S, [Validators.required]],
        externalUrl: [
          qrcode.LANDING_PAGE.S === "externalUrl"
            ? { value: qrcode.REDIRECT_URL.S, disabled: false }
            : { value: "", disabled: true },
          [Validators.required],
        ],
        documentPath: [
          qrcode.LANDING_PAGE.S === "documentPath"
            ? { value: qrcode.REDIRECT_URL.S.split("/").pop(), disabled: false }
            : { value: "", disabled: true },
          [
            fileName(`qrcode_documentPath_${index}`),
            RxwebValidators.extension({
              extensions: listOfImagesExtensions.concat("pdf").concat("ics"),
            }),
            maximumFileSize(`qrcode_documentPath_${index}`, 100000000),
          ],
        ],
        document: [
          qrcode.LANDING_PAGE.S === "documentPath"
            ? { value: null, disabled: false }
            : { value: null, disabled: true },
          [
            fileName(`qrcode_documentPath_${index}`),
            RxwebValidators.extension({
              extensions: listOfImagesExtensions.concat("pdf").concat("ics"),
            }),
            maximumFileSize(`qrcode_documentPath_${index}`, 100000000),
          ],
        ],
        oldDocumentPath: ["", []],
        matCode: [qrcode.MAT_CODE ? qrcode.MAT_CODE.S : ""],
        matName: [qrcode.MAT_NAME ? qrcode.MAT_NAME.S : ""],
        selectboolean: [false],
        creationDate: [
          qrcode.CREATION_DATE ? qrcode.CREATION_DATE.N : "INVALID",
        ],
        icons_copyQRCode: ["content_copy"],
        qrcodeconfig: [qrcode.QR_CODES_CONFIG ? qrcode.QR_CODES_CONFIG : null],
        analytics: [qrcode.ANALYTICS ? qrcode.ANALYTICS : {}],
        textQr: [text],
        srcImgRenderQr: [srcImgRenderQr],
        passwordActivated: [
          typeof qrcode.PASSWORD_ACTIVATED != "undefined"
            ? qrcode.PASSWORD_ACTIVATED.BOOL
            : false,
          [],
        ],
        password: [
          typeof qrcode.PASSWORD != "undefined" ? qrcode.PASSWORD.S : "",
          [],
        ], // keep the hashed password in the form ?
      },
      { validator: this.loc1Andloc2() }
    );
  }

  /** Generate canvas for one QR Code */
  generateCanvasForOneQRCode(index: number) {
    const qrcode = this.qrcodes().controls[index].value;

    const current_qr_code_preview = this._qrCodeGeneration.getQRCodeData(
      qrcode.textQr,
      qrcode.srcImgRenderQr,
      100,
      qrcode.qrcodeconfig
    );

    const canvasElement = document.getElementById(
      `canvasConfig_${index}`
    ) as HTMLCanvasElement;

    const child = canvasElement?.firstChild;

    if (child) {
      canvasElement?.removeChild(child);
    }
    current_qr_code_preview.append(canvasElement);

    this.qrCodeSettingsInitialized = true;
  }

  /**
   * Function that open dialog for manage password
   * When exit dialog, we save the password in the form and then we save the form in the database
   */
  managePassword(selectedQrCode: any = null) {
    const dialogConfig = new MatDialogConfig(); // to rename
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    let qrSelected = [];
    if (selectedQrCode == null) qrSelected = this.getSelectedQRCodes();
    else qrSelected.push(selectedQrCode);

    dialogConfig.data = {
      selectedQRcodes: qrSelected,
    };

    const dialogRef = this.passwordDialog.open(
      PasswordModalComponent,
      dialogConfig
    );

    let uuidsSelected = qrSelected.map((e: any) => e.uuid);
    dialogRef.afterClosed().subscribe((data) => {
      if (data?.formCompleted) {
        //update the form with the new password
        for (let i = 0; i < this.qrcodes().controls.length; i++) {
          if (uuidsSelected.includes(this.qrcodes().controls[i].value.uuid)) {
            this.qrcodes().controls[i].patchValue({
              passwordActivated: data.form.passwordActivated,
              password: data.form.password,
            });
          }
        }
        //save the qrcodes
        this.onSubmit();
      }
    });
  }

  /** Delete selected QR Codes(s) */
  removeQRCodes(index: number) {
    if (index != null)
      if (!this.qrcodes().controls[index].value.selectboolean)
        this.qrcodes().controls[index].patchValue({ selectboolean: true });
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "remove";
    this.ALERT_MESSAGE.MESSAGE =
      "Are you sure you want to remove the selected QR Codes ?";
  }

  /**Callback Remove function call by AlertMessage Component */
  callbackActionModal_remove() {
    this.qrCodeSettingsInitialized = false;
    new Promise((resolve, reject) => {
      const selectedQRCodes = this.getSelectedQRCodes();
      for (let i = 0; i < selectedQRCodes.length; i++) {
        const qrcode: any = selectedQRCodes[i];
        this._httpClientService
          .removeQRCode(this.SITE_NAME, qrcode.uuid)
          .then(async (data) => {
            if (!data["ConsumedCapacity"]) reject(data);
            else {
              if (qrcode.oldDocumentPath != "")
                await this._fileManagementService.removeFile(
                  this.SITE_NAME,
                  qrcode.oldDocumentPath
                );
              if (qrcode.documentPath != "")
                await this._fileManagementService.removeFile(
                  this.SITE_NAME,
                  qrcode.documentPath
                );
              resolve(data);
            }
          })
          .catch((err) => this.callbackHTTPClient(err));
      }
    })
      .then((data) => {
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
        this.ALERT_MESSAGE.MESSAGE = "QR Codes removed successfully";
        this.initForms();
        this.getAllQRCodes();
      })
      .catch((err) => this.callbackHTTPClient(err));
    console.debug("QR Code(s) have been removed !");
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Download selected QR Codes(s) */
  async downloadQRCodes(index: number) {
    if (index != null)
      if (!this.qrcodes().controls[index].value.selectboolean)
        this.qrcodes().controls[index].patchValue({ selectboolean: true });

    const ZIP = new JSZip();
    const arrayName = [];
    const selectedQRCodes = this.getSelectedQRCodes();
    for (let i = 0; i < selectedQRCodes.length; i++) {
      const mainName: string = `${selectedQRCodes[i][
        "site"
      ].toLowerCase()}_${selectedQRCodes[i][
        "loc1"
      ].toLowerCase()}_${selectedQRCodes[i]["loc2"].toLowerCase()}`;
      let name: string = mainName;
      if (arrayName[name]) name = mainName + "(" + arrayName[name] + ")";
      else arrayName[mainName] = 0;
      arrayName[mainName] = arrayName[mainName] + 1;

      const qrcode: any = selectedQRCodes[i];

      // Pour chaque format dans typeOfQRCodes, on génère le QR Code et on l'ajoute au ZIP
      for (let type of this.typesOfQRCodes) {
        // Créer une nouvelle instance de QRCodeStyling pour le téléchargement
        const qrcode_to_download = this._qrCodeGeneration.getQRCodeData(
          qrcode.textQr,
          qrcode.srcImgRenderQr,
          1000,
          type === "png"
            ? {
                M: {
                  ...qrcode.qrcodeconfig.M,
                  ...{
                    color: { S: "rgba(255,255,255,0)" },
                  },
                },
              }
            : qrcode.qrcodeconfig,
          type === "svg" ? "svg" : "canvas"
        );

        if (type === "pdf") {
          const imgWidth = 150;
          const imgHeight = 150;

          const imageData = await qrcode_to_download
            .getRawData("png")
            .catch((error) => {
              console.error("Error generating QR code image:", error);
            });

          const img = new Uint8Array(await imageData.arrayBuffer());
          const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
          pdf.addImage(img, "PNG", 30, 73.5, imgWidth, imgHeight);

          ZIP.file(`${name}.${type}`, pdf.output("blob"));
        } else {
          // Générer l'image du QR Code, téléchargeable
          const imageData = await qrcode_to_download
            .getRawData(type)
            .catch((error) => {
              console.error("Error generating QR code image:", error);
            });

          // Ajouter l'image du QR Code au ZIPƒ
          ZIP.file(`${name}.${type}`, imageData);
        }
      }
    }
    // Téléchargement du fichier ZIP
    ZIP.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "QRCODES.zip");
    });
  }

  //**blob to dataURL**
  blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function (e) {
      callback(e.target.result);
    };
    a.readAsDataURL(blob);
  }

  downloadHistory() {
    const selectedQRCodes = this.getSelectedQRCodes().map(
      (qrcode) => qrcode["uuid"]
    );

    this._httpClientService
      .getQRCodeHistoryFile(this.SITE_NAME, { qrCodes: selectedQRCodes })
      .then((response) => {
        this._fileManagementService.downloadFile(
          response["URL"],
          "QRCodeHistory.xlsx"
        );

        this.ALERT_MESSAGE.IS_DISPLAYED = false;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
        this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
        this.ALERT_MESSAGE.MESSAGE = null;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /** Configure selected QR Code(s) */
  async configQrCodes() {
    this.DATA_IS_LOADING = true;
    this.promiseSaveQRCodes()
      .then(() => {
        this.DATA_IS_LOADING = false;
        console.debug("All QR Codes have been saved !");
        const selectedQRCodes = this.getSelectedQRCodes();
        sessionStorage.setItem(
          "selectedQRCodes",
          JSON.stringify(selectedQRCodes)
        );
        this.navigate("/qr-code-management/config");
      })
      .catch((error) => {
        console.error("An error occured while saving QR Codes : ", error);
        this.DATA_IS_LOADING = false;
        this.callbackHTTPClient(
          `An error occured while saving QR Codes ! ${error}`
        );
      });
  }

  /** Copy content of QR Codes */
  copyQRCodeValue(uuid: string, i: any) {
    const QRCodeUrl = `${environment.redirectUrl}/${this.SITE_NAME}/${uuid}`;
    const copied = this.clipboard.copy(QRCodeUrl);
    if (copied) {
      this.qrcodes().controls[i].patchValue({
        icons_copyQRCode: "check_circle",
      });
      setTimeout(
        () =>
          this.qrcodes().controls[i].patchValue({
            icons_copyQRCode: "content_copy",
          }),
        1000
      );
    }
  }

  /** ----- checkbox select controll ------ */
  getSelectedQRCodes() {
    return Object.values(this.dynamicForm.value.qrcodes).filter(
      (code) => code["selectboolean"] === true
    );
  }
  unselectAllQRCodes() {
    this.qrcodes().controls.forEach((t) =>
      t.patchValue({ selectboolean: false })
    );
  }
  indeterminated(): boolean {
    if (this.qrcodes().controls == null) return false;
    return (
      this.qrcodes().controls.filter((t) => t.value.selectboolean).length > 0 &&
      !this.allchecked
    );
  }
  updateAllComplete(event, code: any) {
    event.stopPropagation();
    event.target.tagName === "TD" || event.target.tagName === "P"
      ? (code.value.selectboolean = !code.value.selectboolean)
      : null;
    this.allchecked =
      this.qrcodes().controls != null &&
      this.qrcodes().controls.every((t) => t.value.selectboolean);
  }
  setAll(completed: boolean) {
    this.allchecked = completed;
    if (this.qrcodes().controls == null) return;
    this.qrcodes().controls.forEach((t) => (t.value.selectboolean = completed));
  }
  /** ----- end checkbox select controll ------ */

  /** Function to save data run by "Save" button */
  async onSubmit() {
    this.DATA_IS_LOADING = true;
    this.promiseSaveQRCodes()
      .then(() => {
        this.qrCodeSettingsInitialized = false;
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
        this.ALERT_MESSAGE.MESSAGE = "All QR Codes have been saved !";
        this.initForms();
        this.getAllQRCodes();
        this.QRCodesGeneration = {
          numberOfQRCodesToGenerate: 1,
          message: "",
        };
        this.DATA_IS_LOADING = false;
      })
      .catch((error) => {
        console.error("An error occured while saving QR Codes : ", error);
        this.DATA_IS_LOADING = false;
        this.callbackHTTPClient(
          `An error occured while saving QR Codes ! ${error}`
        );
      });
  }

  /** Promise that saves QR Codes in database */
  promiseSaveQRCodes = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        console.debug("Form submitted : ", this.dynamicForm);

        // stop here if form is invalid
        if (this.dynamicForm.invalid) {
          this.DATA_IS_LOADING = false;
          this.callbackHTTPClient(
            `<p>Qr codes can't be saved ! <br/> Please check your QR Codes errors.</p>`
          );
          return;
        }

        const input = this.dynamicForm.value.qrcodes;
        let postQRCodes = [];
        for (let index = 0; index < input.length; index++) {
          if (input[index].oldDocumentPath != "")
            await this._fileManagementService.removeFile(
              this.SITE_NAME,
              input[index].oldDocumentPath
            );
          if (
            input[index].landingPage == "externalUrl" &&
            !this._adminFormService.isEmail(input[index].externalUrl)
          ) {
            this.qrcodes().controls[index].patchValue({
              externalUrl: this._adminFormService.getValidUrl(
                input[index].externalUrl
              ),
            });
          } else if (
            input[index].landingPage == "externalUrl" &&
            this._adminFormService.isEmail(input[index].externalUrl)
          ) {
            this.qrcodes().controls[index].patchValue({
              externalUrl: this._adminFormService.getValidMailto(
                input[index].externalUrl
              ),
            });
          } else if (input[index].landingPage == "documentPath") {
            input[
              index
            ].externalUrl = `${environment.inputPath}SITES/${this.SITE_NAME}/${input[index].documentPath}`;
            if (input[index].document != null) {
              if (input[index].oldDocumentPath != "")
                await this._fileManagementService.removeFile(
                  this.SITE_NAME,
                  input[index].oldDocumentPath
                );
              await this._fileManagementService.uploadFile(
                input[index].document,
                "null",
                "null",
                this.SITE_NAME,
                input[index].documentPath
              );
              this.qrcodes().controls[index].patchValue({
                oldDocumentPath: input[index].documentPath,
              });
            }
          }
          postQRCodes.push({
            uuid: input[index].uuid,
            site: input[index].site,
            loc1: input[index].loc1,
            loc2: input[index].loc2,
            passwordActivated: input[index].passwordActivated,
            password: input[index].password,
            landingPage: input[index].landingPage,
            externalUrl: input[index].externalUrl
              ? input[index].externalUrl
              : "",
            matCode: input[index].matCode,
            matName: input[index].matName,
            creationDate: input[index].creationDate,
            qrcodeconfig: input[index].qrcodeconfig,
            isDirty: this.qrcodes().controls[index].dirty,
          });
        }
        console.debug(
          "Input submit after verification : ",
          JSON.parse(JSON.stringify(postQRCodes))
        );
        let i = 0;
        let location = 0;
        //For each entries in form, we divided in x objects of 10 items max.
        const group = postQRCodes.reduce((acc, item) => {
          if (i > 10) {
            i = 0;
            location++;
          }
          if (!acc[location]) {
            acc[location] = [];
          }
          acc[location].push(item);
          i++;
          return acc;
        }, {});
        let promises = [];
        for (let i = 0; i < Object.keys(group).length; i++) {
          promises.push(
            this._httpClientService.postQRCode(this.SITE_NAME, group[i])
          );
        }
        Promise.all(promises)
          .then((value) => {
            console.debug("All QR Codes have been saved :", value);
            resolve(value);
          })
          .catch((err) => reject(err));
      } catch (error) {
        reject(error);
      }
    });
  };

  /** Adjust the dynamic form when we change the landing page */
  landingPageChanged(value: string, i: number) {
    this.qrcodes().controls[i].markAsDirty(); //We mark the formcontrol as dirty
    if (value != "documentPath") {
      this.qrcodes().controls[i].patchValue({
        oldDocumentPath: this.qrcodes().at(i).get("documentPath").value,
      });
    }
    if (value == "externalUrl") {
      this.qrcodes().at(i).get("externalUrl").enable();
      this.qrcodes().at(i).get("documentPath").disable();
      this.qrcodes().at(i).get("document").disable();
    } else if (value == "documentPath") {
      this.qrcodes().controls[i].patchValue({
        oldDocumentPath: "",
      });
      this.qrcodes().at(i).get("externalUrl").disable();
      this.qrcodes().at(i).get("documentPath").enable();
      this.qrcodes().at(i).get("document").enable();
    } else {
      this.qrcodes().at(i).get("externalUrl").disable();
      this.qrcodes().at(i).get("documentPath").disable();
      this.qrcodes().at(i).get("document").disable();
    }
  }

  /** Adjust the dynamic form when we add document file */
  onChangeDocumentFile(event: any, index: number) {
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      const myRenamedFile = new File(
        [file],
        `${file.name.split(".").shift()}_${this.datePipe.transform(
          new Date(),
          "dd-MM-yyyy_hh-mm-ss"
        )}.${file.name.split(".").pop()}`
      );
      const thisQrCode: FormGroup = this.qrcodes().controls[index] as FormGroup;
      thisQrCode["controls"]["oldDocumentPath"].setValue(
        thisQrCode["controls"]["documentPath"].value
      );
      thisQrCode["controls"]["document"].setValue(myRenamedFile);
      thisQrCode["controls"]["documentPath"].setValue(myRenamedFile.name);
      thisQrCode.markAsDirty(); //We mark the formcontrol as dirty
    }
  }

  /** Generate function use to generate QR Codes */
  onGenerateNewQRCodes() {
    //Check if preForm is invalid
    if (this.QRCodesGeneration.numberOfQRCodesToGenerate <= 0) {
      return;
    }

    //Generate QR Codes
    for (let i = 0; i < this.QRCodesGeneration.numberOfQRCodesToGenerate; i++) {
      this.qrcodes().push(
        this.generateOneQRCodeFormControl(
          {
            ID: { S: generateUUID() },
            SITE: { S: this.SITE_NAME },
            LOC1: { S: "" },
            LOC2: { S: "" },
            LANDING_PAGE: { S: `/home/${this.SITE_NAME.toLocaleLowerCase()}` },
            REDIRECT_URL: { S: "" },
            MAT_CODE: { S: "" },
            MAT_NAME: { S: "" },
            CREATION_DATE: { N: new Date().valueOf() + i },
            QR_CODES_CONFIG: this._qrCodeGeneration.getQrCodeDefaultConfig(),
            PASSWORD_ACTIVATED: { BOOL: false },
          },
          this.qrcodes().length
        )
      );
      this.generateCanvasForOneQRCode(this.qrcodes().length - 1);
    }

    this.QRCodesGeneration.message =
      "QR Codes have been generated. Don't forget to save them.";
    $("#modalCreateNewQRCodes").modal("hide");
  }

  /** Function to filter search */
  onFilter() {
    this.search = JSON.parse(JSON.stringify(this.SAVE_search));
    if (this.filter.searchType && this.filter.searchText) {
      if (this.filter.searchType === null) return false;
      else if (this.filter.searchType === "contains") {
        this.search.Items = this.search.Items.filter(
          (item) =>
            item.LOC1.S.toLowerCase().includes(
              this.filter.searchText.toLowerCase()
            ) ||
            item.LOC2.S.toLowerCase().includes(
              this.filter.searchText.toLowerCase()
            )
        );
      } else if (this.filter.searchType === "equal") {
        this.search.Items = this.search.Items.filter(
          (item) =>
            item.LOC1.S.toLowerCase() ===
              this.filter.searchText.toLowerCase() ||
            item.LOC2.S.toLowerCase() === this.filter.searchText.toLowerCase()
        );
      }
      this.filter.filtersActivated = true;
    }
    this.generateFormToDisplayQRCodes();
  }

  /** Reset whole form back to initial state */
  onReset() {
    this.dynamicForm.reset();
    this.qrcodes().clear();
  }

  /** Function to remove filter and reset search */
  removeFilters() {
    this.filter = {
      searchType: "contains",
      searchText: null,
      filtersActivated: false,
    };
    this.initForms();
    this.search = JSON.parse(JSON.stringify(this.SAVE_search));
    this.generateFormToDisplayQRCodes();
  }

  /** Function to navigate through components */
  navigate(route: string) {
    this.router.navigate([`/admin/${this.SITE_NAME}/${route}`]);
  }

  /** Timestamp Converter */
  timeConverter(epochtime) {
    if (epochtime != "INVALID") {
      var a = new Date(parseInt(epochtime));
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours().toString();
      if (hour.length === 1) {
        hour = "0" + hour;
      }
      var min = a.getMinutes().toString();
      if (min.length === 1) {
        min = "0" + min;
      }
      var time = date + " " + month + " " + year + " " + hour + ":" + min;
      return time;
    }
    return epochtime;
  }

  /** Sort by date */
  sortData(myArray) {
    return myArray.sort((a, b) => {
      // return (
      //   <any>new Date(parseInt(a.CREATION_DATE.N ? a.CREATION_DATE.N : 0)) -
      //   <any>new Date(parseInt(b.CREATION_DATE.N ? b.CREATION_DATE.N : 0))
      // );
    });
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(message: string) {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = `Error: ${message}`;
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal, sent to {@link AlertMessage} */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
    this._shareData.changeZIPCodes(null);
  }
}
