import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/core/services/routes.service';
import { SitesService } from 'src/app/core/services/sites.service';

@Component({
  selector: 'aac-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: [
    './reporting.component.scss',
    '../local-back-office.scss',
    '../../private.scss'
  ]
})
export class LocalReportingComponent implements OnInit {

  SITE: any = {};

  SITE_NAME: string = '';

  DATA_IS_LOADED: boolean = false;

  message: string = null;

  constructor(
    private router: Router,
    private titleService: Title,
    private _routesService: RoutesService,
    private _sitesService: SitesService
  ) {
    
    this.titleService.setTitle("Elefight - Reporting");

    this.message = "Data is loading...";

    /** Get routes information then get site information*/
    this._routesService.currentRoutes
      .subscribe(routes => {
        if (routes.PARAMS) {
          console.log(routes);
          if (routes.PARAMS['site'] != undefined) this.SITE_NAME = routes.PARAMS['site'].toUpperCase();
          else this.SITE_NAME = null;
        }
        console.log(this.SITE_NAME)
      });

    this._sitesService.getSite(this.SITE_NAME)
      .then(value => {
        this.SITE = JSON.parse(JSON.stringify(value));
        console.log(this.SITE);
        //Check if site exist and if it's activated
        if (Object.keys(this.SITE).length <= 0 || this.SITE.OPTIONS.ACTIVATED == 'false') {
          console.error("Site doesn't exist or has been deactivated");
          this.message = "This site/page is no longer  \n available or has been deactivated";
          this.DATA_IS_LOADED = false;
          return;
        }
        this.message = null;
        this.DATA_IS_LOADED = true;
      });

  }

  ngOnInit(): void { }

  /** Navigate though components */
  navigate(route: string) {
    this.router.navigate([`/admin/${this.SITE_NAME}/${route}`]);
  }

  /** Callback sent to {@link HTTPClientService} */
  // callbackHTTPClient(message: string, id?: number) {
  //   console.error(message);
  //   console.error(id);
  //   this.message_on_loading = null;
  //   this.message_error.push(`Erreur with promises[${id}] : ${message}.`);
  //   this.DATA_IS_LOADED = false;
  // }
}
