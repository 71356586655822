<div class="row row_main_menu sticky-top">
    <div class="col-md-12 pl-2 pb-2 pt-2">
        <div class="row">
            <div class="btn-toolbar ml-1 d-flex align-items-center" role="toolbar">
                <div role="group">
                    <img src="../../../../../assets/img/elefight_icon.svg" height="40px" width="40px">
                </div>
                <div class="mx-4" role="group" style="overflow-wrap: anywhere; width: 25%;">
                    <h1 class="elefight_title m-0">E L E F I G H T  BackOffice</h1>
                    <ng-container *ngIf="IS_LOADED">
                        <ng-container *ngIf="SITE_ACTIVATED; then thenBlock else elseBlock"></ng-container>
                        <ng-template #thenBlock><h4 class="mb-0">{{SITE_NAME}} - Activated <div class="bg-success light"></div></h4></ng-template>
                        <ng-template #elseBlock><h4 class="mb-0">{{SITE_NAME}} - Deactivated <div class="bg-danger light"></div></h4></ng-template>    
                    </ng-container>
                </div>
                <div class="mx-4" role="group">
                    <button type="button" class="btn btn-global-type btn-type1" [class.visited]="COMPONENT === 'ContentManagementComponent'" (click)="navigate('content-management')">Content Management</button>
                </div>
                <div class="mx-4" role="group">
                    <button type="button" class="btn btn-global-type btn-type1" [class.visited]="COMPONENT === 'QRCodesComponent'" (click)="navigate('qr-code-management')">QR Codes</button>
                </div>
                <div class="mx-4" role="group">
                    <button type="button" class="btn btn-global-type btn-type1" [class.visited]="COMPONENT === 'FormsComponent'" (click)="navigate('survey')">Forms</button>
                </div>
                <div class="mx-4" role="group">
                    <button type="button" class="btn btn-global-type btn-type1" [class.visited]="COMPONENT === 'ReportingComponent'" (click)="navigate('reporting')">Analytics</button>
                </div>
            </div>
            <button type="button" class="align-items-center btn d-flex signout_button p-0 mr-4 mt-3" (click)="signOut()">
                Sign Out
                <span class="material-icons icons_button signout_icon ml-1">
                  arrow_circle_down
                </span>
            </button>
        </div>
    </div>
</div>