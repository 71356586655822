<h2 mat-dialog-title class="text-center">Manage Password</h2>

<mat-dialog-content [formGroup]="form">
    <div class="pass_option">
        <h3>Password Options</h3>
        <mat-slide-toggle matInput #activatePassword formControlName="passwordActivated" class=" mb-2"
            [labelPosition]="'before'" checked>
            {{(activatePassword.checked) ? 'Activated' : 'Deactivated'}}
        </mat-slide-toggle>
        <mat-form-field class="pass_input"
            [style]="(activatePassword.checked)? '' : 'background-color : #80808014; border-radius : 7px'">
            <mat-label>Password</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password"
                [attr.disabled]="!activatePassword.checked? '' : null">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                {{ hidePassword ? 'visibility' : 'visibility_off' }}
            </mat-icon>
        </mat-form-field>
    </div>
    <div class="container_selected_qr_table">
        <h3>Selected QR Codes</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Location 1</th>
                    <th>Location 2</th>
                    <th>Redirects to...</th>
                    <th>Password is...</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let code of this.selectedQRCodes; let i = index">
                    <td>{{code.loc1}}</td>
                    <td>{{code.loc2}}</td>
                    <td *ngIf="code.landingPage === 'externalUrl'" style="max-width: 15vw; overflow: hidden;">
                        {{code.externalUrl}}</td>
                    <td *ngIf="code.landingPage !== 'externalUrl'" style="max-width: 15vw; overflow: hidden;">
                        {{code.landingPage}}</td>
                    <td>{{(code.passwordActivated) ? 'activated' : 'deactivated' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex align-items-center justify-content-center">
    <button type="button" class="btn btn-primary d-flex align-items-center btn-type2 btn-global-type" (click)="save()">
        <span class="material-icons icons_button">
            save
        </span>
        <span class="ml-2">Save Password</span>
    </button>
</mat-dialog-actions>