import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs-compat/Subscription";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { listOfImagesExtensions } from "src/app/core/classes/ImagesExtensions";
import { ROUTES } from "src/app/core/classes/Routes";
import { RoutesService } from "src/app/core/services/routes.service";
import { SitesService } from "src/app/core/services/sites.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "aac-multi-pages",
  templateUrl: "./multi-pages.component.html",
  styleUrls: ["./multi-pages.component.scss"],
})
export class MultiPagesComponent implements OnInit, OnDestroy {
  inputPath: string = environment.inputPath;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };

  SITE: any = {};
  SITE_NAME: string = "";
  PAGE_ID: string = null;
  PAGE_CONTENT: any;

  CTA_LABEL: string = "";
  CTA_TYPE: string = "";
  CTA_LINK: string = "";
  CTA_LIST = [];
  currentLanguage: string = "";
  colorHeader: any;

  isLoaded: boolean = false;
  errorMessage: string = null;

  private currentRoutesSubscribe: Subscription;
  private currentRoutesSubscribeOnLangChange: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private _routesService: RoutesService,
    private _sitesService: SitesService
  ) {
    /** Get routes information then get site information*/
    this.currentRoutesSubscribe = this._routesService.currentRoutes.subscribe(
      (routes) => {
        this.ROUTES = routes;
        if (routes.PARAMS) {
          if (routes.PARAMS["site"] != undefined)
            this.SITE_NAME = routes.PARAMS["site"].toUpperCase();
          else this.SITE_NAME = "";
          if (routes.PARAMS["id"] != undefined)
            this.PAGE_ID = routes.PARAMS["id"];
          else this.PAGE_ID = null;
          if (this.PAGE_ID === null)
            this.errorMessage = "You need to set a page ID";
          this.getTranslation();
        }
      }
    );
    this.currentRoutesSubscribeOnLangChange =
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.getTranslation();
      });
    this._sitesService.getSite(this.SITE_NAME).then((res) => {
      this.SITE = JSON.parse(JSON.stringify(res));
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    console.info("ngOnDestroy multi-pages is executing...");
    this.currentRoutesSubscribe.unsubscribe();
    this.currentRoutesSubscribeOnLangChange.unsubscribe();
  }

  getTranslation() {
    this.translate
      .get("language")
      .subscribe((val) => (this.currentLanguage = val));
    let promisesTranslate = [];
    promisesTranslate.push(this.translate.get(`pages`).toPromise()); //Get pages array
    promisesTranslate.push(this.translate.get("MENU").toPromise()); //Get menu content

    Promise.all(promisesTranslate).then((values) => {
      console.log(values);
      this.PAGE_CONTENT = values[0].find((value) => value.id === this.PAGE_ID);
      console.log(this.PAGE_ID);
      if (this.PAGE_CONTENT != undefined) {
        console.log(this.PAGE_CONTENT);
        //add
        this.colorHeader = this.PAGE_CONTENT.color;
        console.log("colorHeader", this.colorHeader);

        if (this.PAGE_CONTENT.length < 1) this.navigate("home");
        this.isLoaded = true;

        this.CTA_TYPE = this.PAGE_CONTENT["callToAction"]["type"];
        this.CTA_LABEL = this.PAGE_CONTENT["callToAction"]["label"];
        switch (this.CTA_TYPE) {
          case "FILE":
            const fileName =
              this.PAGE_CONTENT["callToAction"]["callToActionDocument"][
                "documentPath"
              ];
            const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
            if (extension === "pdf" || extension === "ics")
              this.CTA_LINK = `${environment.inputPath}SITES/${this.SITE_NAME}/DATA/${extension}/${fileName}`;
            else if (listOfImagesExtensions.includes(extension))
              this.CTA_LINK = `${environment.inputPath}SITES/${this.SITE_NAME}/IMG/${fileName}`;
            break;
          case "EXTERNAL":
            this.CTA_LINK =
              this.PAGE_CONTENT["callToAction"]["callToActionExternalLink"][
                "url"
              ];
            break;
          case "SURVEY":
            this.CTA_LINK = `/survey/${this.SITE_NAME}/${this.PAGE_CONTENT["callToAction"]["callToActionSurvey"]["survey"]}`;
            break;
          case "MULTI_CTA":
            this.CTA_LIST =
              this.PAGE_CONTENT["callToAction"]["callToActionMultiCta"][
                "ctaList"
              ];
            break;
          default:
            console.error(`Error ! Type ${this.CTA_TYPE} is not allowed`);
        }
      }
    });
    // } else {
    //   console.error("Erreur : Aucune maladie trouvé");
    // }
  }

  navigate(route: string) {
    this.router.navigate([`/${route}/${this.SITE_NAME.toLowerCase()}`]);
  }

  closeModal() {
    this.callbackResetModal(this.ALERT_MESSAGE);
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.resetModal();
  }

  resetModal() {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(error) {
    console.log(error);
    this.errorMessage;
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.MESSAGE = error["message"];
  }
}
