<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="modalMessage" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" [class.border-primary]="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'informativeModal'" [class.border-success]="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal'" [class.border-danger]="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'errorModal'">
        <div class="modal-header">
          <span class="modal-title">{{header}}</span>
        </div>
        <div class="modal-body">

          <div *ngIf="ALERT_MESSAGE.TYPE_OF_MESSAGE=='displaypassword'; else normalBody">
            <p class="d-inline p-2">The temporary password is : </p>
            <input [type]="inputType" [value]="ALERT_MESSAGE.MESSAGE" class="d-inline p-2" readonly="readonly">
            <div class="d-inline p-2">
             <button type="button" class="btn btn-outline-info btn-sm" (click)="hidepassword()">{{buttonText}}</button>
            </div>
          </div>
          
          <ng-template #normalBody>
            <div [innerHTML]="ALERT_MESSAGE.MESSAGE"></div>
          </ng-template>
        </div>
        <div *ngIf="ALERT_MESSAGE.TYPE_OF_ACTION === 'publish'; else modifyQuestion" class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="actionModal()">Save & Publish</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Cancel</button>
        </div>
        <ng-template #modifyQuestion>
          <div *ngIf="ALERT_MESSAGE.TYPE_OF_ACTION === 'modifyQuestion'; else default" class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="actionModal()">Modify this question</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Cancel</button>
          </div>
        </ng-template>
        <ng-template #default>
          <div class="modal-footer">
            <button *ngIf="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'actionModal'" type="button" class="btn btn-primary" data-dismiss="modal" (click)="actionModal()">Yes</button>
            <button *ngIf="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'actionModal'" type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">No</button>
            <button *ngIf="ALERT_MESSAGE.TYPE_OF_MESSAGE != 'actionModal'" type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
          </div>
        </ng-template>
      </div>
    </div>
</div>