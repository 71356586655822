<!-- Display form when data is loaded -->
<div class="container container-survey" *ngIf="formIsLoaded; else error_getting_form">
    <!-- Form Title -->
    <div class="row">
        <h2 class="mt-3 mb-0 form_title" [style.color]="colorHeader">{{FORM.title}}</h2>
        <hr class="hr" [style.border-color]="colorHeader" />
    </div>
    <!-- Form Description -->
    <div class="row mt-2">
        <innerHTML HTMLcontent="{{FORM.description}}"></innerHTML>
    </div>
    <!-- Form -->
    <form [formGroup]="surveyForm" (ngSubmit)="onSubmit()">
        <div formArrayName="questions" class="form-group">
            <div class="form-group">
                <div *ngFor="let question of questions().controls; let i=index" [formGroupName]="i" class="row">
                    <div class="card mb-3" [style.border-color]="colorHeader" style="width: 100%;">
                        <!-- Question number -->
                        <div class="p-2 ml-5 mt-2 bd-highlight d-flex align-items-center">
                            <span *ngIf="i >= 9;else lower_than_ten" class="component_id">{{i+1}}</span>
                            <ng-template #lower_than_ten><span class="component_id" [style.background]="colorHeader">0{{i+1}}</span></ng-template>
                        </div>
                        <!-- Question title -->
                        <div class="question_title">
                            <h3 class="card-title blue" [style.color]="colorHeader">{{question.value.title}}</h3>
                        </div>
                        <!-- Question image -->
                        <div *ngIf="question.value.image">
                            <img class="preview_image d-block user-select-none" src="{{inputPath}}SITES/{{SITE_NAME_FORM}}/IMG/{{question.value.image}}" width="100%">
                        </div>
                        <!-- Question options -->
                        <div *ngIf="question.value.type === 'SIMPLE_CHOICE'" class="card-body">
                            <div class="form-group">
                                <label class="form-check-label">
                                    <mat-radio-group formControlName="select_option">
                                        <div *ngFor="let option of question.value.options" class="form-check">
                                            <mat-radio-button value="{{option.uuid}}" color="primary" >{{option.label}}</mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </label>
                            </div>
                        </div>            
                        <div *ngIf="question.value.type === 'MULTIPLE_CHOICE'" class="card-body">
                            <div formArrayName="answers_options" class="form-group">
                                <div class="form-check" *ngFor="let option of options(i).controls; let j=index" [formGroupName]="j">
                                    <label class="form-check-label">
                                        <p><mat-checkbox formControlName="selected" color="primary" value="{{option.value.uuid}}">{{option.value.label}}</mat-checkbox></p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Submit answer -->
        <div class="row justify-content-end mb-2">
            <button class="btn btn-primary btn-type1" [style.background-color]="colorHeader">{{'GENERAL.SEND_ANSWER' | translate}}</button>
        </div>
    </form>
</div>
<!-- Error message -->
<ng-template #error_getting_form>
    <div>{{errorMessage}}</div>
</ng-template>

<div *ngIf="ALERT_MESSAGE.IS_DISPLAYED" class="row">
    <div class="col d-flex justify-content-center">
        <div class="toast show toast-form" role="alert" aria-live="assertive" aria-atomic="true" [class.border-primary]="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'informativeModal'" [class.border-success]="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal'" [class.border-danger]="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'errorModal'">
            <div class="toast-header">
                <strong *ngIf="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'errorModal'" class="mr-auto">{{ 'GENERAL.ERROR' | translate }}</strong>
                <strong *ngIf="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal'" class="mr-auto">{{ 'GENERAL.SUCCESS' | translate }}</strong>
                <button *ngIf="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'errorModal'" type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="toast-body">
                <innerHTML HTMLcontent="{{ALERT_MESSAGE.MESSAGE}}"></innerHTML>
            </div>
            <div *ngIf="ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal'" class="row justify-content-center mb-2">
                <button class="btn btn-primary btn-type1 " [style.background]="colorHeader" (click)="navigate('home')">
                    {{ 'GENERAL.TO_HOME_PAGE' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>