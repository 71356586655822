import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxBootstrapModule } from './modules/ngx-bootstrap.module';
import { NgChartjsModule } from 'ng-chartjs';
import { BeginsWithPipe } from './pipes/begins-with.pipe';
import { ShortUrlPipe } from './pipes/short-url.pipe';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { CookieAcceptComponent } from './components/cookie-accept/cookie-accept.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReportingModule } from './components/reporting/reporting.module';
import { DragStopPropagationDirective } from './directives/drag-stop-propagation.directive';
import { CallToActionComponent } from './components/call-to-action/call-to-action.component';
import { RouterModule } from '@angular/router';
import { LocalNavbarComponent } from './components/localnavbar/localnavbar.component';
import { MessageComponent } from './components/message/message.component';
import { GlobalNavbarComponent } from './components/globalnavbar/globalnavbar.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { AdminNavbarComponent } from './components/adminnavbar/adminnavbar.component';
import { ContentBlockingModalComponent } from './components/content-blocking-modal/content-blocking-modal.component';
import { PasswordModalComponent } from './components/password-modal/password-modal.component';

@NgModule({
  declarations: [BeginsWithPipe, ShortUrlPipe, AlertMessageComponent, CookieAcceptComponent, DragStopPropagationDirective, CallToActionComponent, LocalNavbarComponent, AdminNavbarComponent, MessageComponent, GlobalNavbarComponent, SafeUrlPipe, ContentBlockingModalComponent, PasswordModalComponent],
  imports: [
    CommonModule,
    NgxBootstrapModule,
    TranslateModule,
    NgChartjsModule,
    ColorPickerModule,
    MatSelectModule,
    FormsModule,
    NgxDatatableModule,
    ReportingModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    NgxBootstrapModule,
    TranslateModule,
    NgChartjsModule,
    ColorPickerModule,
    NgxDatatableModule,
    ReportingModule,
    BeginsWithPipe,
    ShortUrlPipe,
    AlertMessageComponent,
    CookieAcceptComponent,
    ContentBlockingModalComponent,
    PasswordModalComponent,
    LocalNavbarComponent,
    AdminNavbarComponent,
    MessageComponent,
    GlobalNavbarComponent,
    DragStopPropagationDirective,
    CallToActionComponent,
    SafeUrlPipe
  ],
  entryComponents: [PasswordModalComponent],
  providers: []
})
export class SharedModule { }
