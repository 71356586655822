<div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center" style="min-height: 100vh;">
        <div class="col-12 text-center">
            <div class="no-uuid-content">
                <p lang="fr" class="item">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 600" width="900" height="600"><rect width="900" height="600" fill="#ED2939"/><rect width="600" height="600" fill="#fff"/><rect width="300" height="600" fill="#002395"/></svg>
                    Erreur le qrCode par lequel vous êtes passé n'est pas valide
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 600" width="900" height="600"><rect width="900" height="600" fill="#ED2939"/><rect width="600" height="600" fill="#fff"/><rect width="300" height="600" fill="#002395"/></svg>
                </p>
                <p lang="en" class="item">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 30" width="1000" height="600"> <clipPath id="t"> <path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z"/> </clipPath> <path d="M0,0v30h50v-30z" fill="#012169"/> <path d="M0,0 50,30M50,0 0,30" stroke="#fff" stroke-width="6"/> <path d="M0,0 50,30M50,0 0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/> <path d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z" fill="#C8102E" stroke="#FFF" stroke-width="2"/> </svg>
                    Error: The QR code you passed through is not valid
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 30" width="1000" height="600"> <clipPath id="t"> <path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z"/> </clipPath> <path d="M0,0v30h50v-30z" fill="#012169"/> <path d="M0,0 50,30M50,0 0,30" stroke="#fff" stroke-width="6"/> <path d="M0,0 50,30M50,0 0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/> <path d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z" fill="#C8102E" stroke="#FFF" stroke-width="2"/> </svg>
                </p>
                <p lang="es" class="item">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 400">
                        <image x="0" y="0" width="600" height="400" xlink:href="https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg" />
                    </svg>
                    Error: El código QR por el que pasaste no es válido
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 400">
                        <image x="0" y="0" width="600" height="400" xlink:href="https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg" />
                    </svg>
                </p>
                <p lang="it" class="item">
                    <svg class="flag-icon"xmlns="http://www.w3.org/2000/svg" width="1500" height="1000" viewBox="0 0 3 2"> <rect width="3" height="2" fill="#009246"/> <rect width="2" height="2" x="1" fill="#fff"/> <rect width="1" height="2" x="2" fill="#ce2b37"/> </svg>
                    Errore: Il codice QR attraverso il quale sei passato non è valido
                    <svg class="flag-icon"xmlns="http://www.w3.org/2000/svg" width="1500" height="1000" viewBox="0 0 3 2"> <rect width="3" height="2" fill="#009246"/> <rect width="2" height="2" x="1" fill="#fff"/> <rect width="1" height="2" x="2" fill="#ce2b37"/> </svg>
                </p>
            </div>
        </div>
    </div>
</div>

