import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'no-uuid',
  templateUrl: './no-uuid.component.html',
  styleUrls: ['./no-uuid.component.scss']
})
export class NoUuidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}