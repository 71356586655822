import { OnInit, Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { listOfImagesExtensions } from "src/app/core/classes/ImagesExtensions";
import { QR_CODES_CONFIG } from "src/app/core/classes/QRCodesConfig";
import { ROUTES } from 'src/app/core/classes/Routes';
import { FileManagementService } from "src/app/core/services/file-management.service";
import { HttpclientService } from "src/app/core/services/httpclient.service";
import { RoutesService } from 'src/app/core/services/routes.service';
import { SitesService } from "src/app/core/services/sites.service";
import { environment } from "src/environments/environment";
import { QRCodeGenerationService } from "src/app/core/services/qrcode-generation.service";
import { getCurrentRoute } from "src/app/core/helpers/window-location";

@Component({
    selector: 'aac-config',
    templateUrl: './config.component.html',
    styleUrls: [
        './config.component.scss',
        '../qr-code-management.scss',
        '../../local-back-office.scss',
        '../../../private.scss'
    ]
})
export class QRConfigComponent implements OnInit, OnDestroy {
    qrCodeSettingsInitialized = false;

    canvas: ElementRef;
    canvasLastConfig: ElementRef;

    @ViewChild('canvas', { static: false }) set content(content: ElementRef) {
        if (content) { // initially setter gets called with undefined
            this.canvas = content;
            this.initQrCodeSettings();
        }
    }

    @ViewChild('canvasLastConfig', { static: false }) set contentLastConfig(contentLastConfig: ElementRef) {
        if (contentLastConfig) {
            this.canvasLastConfig = contentLastConfig;
            this.initSelectedQrCodeSettings();
        }
    }

    DATA_IS_LOADED: boolean = false;
    DATA_IS_LOADING: boolean = true;
    SITE_IS_DEACTIVATED: boolean = false;
    message: string = null;

    SITE: any = {};
    SITE_NAME: string = '';

    ROUTES: ROUTES = {
        IS_PUBLIC: false,
        IS_PRIVATE: false,
        IS_GLOBAL: false,
        ROUTE: null,
        PARAMS: null,
        MANDATORY_PARAMS: [],
        QUERY_PARAMS: null
    };

    /**ALERT_MESSAGE object to feed the alertMessage component child */
    ALERT_MESSAGE: ALERT_MESSAGE = {
        TYPE_OF_MESSAGE: null,
        TYPE_OF_ACTION: null,
        MESSAGE: null,
        IS_DISPLAYED: null
    };

    QR_CODE = null;

    CANVAS_FIXED_SIZE = 300;

    QR_CODES_CONFIG: QR_CODES_CONFIG = {
        COLOR: "#FFFFFF",
        SIZE: this.CANVAS_FIXED_SIZE,
        MARGIN: 0,
        TYPE_NUMBER: 0,
        MODE: 'Byte',
        ERROR_CORRECTION_LEVEL: 'Q',
        COLOR_TYPE: 1,
        GRADIENT_OPTION: {
            TYPE: "linear",
            ROTATION: 0,
            COLOR_STOPS: [
                {
                    OFFSET: 0,
                    COLOR: "#FFFFFF",
                },
                {
                    OFFSET: 1,
                    COLOR: "#FFFFFF",
                }
            ]
        },
        IMAGE: {
            IMAGE_NAME: "Logo_Elefight.png",
            IS_BACK_DOTS_HIDDEN: true,
            IMAGE_SIZE: 0.3,
            MARGIN: 0
        },
        DOTS: {
            STYLE: "rounded",
            COLOR_TYPE: 1,
            COLOR: "#000000",
            DOTS_GRADIENT_OPTION: {
                TYPE: "linear",
                ROTATION: 0,
                COLOR_STOPS: [
                    {
                        OFFSET: 0,
                        COLOR: "#FFFFFF",
                    },
                    {
                        OFFSET: 1,
                        COLOR: "#FFFFFF",
                    }
                ]
            }
        },
        CORNERS_SQUARE: {
            COLOR: "#000000",
            STYLE: "extra-rounded",
            COLOR_TYPE: 1,
            CORNERS_SQUARE_GRADIENT_OPTION: {
                TYPE: "linear",
                ROTATION: 0,
                COLOR_STOPS: [
                    {
                        OFFSET: 0,
                        COLOR: "#FFFFFF",
                    },
                    {
                        OFFSET: 1,
                        COLOR: "#FFFFFF",
                    }
                ]
            }
        },
        CORNERS_DOTS: {
            COLOR: "#000000",
            STYLE: "dot",
            COLOR_TYPE: 1,
            CORNERS_DOTS_GRADIENT_OPTION: {
                TYPE: "linear",
                ROTATION: 0,
                COLOR_STOPS: [
                    {
                        OFFSET: 0,
                        COLOR: "#FFFFFF",
                    },
                    {
                        OFFSET: 1,
                        COLOR: "#FFFFFF",
                    }
                ]
            }
        }
    };

    QR_MODES_LIST = ['Byte', 'Numeric', 'Alphanumeric', 'Kanji'];

    QR_ERROR_CORRECTION_LEVELS_LIST = ['L', 'M', 'Q', 'H'];

    DOTS_STYLES_LIST = ['rounded', 'dots', 'classy', 'classy-rounded', 'square', 'extra-rounded'];

    CORNERS_SQUARE_STYLES_LIST = ["dot", "square", "extra-rounded"];

    CORNERS_DOTS_STYLES_LIST = ["dot", "square"];

    QR_CODE_DOTS_COLORSTOPS = [];

    selectedQRCodes: any[] = [];

    toggle = {
        "backGroundPicker": false,
        "dotPicker": false,
        "backGroundGradientPicker": false,
        "dotGradientPicker": false,
        "cornerSquarePicker": false,
        "cornerSquareGradientPicker": false,
        "cornerDotPicker": false,
        "cornerDotGradientPicker": false
    };

    togglePicker(option: string) {
        Object.keys(this.toggle)
            .map((picker) => {
                if (picker == option)
                    this.toggle[picker] = !this.toggle[picker];
                else this.toggle[picker] = false;
            })
    }

    imageQRCodeError: string = "";
    IMG_FILE_QR: File;
    data: string = `${getCurrentRoute()}/home/`;
    SRC_IMAGE_RENDER = null;
    
    constructor(
        private _routesService: RoutesService,
        private _qrCodeGeneration: QRCodeGenerationService,
        private _httpClientService: HttpclientService,
        private _sitesService: SitesService,
        private _fileManagementService: FileManagementService,
        private router: Router,
        private titleService: Title
    ) {
        this.titleService.setTitle("Elefight - QR Code Management");
        this.message = "Data is loading...";
        this.SRC_IMAGE_RENDER = "../../../../../assets/img/Logo_Elefight.png";

        /** Get routes information then get site information*/
        this._routesService.currentRoutes.subscribe(routes => {
            this.ROUTES = routes;
            if (routes.PARAMS) {
                if (routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
                (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
                this.data = `${getCurrentRoute()}/home/${this.SITE_NAME.toLowerCase()}?utm_source=QR Code Custom&utm_medium=QR Code Custom&utm_campaign=${this.SITE_NAME.toUpperCase()}`;
                this.DATA_IS_LOADING = false;
            }
        });
    }

    ngOnInit(): void {
        /** Get site */
        this._sitesService.getSite(this.SITE_NAME).then(async (value) => {
            this.SITE = value;
            this.selectedQRCodes = JSON.parse(sessionStorage.getItem('selectedQRCodes'));

            // Check if site exists and if it's activated
            if (Object.keys(this.SITE).length <= 0 || this.SITE.OPTIONS.ACTIVATED == 'false') {
                console.error("Site doesn't exist or has been deactivated");
                this.message = "This site/page is no longer available or has been deactivated";
                this.SITE_IS_DEACTIVATED = true;
                return;
            }

            // If one selected QR code
            if (this.selectedQRCodes.length === 1) {

                // Get its QR code's settings has main canvas configuration
                const qrConfig = this.selectedQRCodes[0].qrcodeconfig;

                this.QR_CODES_CONFIG = this._qrCodeGeneration.initGlobalConfig(qrConfig);

                if (qrConfig.M.image.M.image_name && qrConfig.M.image.M.image_name.S != "") {
                    this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME = qrConfig.M.image.M.image_name.S;
                    if (qrConfig.M.image.M.image_name.S === "Logo_Elefight.png") {
                        this.SRC_IMAGE_RENDER = "../../../../../assets/img/Logo_Elefight.png";
                    } else {
                        this.SRC_IMAGE_RENDER = `${environment.inputPath}SITES/${this.SITE_NAME}/IMG/${qrConfig.M.image.M.image_name.S}`;
                    }
                } else if (qrConfig.M.image.M.image_name && qrConfig.M.image.M.image_name.S == "") {
                    this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME = "";
                    this.SRC_IMAGE_RENDER = "";
                }
            }

            this.DATA_IS_LOADED = true;
        }).catch(err => { console.error("Error: ", err); });
    }

    ngOnDestroy(): void {
        console.log("Destroying component...");
        sessionStorage.removeItem('selectedQRCodes');
    }

    initSelectedQrCodeSettings() {
        if (this.qrCodeSettingsInitialized) {
            return;
        }

        for (let i = 0; i < this.selectedQRCodes.length; i++) {
            const qrcode = this.selectedQRCodes[i];

            if (!qrcode.qrcodeconfig) {
                // Appliquer la configuration par défaut
                const qrConfig = this._qrCodeGeneration.getQrCodeDefaultConfig();
                qrcode['qrcodeconfig'] = qrConfig;
            }

            // Créer un aperçu du code QR pour les éléments sélectionnés
            let current_qr_code_selected_preview = this._qrCodeGeneration.getQRCodeData(qrcode.textQr, qrcode.srcImgRenderQr, 100, qrcode.qrcodeconfig);

            current_qr_code_selected_preview.append(document.getElementById(`canvasLastConfig_${i}`));
        }

        this.qrCodeSettingsInitialized = true;
    }


    initQrCodeSettings() {
        if (this.qrCodeSettingsInitialized) {
            return;
        }
        this.QR_CODE = this._qrCodeGeneration.getQRCodeData(this.data, this.SRC_IMAGE_RENDER, this.CANVAS_FIXED_SIZE, this.QR_CODES_CONFIG);
        this.QR_CODE.append(this.canvas.nativeElement);
    }

    addQrColorStop(elemType) {
        if (elemType = "dots") {
            this.QR_CODE_DOTS_COLORSTOPS.push(
                {
                    offset: 0,
                    color: '#FFFFFF'
                }
            )
        }
    }

    updateQrCode() {

        this.QR_CODE.update({
            width: this.CANVAS_FIXED_SIZE,
            height: this.CANVAS_FIXED_SIZE,
            type: 'svg',
            data: this.data,
            image: this.SRC_IMAGE_RENDER,
            margin: parseInt(String(this.QR_CODES_CONFIG.MARGIN)),
            qrOptions: {
                typeNumber: parseInt(String(this.QR_CODES_CONFIG.TYPE_NUMBER)),
                mode: this.QR_CODES_CONFIG.MODE,
                errorCorrectionLevel: this.QR_CODES_CONFIG.ERROR_CORRECTION_LEVEL,
            },
            imageOptions: {
                hideBackgroundDots: this.QR_CODES_CONFIG.IMAGE.IS_BACK_DOTS_HIDDEN,
                imageSize: parseFloat(String(this.QR_CODES_CONFIG.IMAGE.IMAGE_SIZE)),
                margin: parseInt(String(this.QR_CODES_CONFIG.IMAGE.MARGIN)),
                crossOrigin: 'anonymous',
            },
            dotsOptions: {
                color: this.QR_CODES_CONFIG.DOTS.COLOR,
                type: this.QR_CODES_CONFIG.DOTS.STYLE,
                gradient: this.QR_CODES_CONFIG.DOTS.COLOR_TYPE === 2 ? {
                    type: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.TYPE,
                    rotation: parseInt(String(this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.ROTATION * Math.PI / 180)),
                    colorStops: [
                        {
                            offset: parseInt(String(this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET)),
                            color: this.QR_CODES_CONFIG.DOTS.COLOR
                        },
                        {
                            offset: parseInt(String(this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET)),
                            color: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR
                        }
                    ]
                    // colorStops: this.QR_CODE_DOTS_COLORSTOPS
                } : null
            },
            backgroundOptions: {
                color: this.QR_CODES_CONFIG.COLOR,
                gradient: this.QR_CODES_CONFIG.COLOR_TYPE === 2 ? {
                    type: this.QR_CODES_CONFIG.GRADIENT_OPTION.TYPE,
                    rotation: parseInt(String(this.QR_CODES_CONFIG.GRADIENT_OPTION.ROTATION * Math.PI / 180)),
                    colorStops: [
                        {
                            offset: parseInt(String(this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[0].OFFSET)),
                            color: this.QR_CODES_CONFIG.COLOR
                        },
                        {
                            offset: parseInt(String(this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].OFFSET)),
                            color: this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].COLOR
                        }
                    ]
                    // colorStops: this.QR_CODE_COLORSTOPS
                } : null
            },
            cornersSquareOptions: {
                color: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
                type: this.QR_CODES_CONFIG.CORNERS_SQUARE.STYLE,
                gradient: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR_TYPE === 2 ? {
                    type: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.TYPE,
                    rotation: parseInt(String(this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.ROTATION * Math.PI / 180)),
                    colorStops: [
                        {
                            offset: parseInt(String(this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET)),
                            color: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR
                        },
                        {
                            offset: parseInt(String(this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET)),
                            color: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR
                        }
                    ]
                    // colorStops: this.QR_CODE_CORNERS_SQUARE_COLORSTOPS
                } : null
            },
            cornersDotOptions: {
                color: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
                type: this.QR_CODES_CONFIG.CORNERS_DOTS.STYLE,
                gradient: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR_TYPE === 2 ? {
                    type: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.TYPE,
                    rotation: parseInt(String(this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.ROTATION * Math.PI / 180)),
                    colorStops: [
                        {
                            offset: parseInt(String(this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET)),
                            color: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR
                        },
                        {
                            offset: parseInt(String(this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET)),
                            color: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR
                        }
                    ]
                    // colorStops: this.QR_CODE_CORNERS_DOTS_COLORSTOPS
                } : null
            }
        });
    }

    isLightColor(color: string) {
        const hex = color.replace('#', '');
        const rgb = hex.match(/.{1,2}/g)?.map(val => parseInt(val, 16));
        if (rgb) {
            const yiq = ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000;
            return yiq > 214; // valeur de luminance pour déterminer si la couleur est claire
        }
        return false;
    }

    getContrastRatio(color1, color2) {
        // Convertir les couleurs hexadécimales en valeurs décimales
        const r1 = parseInt(color1.substr(1, 2), 16);
        const g1 = parseInt(color1.substr(3, 2), 16);
        const b1 = parseInt(color1.substr(5, 2), 16);

        const r2 = parseInt(color2.substr(1, 2), 16);
        const g2 = parseInt(color2.substr(3, 2), 16);
        const b2 = parseInt(color2.substr(5, 2), 16);

        // Calculer la luminance des couleurs
        const l1 = 0.2126 * (r1 / 255) + 0.7152 * (g1 / 255) + 0.0722 * (b1 / 255);
        const l2 = 0.2126 * (r2 / 255) + 0.7152 * (g2 / 255) + 0.0722 * (b2 / 255);

        // Calculer le contraste
        const contrastRatio = (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);

        // Vérifier si le contraste est trop faible (inférieur à 4.5 pour un texte normal)
        if (contrastRatio < 4.5) {
            console.log("Le contraste est trop faible pour le QR code.");
        }
        return contrastRatio;
    }

    /** Function to get the files selected */
    getFiles(event) {
        this.imageQRCodeError = '';
        if (event.target.files.length > 0) {
            if (event.target.files[0].size > 2000000) this.imageQRCodeError = 'This file is too large. It must be less than 2MB.';
            if (!listOfImagesExtensions.includes(event.target.files[0].name.split('.').pop())) this.imageQRCodeError = 'File format not allowed. It must be an image.';
            if (/[^a-zA-Z_\-0-9\s]/.test(event.target.files[0].name.split('.').shift())) this.imageQRCodeError = 'File name not allowed. It must not contains any special characters other than "-" and "_" .';
            if (this.imageQRCodeError) return;
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (event2: any) => {
                this.SRC_IMAGE_RENDER = event2.target.result;
                this.IMG_FILE_QR = event.target.files[0];
                this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME = this.IMG_FILE_QR.name;
                this.updateQrCode();
            }
        }
    }

    removeQRCodeImage(inputId: string) {
        this.SRC_IMAGE_RENDER = "";
        this.IMG_FILE_QR = null;
        this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME = "";
        this.imageQRCodeError = "";
        const inputElement = <HTMLInputElement>document.getElementById(inputId);
        if (inputElement) {
            inputElement.value = "";
        }
        this.updateQrCode();
    }

    /** Function to save configuration of the QR Codes in the DynamoDB */
    saveConfiguration() {
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
        this.ALERT_MESSAGE.TYPE_OF_ACTION = "save";

        let tableHtml = `
        <p>Are you sure you want to save this QR Code configuration ?</p>
        <span>This will affect ${this.selectedQRCodes.length} QR Code(s)</span><br/>
        <table class="table table_modal mt-2">
            <thead>
                <tr>
                    <th scope="col">Location: Level 1</th>
                    <th scope="col">Location: Level 2</th>
                    <th scope="col">Redirects to...</th>
                </tr>
            </thead>
            <tbody>`;

        for (let code of this.selectedQRCodes) {
            tableHtml += `
                    <tr>
                        <td>${code.loc1}</td>
                        <td>${code.loc2}</td>
                        <td>${code.landingPage === 'externalUrl' ? code.externalUrl : code.landingPage}</td>
                    </tr>`;
        }

        tableHtml += `
            </tbody>
        </table>
        `;

        this.ALERT_MESSAGE.MESSAGE = tableHtml;
    }

    /** Function to navigate through components */
    navigate(route: string) {
        this.router.navigate([`/admin/${this.SITE_NAME}/${route}`]);
    }

    /** Callback sent to {@link HTTPClientService} */
    callbackHTTPClient(message: string) {
        var VAL_CALLBACKHTTPCLIENT = '';
        VAL_CALLBACKHTTPCLIENT = "Erreur";
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
        this.ALERT_MESSAGE.MESSAGE = VAL_CALLBACKHTTPCLIENT;
    }

    /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal, sent to {@link AlertMessage} */
    callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
        this.ALERT_MESSAGE.IS_DISPLAYED = false;
        this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
        this.ALERT_MESSAGE.MESSAGE = null;

        if (ALERT_MESSAGE.TYPE_OF_MESSAGE === "successModal") {
            this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
            this.router.navigate([`/admin/${this.SITE_NAME}/qr-code-management/update`]);
        }
    }

    callbackActionModal_save() {
        this.ALERT_MESSAGE.IS_DISPLAYED = false;

        let config = {
            color: this.QR_CODES_CONFIG.COLOR,
            size: parseInt(String(this.QR_CODES_CONFIG.SIZE)),
            margin: this.QR_CODES_CONFIG.MARGIN,
            type_number: parseInt(String(this.QR_CODES_CONFIG.TYPE_NUMBER)),
            mode: this.QR_CODES_CONFIG.MODE,
            error_correction_level: this.QR_CODES_CONFIG.ERROR_CORRECTION_LEVEL,
            color_type: parseInt(String(this.QR_CODES_CONFIG.COLOR_TYPE)),
            gradient_option: {
                type: this.QR_CODES_CONFIG.GRADIENT_OPTION.TYPE,
                rotation: this.QR_CODES_CONFIG.GRADIENT_OPTION.ROTATION,
                color_stops: [
                    {
                        offset: this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[0].OFFSET,
                        color: this.QR_CODES_CONFIG.COLOR,
                    },
                    {
                        offset: this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].OFFSET,
                        color: this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                    }
                ]
            },
            image: {
                image_name: this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME,
                is_back_dots_hidden: this.QR_CODES_CONFIG.IMAGE.IS_BACK_DOTS_HIDDEN,
                image_size: this.QR_CODES_CONFIG.IMAGE.IMAGE_SIZE,
                margin: this.QR_CODES_CONFIG.IMAGE.MARGIN
            },
            dots: {
                style: this.QR_CODES_CONFIG.DOTS.STYLE,
                color_type: parseInt(String(this.QR_CODES_CONFIG.DOTS.COLOR_TYPE)),
                color: this.QR_CODES_CONFIG.DOTS.COLOR,
                dots_gradient_option: {
                    type: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.TYPE,
                    rotation: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.ROTATION,
                    color_stops: [
                        {
                            offset: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET,
                            color: this.QR_CODES_CONFIG.DOTS.COLOR,
                        },
                        {
                            offset: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET,
                            color: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                        }
                    ]
                }
            },
            corners_square: {
                color: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
                style: this.QR_CODES_CONFIG.CORNERS_SQUARE.STYLE,
                color_type: parseInt(String(this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR_TYPE)),
                corners_square_gradient_option: {
                    type: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.TYPE,
                    rotation: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.ROTATION,
                    color_stops: [
                        {
                            offset: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET,
                            color: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
                        },
                        {
                            offset: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET,
                            color: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                        }
                    ]
                }
            },
            corners_dots: {
                color: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
                style: this.QR_CODES_CONFIG.CORNERS_DOTS.STYLE,
                color_type: parseInt(String(this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR_TYPE)),
                corners_dots_gradient_option: {
                    type: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.TYPE,
                    rotation: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.ROTATION,
                    color_stops: [
                        {
                            offset: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET,
                            color: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
                        },
                        {
                            offset: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET,
                            color: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                        }
                    ]
                }
            }
        };

        let QRCodeConfig = {
            qrCodes: this.selectedQRCodes.map(qrCode => {return qrCode.uuid}),
            config: config
        };

        if (this.IMG_FILE_QR) this._fileManagementService.uploadFile(this.IMG_FILE_QR, null, this.IMG_FILE_QR.name.substring(this.IMG_FILE_QR.name.lastIndexOf('.') + 1), this.SITE_NAME, this.IMG_FILE_QR.name);
        this._httpClientService.configQRCode(this.SITE_NAME, QRCodeConfig).then(data => {
            console.debug("Your QR Codes configuration has been saved !");
            this.ALERT_MESSAGE.IS_DISPLAYED = true;
            this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
            this.ALERT_MESSAGE.MESSAGE = "Your QR Codes configuration has been saved !";
        }).catch(err => {
            console.error("Error: ", err);
            this.ALERT_MESSAGE.IS_DISPLAYED = true;
            this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
            this.ALERT_MESSAGE.MESSAGE = "There is an error with the services. Please contact your administrator.";
        });
    }
}
