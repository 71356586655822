<div class="main">
    <!-- Navigation -->
    <LocalNavBar [COMPONENT]="'FormsComponent'"></LocalNavBar>

    <div *ngIf="SITE_IS_DEACTIVATED; else elseBlock" class="component text-center my-5">
        <Message [MESSAGE]="message"></Message>
    </div>

    <ng-template #elseBlock>
        <div class="container my-4" style="width: 50vw;">
            <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
                <!-- Form title -->
                <div class="form-group">
                    <p class="labelLike">Form title</p>
                    <input type="text" id="form_title" class="form-control" formControlName="title" [ngClass]="{ 'is-invalid': submitted && newForm.controls.title.errors }"/>
                    <div *ngIf="submitted && nF.title.errors" class="invalid-feedback">
                        <div *ngIf="nF.title.errors.required">Form title is required</div>
                    </div>
                </div>
    
                <!-- Description -->
                <div class="form-group">
                    <p class="labelLike">Description</p>
                    <ckeditor class="ckEditor" [editor]="Editor" [config]="editorConfig" id="description" formControlName="description"></ckeditor>
                </div>
                
                <!-- Questions -->
                <div formArrayName="questions" class="form-group">
                    <div class="form-group">
                        <div *ngFor="let question of questions().controls; let i=index" [formGroupName]="i" class="card my-3">
                            <div class="d-flex mb-3">
                                <!-- Number of the question -->
                                <div class="p-2 ml-5 d-flex align-items-center">
                                    <span *ngIf="i >= 9;else lower_than_ten"class="component_id">{{i+1}}</span>
                                    <ng-template #lower_than_ten>
                                        <span class="component_id">0{{i+1}}</span>
                                    </ng-template>
                                </div>
                                <!-- Question options -->
                                <div class="ml-auto p-2 mr-2 d-flex align-items-center">
                                    <div class="row bottom_row_buttons">
                                        <div class="btn-toolbar ml-1" role="toolbar">
                                            <!-- Activation -->
                                            <div class="btn-group mr-1" role="group">
                                                <button type="button" class="btn m-0"><mat-slide-toggle class="m-0 p-0 mat-slide-toggle-long" formControlName="status_toogle"></mat-slide-toggle></button>
                                            </div>
                                            <!-- Position -->
                                            <div *ngIf="questions().controls.length > 1" class="btn-group" role="group">
                                                <button type="button" class="btn m-0 p-0" [disabled]="i === 0" (click)="moveItem(i, i-1)"><span class="material-icons blue">arrow_upward</span></button>
                                                <button type="button" class="btn m-0 p-0" [disabled]="i+1 === questions().controls.length" (click)="moveItem(i, i+1)"><span class="material-icons blue">arrow_downward</span></button>
                                            </div>
                                            <!-- Remove Question -->
                                            <div *ngIf="questions().controls.length > 1" class="btn-group" role="group">
                                                <button type="button" class="btn m-0"><span class="material-icons red" (click)="removeQuestion(i)">remove_circle</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <!-- Question title -->
                                <div class="form-group">
                                    <p class="labelLike">Question title</p>
                                    <input type="text" placeholder="Title" id="question_title" formControlName="title" class="form-control" [ngClass]="{ 'is-invalid': submitted && question.controls.title.errors }"/>
                                    <div *ngIf="submitted && question.controls.title.errors" class="invalid-feedback">
                                        <div *ngIf="question.controls.title.errors.required">Question title is required</div>
                                    </div>
                                </div>
                                <!-- Image -->
                                <div class="form-group">
                                    <p class="labelLike">Image (max 2MB)</p>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" [id]="'image_file_' + i" formControlName="image_file" accept="image/*" (change)="onFileChange($event, i)">
                                        <label *ngIf="question.value.image; else no_image_name" class="custom-file-label" for="image">{{question.value.image}}</label>
                                        <ng-template #no_image_name><label class="custom-file-label" for="image">IMAGE</label></ng-template>
                                    </div>
                                    <div *ngIf="imageFileAt(i).invalid && (imageFileAt(i).dirty || imageFileAt(i).touched)" class="alert alert-danger">
                                        <div *ngIf="imageFileAt(i).errors.invalidFileSize">
                                            This file is too large. It must be less than 2MB.
                                        </div>
                                        <div *ngIf="imageFileAt(i).errors.extension">
                                            File format not allowed. It must be an image.
                                        </div>
                                        <div *ngIf="imageFileAt(i).errors?.unauthorizedFileName">
                                            File name not allowed. It must not contains any special characters other than "-" and "_" .
                                        </div>
                                    </div>
                                    <!-- Image Preview -->
                                    <div *ngIf="imageFileAt(i).valid && file_to_display[i] && file_to_display[i] !== ''">
                                        <img class="preview_image" [src]="file_to_display[i]">
                                    </div>
                                </div>
                                <!-- Type -->
                                <div class="form-group">
                                    <p class="labelLike">Type</p>
                                    <select name="sel2" formControlName="type" class="form-control" [ngClass]="{ 'is-invalid': submitted && question.controls.type.errors }">
                                        <option [ngValue]="null">Select a type</option>
                                        <option value="SIMPLE_CHOICE">Simple choice</option>
                                        <option value="MULTIPLE_CHOICE">Multiple Choice</option>
                                    </select>
                                    <div *ngIf="submitted && question.controls.type.errors" class="invalid-feedback">
                                        <div *ngIf="question.controls.type.errors.required">Question type is required</div>
                                    </div>
                                </div>
                                <!-- Options -->
                                <div formArrayName="answers_options" class="form-group">
                                    <p class="labelLike">Options</p>
                                    <div class="row justify-content-md-center" *ngFor="let option of options(i).controls; let j=index" [formGroupName]="j">
                                        <div [class.col-md-12]="options(i).length == 1" [class.px-0]="options(i).length == 1" [class.col-md-11]="options(i).length > 1" [class.pl-0]="options(i).length > 1">
                                            <input type="text" placeholder="Option title" formControlName="label" class="form-control" [ngClass]="{ 'is-invalid': submitted && option.controls.label.errors }">
                                            <div *ngIf="submitted && option.controls.label.errors" class="invalid-feedback">
                                                <div *ngIf="option.controls.label.errors.required">Question otpion is required</div>
                                            </div>
                                        </div>
                                        <div class="col-md-1 pl-0 align-self-end" [class.align-self-baseline]="submitted && option.controls.label.errors">
                                            <button *ngIf="options(i).length > 1" type="button" class="btn"><span class="material-icons red" (click)="removeOption(i, j)">remove_circle</span></button>
                                        </div>    
                                    </div>
                                </div>
                                <!-- Add option -->
                                <div class="row">
                                    <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center" (click)="addOption(i)">
                                        Add option
                                        <span class="material-icons white icons_button ml-1">
                                            add_circle
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Add question -->
                <div class="row">
                    <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center" (click)="addQuestion()">
                        Add question
                        <span class="material-icons white icons_button ml-1">
                            add_circle
                        </span>
                    </button>
                </div>
    
                <!-- End Message -->
                <div class="form-group mt-4">
                    <p class="labelLike">End Message</p>
                    <ckeditor class="ckEditor" [editor]="Editor" [config]="editorConfig" id="end_message" formControlName="end_message" [ngClass]="{ 'is-invalid': submitted && newForm.controls.end_message.errors }"></ckeditor>
                    <div *ngIf="submitted && nF.end_message.errors" class="invalid-feedback">
                        <div *ngIf="nF.end_message.errors.required">Form end message is required</div>
                    </div>
                </div>

    
                <!-- Submit form creation -->
                <div class="row justify-content-md-end">
                    <button class="align-items-center btn btn-global-type btn-type2 d-flex mb-3">Create form</button>
                </div>
            </form>
        </div>
    </ng-template>

</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>