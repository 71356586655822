import { Component, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EDITOR_CONFIG from "../../../../../../assets/constants/editorConfig";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ImageAndTextForm',
  templateUrl: './image-and-text.component.html',
  styleUrls: [
    './image-and-text.component.scss',
    '../../content-management.scss',
    '../../../local-back-office.scss',
    '../../../../private.scss'
  ]
})
export class ImageAndTextComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;
  @Input() option: string;
  @Input() id: number;
  @Output() files: EventEmitter<{ file: any, id: number, type: string }> = new EventEmitter<{ file: '', id: -1, type: '' }>();

  public Editor = ClassicEditor;
  public editorConfig;

  currentFiles: Array<File> = [];

  default_line = {
    imagePath: '',
    text: ''
  }

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.editorConfig = EDITOR_CONFIG;
  }

  ngOnInit(): void {
    console.log(this.pageCompositeFields(this.id));
    if (this.pageCompositeFields(this.id).value.length <= 0)
      this.addLine(this.id, this.default_line);
  }

  onChangeFile(event, i: number) {
    if(event.target.files.length > 0){
      const thisLine: FormGroup = this.pageCompositeFields(this.id).controls[i] as FormGroup;

      //fetch only the file name from the html
      const file = event.target.files.item(0);
      const myRenamedFile = new File([file], `${file.name.split('.').shift()}_${this.datePipe.transform(new Date(), 'dd-MM-yyyy_hh-mm-ss')}.${file.name.split('.').pop()}`);
      thisLine.controls['imagePath'].setValue(myRenamedFile.name);
      this.currentFiles[i] = myRenamedFile;
      this.files.emit({ file: this.currentFiles, id: this.id, type: this.type });
    }
  }

  pageCompositeFields(index: number): FormArray { return this.parentForm.get(`pages_${this.type}`)['controls'][index].get("pageCompositeFields") as FormArray }
  addLine(i: number, field) {
    const newLine: FormGroup = this.formBuilder.group({
      imagePath: [field.imagePath],
      text: [field.text]
    });
    this.pageCompositeFields(i).push(newLine);
  }

  removeLine(index: number, i: number) {
    this.pageCompositeFields(index).removeAt(i);
  }
}
