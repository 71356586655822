import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'call-to-actions-reporting',
  templateUrl: './call-to-actions-reporting.component.html',
  styleUrls: ['./call-to-actions-reporting.component.scss']
})
export class CallToActionsReportingComponent implements OnInit {

  @Input() CTA_LIST: any[];
  @Input() SITE_NAME: string;

  ENVIRONMENT_PATH: string = environment.inputPath;

  constructor() {}

  ngOnInit(): void { }

}
