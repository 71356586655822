import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { SurveysService } from 'src/app/core/services/surveys.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { RoutesService } from 'src/app/core/services/routes.service';
import { ROUTES } from 'src/app/core/classes/Routes';
import { DragAndDropService } from 'src/app/core/services/drag-and-drop.service';
import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { MultiCallToActionModalComponent } from './multi-call-to-action/multi-call-to-action-modal/multi-call-to-action-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'form-multi-pages-common',
  templateUrl: './multi-pages-common.component.html',
  styleUrls: [
    './multi-pages-common.component.scss',
    '../../content-management.scss',
    '../../../local-back-office.scss',
    '../../../../private.scss'
  ]
})
export class MainMultiPagesCommonComponent implements OnInit, AfterViewChecked {

  @Input() siteName: string;
  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;
  @Input() selectedPage: string;
  @Output() files: EventEmitter<{ file: any, idPage: number, type: string, section: string }> = new EventEmitter<{ file: '', idPage: -1, type: '', section: '' }>();
  @Output() pageIdChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList)
  set placeholder(ph: CdkDropList) {
    if (ph && !this._placeholder) {
      this._placeholder = ph;
      let phElement = ph.element.nativeElement;
      phElement.style.display = 'none';
      phElement.parentElement.removeChild(phElement);
    }
  }

  currentPageIndex: number = null;
  currentEditedCta: FormGroup | null = null;
  currentCopyCtaStyle: FormGroup | null = null;
  currentEditedCtaIndex: number = null;
  submitted: boolean = false;
  isCtaEditionMenuOpen: boolean = false;

  options_array: any[] = [
    {
      label: "Full image page",
      value: "FULL_IMAGE"
    },
    {
      label: "Full text page",
      value: "FULL_TEXT"
    },
    {
      label: "Image and text page",
      value: "IMAGE_AND_TEXT"
    }
  ];

  default_line = {
    imagePath: '',
    text: ''
  }

  PAGES: any;

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null
  };
  SITE_NAME: string = '';
  ENVIRONMENT_PATH: string = environment.inputPath;

  pages_array: Array<any> = [];
  NumberMaxOfPages: number = 6;

  SURVEYS_LIST = [];
  currentFiles: Array<{ file: File, multi_cta?: Array<{ file: File, logo: File }> }> = [];

  public color: string = '#264F6F';
  public editorConfig;

  private _placeholder: CdkDropList;

  constructor(
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private datePipe: DatePipe,
    private _surveysService: SurveysService,
    private _routesService: RoutesService,
    private dragAndDropService: DragAndDropService,
    private multiCtaDialog: MatDialog,
  ) {
    /** Get routes information then get site information */
    this._routesService.currentRoutes.subscribe(routes => {
      this.ROUTES = routes;
      if (routes.PARAMS) {
        if (routes.PARAMS['site'] != undefined) this.SITE_NAME = routes.PARAMS['site'].toUpperCase();
        else this.SITE_NAME = '';
      }
    });
    this.initCtaEditionForm();
  }

  ngOnInit(): void {
    this._surveysService.getForms(this.siteName).then((res) => {
      this.SURVEYS_LIST.length = 0;
      this.SURVEYS_LIST = JSON.parse(JSON.stringify(res));
    });
  }

  initCtaEditionForm() {
    this.currentEditedCta = this.formBuilder.group({
      size: ['Medium'],
      text: [''],
      textColor: ['#2B2B38'],
      bgColor: ['#FFFFFF'],
      logo: [''],
      currentUploadedLogo: [''],
      action: this.formBuilder.group({
        type: ['OpenFile'],
        documentPath: [''],
        url: [''],
        survey: ['']
      })
    });

    this.currentEditedCta.valueChanges.subscribe(value => {
      if (this.currentEditedCtaIndex !== null) {
        let ctaIndex = this.currentEditedCtaIndex;
        let ctaList: FormArray = this.getCtaListControl() as FormArray;

        if (ctaList) {
          if (ctaIndex >= 0 && ctaIndex < ctaList.length) {
            ctaList.at(ctaIndex).patchValue({
              size: this.currentEditedCta.value.size,
              text: this.currentEditedCta.value.text,
              textColor: this.currentEditedCta.value.textColor,
              bgColor: this.currentEditedCta.value.bgColor,
              logo: this.currentEditedCta.value.logo,
              currentUploadedLogo: this.currentEditedCta.value.currentUploadedLogo,
              action: {
                type: this.currentEditedCta.value.action.type,
                documentPath: this.currentEditedCta.value.action.documentPath,
                url: this.currentEditedCta.value.action.url,
                survey: this.currentEditedCta.value.action.survey
              }
            });
            this.parentForm.updateValueAndValidity();
          }
        }
      }
      this.changeDetector.detectChanges();
    });
  }

  public onEventLog(color: any, idPage): void {
    this.parentForm['controls'][`pages_${this.type}`]['controls'][idPage]['controls']['color'].setValue(color);
  }

  ngAfterViewChecked() { this.changeDetector.detectChanges(); }

  closeEditMenu() {
    this.initCtaEditionForm();
    this.isCtaEditionMenuOpen = false;
    this.currentEditedCtaIndex = null;
  }

  toggleCtaEdition(event: Event, ctaIndex: number) {
    event.stopPropagation();
    if (!this.isCtaEditionMenuOpen) {
      this.isCtaEditionMenuOpen = true;
    }
    this.currentEditedCtaIndex = ctaIndex;

    let ctaList: FormArray = this.getCtaListControl() as FormArray;
    let cta = ctaList.at(ctaIndex).value;

    this.currentEditedCta.setValue({
      size: cta.size,
      text: cta.text,
      textColor: cta.textColor,
      bgColor: cta.bgColor,
      logo: cta.logo,
      currentUploadedLogo: cta.currentUploadedLogo,
      action: {
        type: cta.action.type,
        documentPath: cta.action.documentPath,
        url: cta.action.url,
        survey: cta.action.survey
      }
    });

    const dialogRef = this.multiCtaDialog.open(MultiCallToActionModalComponent, {
      data: {
        siteName: this.siteName,
        environmentPath: this.ENVIRONMENT_PATH,
        ctaIndex: ctaIndex,
        currentEditedCta: cta,
        filesEvent: this.files,
        surveysList: this.SURVEYS_LIST
      }
    })

    dialogRef.componentInstance.filesEvent.subscribe((filesData) => {
      this.files.emit({ file: filesData.file, idPage: this.currentPageIndex, type: this.type, section: filesData.section });
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log("data : ", data);
      let CTA = data.event
      this.currentEditedCta.patchValue({
        size: CTA.value.size,
        text: CTA.value.text,
        textColor: CTA.value.textColor,
        bgColor: CTA.value.bgColor,
        logo: CTA.value.logo,
        currentUploadedLogo: CTA.value.currentUploadedLogo,
        action: {
          type: CTA.value.action.type,
          documentPath: CTA.value.action.documentPath,
          url: CTA.value.action.url,
          survey: CTA.value.action.survey
        }
      });
      this.changeDetector.detectChanges();
      if (this.currentEditedCta.value.currentUploadedLogo) {
        let outputImage = document.getElementById('output_image_' + ctaIndex) as HTMLImageElement;
        outputImage.src = URL.createObjectURL(this.currentEditedCta.value.currentUploadedLogo);
      }
    });
  }

  /**
   * on changing pages
   * @param event event of the page
   */
  onChangePages(event) {
    this.selectedPage = event.target.value;

    this.closeEditMenu();

    const pagesArray = this.pages()['value'];
    this.currentPageIndex = pagesArray.findIndex(page => page.id == event.target.value);

    let pagecourante = pagesArray[this.currentPageIndex];

    if (pagecourante != undefined) {
      if (pagecourante['color'] != null) {
        this.color = pagecourante['color'];
      } else {
        if (this.type === 'primary') this.color = this.parentForm.value.headerColor_09_primary;
        if (this.type === 'secondary') this.color = this.parentForm.value.headerColor_09_secondary;
      }
    }
  }

  getCallToActionMultiCtaControl(pageId: number) {
    const pagesControl = this.pages();
    if (pagesControl && pageId >= 0 && pageId < pagesControl.length) {
      const pageControl = pagesControl.at(pageId);
      if (pageControl) {
        return pageControl.get('callToAction').get('callToActionMultiCta') as FormGroup;
      }
    }
    return null;
  }

  getCtaListControl() {
    const callToActionMultiCtaControl = this.getCallToActionMultiCtaControl(this.currentPageIndex);
    if (callToActionMultiCtaControl) {
      return callToActionMultiCtaControl.get('ctaList') as FormArray;
    }
    return null;
  }

  /** ---- Page Management ---- */
  pages(): FormArray { return this.parentForm.get(`pages_${this.type}`) as FormArray }

  //page(i) : FormGroup { return this.pages().controls[i] as FormGroup }
  addPage(page: any) {
    this.addFields(this.pages().length - 1, this.pages().value[this.pages().length - 1]['type'], false);
    this.setFields(page);
  }
  addFields(i: number, type: string, isChange: boolean) {
    const formFields = this.pages().controls[i] as FormGroup;
    console.log(formFields);
    var newFields: FormGroup = this.getFields(type);
    if (isChange) formFields.removeControl('fields');
    formFields.addControl('fields', newFields);
  }
  getFields(type: string) {
    var newFields: FormGroup;
    switch (type) {
      case 'FULL_IMAGE':
        newFields = this.formBuilder.group({
          imagePath: new FormControl()
        });
        break;
      case 'FULL_TEXT':
        newFields = this.formBuilder.group({
          text: new FormControl()
        });
        break;
      case 'IMAGE_AND_TEXT':
        newFields = this.formBuilder.group({
          lines: new FormArray([])
        });
        break;
      default:
        break;
    }
    return newFields;
  }
  setFields(page) {
    switch (page.type) {
      case 'FULL_IMAGE':
        this.fields(this.pages().length - 1).controls['image'].setValue(page['pageImageFields']['imagePath']);
        break;
      case 'FULL_TEXT':
        this.fields(this.pages().length - 1).controls['text_content'].setValue(page['pageTextFields']['text']);
        break;
      case 'IMAGE_AND_TEXT':
        if (page['pageCompositeFields'].length > 0) {
          page['pageCompositeFields'].forEach(field => {
            this.addLine(this.pages().length - 1, field);
          });
        } else this.addLine(this.pages().length - 1, this.default_line);
        break;
      default:
        break;
    }
  }
  removePage(i: number) {
    this.pages().removeAt(i);
  }
  /** ---- Page Management ---- */

  fields(index: number): FormArray { return this.parentForm.get(`pages_${this.type}`)['controls'][index].get("fields") as FormArray }
  lines(index: number): FormArray { return this.fields(index).get('lines') as FormArray }
  addLine(i: number, field) {
    const newLine: FormGroup = this.formBuilder.group({
      image: [field.image],
      text: [field.text]
    });
    this.lines(i).push(newLine);
  }

  addFiles(event) {
    event.file.forEach(file => {
      this.files.emit({ file: file, idPage: event.id, type: event.type, section: 'page_edition' });
    });
  }

  changePageId() {
    this.pageIdChanged.emit();
  }

  callToActionExternalLink() {
    return
  }

  onChangeFile(event, formLevel) {
    const file = event.target.files.item(0);
    const myRenamedFile = new File([file], `${file.name.split('.').shift()}_${this.datePipe.transform(new Date(), 'dd-MM-yyyy_hh-mm-ss')}.${file.name.split('.').pop()}`);
    const pageIndex = this.currentPageIndex;

    if (formLevel === "callToAction") {
      const thisCallToAction: FormGroup = this.pages().controls[pageIndex].get('callToAction') as FormGroup;
      thisCallToAction.controls['callToActionDocument']['controls']['documentPath'].setValue(myRenamedFile.name);
    }

    this.files.emit({ file: myRenamedFile, idPage: pageIndex, type: this.type, section: formLevel });
  }

  addEmptyCtaToForm() {
    let ctaList: FormArray = this.getCtaListControl() as FormArray;

    const ctaFormGroup = this.formBuilder.group({
      size: ['Medium'],
      text: [''],
      textColor: ['#2B2B38'],
      bgColor: ['#FFFFFF'],
      logo: [''],
      currentUploadedLogo: [''],
      action: this.formBuilder.group({
        type: ['OpenFile'],
        documentPath: [''],
        url: [''],
        survey: ['']
      })
    });

    ctaList.push(ctaFormGroup);

    this.parentForm.updateValueAndValidity();
    this.changeDetector.detectChanges();
  }

  removeCta(event: Event, ctaIndex: number) {
    event.stopPropagation();
    let ctaList: FormArray = this.getCtaListControl() as FormArray;

    if (ctaIndex >= 0 && ctaIndex < ctaList.length) {
      if (this.currentEditedCta === ctaList.controls[ctaIndex]) {
        this.closeEditMenu();
      }
      ctaList.removeAt(ctaIndex);
    }
  }

  copyCta(event: Event, ctaIndex: number) {
    event.stopPropagation();
    let ctaList: FormArray = this.getCtaListControl() as FormArray;
    let cta = ctaList.at(ctaIndex);

    this.currentCopyCtaStyle = this.formBuilder.group({
      size: [cta.get('size').value],
      text: [cta.get('text').value],
      textColor: [cta.get('textColor').value],
      bgColor: [cta.get('bgColor').value],
      logo: [cta.get('logo').value],
      currentUploadedLogo: [cta.get('currentUploadedLogo').value],
      action: this.formBuilder.group({
        type: [cta.get('action.type').value],
        documentPath: [cta.get('action.documentPath').value],
        url: [cta.get('action.url').value],
        survey: [cta.get('action.survey').value]
      })
    });
  }

  pasteCta(event: Event, ctaIndex: number) {
    event.stopPropagation();
    if (this.currentCopyCtaStyle) {
      let ctaList: FormArray = this.getCtaListControl() as FormArray;
      let cta = ctaList.at(ctaIndex);

      cta.get('size').setValue(this.currentCopyCtaStyle.get('size').value);
      cta.get('text').setValue(this.currentCopyCtaStyle.get('text').value);
      cta.get('textColor').setValue(this.currentCopyCtaStyle.get('textColor').value);
      cta.get('bgColor').setValue(this.currentCopyCtaStyle.get('bgColor').value);
      cta.get('logo').setValue(this.currentCopyCtaStyle.get('logo').value);
      cta.get('currentUploadedLogo').setValue(this.currentCopyCtaStyle.get('currentUploadedLogo').value);

      let ctaAction = cta.get('action');
      ctaAction.get('type').setValue(this.currentCopyCtaStyle.get('action.type').value);
      ctaAction.get('documentPath').setValue(this.currentCopyCtaStyle.get('action.documentPath').value);
      ctaAction.get('url').setValue(this.currentCopyCtaStyle.get('action.url').value);
      ctaAction.get('survey').setValue(this.currentCopyCtaStyle.get('action.survey').value);

      this.changeDetector.detectChanges();
      if (cta.get('currentUploadedLogo')) {
        let outputImage = document.getElementById('output_image_' + ctaIndex) as HTMLImageElement;
        outputImage.src = URL.createObjectURL(cta.get('currentUploadedLogo').value);
      }
    }
  }

  updateCurrentEditedCta() {
    if (this.currentEditedCta) {
      this.currentEditedCta.patchValue({
        size: this.currentEditedCta.value.size,
        text: this.currentEditedCta.value.text,
        textColor: this.currentEditedCta.value.textColor,
        bgColor: this.currentEditedCta.value.bgColor,
        logo: this.currentEditedCta.value.logo,
        currentUploadedLogo: this.currentEditedCta.value.currentUploadedLogo,
        action: {
          type: this.currentEditedCta.value.action.type,
          documentPath: this.currentEditedCta.value.action.documentPath,
          url: this.currentEditedCta.value.action.url,
          survey: this.currentEditedCta.value.action.survey
        }
      });
    }
  }

  onDragMoved(e: CdkDragMove) {
    this.dragAndDropService.dragMoved(e, this.listGroup);
  }

  onDropListDropped() {
    this.currentEditedCtaIndex = this.dragAndDropService.dropListDropped(this._placeholder, this.getCtaListControl().controls, this.currentEditedCtaIndex);
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    return this.dragAndDropService.dropListEnterPredicate(drag, drop, this._placeholder);
  };

  isLightColor(color: string) {
    const hex = color.replace('#', '');
    const rgb = hex.match(/.{1,2}/g)?.map(val => parseInt(val, 16));
    if (rgb) {
      const yiq = ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000;
      return yiq > 214; // valeur de luminance pour déterminer si la couleur est claire
    }
    return false;
  }
}