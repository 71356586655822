/** List of images extensions */
export var listOfImagesExtensions: Array<string> = [
    'jpg',
    'jpeg',
    'jpe',
    'jif',
    'jfif',
    'jfi',
    'png',
    'gif',
    'webp',
    'tiff',
    'tif',
    'raw',
    'arw',
    'cr2',
    'nrw',
    'k25',
    'bmp',
    'dib',
    'heif',
    'heic',
    'ind',
    'indd',
    'indt',
    'jp2',
    'j2k',
    'jpf',
    'jpx',
    'jpm',
    'mj2'
];


export const tableCorrespondance = new Map([
    ['jpg' , 'image/jpeg'],
    ['jpeg' , 'image/jpeg'],
    ['jpe' , 'image/jpeg'],
    ['jif' , 'image/jpeg'],
    ['jfif' , 'image/jpeg'],
    ['jfi' , 'image/jpeg'],
    ['png' , 'image/png'],
    ['gif' , 'image/gif'],
    ['webp' , 'image/webp'],
    ['tiff' , 'image/tiff'],
    ['tif' , 'image/tiff'],
    ['raw' , 'image/jpeg'],
    ['arw' , 'image/jpeg'],
    ['cr2' , 'image/jpeg'],
    ['nrw' , 'image/jpeg'],
    ['k25' , 'image/jpeg'],
    ['bmp' , 'image/bmp'],
    ['dib' , 'image/jpeg'],
    ['heif' , 'image/jpeg'],
    ['heic' , 'image/jpeg'],
    ['ind' , 'image/jpeg'],
    ['indd' , 'image/jpeg'],
    ['indt' , 'image/jpeg'],
    ['jp2' , 'image/jpeg'],
    ['j2k' , 'image/jpeg'],
    ['jpf' , 'image/jpeg'],
    ['jpx' , 'image/jpeg'],
    ['jpm' , 'image/jpeg'],
    ['mj2' , 'image/jpeg'],
    ['pdf' , 'application/pdf'],
    ['ics' , 'text/calendar'],
    ['csv' , 'text/csv'],
    ['json' , 'application/json']
  ]);