<form [formGroup]="parentForm">
    <div [formGroupName]="'pages_' + type">
        <div [formGroupName]="id">
            <div [formGroupName]="'pageTextFields'" class="form-group">
                <div class="row my-2">
                    <!-- Text for Cookie Policy -->
                    <div class="form-group" style="width: 100%;">
                        <p class="labelLike">Text Content :</p>
                        <ckeditor [editor]="Editor" [config]="editorConfig" id="text{{id}}" formControlName="text"></ckeditor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>