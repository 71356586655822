<form [formGroup]="parentForm">
    <!-- Links Menu -->
    <p class="labelLike">Menu Links</p>
    <div [formGroupName]="'links_menu_' + type">
        <div class="form-group">
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div *ngFor="let link of linksMenu().controls; let i=index" [formGroupName]="i" class="example-box" cdkDrag>
                        <!-- Links Number -->
                        <div class="col-md-6 px-0 align-self-center">
                            <p class="labelLike">Link {{i+1}}</p>
                        </div>
                    <div class="row my-1">
                        <!-- Select a type -->
                        <div class="col-md-6 px-0">
                            <select dragStopPropagation class="form-control" formControlName="type">
                                <option [value]="null">Select a type</option>
                                <option *ngFor="let type of types_array" [value]="type.value">{{type.label}}</option>
                            </select>
                        </div>
                        <!-- Remove Links Menu -->
                        <div class="col-md-1 px-1 align-self-end">
                            <span class="material-icons red clickable" (click)="removeLinksMenu(i)">
                                remove_circle
                            </span>
                        </div>
                    </div>

                    <!-- App Page -->
                    <div *ngIf="link.value.type === 'APP_PAGE'" class="row my-1 justify-content-end" [formGroupName]="'typeAppPage'">
                        <div class="col-md-6 px-0">
                            <p class="labelLike">App Page</p>
                            <select dragStopPropagation class="form-control" formControlName="pageId">
                                <option [value]=null>Select a page</option>
                                <option *ngIf="isMapEnabled && parentForm.value.activateDashboardCheck_04_primary" value="home-map">Epidemic Map</option>
                                <ng-container *ngFor="let page of (this.type === 'primary' ? parentForm.value.pages_primary : parentForm.value.pages_secondary)">
                                    <option *ngIf="page.activated" [value]="page.id">
                                        {{page.name}} - ACTIVATED
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                    </div>

                    <!-- Text in menu -->
                    <div class="row my-1 justify-content-end">
                        <div class="col-md-6 px-0">
                            <p class="labelLike">Text in Menu</p>
                            <input dragStopPropagation type="text" class="form-control" formControlName="label">
                        </div>
                    </div>
                    
                    <!-- External Link -->
                    <div *ngIf="link.value.type === 'EXTERNAL_LINK'" class="row my-1 justify-content-end" [formGroupName]="'typeExternalLink'">
                        <div class="col-md-6 px-0">
                            <p class="labelLike">External Link</p>
                            <input dragStopPropagation type="text" class="form-control" formControlName="url" placeholder="Enter an external link" aria-describedby="external-link">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Links Menu -->
    <div class="d-flex justify-content-center">
        <div class="col-6 justify-content-center add_line">
            <hr />
            <span class="material-icons blue add_line_button clickable" (click)="addLinksMenu(generateDefaultLink())">
                add_circle
            </span>
            <hr />
        </div>
    </div>

    <!-- Bottom Text -->
    <div class="form-group">
        <p class="labelLike">Bottom Text</p>
        <input type="text" id="internalUsage_09_{{type}}" class="form-control" [formControlName]="'internalUsage_09_' + type" />
    </div>

</form>