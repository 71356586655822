<div class="modal" id="blockingModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="blockingModal"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">
                    {{blockingModalContent.title}}
                </h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="handleDecline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div [innerHTML]="blockingModalContent.content"></div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-accept" data-dismiss="modal" (click)="handleAccept()">
                    {{blockingModalContent.accept}}
                </button>
                <button type="button" class="btn btn-dismiss" data-dismiss="modal" (click)="handleDecline()">
                    {{blockingModalContent.decline}}
                </button>
            </div>
        </div>
    </div>
</div>