import { environment } from './environments/environment';

const awsmobile = {
  Auth: {
    identityPoolId: environment.identityPoolId, //REQUIRED - Amazon Cognito Identity Pool ID
    region: environment.region, // REQUIRED - Amazon Cognito Region
    userPoolId: environment.userPoolId, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: environment.userPoolWebClientId, //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
        bucket: environment.bucketS3, //REQUIRED -  Amazon S3 bucket
        region: environment.region, //OPTIONAL -  Amazon service region
    }
  }
};

export default awsmobile;