<!-- Toast alert for error -->
<div class="toast toast-alert" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
        <strong class="mr-auto">Error</strong>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="toast-body">
        {{ errorMessage }}
    </div>
</div>
<!-- Toast alert when modifying site -->
<div class="toast toast-wait" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
        <strong class="mr-auto">Info</strong>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="toast-body">
        Site is modifying...
    </div>
</div>
<div class="toast toast-warning" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
        <strong class="mr-auto">Warning</strong>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="toast-body">
        {{ errorMessage }}
    </div>
</div>

<div class="main">
    <!-- Navigation -->
    <GlobalNavBar [COMPONENT]="'SiteManagement'"></GlobalNavBar>

    <div *ngIf="!DATA_IS_LOADED" class="component text-center my-5">
        <Message MESSAGE="Data is loading..."></Message>
    </div>

    <div *ngIf="SITE && DATA_IS_LOADED" class="col-6 mx-auto my-4">
        <h1 class="blue" style="overflow-wrap: anywhere;">SITE MODIFICATION - {{SITE['SITE']}}</h1>
        <!-- Form modification -->
        <form [formGroup]="modifyForm" (ngSubmit)="onSubmit()" *ngIf="formIsLoaded">

            <!-- Site Name -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10 p-0">
                    <p class="labelLike">Site name</p>
                    <input type="text" formControlName="site" class="form-control" [ngClass]="{ 'is-invalid': submitted && modifyForm.controls.site.errors }" pattern="^[a-zA-Z0-9\u00C0-\u00FF_-]*$" [attr.disabled]="doNotModified" />
                    <div *ngIf="submitted && mF.site.errors" class="invalid-feedback">
                        <div *ngIf="mF.site.errors.required">Site Name is required</div>
                        <div *ngIf="mF.site.errors.pattern">Site Name must includes only letters or numbers, without 'space'</div>
                    </div>
                </div>
            </div>
            <!-- Activate Site -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10 d-flex justify-content-start">
                    <div class="form-check custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="activated" formControlName="activated">
                        <label for="activated" class="custom-control-label">Activate site</label>
                    </div>
                </div>
            </div>

            <!-- Languages -->
            <div class="form-row row justify-content-md-center">
                <div formArrayName="languages" class="card col-10 form-group p-2">
                    <div class="form-group">
                        <span *ngFor="let language of languages().controls; let i=index" [formGroupName]="i">
                            <div class="row">
                                <div [class.col-md-12]="languages().length == 1" [class.px-0]="languages().length == 1" [class.col-md-11]="languages().length > 1" [class.pl-0]="languages().length > 1">
                                    <p *ngIf="i==0" class="labelLike">Language (default)</p>
                                    <p *ngIf="i!==0" class="labelLike">Language</p>
                                    <select class="form-control" formControlName="language" [ngClass]="{ 'is-invalid': submitted && language.controls.language.errors }">
                                        <option [value]="language.short_language" *ngFor="let language of languages_array">{{language.long_language}}</option>
                                    </select>
                                    <div *ngIf="submitted && language.controls.language.errors" class="invalid-feedback">
                                        <div *ngIf="language.controls.language.errors.required">Language is required</div>
                                        <div *ngIf="language.controls.language.errors.unique">This language is already added</div>
                                    </div>
                                </div>
                                <div class="col-md-1 pl-0 align-self-end" [class.align-self-baseline]="submitted && language.controls.language.errors">
                                    <span class="material-icons red clickable" *ngIf="languages().length > 1" (click)="removeLanguage(i)">
                                        remove_circle
                                    </span>
                                </div>
                            </div>
                        </span>
                    </div>
                    <!-- Add Language -->
                    <div class="form-row">
                        <div class="form-group col-10 d-flex">
                            <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center" (click)="addLanguage()">
                                Add language
                                <span class="material-icons white icons_button ml-1">
                                    add_circle
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Creation Date -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10 p-0">
                    <p class="labelLike">Creation date</p>
                    <input type="text" [attr.disabled]="doNotModified" formControlName="creationDate" class="form-control" />
                </div>
            </div>

            <!-- Last activation date -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10 p-0">
                    <p class="labelLike">Last activation date</p>
                    <input type="text" [attr.disabled]="doNotModified" formControlName="lastActivationDate" class="form-control" />
                </div>
            </div>

            <!-- Expiration date -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10 p-0">
                    <p class="labelLike">Expiration date</p>
                    <input type="text" formControlName="expirationDate" placeholder="N/A" class="form-control" bsDatepicker [bsConfig]="bsConfig" [minDate]="minDate">
                </div>
            </div>

            <!-- Map Activation -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10 p-0">
                    <p class="labelLike">Map enabled&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div class="form-check-inline">
                        <label class="form-check-label">
                            <input formControlName="mapEnabled" class="form-check-input" type="radio" name="mapEnabled" value="Yes">Yes
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label">
                            <input formControlName="mapEnabled" class="form-check-input" type="radio" name="mapEnabled" value="No">No
                        </label>
                    </div>
                </div>
            </div>

            <!-- Number of pages -->
            <div class="form-row row justify-content-md-center">
                <div class="form-group col-10 p-0">
                    <p class="labelLike">Number of pages</p>
                    <div>
                        <ng-container *ngFor="let i of arrayNumberMaxOfPages">
                            <div class="form-check-inline">
                                <label class="form-check-label" for="{{i}}">
                                    <input id="{{i}}" formControlName="maxPages" class="form-check-input" type="radio" name="maxPages" value="{{i}}">{{i}}
                                </label>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Privacy policy -->
            <div class="form-row row justify-content-md-center">
                <div formArrayName="privacy_policies" class="form-group col-10 p-0">
                    <label>Manage the Privacy Policies</label>
                    <div *ngFor="let privacy_policy of privacy_policies().controls; let i=index" [formGroupName]="i" class="my-1">
                        <a class="collapse_legal_notice" data-toggle="collapse" href="#collapse_privacy_{{privacy_policy.value.language}}" aria-expanded="false">
                            <span class="align-bottom material-icons">
                                expand_more
                            </span>
                            {{listOfLanguages[privacy_policy.value.language]}}
                        </a>
                        <div class="collapse" id="collapse_privacy_{{privacy_policy.value.language}}">
                            <div class="my-1">
                                <ckeditor class="ckEditor" [editor]="Editor" [config]="editorConfig" id="privacy_policy" formControlName="text"></ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Legal informations  -->
            <div class="form-row row justify-content-md-center">
                <div formArrayName="legal_informations" class="form-group col-10">
                    <label>Manage the Legal Informations</label>
                    <div *ngFor="let legal_information of legal_informations().controls; let i=index" [formGroupName]="i" class="my-1">
                        <a class="collapse_legal_notice" data-toggle="collapse" href="#collapse_legal_information_{{legal_information.value.language}}" aria-expanded="false">
                            <span class="align-bottom material-icons">
                                expand_more
                            </span>
                            {{listOfLanguages[legal_information.value.language]}}
                        </a>
                        <div class="collapse" id="collapse_legal_information_{{legal_information.value.language}}">
                            <div class="my-1">
                                <ckeditor class="ckEditor" [editor]="Editor" [config]="editorConfig" id="legal_information" formControlName="text"></ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Send modification -->
            <div class="row justify-content-md-end">
                <button class="btn btn-global-type btn-type2 mb-3">Modify Site</button>
            </div>
        </form>
    </div>
</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>