<div class="main">
    <!-- Navigation -->
    <LocalNavBar [COMPONENT]="'QRCodesComponent'"></LocalNavBar>

    <div *ngIf="DATA_IS_LOADING || SITE_IS_DEACTIVATED || IS_ERROR; else elseBlock" class="component text-center my-5">
        <Message [MESSAGE]="message"></Message>
    </div>

    <ng-template #elseBlock>
        <!-- QR Codes menu -->
        <div class="row mt-2">
            <div class="col-md-12 pl-2 pb-2 pt-2 d-flex">
                <div class="bottom_row_buttons">
                    <div class="btn-toolbar ml-1" role="toolbar">
                        <div class="mr-2" role="group">
                            <button type="button" class="btn btn-global-type btn-type3 d-flex align-items-center"
                                (click)="navigate('qr-code-management/update')">
                                <span class="material-icons icons_button">
                                    list
                                </span>
                                <span class="ml-2">List of QR Codes</span>
                            </button>
                        </div>
                        <div class="mr-2" role="group">
                            <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center"
                                (click)="downloadHistory()">
                                <span class="material-icons icons_button">
                                    cloud_download
                                </span>
                                <span class="ml-2">Download History</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Main component -->
        <div class="container_global mt-2">
            <div class="tableWrap">
                <table class="table qrcodes_tables">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Updated date</th>
                            <th>Updated by</th>
                            <th>Location: Level 1</th>
                            <th>Location: Level 2</th>
                            <th>Redirect to...</th>
                            <th>File information 1</th>
                            <th>File information 2</th>
                            <th>Configuration</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let code of QR_CODE_HISTORY; let i = index; first as isFirst" >
                        <tr style="display: table-row;" >
                            <td class="align-middle">
                                <span *ngIf="isFirst" class="badge badge-success">Latest</span>
                            </td>
                            <td class="align-middle">
                                <span>{{timeConverter(code.M.UPDATE_DATE.N)}}</span>
                            </td>
                            <td class="align-middle">
                                <span>{{code.M.USER.S}}</span>
                            </td>
                            <td class="align-middle">
                                <span>{{code.M.LOC1.S}}</span>
                            </td>
                            <td class="align-middle">
                                <span>{{code.M.LOC2.S}}</span>
                            </td>
                            <td class="align-middle">
                                <span *ngIf="code.M.LANDING_PAGE.S === 'documentPath'">{{code.M.REDIRECT_URL.S.split("/").pop()}}</span>
                                <span *ngIf="code.M.LANDING_PAGE.S === 'externalUrl'">{{code.M.REDIRECT_URL.S}}</span>
                                <span *ngIf="code.M.LANDING_PAGE.S != 'documentPath' && code.M.LANDING_PAGE.S != 'externalUrl'">{{findPageinLandingPageArray(code.M.LANDING_PAGE.S)}}</span>
                            </td>
                            <td class="align-middle">
                                <span *ngIf="code.M.LANDING_PAGE.S === 'documentPath'">{{code.M.MAT_CODE.S}}</span>
                            </td>
                            <td class="align-middle">
                                <span *ngIf="code.M.LANDING_PAGE.S === 'documentPath'">{{code.M.MAT_NAME.S}}</span>
                            </td>
                            <td class="align-middle">
                                <div id="canvasConfig_{{i}}" #canvasConfig></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-template>
</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>