import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[dragStopPropagation]'
})
export class DragStopPropagationDirective {

  @HostListener("mousedown", ["$event"])
  public onDrag(event: any): void
  {
      event.stopPropagation();
  }
}
