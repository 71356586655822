import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cellContent',
  templateUrl: './cell-content.component.html',
  styleUrls: ['./cell-content.component.scss']
})
export class CellContentComponent implements OnInit {

  @Input() value: any;
  @Input() percent: number = 0;

  constructor() { }

  ngOnInit(): void { }

}
