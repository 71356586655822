import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/core/services/users.service';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { Title } from '@angular/platform-browser';
import Auth from '@aws-amplify/auth';

@Component({
  selector: 'aac-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: [
    './list-users.component.scss',
    '../users-generator.scss',
    '../../global-back-office.scss',
    '../../../private.scss'
  ]
})

export class ListUsersComponent implements OnInit {

  DATA_IS_LOADED: boolean = false;
  
  USERS: any[] = [];

  userToDelete: string;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null
  };

  constructor(
    private router: Router,
    private titleService: Title,
    private _usersService: UsersService
  ) {
    this.titleService.setTitle("Elefight - User Management");
  }

  ngOnInit(): void {
    this.DATA_IS_LOADED = false;
    this.getAllUsers();
  }

  /**
   * Get list of users
   */
  getAllUsers() {
    this.USERS = [];
    this._usersService.getUsers().then((users) => {
      this.USERS = JSON.parse(JSON.stringify(users));
      this.DATA_IS_LOADED = true;
    });
  }

  /**
   * Navigate through components
   * @param route route we want to reach
   * @param username username of the user, if we want to go to user-modification component
   */
  navigate(route: string, username?: string) {
    if (username) this.router.navigate([`/admin/${route}/${username}`])
    else this.router.navigate([`/admin/${route}`]);
  }

  /**
   * Remove current user
   * @param user Name of the user
   */
  removeUser(user: string) {
    this.userToDelete = user;
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "remove";
    this.ALERT_MESSAGE.MESSAGE =
      `<p>Are you sure you want to remove user ${user} ?</p>`;
  }

  /**
   * Callback of removeUser() action modal
   */
  callbackActionModal_remove() {
    this._usersService.deleteUser(this.userToDelete).then((message) => {
      this.userToDelete = '';
      this.callbackResetModal(this.ALERT_MESSAGE);
      this.DATA_IS_LOADED = false;
      this.getAllUsers();
    });
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  signOut(){
    Auth.signOut();
  }
}