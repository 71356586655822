<div>
    <div *ngIf="languages.length > 1;else one_language_form_multi_pages"class="row">
        <div class="col col-md-6">
            <form [formGroup]="parentForm">
                <form-multi-pages-common #MultiPageComponentPrimary [parentForm]="parentForm" [language]=languages[0]  [type]="'primary'" [selectedPage]="selectedPage" [siteName]="siteName" (files)="addFiles($event)" (pageIdChanged)="changePageId()"></form-multi-pages-common>
            </form>
        </div>
        <div class="col col-md-6">
            <form [formGroup]="parentForm">
                <form-multi-pages-common #MultiPageComponentSecondary [parentForm]="parentForm" [language]=languages[1]  [type]="'secondary'" [selectedPage]="selectedPage" [siteName]="siteName" (files)="addFiles($event)" (pageIdChanged)="changePageId()"></form-multi-pages-common>
            </form>
        </div>
    </div>
    <ng-template #one_language_form_multi_pages>
        <div class="row">
            <div class="col">
                <form [formGroup]="parentForm">
                    <form-multi-pages-common #MultiPageComponentPrimary [parentForm]="parentForm" [language]=languages[0] [type]="'primary'" [selectedPage]="selectedPage" [siteName]="siteName" (files)="addFiles($event)" (pageIdChanged)="changePageId()"></form-multi-pages-common>
                </form>
            </div>
        </div>
    </ng-template>
</div>