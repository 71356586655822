import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class JsonMultiLanguageService {

  constructor() { }

  async getJSON(formValue, VALUE_TRANSLATIONS) {
    var activateDashboardCheck_04_primary = (formValue.activateDashboardCheck_04_primary) ? true : false;
    var mapData_04_filename_primary = (formValue.mapData_04_filename_primary != '') ? formValue.mapData_04_filename_primary : VALUE_TRANSLATIONS['map']['dataPath'];

    if (VALUE_TRANSLATIONS.menu.menuLinks.length < 1) {
      VALUE_TRANSLATIONS.menu.menuLinks.push({
        "id": uuid(),
        "label": "New link",
        "type": "null",
        "typeAppPage": { pageId: '' },
        "typeExternalLink": { url: '' }
      })
    }

    var json = {
      "pages": VALUE_TRANSLATIONS.pages,
      "language": VALUE_TRANSLATIONS.language,
      "graphics": {
        "menuBottomLogoPath": VALUE_TRANSLATIONS.graphics ? VALUE_TRANSLATIONS.graphics.menuBottomLogoPath : '',
        "menuTopLogoPath": VALUE_TRANSLATIONS.graphics ? VALUE_TRANSLATIONS.graphics.menuTopLogoPath : '',
        "headerLogoPath": VALUE_TRANSLATIONS.graphics ? VALUE_TRANSLATIONS.graphics.headerLogoPath : ''
      },
      "legalNotices": {
        "legalInformation": VALUE_TRANSLATIONS.legalNotices ? VALUE_TRANSLATIONS.legalNotices.legalInformation : '',
        "privacyPolicy": VALUE_TRANSLATIONS.legalNotices? VALUE_TRANSLATIONS.legalNotices.privacyPolicy : ''
      },
      "menu": {
        "menuLinks": VALUE_TRANSLATIONS.menu ? VALUE_TRANSLATIONS.menu.menuLinks : '',
        "bottomText": VALUE_TRANSLATIONS.menu ? VALUE_TRANSLATIONS.menu.bottomText : ''
      },
      "contentBlockingModal": {
        "title": VALUE_TRANSLATIONS.contentBlockingModal ? VALUE_TRANSLATIONS.contentBlockingModal.title : '',
        "content": VALUE_TRANSLATIONS.contentBlockingModal ? VALUE_TRANSLATIONS.contentBlockingModal.content : '',
        "accept": VALUE_TRANSLATIONS.contentBlockingModal ? VALUE_TRANSLATIONS.contentBlockingModal.accept : '',
        "decline": VALUE_TRANSLATIONS.contentBlockingModal ? VALUE_TRANSLATIONS.contentBlockingModal.decline : ''
      },
      "passwordModal" : {
        "title" : VALUE_TRANSLATIONS.passwordModal ? VALUE_TRANSLATIONS.passwordModal.title : '',
        "content" : VALUE_TRANSLATIONS.passwordModal ? VALUE_TRANSLATIONS.passwordModal.content : ''
      },
      "map": {
        "startDateCampaign": formValue.campaignDate_04_primary[0].getTime(),
        "endDateCampaign": formValue.campaignDate_04_primary[1].getTime(),
        "dataPath": formValue.mapData_04_filename_primary,
        "activated": formValue.activateDashboardCheck_04_primary,
        "title": VALUE_TRANSLATIONS.map ? VALUE_TRANSLATIONS.map.title : '',
        "legend1": VALUE_TRANSLATIONS.map ? VALUE_TRANSLATIONS.map.legend1 : '',
        "legend2": VALUE_TRANSLATIONS.map ? VALUE_TRANSLATIONS.map.legend2 : '',
        "informationAboutData": VALUE_TRANSLATIONS.map ? VALUE_TRANSLATIONS.map.informationAboutData : ''
      }
    };
    return json;
  }
}
