import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { Observable } from 'rxjs';
import { JwtClaims, UserLoginService } from 'src/app/core/services/user-login.service';

@Component({
  selector: 'aac-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: [
    './reporting.component.scss',
    '../global-back-office.scss',
    '../../private.scss'
  ]
})
export class GlobalReportingComponent {

  message: string = null;
  
  userToken$: Observable<JwtClaims>;

  DATA_IS_LOADED: boolean = false;
  message_on_loading: string = null;
  message_error:string[] = [];

  constructor(
    private router: Router,
    private titleService: Title,
    private _userLoginService: UserLoginService){
    this.titleService.setTitle("Elefight - Reporting");
    this.userToken$ = this._userLoginService.getTokenJwtClaims();
  }

  /** Navigate though components */
  navigate(route: string, site?: string){
    if (site) {
      this.router.navigate([`/admin/${route}/${site}`])
    }
    else {
      this.router.navigate([`/admin/${route}`]);
    }
  }

  signOut(){
    Auth.signOut();
  }
}
