import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { catchError } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(Auth.currentSession())
            .pipe(
                switchMap((auth: any) => { // switchMap() is used instead of map().
                    if(!auth || !auth.idToken) {
                        console.error('No Cognito JWT token found in the current session');
                        return next.handle(request);
                    }
                    let jwt = auth.idToken.jwtToken;
                    let authentifiedRequest = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${jwt}`
                        }
                    });
                    console.debug('Cognito JWT token added to HTTP request header');
                    return next.handle(authentifiedRequest);
                }),catchError((err) => {
                    if(err instanceof HttpErrorResponse) // error is from server and will be catched by error interceptor
                        return throwError(err);
                    else    
                        return next.handle(request); //error is from Auth and request needs to be sent without Authorization header
            }));
        
    }

}