import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Chart, ChartElement } from 'chart.js';
import { COLORS_ } from 'src/app/core/classes/Colors';
import { ShareDataService } from 'src/app/core/services/share-data.service';
import { TranslateService } from '@ngx-translate/core';
declare var $:any;

const CHART_SELECTION_COLOR = '#F3B337';

@Component({
  selector: 'ChartComponent',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  providers: [DatePipe]
})
export class ChartComponent implements OnInit, AfterViewInit {

  middleElementIndex = -1;

  @Input() DATA;
  @Input() DATA_READY;
  @Input() SITE;
  @Input() campaignDate;

  WEEK_CASES: number;
  CURRENT_CASES: any;
  TOTAL_CASES: any;
  CURRENT_CASES_PERCENTAGE: number;
  TOTAL_CASES_PERCENTAGE: number;

  selectedWeek: any = 0;
  WEEKS = '';
  WEEK_START: Date;
  WEEK_END: Date;

  myChart: Chart;
  dataChart = {
    labels: [],
    values: []
  };

  chartElementColors: string[] = [];

  lastValueLabels: string;

  currentLang: string = '';
  datePipeLocale: string = '';

  constructor(
    private datepipe: DatePipe,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private _shareDataService: ShareDataService
  ) {
    this.currentLang = this.translate.currentLang;
    this.datePipeLocale = `${this.currentLang}-${this.currentLang.toUpperCase()}`;
  }

  ngOnDestroy(): void {
    this.myChart.destroy();
  }

  ngOnInit(): void {
    $("[data-toggle=popover]").popover({
      html: true, 
      content: function() {
        return $('#popover-content').html();
      }
    });
    $('body').on('click', function (e) {
      $('[data-toggle="popover"]').each(function () {
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
          }
      });
    });
    this.setDefaultWeek();
    this.selectWeek();
  }

  /** Determine default week depeding the number of cases */
  setDefaultWeek(){
    const today = new Date();
    var data = JSON.parse(JSON.stringify(this.DATA));
    for(let i=0; i<this.DATA.length; i++){
      data[i]['weekStart'] = data[i]['weekStart'].replace(/-/g,'/');
      data[i]['weekEnd'] = data[i]['weekEnd'].replace(/-/g,'/');
      var weekStart = new Date(data[i]['weekStart']).getTime();
      var weekEnd = new Date(data[i]['weekEnd']).getTime();
      data[i]['casesForLocation'] = data[i]['cases'];
      data[i]["partialSumForLocationCases"] = data[i]['partialSumLocationCases'];
      if(today.getTime() > weekStart && today.getTime() < weekEnd){
        if(this.DATA[i]['casesForLocation'] > 0){
          this.selectedWeek = i;
          return;
        }
      }
      var lastMonthWithCases: number = 0;
      for(let i=0; i < Object.keys(this.DATA).length; i++){
        if(this.DATA[i]['casesForLocation'] > 0) lastMonthWithCases = i;
      }
      this.selectedWeek = lastMonthWithCases;
    }
  }

  ngAfterViewInit(){
    this.setChartData();
    this.setChart();
  }

  onChanges(){
    this.selectWeek();
    this.setChartData();
    this.myChart.update();
    this.changeDetectorRef.detectChanges();
  }

  /** Prepare and set chart data */
  setChartData(){
    //Reset chart data
    this.dataChart['values'].length = 0; this.dataChart['labels'].length = 0;

    //For each object(week) in DATA
    for(let i=0; i < Object.keys(this.DATA).length; i++){
      //We get and store weekStart and weekEnd
      const weekStart = new Date(this.DATA[i]['weekStart']).getTime();
      const weekEnd = new Date(this.DATA[i]['weekEnd']).getTime();
      
      //Check if the week of the loop (i) is includes in the campaignDate
      if(weekStart > this.campaignDate.weekStart && weekEnd < this.campaignDate.weekEnd){
        //We store the number of cases in the dataChart.values
        this.dataChart['values'].push(this.DATA[i]['casesForLocation']);

        //We get the month of the week of the loop (i)
        const weekStartDate = new Date(this.DATA[i]['weekStart'].replace(/-/g, "/"));
        const month = this.datepipe.transform(weekStartDate,'MMM', undefined, `${this.currentLang}-${this.currentLang.toUpperCase()}`);
  
        //We add the month to the dataChart.labels
        if(month === this.lastValueLabels){
          //If the month is already added, we push an empty string
          this.dataChart['labels'].push('');
        } else {
          //Else we push a pipe with the month
          this.dataChart['labels'].push('|' + month);
        }

        //We store the current month as lastValuesLabels for the previous if loop
        this.lastValueLabels = month;
      }
    }
    //If second label is different of empty string AND if first label is different of first label we put an empty string on the first label
    if(this.dataChart['labels'][1] != '' && (this.dataChart['labels'][0] != this.dataChart['labels'][1])) this.dataChart['labels'][0] = '';
    this.lastValueLabels = '';

    this.initializeChartElementsColor();
  }

  /** Set chart */
  setChart(){
    var canvas: any = document.getElementById('myChart');
    var ctxChart: any = canvas.getContext('2d');

    this.myChart = new Chart(ctxChart, {
      type: 'bar',
      data: {
        datasets: [{
          data: this.dataChart['values'],
          backgroundColor: this.chartElementColors
        }],
        labels: this.dataChart['labels']
      },
      options: {
        responsive: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            labelColor: function(tooltipItem, chart) {
                return {
                    borderColor: CHART_SELECTION_COLOR,
                    backgroundColor: CHART_SELECTION_COLOR
                };
            }
          }
        },
        animation: {
          duration: 0
        },
        scales: {
          xAxes: [{
            scaleLineColor: 'transparent',
            ticks: {
              display: true,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0
            },
            gridLines: {
              color: COLORS_['color-opacity-black'],
              drawBorder: false,
              display: false
            }
          }],
          yAxes: [{
            scaleLineColor: 'transparent',
            ticks: {
              display: false
            },
            gridLines: {
              color: COLORS_['color-opacity-black'],
              drawBorder: false,
            }
          }]
        },
        events: ["mousemove", "mouseout", "click", "touchstart", "touchmove", "touchend"],
        'onClick':(e, chartElements: ChartElement[]) => {
          if (chartElements.length) {
            this.selectedWeek = chartElements[0]._index;
            this.resetChartElementsColor()
            this.selectChartElement(this.selectedWeek);
            this.selectWeek();
            this.myChart.update();
          }
        }
      }
    });
    this.selectChartElement(this.selectedWeek);
  }

  /**
   * Listener of the scroll event for the bar chart
   * @param event event of the scroll
   */
  @HostListener('window:scrollX', ['$event']) 
  scrollEvent(event) {
    this.hideGif();
    const weeksNumber = this.dataChart['values'].length - 1;
    const scrollLeft = event.target.scrollLeft;
    const maxScrollLeft = document.getElementById('chartWrapper').clientWidth + 10;
    const ratio = maxScrollLeft/weeksNumber;
    let middleElementIndex = Math.round(scrollLeft/ratio);
    if(scrollLeft < 0 || scrollLeft > maxScrollLeft) return;
    else {
      this.selectedWeek = middleElementIndex;
      this.resetChartElementsColor()
      this.selectChartElement(this.selectedWeek);
      this.selectWeek();
      this.myChart.update();
    }
  }

  /** Hide scroll GIF */
  hideGif = () => {
    if(document.getElementById('timelineGif'))
      document.getElementById('timelineGif').remove();
  };

  /** initalize chart element color */
  initializeChartElementsColor() {
    for (let i = 0; i < Object.keys(this.DATA).length; i++){
      this.chartElementColors.push(COLORS_['color-primary']);
    }
  }

  /** reset chart elements color */
  resetChartElementsColor() {
    for (let i = 0; i < Object.keys(this.DATA).length; i++){
      this.chartElementColors[i] = COLORS_['color-primary'];
    }
  }

  /**
   * When a new column is selected on the scroll chart we update colors
   * @param index index of the column (week)
   */
  selectChartElement(index: number) {
    this.chartElementColors[index] = COLORS_['color-secondary'];
    this.myChart.options.tooltips.enabled = true;
    this.myChart.update();
  }

  /** Function to change week values and adapt map and graph */
  selectWeek() {
    //Get weekStart and weekEnd value in DATA array
    //Replace all - by /
    //Create a new Date() format
    this.WEEK_START = new Date(this.DATA[this.selectedWeek]['weekStart'].replace(/-/g, "/"));
    this.WEEK_END = new Date(this.DATA[this.selectedWeek]['weekEnd'].replace(/-/g, "/"));

    //Get number of cases in a week -> stringify as french number
    this.WEEK_CASES = (this.DATA[this.selectedWeek]['casesForLocation']);

    //Get current week flu data
    const currentWeekFluData = this.DATA[this.selectedWeek];

    //Get length of data
    const DATA_length = Object.keys(this.DATA).length - 1;

    //Get current week cases and total cases
    this.CURRENT_CASES = currentWeekFluData['partialSumForLocationCases'];
    const TOTAL_CASES = this.DATA[DATA_length]['partialSumForLocationCases'];

    //Calcul percentage for progressbar
    this.CURRENT_CASES_PERCENTAGE = (100 * this.CURRENT_CASES) / TOTAL_CASES;
    this.TOTAL_CASES_PERCENTAGE = 100;

    //Send value of week to map component
    this._shareDataService.changeWeekNumber(this.selectedWeek);
  }
}