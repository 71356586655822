import { Component, OnInit, Input } from '@angular/core';
declare var $: any;

@Component({
	selector: 'ContentBlockingModal',
	templateUrl: './content-blocking-modal.component.html',
	styleUrls: ['./content-blocking-modal.component.scss']
})
export class ContentBlockingModalComponent implements OnInit {

	@Input() callback: any;
	@Input() blockingModalContent: {
		"title": "",
		"content": "",
		"accept": "",
		"decline": ""
	};

	constructor() { }

	ngOnInit(): void {
		console.log("Content Blocing Modal has been init");
		$('#blockingModal').modal('show');
	}

	handleAccept(){
		console.log("handleAccept");
		$('#blockingModal').modal('hide');
		this.callback.blockingModalAccepted();
	}

	handleDecline(){
		if(window.history.length > 1) window.history.back();
		else window.location.href = "https://www.google.fr";
	}
}
