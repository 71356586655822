import { Injectable } from '@angular/core';
import { CdkDragMove, CdkDrag, CdkDropList, moveItemInArray, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';

@Injectable({
  providedIn: 'root'
})
export class DragAndDropService {
  private activeContainer: CdkDropList | null = null;
  private source: CdkDropList | null = null;
  private sourceIndex: number = -1;
  private target: CdkDropList | null = null;
  private targetIndex: number = -1;

  constructor(private viewportRuler: ViewportRuler) { }

  dragMoved(e: CdkDragMove, listGroup: CdkDropListGroup<CdkDropList>) {
    let point = this.getPointerPositionOnPage(e.event);

    listGroup._items.forEach(dropList => {
      if (this.isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  dropListDropped(
    placeholder: CdkDropList,
    items: any[],
    currentEditedCtaIndex: number
  ) {

    if (!this.target) {
      return currentEditedCtaIndex;
    }

    const phElement = placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(items, this.sourceIndex, this.targetIndex);
      currentEditedCtaIndex = this.targetIndex;
    }
    return currentEditedCtaIndex;
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList, placeholder: CdkDropList) => {

    if (drop == placeholder)
      return true;

    if (drop != this.activeContainer)
      return false;

    let phElement = placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = this.__indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = this.__indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  // Helper function to get the pointer position on the page
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = this.isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }

  // Helper function to check if an event is a touch event
  private isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
  }

  // Helper function to check if a point is inside a drop list's client rectangle
  private isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
    const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
    return y >= top && y <= bottom && x >= left && x <= right;
  }

  // Helper function to find the index of a node in a collection
  private __indexOf(collection, node) {
    return Array.prototype.indexOf.call(collection, node);
  };

}