import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RoutesService } from 'src/app/core/services/routes.service';

@Component({
  selector: 'aac-legale-information',
  templateUrl: './legale-information.component.html',
  styleUrls: ['./legale-information.component.scss']
})
export class LegaleInformationComponent implements OnInit {

  DISEASE:string = "COVID";
  
  constructor(
    private translate: TranslateService,
    private _routesService: RoutesService
  ) {
    //Get routes objects in RoutesService
    this._routesService.currentRoutes
    .subscribe(routes => {
      if(routes.PARAMS){
        console.log(routes);
        const disease = routes.PARAMS['disease'];
        const site = routes.PARAMS['site'];
        // if(disease){
        //   this.DISEASE = disease.toUpperCase();
        //   this.translate.get(this.DISEASE).subscribe(val => {
        //     if(val === this.DISEASE){
        //       console.log("Error on getting the disease");
        //       this.DISEASE = '';
        //     }
        //   });
        // }
      }
    });
  }

  ngOnInit(): void {}

}
