import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {

  constructor(
    private datePipe: DatePipe,
  ) { }

  /**
   * Export forms results as excel file
   * @param json JSON of form results
   * @param siteName name of the excel file
   */
  public exportAsExcelFile(json: any[], siteName: string): void {
    json = JSON.parse(JSON.stringify(json));

    var return_array = [];
    json.forEach(ANSWER => {
      var obj = {};
      obj['Date'] = this.datePipe.transform(new Date(parseInt(ANSWER.CREATION_DATE,10)), 'dd/MM/yyyy hh:mm:ss');
      obj['Location Level 1'] = ANSWER.LOCATION1;
      obj['Location Level 2'] = ANSWER.LOCATION2;
      ANSWER.QUESTIONS.forEach(QUESTION => {
        obj[QUESTION.TITLE] = QUESTION.ANSWER;
      })
      return_array.push(obj);
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(return_array);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, siteName);
  }

  private saveAsExcelFile(buffer: any, siteName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, siteName + '_form_export_' + this.datePipe.transform(new Date(), 'dd/MM/yyyy') + EXCEL_EXTENSION);
  }
}