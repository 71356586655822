<div class="main">
    <!-- Navigation -->
    <LocalNavBar [COMPONENT]="'QRCodesComponent'"></LocalNavBar>

    <div *ngIf="DATA_IS_LOADING || SITE_IS_DEACTIVATED; else elseBlock" class="component text-center my-5">
        <Message [MESSAGE]="message"></Message>
    </div>

    <ng-template #elseBlock>
        <div class="row mt-2">
            <div class="col-md-12 pl-2 pb-2 pt-2 d-flex">
                <!-- QR Codes navigation -->
                <div class="bottom_row_buttons">
                    <div class="btn-toolbar ml-1" role="toolbar">
                        <div class="mr-2 mt-1" role="group">
                            <button type="button" class="btn btn-global-type btn-type3 d-flex align-items-center"
                                data-toggle="modal" data-target="#modalCreateNewQRCodes">
                                <span class="material-icons icons_button">
                                    add_circle
                                </span>
                                <span class="ml-2">Create New QR Codes</span>
                            </button>
                        </div>
                        <ng-container *ngIf="qrcodes().length > 0">
                            <div class="mr-2 mt-1" role="group">
                                <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center"
                                    (click)="configQrCodes()"
                                    [disabled]="!(indeterminated() || allchecked)">
                                    <span class="material-icons icons_button">
                                        brush
                                    </span>
                                    <span class="ml-2">QR Code Custom</span>
                                </button>
                            </div>
                            <div class="mr-2 mt-1" role="group">
                                <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center"
                                    (click)="managePassword()"
                                    [disabled]="!(indeterminated() || allchecked)">
                                    <span class="material-icons icons_button">
                                        lock
                                    </span>
                                    <span class="ml-2">Manage password</span>
                                </button>
                            </div>
                            <div class="mr-2 mt-1" role="group">
                                <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center"
                                    (click)="downloadQRCodes(null)"
                                    [disabled]="!(indeterminated() || allchecked)">
                                    <span class="material-icons icons_button">
                                        cloud_download
                                    </span>
                                    <span class="ml-2">Download</span>
                                </button>
                            </div>
                            <div class="mr-2 mt-1" role="group">
                                <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center"
                                    (click)="downloadHistory()"
                                    [disabled]="!(indeterminated() || allchecked)">
                                    <span class="material-icons icons_button">
                                        cloud_download
                                    </span>
                                    <span class="ml-2">Download History</span>
                                </button>
                            </div>
                            <div class="mr-2 mt-1" role="group">
                                <button type="button" class="btn btn-global-type btn-danger d-flex align-items-center"
                                    (click)="removeQRCodes(null);"
                                    [disabled]="!(indeterminated() || allchecked)">
                                    <span class="material-icons white">
                                        highlight_off
                                    </span>
                                    <span class="ml-2">Delete</span>
                                </button>
                            </div>
                            <div class="mr-2 mt-1" role="group">
                                <button *ngIf="!isMobile" type="button"
                                    class="btn btn-global-type btn-type2 d-flex align-items-center" data-toggle="modal"
                                    data-target="#exampleModal">
                                    <span class="material-icons icons_button">
                                        phone_iphone
                                    </span>
                                    <span class="ml-2">Check & Pair</span>
                                </button>
                                <button *ngIf="isMobile" type="button"
                                    class="btn btn-global-type btn-type2 d-flex align-items-center"
                                    (click)="navigate('/qr-code-management/check-and-pair')">
                                    <span class="material-icons icons_button">
                                        phone_iphone
                                    </span>
                                    <span class="ml-2">Check & Pair</span>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!-- QR Codes search -->
                <div *ngIf="qrcodes().length > 0 || (!(qrcodes().length > 0) && filter.filtersActivated)" class="ml-auto bottom_row_buttons">
                    <div class="btn-toolbar justify-content-end ml-1 mr-2 mt-1" role="toolbar">
                        <div class="mr-2 mb-1" role="group">
                            <select class="btn btn-global-type btn-type1" [(ngModel)]="filter.searchType">
                                <option value="contains">Contains</option>
                                <option value="equal">Is equal to</option>
                            </select>
                        </div>
						<div class="input-group mr-2 mb-1 pointerCursor" role="group">
							<input type="text" [(ngModel)]="filter.searchText" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="btnGroupAddon">
							<div class="input-group-prepend" (click)="onFilter()">
								<div class="input-group-text" id="btnGroupAddon">
									<span class="material-icons icons_button">
										search
									</span>
								</div>
							</div>
							<div *ngIf="filter.filtersActivated" class="input-group-prepend" (click)="removeFilters()">
								<div class="input-group-text" id="btnGroupAddon">
									<span class="material-icons icons_button">
										highlight_off
									</span>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Display data into table -->
        <div *ngIf="!(qrcodes().length > 0)" class="component text-center my-5">
            <Message MESSAGE="No QR Code found"></Message>
        </div>
        <div *ngIf="oneQRCodeIsCorrupted" class="alert alert-warning" role="alert">
            <h4 class="alert-heading">⚠️ Warning!</h4>
            <p>At least one QR Code has been detected as corrupt. Please contact your administrator.</p>
        </div>
        <div *ngIf="qrcodes().length > 0" class="form_container">
            <form *ngIf="qrcodes().length > 0" [formGroup]="dynamicForm" class="qr_form">
                <div class="mt-4 mb-4">
                    <div class="tableWrap">
                        <table class="table qrcodes_tables" formArrayName="qrcodes">
                            <thead>
                                <tr>
                                    <th class="align-middle">
                                        <mat-checkbox color="primary" [checked]="allchecked"
                                            [indeterminate]="indeterminated()"
                                            (change)="setAll($event.checked)"></mat-checkbox>
                                    </th>
                                    <th>Date of Creation</th>
                                    <th scope="col">Location: Level 1</th>
                                    <th scope="col">Location: Level 2</th>
                                    <th scope="col">Redirects to...</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody
								*ngFor="let code of qrcodes().controls; let i = index" 
								[formGroupName]="i"
                                (click)="updateAllComplete($event, code)"
                                [ngClass]="{'qr_table_row': true, 'qr_select': code.value.selectboolean}">
								<tr style="display: table-row;" >
                                    <td class="align-middle">
                                        <mat-checkbox [(ngModel)]="code.value.selectboolean"
                                            (click)="updateAllComplete($event, code)" formControlName="selectboolean">
                                        </mat-checkbox>
                                    </td>
                                    <td style="width: 18%" class="align-middle">
                                        <p class="table_uuid_value" style="color: rgb(26, 26, 26);">
                                            {{timeConverter(code.value.creationDate)}}</p>
                                    </td>
                                    <td style="width: 18%;" class="align-middle">
                                        <input type="text" formControlName="loc1" class="form-control" />
                                    </td>
                                    <td style="width: 18%;" class="align-middle">
                                        <input type="text" formControlName="loc2" class="form-control" />
                                    </td>
                                    <td style="width: 18%;" class="align-middle">
                                        <select #selectPage class="form-control" formControlName="landingPage"
                                            (change)="landingPageChanged(selectPage.value,i)">
                                            <option [value]="landing_page.Value"
                                                *ngFor="let landing_page of landing_page_array">{{landing_page.Key}}
                                            </option>
                                            <option value="externalUrl">External URL</option>
                                            <option value="documentPath">Images (.png or .jpeg), .pdf, Calendar file (.ics)</option>
                                        </select>
                                    </td>
                                    <td class="align-middle">
                                        <div id="canvasConfig_{{i}}" #canvasConfig></div>
                                    </td>
                                    <td class="align-middle">
                                        <span class="material-icons blue clickable"
                                            (click)="navigate('/qr-code-management/history/'+code.value.uuid);" matTooltip="Go to history"
                                            matTooltipPosition="right" aria-label="Go to history">
                                            history
                                        </span>
                                    </td>
                                    <td class="align-middle">
                                        <span *ngIf="!code.value.passwordActivated" class="material-icons blue clickable" matTooltip="Password deactiavted" matTooltipPosition="right" (click)="managePassword(code.value)">
                                            key_off
                                        </span>
                                        <span *ngIf="code.value.passwordActivated" class="material-icons blue clickable" matTooltip="Password activated" matTooltipPosition="right" (click)="managePassword(code.value)">
                                            key
                                        </span>
                                    </td>
                                    <td class="align-middle" >
                                        <span class="material-icons blue clickable"
                                            (click)="unselectAllQRCodes();downloadQRCodes(i);" matTooltip="Download QR Code"
                                            matTooltipPosition="right" aria-label="Download QR Code">
                                            cloud_download
                                        </span>
                                    </td>
                                    <td class="align-middle">
                                        <span class="material-icons blue clickable"
                                            (click)="copyQRCodeValue(code.value.uuid, i)" matTooltip="Copy to clipboard"
                                            matTooltipPosition="right" aria-label="Copy to clipboard">
                                            {{code.value.icons_copyQRCode}}
                                        </span>
                                    </td>
                                    <td class="align-middle">
                                        <span class="material-icons red clickable"
                                            (click)="unselectAllQRCodes();removeQRCodes(i);" matTooltip="Remove QR Code"
                                            matTooltipPosition="right" aria-label="Remove QR Code">
                                            highlight_off
                                        </span>
                                    </td>
                            	</tr>
								<tr *ngIf="code.get('externalUrl').enabled" style="display: table-row;" class="fadeInDown">
									<td colspan=12>
										<p class="labelLike">External URL</p>
										<input class="form-control w-25"
                                            formControlName="externalUrl" type="text" />
									</td>
								</tr>
								<tr *ngIf="code.get('documentPath').enabled" style="display: table-row;" class="fadeInDown">
									<td colspan=3>
										<p>
											<span class="labelLike">File </span>
											<span class="aspect-indication">(Size Max. 100MB)</span>
										</p>
										<div class="row">
											<div class="col-6 px-0">
												<input 
													type="file"
													[id]="'qrcode_documentPath_' + i"
													class="custom-file-input"
													accept="image/*, .pdf, .ics"
													(change)="onChangeDocumentFile($event, i)"
													>
												<label class="custom-file-label" for="image">Upload</label>
                                                <div *ngIf="code.get('document').invalid"
                                                class="alert alert-danger">
                                                <div
                                                    *ngIf="code.get('document').errors.invalidFileSize">
                                                    This file is too large. It must be less than 100MB.
                                                </div>
                                                <div
                                                    *ngIf="code.get('document').errors.extension">
                                                    File format not allowed. It must be an image, PDF or Calendar.
                                                </div>
                                                <div
                                                    *ngIf="code.get('document').errors.unauthorizedFileName">
                                                    File name not allowed. It must not contains any special characters
                                                    other than "-" and "_".
                                                </div>
                                            </div>
											</div>
											<div class="col-6 d-flex align-items-center">
												<p *ngIf="code.value.documentPath; else no_value_image"
													class="labelLike">
													{{code.value.documentPath}}</p>
												<ng-template #no_value_image>
													<p class="labelLike">No file</p>
												</ng-template>
											</div>
										</div>
									</td>
									<td colspan=1>
										<p>
											<span class="labelLike">File information 1 </span>
										</p>
										<input class="form-control" formControlName="matCode" type="text" />
									</td>
									<td colspan=1>
										<p>
											<span class="labelLike">File information 2 </span>
										</p>
										<input class="form-control" formControlName="matName" type="text" />
									</td>
                                    <td colspan=6></td>
								</tr>
								<tr *ngIf="code.errors?.loc1Andloc2DuplicateError" class="invalid-feedback" style="display: table-row;">
									<td colspan=12>
										<div>
											<span class="material-icons">
												warning
											</span>
											<span> This QR Code already exists</span>
										</div>
									</td>
								</tr>
								<tr *ngIf="code.errors?.loc1Andloc2RequiredError" class="invalid-feedback" style="display: table-row;">
									<td colspan=12>
										<div>
											<span class="material-icons">
												warning
											</span>
											<span> Location : level 1 and level 2 are required</span>
										</div>
									</td>
								</tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="align-items-center d-flex flex-column justify-content-center save-footer">
                        <p class="error_message">{{QRCodesGeneration.message}}</p>
                        <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center"
                            (click)="onSubmit()">
                            <span class="material-icons icons_button">
                                save
                            </span>
                            <span class="ml-2">Save Changes</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>

    </ng-template>
</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row align-items-center d-flex justify-content-center">
                    <div class="col-sm-7 text-center">
                        <h1>Check & Pair</h1>
                        <span>You can access to the Check & Pair on your mobile device with URL<br /><br />
                            <a href="{{checkAndPairURL}}">{{checkAndPairURL}}</a>
                            <br /><br />
                            OR<br /><br />
                            you can scan the QR Code to access it instantly<br /><br />
                        </span>
                    </div>
                    <div class="col-sm-5 align-items-center col-5 d-flex justify-content-center">
                        <div #canvasCheckAndPair></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalCreateNewQRCodes" tabindex="-1" role="dialog" aria-labelledby="modalCreateNewQRCodes"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row align-items-center d-flex justify-content-center">
                    <div class="col-sm-7 text-center">
                        <h1>Create new QR Codes</h1>
                        <div>
                            <input type="number" class="form-control m-auto w-50" [(ngModel)]="QRCodesGeneration.numberOfQRCodesToGenerate" min="1" max="100"
                                value="1">
                            <button type="button"
                                class="align-items-center btn btn-global-type btn-type3 mt-2" (click)="onGenerateNewQRCodes()">
                                <span class="mr-2">Ok</span>
                                <span class="material-icons icons_button">
                                    arrow_right_alt
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>