<div *ngIf="isLoaded" class="container m-0 p-0">

    <!-- Page is not activated -->
    <div *ngIf="!PAGE_CONTENT.activated">
        <img class="img-fluid m-auto d-block" src="assets/img/deactivated_page.png">
    </div>

    <!-- Page is activated -->
    <div *ngIf="PAGE_CONTENT.activated">
        <!-- Page content is an image -->
        <div *ngIf="PAGE_CONTENT.type == 'FULL_IMAGE'" style="height: 100vh;">
            <img src="{{inputPath}}SITES/{{SITE_NAME}}/IMG/{{PAGE_CONTENT.pageImageFields.imagePath}}" class="responsive_full_image"/>
        </div>
        <!-- Page content is a full text -->
        <div *ngIf="PAGE_CONTENT.type == 'FULL_TEXT'" style="height: 100vh;">
            <div class=" my-2 mx-auto px-3">
                <innerHTML HTMLcontent="{{PAGE_CONTENT.pageTextFields.text}}"></innerHTML>
            </div>
        </div>
        <!-- Page content is image and text -->
        <div *ngIf="PAGE_CONTENT.type == 'IMAGE_AND_TEXT'" class=" my-2" style="height: 100vh;">
            <!-- Browse arrayMoreInformation to get measures and display it-->
            <div *ngFor="let line of PAGE_CONTENT.pageCompositeFields; let index=index; let odd=odd; let even=even;" class="mx-1">
                <div class="row justify-content-center mt-4">
                    <img src="../../../assets/img/round.png" class="iconRound"/>
                </div>
                <!-- Get odd measures to display text then image -->
                <div *ngIf="odd" class="row justify-content-right mt-4">
                    <div class="col-9 text-right">
                        <innerHTML HTMLcontent="{{line.text}}"></innerHTML>
                    </div>
                    <div class="col-3">
                        <img src="{{inputPath}}SITES/{{SITE_NAME}}/IMG/{{line.imagePath}}" class="iconText"/>
                    </div>
                </div>
                <!-- Get even measures to display image then text -->
                <div *ngIf="even" class="row justify-content-left mt-4">
                    <div class="col-3">
                        <img src="{{inputPath}}SITES/{{SITE_NAME}}/IMG/{{line.imagePath}}" class="iconText"/>
                    </div>
                    <div class="col-9 text-left">
                        <innerHTML HTMLcontent="{{line.text}}"></innerHTML>
                    </div>
                </div>
            </div>
        </div>
            
        <!-- Call to action -->
        <call-to-action [colorHeader]="colorHeader" [CTA_LINK]="CTA_LINK" [CTA_TYPE]="CTA_TYPE" [CTA_LIST]="CTA_LIST" [CTA_LABEL]="CTA_LABEL" [ROUTES]="ROUTES" [language]="currentLanguage" [page]="PAGE_ID"></call-to-action>
    </div>
</div>