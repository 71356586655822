import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { NotFoundComponent } from './public/not-found/not-found/not-found.component';
import { NoUuidComponent } from './public/no-uuid/no-uuid.component';
import { SplashScreenComponent } from './public/splash-screen/splash-screen.component';
const extraOption: ExtraOptions = {
  scrollPositionRestoration: 'enabled'
}
const APP_ROUTES: Routes = [
  { path: '', redirectTo: '/coming-soon', pathMatch: 'full' },
  { path: 'coming-soon', component: SplashScreenComponent },
  { path: 'no-uuid', component: NoUuidComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, extraOption)],
  exports: [RouterModule]
})
export class AppRoutingModule { }