import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

/** Service with all the functions use for call API */
@Injectable({
  providedIn: "root",
})
export class HttpclientService {
  /** URL de l'API */
  apiURL = environment.apiUrl;
  inputPath = environment.inputPath;

  VAL_SESSION_EXPIRED_MESSAGE: string = "";

  /** Variable to avoid cache for JSON files */
  readonly httpCacheOptions = {
    headers: new HttpHeaders({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      "Content-Type": "application/json",
    }),
  };

  constructor(private httpClient: HttpClient) {}

  /** Get JSON data from S3 */
  getData(siteName: string) {
    return this.httpClient
      .get(
        `${this.inputPath}SITES/${siteName}/DATA/json/Real_Data_2020_France.csv-data.json`
      )
      .toPromise();
  }

  /** Get General JSON data from DynamoDB */
  getGeneralData(language: string) {
    return this.httpClient
      .get(`${this.inputPath}SITES/SITE-BASE/DATA/i18n/${language}.json`)
      .toPromise();
  }

  /** Get JSON translation from DynamoDB */
  getTranslation(siteName: string, language: string) {
    return this.httpClient
      .get(`${this.apiURL}/sites/${siteName}/translations?language=${language}`)
      .toPromise();
  }

  /** Get JSON preview translation from DynamoDB */
  getPreviewTranslation(siteName: string, language: string) {
    return this.httpClient
      .get(
        `${this.apiURL}/sites/${siteName}-PREVIEW/translations?language=${language}`
      )
      .toPromise();
  }

  /** Post JSON translation on DynamoDB */
  postTranslation(siteName: string, body: any) {
    return this.httpClient
      .post(`${this.apiURL}/sites/${siteName}/translations`, body)
      .toPromise();
  }

  /** Return one QR Code */
  getQRCode(siteName: string, uuid: string) {
    return this.httpClient
      .get(`${this.apiURL}/sites/${siteName}/qrcodes/${uuid}`)
      .toPromise();
  }

  /** Return all the QR Codes */
  getQRCodes(siteName: string) {
    return this.httpClient
      .get(`${this.apiURL}/sites/${siteName}/qrcodes`)
      .toPromise();
  }

  /** Push new QR Codes */
  postQRCodes(siteName: string, body: any) {
    return this.httpClient.post(`${this.apiURL}/sites/${siteName}/qrcodes`, body, { observe: 'response' }).toPromise();
  }

  /** Push config of QR Code */
  configQRCode(siteName: string, body: any) {
    return this.httpClient
      .post(`${this.apiURL}/sites/${siteName}/qrcodes/config`, body)
      .toPromise();
  }

  /** Remove one QR Code */
  removeQRCode(siteName: string, uuid: string) {
    return this.httpClient
      .delete(`${this.apiURL}/sites/${siteName}/qrcodes/${uuid}`)
      .toPromise();
  }

  /** Get the QR Code history */
  getQRCodeHistory(siteName: string, uuid: string) {
    return this.httpClient
      .get(`${this.apiURL}/sites/${siteName}/qrcodes/${uuid}/history`)
      .toPromise();
  }

  /** Run the generation of QR Code history file */
  getQRCodeHistoryFile(siteName: string, body: any) {
    return this.httpClient
      .post(`${this.apiURL}/sites/${siteName}/qrcodes/history`, body)
      .toPromise();
  }
}
