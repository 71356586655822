import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { COLORS_ } from 'src/app/core/classes/Colors';

@Component({
  selector: 'results-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  @Input() RECEIVE: any;
  @Input() INDEX: any;

  BACKGROUND_COLOR = [
    '#FF5050',
    '#70FF50',
    '#5072FF',
    "#FAFF50"
  ]

  chartDataSets: Chart.ChartDataSets[] = [];
  chartLabels: Array<any> = [];
  chartOptions: Chart.ChartOptions = {
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        scaleLineColor: 'transparent',
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: "Week Number",
          fontColor: "#000000"
        },
        ticks: {
          display: true,
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          fontColor: COLORS_['color-negative']
        },
        gridLines: {
          color: COLORS_['color-opacity-black'],
          drawBorder: false
        }
      }],
      yAxes: [{
        scaleLineColor: 'transparent',
        stacked: true,
        ticks: {
          display: true,
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          min: 0,
          max: 100,
          stepSize: 25,
          fontColor: COLORS_['color-negative'],
          callback: function(value){return value+ "%"}
        },
        gridLines: {
          color: COLORS_['color-opacity-black'],
          drawBorder: false,
        }
      }]
    },
    tooltips: {
      /**enabled: true,
      mode: 'single',
      callbacks: {
        label: function(tooltipItems, data) {
            return data.datasets[tooltipItems.datasetIndex].label + ' : ' + tooltipItems.yLabel + '%';
        },
        title: function(tooltipItems, data) {
          return 'Week ' + tooltipItems[0].xLabel;
        }
      }*/
      // Disable the on-canvas tooltip
      enabled: false,
      mode: 'single',
      custom: function(tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          
          // Create element on first render
          if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              tooltipEl.style.backgroundColor = "#FFFFFF";
              tooltipEl.style.borderColor = "#000000";
              tooltipEl.style.borderWidth = "thin";
              tooltipEl.style.borderStyle = "solid";
              tooltipEl.style.borderRadius = "6px";
              tooltipEl.style.maxWidth = "300px"
              document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
              tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
              return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = '<thead>';

              titleLines.forEach(function(title) {
                  innerHtml += '<tr><th>' +'Week ' + title + '</th></tr>';
              });
              innerHtml += '</thead><tbody>';

              bodyLines.forEach(function(body, i) {
                  var colors = tooltipModel.labelColors[i];
                  
                  var style = 'width: 10px; height: 10px; background-color:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px; border: 0px; display:inline-block;';
                  var span = '<span style="' + style + '"></span>';
                innerHtml += '<tr><td>' + span +' '+ body + '%' + '</td></tr>';
              });
              innerHtml += '</tbody>';

              var tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
      }
    }
  };

  constructor() { }

  ngOnInit(): void {
    this.setChartData();
  }

  /** Set chart with received data */
  setChartData(): void {
    const chartData = JSON.parse(JSON.stringify(this.RECEIVE));
    
    this.chartLabels = chartData.WEEK.map(week => week.toString());

    var total_of_week = [];
    const number_of_week = chartData.WEEK.length;
    for (let i=0; i< number_of_week; i++){
      total_of_week.push(0);
    }
    for (let j = 0; j < chartData.DATA.length; j++){
      for (let i = 0; i < number_of_week; i++){
        total_of_week[i] = total_of_week[i] + chartData.DATA[j]['DATA'][i];
      }
    }
    for (let j = 0; j < chartData.DATA.length; j++){
      for (let i = 0; i < number_of_week; i++){
        chartData.DATA[j]['DATA'][i] = ((chartData.DATA[j]['DATA'][i] / total_of_week[i]) * 100).toFixed(2);
      }
    }
    for (let i = 0; i < chartData.DATA.length; i++){
      this.chartDataSets.push({
        label: chartData.DATA[i]['LABEL'],
        data: chartData.DATA[i]['DATA'],
        backgroundColor: this.BACKGROUND_COLOR[i],
        barThickness: 10
      })
    }
  }
}