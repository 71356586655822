<div
  class="modal"
  id="modalMessage"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  aria-labelledby="modalMessage"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <h1 class="modal-title">
          {{ "GENERAL.COOKIE_POLICY.COOKIE_POLICY_HEADER" | translate }}
        </h1>
      </div>

      <div class="modal-body">
        <p>{{ "GENERAL.COOKIE_POLICY.COOKIE_POLICY_TEXT" | translate }}</p>
        <select
          *ngIf="callback.SITE.LANGUAGES.length > 1"
          [(ngModel)]="callback.CURRENT_LANGUAGE"
          (change)="switchLanguage()"
          class="form-control"
        >
          <option
            *ngFor="let language of callback.SITE.LANGUAGES"
            [value]="language"
          >
            {{ callback.LANGUAGES[language]["long_language"] }}
          </option>
        </select>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-accept"
          data-dismiss="modal"
          (click)="acceptCookie()"
        >
          OK
        </button>
        <button
          type="button"
          class="btn btn-dismiss"
          data-dismiss="modal"
          (click)="dismissCookie()"
        >
          Dismiss
        </button>
      </div>
    </div>
  </div>
</div>
