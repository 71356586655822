import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
	selector: 'CookieAccept',
	templateUrl: './cookie-accept.component.html',
	styleUrls: ['./cookie-accept.component.scss']
})
export class CookieAcceptComponent implements OnInit {

	@Input() callback: any;

	googleAnalyticsId = environment.gaTrackingId;
	GENERAL: any;

	constructor(private _cookieService: CookieService) { }

	ngOnInit(): void {
		$('#modalMessage').modal('show');
	}

	/** Cookie policy is accepted */
	acceptCookie() {
		this._cookieService.set('cookie-acceptance', 'true', 1, '/');
		this.callback.cookiesAccepted();
	}

	/** Cookie policy is not accepted */
	dismissCookie() {
		this._cookieService.set('cookie-acceptance', 'false', 1, '/');
		this.removeGACookies();
	}

	/** Function to switch the language of the popup */
	switchLanguage() {
		if (this.callback.CURRENT_LANGUAGE === null)
			return false;
		else {
			this.callback.setTranslation();
		}
	}

	/** Remove Google Analytics cookies */
	removeGACookies() {
		console.info("Removing Google Analytics cookies");
		const listOfCookies = this._cookieService.getAll();
		//supprime tous les cookies en liens avec google analytics
		for (const key in listOfCookies) {
			console.log(key);
			if (key.includes('_ga') || key.includes('_gid')) {
				this._cookieService.delete(key);
			}
		}
		//désactive les cookies google analytics
		window[`ga-disable-${this.googleAnalyticsId}`] = true;
	}
}
