<div class="main">
    <!-- Navigation -->
    <GlobalNavBar [COMPONENT]="'SiteManagement'"></GlobalNavBar>

    <div *ngIf="!DATA_IS_LOADED" class="component text-center my-5">
        <Message MESSAGE="Data is loading..."></Message>
    </div>

    <!-- Button to create new site -->
    <div *ngIf="DATA_IS_LOADED" role="group" class="m-3">
        <button type="button" class="btn btn-global-type btn-type3 d-flex align-items-center" (click)="navigate('sites/sites-generator')" >
            <span class="material-icons icons_button">
                add_circle
            </span>
            <span class="ml-2">Create new site</span>
        </button>
    </div>

    <!-- Main Component -->
    <div *ngIf="DATA_IS_LOADED" class="row">
        <div class="col">
            <!-- Filters -->
            <div class="btn-group btn-no-padding col-filter col-md-12 p-0">
                <div class="dropdown keep-open w-100">
                    <button type="button" class="btn btn-primary btn-type1 btn-global-type dropdown-toggle w-100" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        FILTER
                    </button>
                    <div class="dropdown-menu keep-open-on-click">
                        <form [formGroup]="filterForm" (ngSubmit)="onFilter()">
                            <div class="form-group-filters">
                                <input type="text" formControlName="SITE" class="form-control" placeholder="Site name contains..." (click)="onClick($event)" oninput="this.value = this.value.replace(' ', '_').replace(/[^A-Za-z0-9-_]/gi, '').toUpperCase()"/>
                            </div>
                            <div class="form-group-filters">
                                <select class="form-control input_form" formControlName="LANGUAGES" (click)="onClick($event)">
                                    <option value="" selected>Languages</option>
                                    <option *ngFor="let element of filteredArrayLanguages" value="{{element}}">{{LANGUAGES[element]['long_language']}}</option>
                                </select>
                            </div>
                            <div class="form-group-filters">
                                <select class="form-control input_form" formControlName="ACTIVATED" (click)="onClick($event)">
                                    <option value="" selected>Activation Status</option>
                                    <option *ngFor="let element of filteredArray" value="{{element}}">
                                        <span *ngIf="element === 'true'">Activated</span>
                                        <span *ngIf="element === 'false'">Deactivated</span>
                                    </option>
                                </select>
                            </div>
                            <div class="form-group-filters">
                                <input type="text" formControlName="CREATION_DATE" placeholder="Creation date" class="form-control" bsDatepicker [bsConfig]="bsConfig" (click)="onClick($event)">
                            </div>
                            <div class="form-group-filters">
                                <input type="text" formControlName="LAST_ACTIVATION_DATE" placeholder="Last activation date" class="form-control" bsDatepicker [bsConfig]="bsConfig" (click)="onClick($event)">
                            </div>
                            <div class="form-group-filters">
                                <input type="text" formControlName="EXPIRATION_DATE" placeholder="Expiration date" class="form-control" bsDatepicker [bsConfig]="bsConfig" (click)="onClick($event)">
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="form-group-filters">
                                <input type="submit" class="btn btn-primary btn-type1 btn-global-type btn-sm btn-block btn-margin-top" value="Filter">
                            </div>
                        </form>
                        <div class="form-group-filters">
                            <button type="button" class="btn btn-primary btn-type1 btn-global-type btn-sm btn-block" (click)="removeFilters()">
                                Remove filters
                            </button>
                        </div>
                        <div class="dropdown-divider"></div>
                        <div class="form-group-filters">
                            <button type="button" class="btn btn-primary btn-type2 btn-global-type btn-sm btn-block" (click)="closeDropdown()">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- List of sites -->
            <table class="table mt-3">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Name of site</th>
                        <th scope="col">Languages</th>
                        <th scope="col">Activation Status</th>
                        <th scope="col">Creation date</th>
                        <th scope="col">Last activation date</th>
                        <th scope="col">Expiration date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let SITE of SITES">
                        <th scope="row">
                            <i class="material-icons blue clickable" (click)="modifySite(SITE.SITE)" data-toggle="tooltip" data-placement="top" title="View more">
                                more_horiz
                            </i>
                        </th>
                        <td>{{SITE.SITE}}</td>
                        <td>{{SITE.LANGUAGES_STRING}}</td>
                        <td *ngIf="SITE.OPTIONS['ACTIVATED'] == 'true'">Activated</td>
                        <td *ngIf="SITE.OPTIONS['ACTIVATED'] == 'false'">Deactivated</td>
                        <td>{{SITE.CREATION_DATE}}</td>
                        <td>{{SITE.LAST_ACTIVATION_DATE}}</td>
                        <td>{{SITE.EXPIRATION_DATE}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Sites Recap -->
        <div class="col-12 col-lg-auto">
            <div class="card" style="width: 18rem;">
                <div class="card-header">
                    SITES RECAP
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Sites generated : {{COUNTERS.COUNTER_SITES_GENERATED}}</li>
                    <li class="list-group-item">Sites activated : {{COUNTERS.COUNTER_SITE_ACTIVATED}}</li>
                    <li class="list-group-item">Sites deactivated : {{COUNTERS.COUNTER_SITE_DEACTIVATED}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>