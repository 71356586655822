import { NgModule } from '@angular/core';
import { PrivateRoutingModule } from './private-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LocalBackOfficeModule } from './local-back-office/local-back-office.module';
import { GlobalBackOfficeModule } from './global-back-office/global-back-office.module';
import { AdminBackOfficeModule } from './admin-back-office/admin-back-office.module';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    PrivateRoutingModule,
    LocalBackOfficeModule,
    GlobalBackOfficeModule,
    AdminBackOfficeModule,
  ]
})
export class PrivateModule { }
