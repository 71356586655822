import { Component, OnInit, Input, AfterViewChecked } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FileManagementService } from 'src/app/core/services/file-management.service';
import { RoutesService } from 'src/app/core/services/routes.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { SitesService } from 'src/app/core/services/sites.service';
import { v4 as uuid } from 'uuid';
import { SITE } from 'src/app/core/classes/Site';
import { generateUUID } from 'src/app/core/helpers/generate-uuid';

@Component({
  selector: 'form-menu',
  templateUrl: './menu.component.html',
  styleUrls: [
    './menu.component.scss',
    '../content-management.scss',
    '../../local-back-office.scss',
    '../../../private.scss'
  ]
})
export class MenuComponent implements OnInit, AfterViewChecked {

  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;

  SITE_NAME: string = '';

  types_array: any[] = [
    {
      label: "App Page",
      value:"APP_PAGE"
    },
    {
      label: "External Link",
      value: "EXTERNAL_LINK"
    }
  ];

  SITE: SITE;
  isMapEnabled: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _fileManagement: FileManagementService,
    private _routesService: RoutesService,
    private _sitesService: SitesService
  ) {
    /** Get routes information then get site information*/
    this._routesService.currentRoutes
    .subscribe(routes => {
      console.log(routes);
      if(routes.PARAMS){
        if(routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
      }
    });
  }

  ngOnInit(): void {
    let promiseGetSite = this._sitesService.getSite(this.SITE_NAME);
    promiseGetSite.then(value => {
      this.SITE = JSON.parse(JSON.stringify(value));
      this.isMapEnabled = this.SITE.OPTIONS.MAP_ENABLED  == 'true' ? true : false;
    })
  }

  ngAfterViewChecked(): void {
    // Patch to allow menu links in view to be properly uptaded
    this.linksMenu().patchValue(
      this.linksMenu().value
      .map((link: any) => Object.assign(link, {value: link.value})));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.linksMenu().controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.linksMenu().value, event.previousIndex, event.currentIndex);
  }


   addMenuLinkTypeAppPage = (typeAppPage) => {
    var formMenuLinkTypeAppPage: FormGroup = this.formBuilder.group({
      pageId: typeAppPage.pageId
    });
    return formMenuLinkTypeAppPage;
  };

   addMenuLinkTypeExternalLink = (typeExternalLink) => {
    var formMenuLinkTypeExternalLink: FormGroup = this.formBuilder.group({
      url: typeExternalLink.url
    });
    return formMenuLinkTypeExternalLink;
  };

  /** ---- Page Management ---- */
  linksMenu() : FormArray { return this.parentForm.get(`links_menu_${this.type}`) as FormArray }
  addLinksMenu(linksMenu){
    
    const newLinksMenu: FormGroup = this.formBuilder.group({
      id: [linksMenu.id],
      label: [linksMenu.label],
      type: [linksMenu.type],
      typeAppPage: this.addMenuLinkTypeAppPage(linksMenu.typeAppPage),
      typeExternalLink: this.addMenuLinkTypeExternalLink(linksMenu.typeExternalLink)
    });
    this.linksMenu().push(newLinksMenu);
  }
  removeLinksMenu(i: number){
    this.linksMenu().removeAt(i);
  }

  /**
   * Generate default menu link
   * @returns array of menu lnik
   */
  generateDefaultLink(){
    return {
      id: generateUUID(),
      label: `New link`,
      type: 'null',
      typeAppPage: this.addMenuLinkTypeAppPage(""),
      typeExternalLink: this.addMenuLinkTypeExternalLink("")
    }
  }
}
