import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EDITOR_CONFIG from "../../../../../../assets/constants/editorConfig";

@Component({
  selector: 'FullTextForm',
  templateUrl: './full-text.component.html',
  styleUrls: [
    './full-text.component.scss',
    '../../content-management.scss',
    '../../../local-back-office.scss',
    '../../../../private.scss'
  ]
})
export class FullTextComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;
  @Input() option: string;
  @Input() id: number;

  public Editor = ClassicEditor;
  public editorConfig;

  constructor() {
    this.editorConfig = EDITOR_CONFIG;
  }

  ngOnInit(): void {
    console.log("parentForm = ", this.parentForm);
    console.log("language = ", this.language);
    console.log("type = ", this.type);
    console.log("option = ", this.option);
    console.log("id = ", this.id);
  }

  /**
   * get pageTextFields of pages :index
   * @param index index of the pages
   * @returns return FormArray of pageTextFields
   */
   pageTextFields(index: number): FormArray { return this.parentForm.get(`pages_${this.type}`)['controls'][index].get("pageTextFields") as FormArray }
}
