/** Main colors */
export var COLORS_ = {
    'color-primary': '#4084B9',
    'color-primary-dark': '#525CA3',
    'color-secondary': '#F3B337',
    'color-tertiary': '#BCBC1C',
    'color-quaternary': '#008678',
    'color-alt': '#EAF5FD',
    'color-neutral': '#DDD',
    'color-neutral-dark': '#555',
    'color-neutral-light': '#AAA',
    'color-neutral-grey': '#888',
    'color-positive': '#FFFFFF',
    'color-negative': '#000000',
    'color-opacity-black': 'rgba(0, 0, 0, 0)'
}