<div *ngIf="(userToken$ | async) as userToken">

    <!-- Modal dashboard global -->
    <div *ngIf="!DATA_IS_LOADED && CAMPAIGNS_LIST_IS_LOADED && SHOW_FILTERS_MODAL" class="container_filters_modal_bg">
        <div class="container_filters_modal_container">
            <div class="container_header">
                <div class="container_header_top">
                    <div class="container_dashboard_title">
                        <h3 *ngIf="TYPE === 'GLOBAL'" style="overflow-wrap: anywhere;">ELEFIGHT DASHBOARD</h3>
                        <h3 *ngIf="TYPE === 'ADMIN'" style="overflow-wrap: anywhere;">ELEFIGHT {{USER_NAME}} DASHBOARD
                        </h3>
                        <h3 *ngIf="TYPE === 'LOCAL'" style="overflow-wrap: anywhere;">ELEFIGHT {{SITE_NAME}} DASHBOARD
                        </h3>

                        <p *ngIf="(TYPE === 'GLOBAL' || TYPE === 'ADMIN') && this.selectedCampaigns.length==1">
                            <b>Campaign Info.
                                :</b>
                            {{campaignDateAnalytics}}
                        </p>
                        <p *ngIf="TYPE === 'LOCAL'"><b>Campaign Info. :</b> {{campaignDateAnalytics}}</p>
                        <div class="row mb-2">
                            <span class="label_like d-flex align-items-end">Select campaign(s)</span>
                        </div>
                        <mat-form-field
                            *ngIf="(userToken['cognito:groups'].includes('GlobalAdministrators') || userToken['cognito:groups'].includes('SiteAdministrators')) && (TYPE==='GLOBAL' || TYPE === 'ADMIN')"
                            appearance="fill">

                            <mat-select disableOptionCentering [(ngModel)]="tempSelectedCampaigns"
                                name="selectedCampaigns" placeholder="Select Campaign(s)" multiple>
                                <mat-select-trigger>
                                    <div *ngIf="this.selectedCampaigns.includes(this.SELECT_VALUE)">

                                        <ng-container *ngIf="this.selectedCampaigns[0]!=this.SELECT_VALUE">
                                            {{this.selectedCampaigns ? this.selectedCampaigns[0] : ''}}
                                        </ng-container>
                                        <ng-container *ngIf="this.selectedCampaigns[0]==this.SELECT_VALUE">
                                            {{this.selectedCampaigns ? this.selectedCampaigns[1] : ''}}
                                        </ng-container>

                                        <span *ngIf="this.selectedCampaigns?.length > 2"
                                            class="example-additional-selection">
                                            (+{{this.selectedCampaigns.length - 2}} {{this.selectedCampaigns?.length ===
                                            3 ?
                                            'other'
                                            : 'others'}})
                                        </span>
                                    </div>
                                    <div *ngIf="!this.selectedCampaigns.includes(this.SELECT_VALUE)">
                                        {{this.selectedCampaigns ? this.selectedCampaigns[0] : ''}}
                                        <span *ngIf="this.selectedCampaigns?.length > 1"
                                            class="example-additional-selection">
                                            (+{{this.selectedCampaigns.length - 1}} {{this.selectedCampaigns?.length ===
                                            2 ?
                                            'other'
                                            : 'others'}})
                                        </span>
                                    </div>
                                </mat-select-trigger>
                                <input placeholder="Search campaigns" matInput type="text"
                                    (keyup)="onKeyCampaignInput($event.target.value)" class="input_sites_filtered">
                                <mat-option #allCampaignSelected (click)="toggleAllCampaignSelection()"
                                    [value]="SELECT_VALUE">Select All</mat-option>
                                <mat-option *ngFor="let campaign of filteredCampaigns"
                                    (onSelectionChange)="changeCampaignSelection($event)" [value]="campaign"
                                    (click)="toggleCampaignPerOne()">{{campaign}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="container_date_range_picker">
                        <div class="row mb-2">
                            <span class="label_like d-flex align-items-end">Select a date range</span>
                        </div>
                        <div class="row mb-2">
                            <input type="text" placeholder="Date Range" class="form-control" bsDaterangepicker
                                [bsConfig]="bsConfig" [maxDate]="maxDate" [(ngModel)]="bsRangeValue" />
                        </div>
                    </div>
                </div>

                <div class="container_header_bottom">
                    <div class="container_buttons pl-0 pr-1">
                        <div class="d-flex flex-column">
                            <!-- Refresh data button -->
                            <div class="p-1 bd-highlight text-center">
                                <button class="btn btn-primary btn-sm btn-global-type btn-type1 btn-search"
                                    mat-raised-button matTooltip="Filter data with the filters selected"
                                    aria-label="Button that filter data with the filters selected"
                                    (click)="onSearch(true)">
                                    Load analytics Data
                                </button>
                            </div>
                            <!-- Last refresh date -->
                            <div class="d-flex justify-content-center p-1 last_refresh">
                                <p>Last refresh: {{lastRefresh | date :'MM/dd/yyyy hh:mm:ss a' }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="DATA_IS_LOADED && !SHOW_FILTERS_MODAL" class="container_header reporting">
        <div class="container_header_top">
            <!-- Title of the Dashboard + Dropdown select a campaign -->
            <div class="container_dashboard_title">

                <h3 *ngIf="TYPE === 'GLOBAL'" style="overflow-wrap: anywhere;">ELEFIGHT DASHBOARD</h3>
                <h3 *ngIf="TYPE === 'ADMIN'" style="overflow-wrap: anywhere;">ELEFIGHT {{USER_NAME}} DASHBOARD</h3>
                <h3 *ngIf="TYPE === 'LOCAL'" style="overflow-wrap: anywhere;">ELEFIGHT {{SITE_NAME}} DASHBOARD</h3>

                <p *ngIf="(TYPE === 'GLOBAL' || TYPE === 'ADMIN') && this.selectedCampaigns.length==1"><b>Campaign Info.
                        :</b>
                    {{campaignDateAnalytics}}</p>
                <p *ngIf="TYPE === 'LOCAL'"><b>Campaign Info. :</b> {{campaignDateAnalytics}}</p>

                <mat-form-field
                    *ngIf="(userToken['cognito:groups'].includes('GlobalAdministrators') || userToken['cognito:groups'].includes('SiteAdministrators')) && (TYPE==='GLOBAL' || TYPE === 'ADMIN')"
                    appearance="fill">
                    <mat-label> Select Campaign(s) </mat-label>
                    <mat-select disableOptionCentering [(ngModel)]="tempSelectedCampaigns" name="selectedCampaigns"
                        placeholder="Select Campaign(s)" multiple>
                        <mat-select-trigger>
                            <div *ngIf="this.selectedCampaigns.includes(this.SELECT_VALUE)">
                                <ng-container *ngIf="this.selectedCampaigns[0]!=this.SELECT_VALUE">
                                    {{this.selectedCampaigns ? this.selectedCampaigns[0] : ''}}
                                </ng-container>
                                <ng-container *ngIf="this.selectedCampaigns[0]==this.SELECT_VALUE">
                                    {{this.selectedCampaigns ? this.selectedCampaigns[1] : ''}}
                                </ng-container>

                                <span *ngIf="this.selectedCampaigns?.length > 2" class="example-additional-selection">
                                    (+{{this.selectedCampaigns.length - 2}} {{this.selectedCampaigns?.length === 3 ?
                                    'other'
                                    : 'others'}})
                                </span>
                            </div>
                            <div *ngIf="!this.selectedCampaigns.includes(this.SELECT_VALUE)">
                                {{this.selectedCampaigns ? this.selectedCampaigns[0] : ''}}
                                <span *ngIf="this.selectedCampaigns?.length > 1" class="example-additional-selection">
                                    (+{{this.selectedCampaigns.length - 1}} {{this.selectedCampaigns?.length === 2 ?
                                    'other'
                                    : 'others'}})
                                </span>
                            </div>
                        </mat-select-trigger>
                        <input placeholder="Search campaigns" matInput type="text"
                            (keyup)="onKeyCampaignInput($event.target.value)" class="input_sites_filtered">
                        <mat-option #allCampaignSelected (click)="toggleAllCampaignSelection()"
                            [value]="SELECT_VALUE">Select All</mat-option>
                        <mat-option *ngFor="let campaign of filteredCampaigns"
                            (onSelectionChange)="changeCampaignSelection($event)" [value]="campaign"
                            (click)="toggleCampaignPerOne()">{{campaign}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Date Range Picker -->
            <div class="container_date_range_picker">
                <div class="row mb-2">
                    <span class="label_like d-flex align-items-end">Date Range Picker</span>
                </div>
                <div class="row mb-2">
                    <input type="text" placeholder="Date Range" class="form-control" bsDaterangepicker
                        [bsConfig]="bsConfig" [maxDate]="maxDate" [(ngModel)]="bsRangeValue" />
                </div>
            </div>
        </div>

        <div class="container_header_bottom">
            <!-- Filters section -->
            <div class="container_filter_section">
                <div class="row mb-2">
                    <!-- Dropdown select a source -->
                    <div class="col-6 pl-0 pr-1">
                        <mat-form-field appearance="fill">
                            <mat-label> Select Location Level 1 </mat-label>
                            <mat-select disableOptionCentering [(ngModel)]="selectedLocation1"
                                (ngModelChange)="selectedLocation1" name="selectedLocation1"
                                placeholder="Select Location Level 1" multiple>
                                <mat-select-trigger>
                                    <ng-container *ngIf="this.selectedLocation1.includes(this.SELECT_VALUE)">
                                        <ng-container *ngIf="this.selectedLocation1[0]!=this.SELECT_VALUE">
                                            {{this.selectedLocation1 ? this.selectedLocation1[0] : ''}}
                                        </ng-container>
                                        <ng-container *ngIf="this.selectedLocation1[0]==this.SELECT_VALUE">
                                            {{this.selectedLocation1 ? this.selectedLocation1[1] : ''}}
                                        </ng-container>
                                        <span *ngIf="this.selectedLocation1.length > 2"
                                            class="example-additional-selection">
                                            (+{{this.selectedLocation1.length - 2}} {{this.selectedLocation1.length ===
                                            3 ?
                                            'other' : 'others'}})
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!this.selectedLocation1.includes(this.SELECT_VALUE)">
                                        {{this.selectedLocation1 ? this.selectedLocation1[0] : ''}}
                                        <span *ngIf="this.selectedLocation1.length > 1"
                                            class="example-additional-selection">
                                            (+{{this.selectedLocation1.length - 1}} {{this.selectedLocation1.length ===
                                            2 ?
                                            'other' : 'others'}})
                                        </span>
                                    </ng-container>
                                </mat-select-trigger>
                                <mat-option #allSourceSelected (click)="toggleAllSourceSelection()"
                                    [value]="SELECT_VALUE">Select All</mat-option>
                                <ng-container *ngFor="let source of sourceArray">
                                    <mat-option *ngIf="source != SELECT_VALUE" [value]="source"
                                        (click)="toggleSourcePerOne(allSourceSelected.viewValue)">
                                        {{source}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- Dropdown select a medium -->
                    <div class="col-6 pl-1 pr-0">
                        <mat-form-field appearance="fill">
                            <mat-label> Select Location Level 2 </mat-label>
                            <mat-select disableOptionCentering [(ngModel)]="selectedLocation2"
                                (ngModelChange)="selectedLocation2" name="selectedLocation2"
                                placeholder="Select Location Level 2" multiple>
                                <mat-select-trigger style="height: 200px;">

                                    <ng-container *ngIf="this.selectedLocation2.includes(this.SELECT_VALUE)">

                                        <ng-container *ngIf="this.selectedLocation2[0]!=this.SELECT_VALUE">
                                            {{this.selectedLocation2 ? this.selectedLocation2[0] : ''}}
                                        </ng-container>

                                        <ng-container *ngIf="this.selectedLocation2[0]==this.SELECT_VALUE">
                                            {{this.selectedLocation2 ? this.selectedLocation2[1] : ''}}
                                        </ng-container>

                                        <span *ngIf="this.selectedLocation2.length > 2"
                                            class="example-additional-selection">
                                            (+{{this.selectedLocation2.length - 2}} {{this.selectedLocation2.length ===
                                            3 ?
                                            'other' : 'others'}})
                                        </span>

                                    </ng-container>

                                    <ng-container *ngIf="!this.selectedLocation2.includes(this.SELECT_VALUE)">
                                        {{this.selectedLocation2 ? this.selectedLocation2[0] : ''}}
                                        <span *ngIf="this.selectedLocation2.length > 1"
                                            class="example-additional-selection">
                                            (+{{this.selectedLocation2.length - 1}} {{this.selectedLocation2.length ===
                                            2 ?
                                            'other' : 'others'}})
                                        </span>
                                    </ng-container>

                                </mat-select-trigger>
                                <mat-option #allMediumSelected (click)="toggleAllMediumSelection()"
                                    [value]="SELECT_VALUE">Select All</mat-option>
                                <ng-container *ngFor="let medium of mediumArray">
                                    <mat-option *ngIf="medium != SELECT_VALUE" [value]="medium"
                                        (click)="toggleMediumPerOne(allMediumSelected.viewValue)">
                                        {{medium}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- Source or Medium incldue input -->
                <div class="row">
                    <div class="col-12 px-0">
                        <div class="input-group">
                            <input type="text" class="form-control"
                                placeholder="Location level 1 or location level 2 contains ..."
                                [(ngModel)]="searchInput">
                        </div>
                    </div>
                </div>
            </div>

            <div class="container_buttons pl-0 pr-1">
                <div class="d-flex flex-column">
                    <!-- Refresh data button -->
                    <div class="p-1 bd-highlight text-center">
                        <button class="btn btn-primary btn-sm btn-global-type btn-type1 btn-search" mat-raised-button
                            matTooltip="Filter data with the filters selected"
                            aria-label="Button that filter data with the filters selected" (click)="onSearch(false)">
                            Refresh Data
                        </button>
                    </div>
                    <div class="p-1 bd-highlight text-center">
                        <button class="btn btn-primary btn-sm btn-global-type btn-type1 btn-search" mat-raised-button
                            matTooltip="General Excel report based on data filtered"
                            aria-label="Button that generate Excel report with data based on filters selected"
                            (click)="callbackActionModal_downloadData()">
                            Download Data
                        </button>
                    </div>
                    <!-- Last refresh date -->
                    <div class="d-flex justify-content-center p-1 last_refresh">
                        <p>Last refresh: {{lastRefresh | date :'MM/dd/yyyy hh:mm:ss a' }}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- Dashboard -->
    <ng-container>
        <div class="container_figures_graph_scan">
            <!-- KPI sections -->
            <div *ngIf="DATA_IS_LOADED && !DATA_IS_LOADING" class="container_main_figures p-2">
                <mainFigures [type]="'NEGATIVE'" [text]="'QR Codes Selected'" [figure]="SelectedQRCodes"
                    [totalFigure]="TotalSelectedQRCodes" class="main_figures"></mainFigures>
                <div class="container_main_figures_inline">
                    <mainFigures [type]="'NEGATIVE'" [text]="'Total number of visits'" [figure]="ScansNumber"
                        [infoText]="'Number of arrivals on the page (First connection, cookies accepted or not, + all additional connections from the same IP addresses), this also includes arrivals on external pages redirections from QrCodes'"
                        class="main_figures inline"></mainFigures>
                    <mainFigures [type]="'REFUSED'" [text]="'Cookies refused'" [figure]="CookiesRefusedRate + '%'"
                        [infoText]="'Percentage of visits without accepting cookies'" class="main_figures inline">
                    </mainFigures>
                </div>
                <div class="container_sub_figures">
                    <mainFigures [type]="'ACCEPTED'" [text]="'Application visits with cookies accepted'"
                        [figure]="CookiesAcceptedNumber"
                        [infoText]="'Application visits after accepting cookies (30-minute session = 1 visit)'"
                        class="main_figures cookie_accepted"></mainFigures>
                    <mainFigures [type]="'NEGATIVE'" [text]="'Unique Users'" [figure]="UniqueUsers"
                        [infoText]="'Number of people who interacted with the application'"
                        [totalFigure]="TotalUniqueUsers" class="main_figures"></mainFigures>
                    <mainFigures [type]="'NEGATIVE'" [text]="'Bounce Rate'" [figure]="BounceRate + '%'"
                        [infoText]="'Percentage of people with no engagement, i.e. who stayed less than 10 seconds and did not click to another page (via menu or CTA).'"
                        class="main_figures"></mainFigures>
                    <mainFigures [type]="'NEGATIVE'" [text]="'Average Time Spent'" [figure]="AverageSpentTime"
                        class="main_figures"></mainFigures>
                </div>
            </div>
            <!-- Chart visits per day -->
            <div *ngIf="DATA_IS_LOADED && !DATA_IS_LOADING" class="align-items-center container_graph_scans">
                <!-- Total Visits  -->
                <div class="graph_filter_container w-100 p-2">
                    <div class="graph_filter scan_filter">
                        <div class="filter_item">
                            <label for="scansPerDayDailyChart">
                                <input class="m-1" id="scansPerDayDailyChart" type="checkbox"
                                    [(ngModel)]="scansPerDayCheckedDailyChart"
                                    (change)="changeDailyChartScanData($event)" />
                                Number of visits / day
                            </label>
                        </div>
                        <div class="filter_item">
                            <label for="cumulScansPeriodDailyChart">
                                <input class="m-1" id="cumulScansPeriodDailyChart" type="checkbox"
                                    [(ngModel)]="cumulScansPeriodCheckedDailyChart"
                                    (change)="changeDailyChartScanData($event)" />
                                Cumulated number of visits over the period
                            </label>
                        </div>
                    </div>
                    <div class="graph_filter cookies_filter">
                        <div class="filter_item">
                            <label for="CookAcceptPerDayDailyChart">
                                <input class="m-1" id="CookAcceptPerDayDailyChart" type="checkbox"
                                    [(ngModel)]="CookAcceptPerDayCheckedDailyChart"
                                    (change)="changeDailyChartScanData($event)" />
                                Number of cookies accepted / day
                            </label>
                        </div>
                        <div class="filter_item">
                            <label for="cumulCookAcceptPeriodDailyChart">
                                <input class="m-1" id="cumulCookAcceptPeriodDailyChart" type="checkbox"
                                    [(ngModel)]="cumulCookAcceptPeriodCheckedDailyChart"
                                    (change)="changeDailyChartScanData($event)" />
                                Cumulated number of cookies accepted over the period
                            </label>
                        </div>
                    </div>
                </div>

                <div class="scan_chart">
                    <canvas id="myDailyScanChart" [hidden]="!DATA_IS_LOADED" class="py-2" height="35%"
                        width="100%"></canvas>
                </div>
            </div>
        </div>
        <div *ngIf="DATA_IS_LOADED && !DATA_IS_LOADING" class="top_qrcode_table_non_active_container mt-3">
            <div class="top_qrcode_table_container">
                <!-- TOP QR CODES GA-->
                <ngx-datatable #table class="bootstrap datatableCustom" [rows]="top10array" [columnMode]="'force'"
                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="35"
                    [sorts]="[{prop: 'metrics.scansTotal', dir: 'desc'}]" [limit]="10">

                    <ngx-datatable-column name="Location level 1 / Location level 2" prop="dimension">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                                <p *ngIf = "row.dimension == 'other / clicks'" matTooltip="These data correspond to clicks that cannot be associated with a QR Code but are part of the campaign">{{row.dimension}}</p>
                                <p *ngIf = "row.dimension != 'other / clicks'">{{row.dimension}}</p>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Total number of visits" prop="metrics.scansTotal.value">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <cellContent [value]="row.metrics.scansTotal.value"
                                [percent]="((row.metrics.scansTotal.value / Number.parseInt(ScansNumber)) * 100).toFixed(2)"></cellContent>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Visits with cookies accepted" prop="metrics.scans.value">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <cellContent [value]="row.metrics.scans.value"
                                [percent]="row.metrics.scans.percent.toFixed(2)"></cellContent>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Unique Users" prop="metrics.uniqueUsers.value">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <cellContent [value]="row.metrics.uniqueUsers.value"
                                [percent]="row.metrics.uniqueUsers.percent.toFixed(2)"></cellContent>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Bounce Rate" prop="metrics.bounceRate.value">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <cellContent [value]="row.metrics.bounceRate.value + '%'"></cellContent>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Average Time Spent" prop="metrics.averageSpentTime.value">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <cellContent [value]="row.metrics.averageSpentTime.value"
                                [percent]="row.metrics.averageSpentTime.percent.toFixed(2)"></cellContent>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="New Users" prop="metrics.newUsers.value">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <cellContent [value]="row.metrics.newUsers.value"
                                [percent]="row.metrics.newUsers.percent.toFixed(2)"></cellContent>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Returning Users" prop="metrics.returningUsers.value">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <cellContent [value]="row.metrics.returningUsers.value"
                                [percent]="row.metrics.returningUsers.percent.toFixed(2)"></cellContent>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-curPage="curPage"
                            let-pageSize="pageSize">
                            <div style="padding: 5px 10px">
                                <div>
                                    Total number of locations: {{ rowCount }}
                                </div>
                            </div>
                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                [page]="curPage" [size]="pageSize" [count]="rowCount"
                                [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
            <!-- Non Active QR Codes -->
            <div class="row non_active_qrcode_container">
                <div class="col-12 col-sm-12 col-lg-8">
                    <mainFigures [type]="'POSITIVE'" [text]="'Non active QR Codes'"
                        [figure]="listOfSourceMediumNotScanned.length"></mainFigures>
                    <div class="table_non_active_qrcode">
                        <div class="header_container">
                            <label class="header_label">Location level 1</label>
                            <label class="header_label">Location level 2</label>
                        </div>
                        <div class="ul_nonActiveCodes"
                            [style.height]="(table.element.getBoundingClientRect().height - 100) < 140 ? '140px' : (table.element.getBoundingClientRect().height - 100) + 'px'">
                            <div class="item_qr_not_scan" *ngFor="let item of listOfSourceMediumNotScanned; let even = even"
                                [ngClass]="{'even-row': even}">
                                <div class="source">{{item.source}}</div>
                                <div class="medium">{{item.medium}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-lg-4 container_funnel">
                    <div class="funnel">
                        <div class='funnel_item item0'>
                            <div class="text_funnel">
                                <div class="label">Total number of visits</div>
                                <div>{{ScansNumber}} Visits</div>
                            </div>
                            <div class="funnel_link link0">
                                <div class="text_link_funnel">
                                    <div class="label">Cookies accepted rate</div>
                                    <div>{{CookiesAcceptedRate}}%</div>
                                </div>
                            </div>
                        </div>
    
                        <div class='funnel_item item1'>
                            <div class="text_funnel">
                                <div class="label">Visits with cookies accepted</div>
                                <div>{{UniqueUsers}} Users - {{TotalCookiesAccepted}} Visits</div>
                            </div>
                            <div class="funnel_link link1">
                                <div class="text_link_funnel">
                                    <div class="label">Engagement Rate</div>
                                    <div>{{RetentionRateLink1}}%</div>
                                </div>
                            </div>
                        </div>
    
                        <div class='funnel_item item2'>
                            <div class="text_funnel">
                                <div class="label">Visits with engagement</div>
                                <div>{{StayingUsers}} Users - {{StayingScans}} Visits</div>
                            </div>
                            <!-- <div class="funnel_link link2">
                                <div class="text_link_funnel">
                                    <div class="label">Click-through rate</div>
                                    <div>{{ (((ClickUniqueEvents) / (UniqueUsers)) * 100).toFixed(1) }}%</div>
                                    <div>{{ConvertionRateLink2}}%</div>
                                </div>
                            </div> -->
                        </div>
    
                        <!-- <div class='funnel_item item3'>
                            <div class="text_funnel">
                                <div class="label">Visits with clicks</div>
                                <div>{{ClickUniqueEvents}} Users - {{ClickTotalEvents}} Clicks</div>
                            </div>
                            <div *ngIf="hasSurveys" class="funnel_link link3">
                                <div class="text_link_funnel">
                                    <div class="label">Conversion Rate</div>
                                    <div>{{ConvertionRateLink3}}%</div>
                                </div>
                            </div>
                        </div> -->
    
                        <!-- <div *ngIf="hasSurveys" class='funnel_item item4'>
                            <div class="text_funnel">
                                <div class="label">Visits with clicks on the CTA</div>
                                <div>{{AnswerTotal}} Answers</div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="DATA_IS_LOADED && !DATA_IS_LOADING" class="row container_chart_event">
            <!-- Multi call to action representation -->
            <div *ngIf="TYPE === 'LOCAL'" class="col-12 col-sm-12 col-lg-4 container_floating_event">
                <div class="d-flex justify-content-center my-3">
                    <select class="form-control" (change)="onChangePagesMultiCallToActionsEvents($event.target.value)">
                        <option [value]="null">Choose a page to edit</option>
                        <option *ngFor="let page of listOfPages; let i = index" [value]="page.key" [selected]="page.key === SITES_METADATA[0]['LANGUAGES'][0]+'_home'">
                            {{page.value}}
                        </option>
                    </select>
                </div>
                <div 
                    class="cta_item floating-button-large"
                    [style.background]="callToActions.color">
                    <p class="floating-button-large-text">{{callToActions.label | uppercase}}</p>
                    <div class="info_bulle">
                        <div class="figures">
                            <div class="figure-container">
                                <span class="text">
                                    CLICKS
                                </span>
                                <span class="figure">
                                    {{callToActions.metrics.totalEvents.value}}
                                    <!-- {{callToActions.metrics.totalEvents.percent.toFixed(2)}} -->
                                </span>
                                <span> | </span>
                                <span class="text">
                                    USERS
                                </span>
                                <span class="figure">
                                    {{callToActions.metrics.uniqueEvents.value}}
                                    <!-- {{callToActions.metrics.uniqueEvents.percent.toFixed(2)}} -->
                                </span>
                                <span> | </span>
                                <span class="text">
                                    RATE
                                </span>
                                <span class="figure">
                                    {{ callToActions.rate.value.toFixed(2) }}%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="callToActions.type === 'MULTI_CTA'">
                    <call-to-actions-reporting [CTA_LIST]="callToActions.ctaList" [SITE_NAME]="SITES_METADATA[0]['SITE']"></call-to-actions-reporting>
                </ng-container>
                <ng-container *ngIf="callToActions.type != 'MULTI_CTA'">
                    <span class="no_data_text">No severall call to actions buttons</span>
                </ng-container>
            </div>

            <!-- Table with events like Google Analytics -->
            <div class="col-12 col-sm-12 col-lg-8 container_floating_event {{(TYPE === 'GLOBAL' || (TYPE === 'LOCAL' && callToActions.type != 'MULTI_CTA')) ? 'container_floating_event_global' : ''}}">
                <div class="top_events_table_container">
                    <ngx-datatable #table class="bootstrap datatableCustom" [rows]="displayedEventsArray"
                        [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="40"
                        [sorts]="[{prop: 'metrics', dir: 'desc'}]">
                        <ngx-datatable-column name="Event" prop="label" [width]="100">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.label}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Clicks" prop="metrics.totalEvents.value">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <cellContent [value]="row.metrics.totalEvents.value"
                                    [percent]="row.metrics.totalEvents.percent.toFixed(2)"></cellContent>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Users" prop="metrics.uniqueEvents.value">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <cellContent [value]="row.metrics.uniqueEvents.value"
                                    [percent]="row.metrics.uniqueEvents.percent.toFixed(2)"></cellContent>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-curPage="curPage"
                                let-pageSize="pageSize">
                                <div style="padding: 5px 10px">
                                    <div>
                                        Total number of events: {{ rowCount }}
                                    </div>
                                </div>
                                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage" [size]="pageSize" [count]="rowCount"
                                    [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                                </datatable-pager>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>

        <div *ngIf="(userToken['cognito:groups'].includes('GlobalAdministrators') || userToken['cognito:groups'].includes('SiteAdministrators')) && DATA_IS_LOADED && !DATA_IS_LOADING"
            class="mt-3 d-flex align-items-center justify-content-center">
            <!-- Table campaign -->
            <div class="container_datatableCampaignCustom">
                <ngx-datatable #table class="bootstrap datatableCampaignCustom mb-3" [rows]="sitesArray"
                    [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="40"
                    [sorts]="[{prop: 'metrics', dir: 'desc'}]" [limit]="5"
                    *ngIf="TYPE === 'GLOBAL' || TYPE === 'ADMIN'">
                    <ngx-datatable-column name="Campaign" prop="dimensions" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.dimensions}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Visits" prop="metrics" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.scans}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Visits with cookies accepted" prop="metrics" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.metrics}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-curPage="curPage"
                            let-pageSize="pageSize">
                            <div style="padding: 5px 10px">
                                <div>
                                    Total number of Campaign: {{ rowCount }}
                                </div>
                            </div>
                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                [page]="curPage" [size]="pageSize" [count]="rowCount"
                                [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </div>
    </ng-container>

    <!-- Display message when errors occured -->
    <div *ngIf="!DATA_IS_LOADED && !DATA_IS_LOADING" class="component text-center">
        <div *ngIf="message_error.length > 0">
            <div *ngIf="message_error.length > 0" class="col col_loading_message">
                <div class="justify-content-center my-5">
                    <div role="group" class="img_elephant">
                        <img src="../../../../../assets/img/Elefight_WithBaby_Text.svg">
                    </div>
                    <ul class="list_loading_message">
                        <li *ngFor="let message of message_error" class="loading_message">{{message}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Display message when data is loading -->
    <div *ngIf="DATA_IS_LOADING" class="component text-center">
        <div *ngIf="DATA_IS_LOADING" class="col col_loading_message">
            <div class="justify-content-center my-5">
                <div role="group" class="img_elephant">
                    <img src="../../../../../assets/img/Elefight_WithBaby_Text.svg">
                </div>
                <span class="loading_message">
                    Data is loading...
                </span>
            </div>
        </div>
    </div>
</div>