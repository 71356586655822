import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/core/classes/Routes';
import { RoutesService } from 'src/app/core/services/routes.service';
import { SitesService } from 'src/app/core/services/sites.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ExportFileService } from 'src/app/core/services/export-file.service';
import { SurveysService } from 'src/app/core/services/surveys.service';
import { HttpclientService } from 'src/app/core/services/httpclient.service';
import { LocationsDependency } from 'src/app/core/helpers/locations-dependency';

@Component({
  selector: 'aac-results',
  templateUrl: './results.component.html',
  styleUrls: [
    './results.component.scss',
    '../survey.scss',
    '../../local-back-office.scss',
    '../../../private.scss'
  ]
})
export class ResultsComponent implements OnInit {

  readonly SELECT_VALUE = LocationsDependency.SELECT_VALUE;
  
  message: string = null;

  bsConfig: Partial<BsDatepickerConfig>;
  maxDate: Date;
  minDate: Date;
  daterangepickerModel: Date[] = [];

  ROUTES : ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null
  };
  SITE: any = {};
  SITE_NAME:string = '';

  SITE_IS_DEACTIVATED: boolean = false;
  DATA_IS_LOADING: boolean = false;

  LOCATIONS = {
    location1: [],
    location2: []
  }

  FORM_ID: string = null;

  searchDate = {
    startDate: '',
    endDate: ''
  };
  searchLocation = {
    location1: '',
    location2: ''
  }

  locations1Links: Map<string, string[]>;
  locations2Links: Map<string, string[]>;

  BACKGROUND_COLOR = [
    '#FF5050',
    '#70FF50',
    '#5072FF',
    "#FAFF50"
  ]

  RESULTS: any;
  RESULTS_EXPORT: any;

  constructor(
    private router: Router,
    private titleService: Title,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _httpClientService: HttpclientService,
    private _surveysService: SurveysService,
    private _exportFileService: ExportFileService
  ) {
    this.titleService.setTitle("Elefight - Result Form");
    
    this.DATA_IS_LOADING = true;
    this.message = "Data is loading...";

    /** Get routes information then get site information*/
    this._routesService.currentRoutes
    .subscribe(routes => {
      this.ROUTES = routes;
      console.log(routes);
      if(routes.PARAMS){
        console.log(routes.PARAMS);
        if(routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        if(routes.PARAMS['id'] != undefined) var id = routes.PARAMS['id'];
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
        (id) ? this.FORM_ID = id : this.FORM_ID = null;
      }
    });

    /** Get information about the site */
    this._sitesService.getSite(this.SITE_NAME).then((res) => {
      this.SITE = JSON.parse(JSON.stringify(res));
      console.log(this.SITE);

      //Check if site exist and if it's activated
      if (Object.keys(this.SITE).length <= 0 || this.SITE.OPTIONS.ACTIVATED == 'false') {
        console.error("Site doesn't exist or has been deactivated");
        this.message = "This site/page is no longer  \n available or has been deactivated";
        this.SITE_IS_DEACTIVATED = true;
        return;
      }

      /** Get list of QR Codes */
      this._httpClientService.getQRCodes(this.SITE_NAME).then((response) => {
        console.log(`QR Code for ${this.SITE_NAME}:`, response);
        
        /** We store Location1 and Location2 values for dropdowns select */
        this.locations1Links = LocationsDependency.locationLinks(response['Items'], LocationsDependency.location1Reducer, 'RESULTS');
        this.locations2Links = LocationsDependency.locationLinks(response['Items'], LocationsDependency.location2Reducer, 'RESULTS');

        this.LOCATIONS.location1 = this.locations2Links.get(this.SELECT_VALUE);
        this.LOCATIONS.location2 = this.locations1Links.get(this.SELECT_VALUE);
      })
    });

    /** Configuraton for calendar */
    this.bsConfig = {
      useUtc: true,
      adaptivePosition: true,
      isAnimated: true,
      containerClass: 'theme-sanofi',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.maxDate = this.upperLimitDate();
    //Temporary add min Date. 2 different years doesn't work in lambda
    this.minDate = new Date(1609495200000);
  }

  ngOnInit(): void {
    /** Settings of dates for calendar and API */
    this.daterangepickerModel.push(new Date(new Date().setDate(new Date().getDate()-30))); //We set startDate at (currentDate - 30 days)
    this.daterangepickerModel[0].setUTCHours(0,0,0,0); //We set the startDate to midnight in order to have all result of the day
    this.daterangepickerModel.push(this.maxDate); //We set endDate at the maxDate (current day)
    this.searchDate.startDate = this.toUtc(this.daterangepickerModel[0]).toString();
    this.searchDate.endDate = this.plusOneDay(this.toUtc(this.daterangepickerModel[1])).toString();
    this.getAnalysis();
  }

  /** Get anlysis values */
  getAnalysis(){
    //Setting of queryParams value for API
    var queryParams = [
      {
        label: 'startDate',
        value: this.searchDate.startDate
      },
      {
        label: 'endDate',
        value: this.searchDate.endDate
      }
    ];
    if (this.searchLocation.location1 != null) {
      queryParams.push({
        label: 'location1',
        value: this.searchLocation.location1
      });
    }
    if (this.searchLocation.location2 != null) {
      queryParams.push({
        label: 'location2',
        value: this.searchLocation.location2
      });
    }

    let promiseAnalysis = [];
    promiseAnalysis.push(this._surveysService.getAnalysis(this.SITE_NAME, this.FORM_ID, queryParams)); //Call API to get analysis values
    promiseAnalysis.push(this._surveysService.getExport(this.SITE_NAME, this.FORM_ID)); //Call API to get analysis value for export

    Promise.all(promiseAnalysis).then(arrayResultsAnalysis => {
      console.log(arrayResultsAnalysis);
      if(arrayResultsAnalysis[0]){
        this.RESULTS = JSON.parse(JSON.stringify(arrayResultsAnalysis[0]));
        //Check if each questions has at least one answer or not
        for(let i=0; i<this.RESULTS['QUESTIONS'].length; i++){
          const found = this.RESULTS['QUESTIONS'][i]['ANSWERS_BY_OPTION'].some(el => el['NB_ANSWERS'] > 0)
          if (found) this.RESULTS['QUESTIONS'][i]['HAS_ANSWER'] = true;
          else this.RESULTS['QUESTIONS'][i]['HAS_ANSWER'] = false;
        }
      }
      if(arrayResultsAnalysis[1]){
        this.RESULTS_EXPORT = JSON.parse(JSON.stringify(arrayResultsAnalysis[1]));
      }
      this.DATA_IS_LOADING = false;
    })
  }

  /** Listener executed when the date is change on calendar */
  changeDateRange(){
    this.searchDate.startDate = this.toUtc(this.daterangepickerModel[0]).toString();
    this.searchDate.endDate = this.plusOneDay(this.toUtc(this.daterangepickerModel[1])).toString();
    this.getAnalysis();
  }

  /**
   * Listener executed when a value is changed in dropdown Location1 and Location2
   * @param value new value of the dropdown
   * @param drilldown ID of the dropdown
   */
  changeSearchDrilldown(value, drilldown){
    console.debug(`Value ${value} chosen for drilldown ${drilldown}`);
    this.DATA_IS_LOADING = false;
    this.searchLocation[drilldown] = (value === this.SELECT_VALUE ? null : value);

    if (drilldown === 'location1') {
      this.LOCATIONS.location2 = this.locations1Links.get(value);
    }
    if (drilldown === 'location2') {
      this.LOCATIONS.location1 = this.locations2Links.get(value);
    }

    this.getAnalysis();
  }

  /** Export results as excel */
  exportFile(){
    console.log(this.RESULTS);
    this._exportFileService.exportAsExcelFile(this.RESULTS_EXPORT, this.SITE_NAME);
  }

  /**
   * Navigate through component
   * @param route route to reach
   * @param id id of the form (not mandatory)
   */
  navigate(route:string, id?: number){
    if(id) var route = `/admin/${this.SITE_NAME}/${route}/${id}`;
    else var route = `/admin/${this.SITE_NAME}/${route}`;
    console.log(route);
    this.router.navigate([route]);
  }

  /**
   * Get value of the day to set limit on calendar
   * @returns return value of the date
   */
  private upperLimitDate(): Date {
    let current = new Date();
    current.setUTCHours(0, 0, 0, 0);

    return current;
  }

  /**
   * Convert date to UTC date
   * @param date date we convert
   * @returns date converted in UTC
   */
  private toUtc(date: Date): number {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).getTime();
  }

  /**
   * Add one day to a date
   * @param date date we convert
   * @returns date plus on day
   */
  private plusOneDay(date: number): number {
    return date + 24* 60 * 60 * 1000
  }
}