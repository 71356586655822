import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { RoutesService } from 'src/app/core/services/routes.service';
import { ROUTES } from 'src/app/core/classes/Routes';
import { SitesService } from 'src/app/core/services/sites.service';

@Component({
  selector: 'LocalNavBar',
  templateUrl: './localnavbar.component.html',
  styleUrls: ['./localnavbar.component.scss']
})
export class LocalNavbarComponent implements OnInit {

  @Input() COMPONENT: string;

  SITE_NAME:string = '';
  SITE_ACTIVATED: boolean;
  IS_LOADED: boolean = false;
  ROUTES : ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null
  };

  constructor(
    private router: Router,
    private _routesService: RoutesService,
    private _sitesService: SitesService
  ) {
    /** Get routes information then get site information*/
    this._routesService.currentRoutes.subscribe(routes => {
      this.ROUTES = routes;
      if(routes.PARAMS){
        if(routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        //Get the Site Name
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
        if(this.SITE_NAME) {
          //Get the Site Activated value
          this._sitesService.getSite(this.SITE_NAME).then( (site) => {
            if(Object.keys(site).length > 0) this.SITE_ACTIVATED = site["OPTIONS"].ACTIVATED === "true" ? true : false;
            this.IS_LOADED = true;
          });
        }
      }
    });
  }

  ngOnInit(): void { }

  /**Function to navigate through components */
  navigate(route:string){
    this.router.navigate([`/admin/${this.SITE_NAME}/${route}`]);
  }

  signOut(){
    Auth.signOut();
  }
}
