import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/core/classes/Routes';
import { RoutesService } from 'src/app/core/services/routes.service';
import { SitesService } from 'src/app/core/services/sites.service';
import { FileManagementService } from 'src/app/core/services/file-management.service';
import { SurveysService } from 'src/app/core/services/surveys.service';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { extensionFile } from '../../../../core/validators/extensionFile';
import { maximumFileSize } from '../../../../core/validators/maximumFileSize';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EDITOR_CONFIG from "../../../../../assets/constants/editorConfigSurvey";
import { fileName } from 'src/app/core/validators/fileName';
import {listOfImagesExtensions} from 'src/app/core/classes/ImagesExtensions';
import { generateUUID } from 'src/app/core/helpers/generate-uuid';
@Component({
  selector: 'aac-create',
  templateUrl: './create.component.html',
  styleUrls: [
    './create.component.scss',
    '../survey.scss',
    '../../local-back-office.scss',
    '../../../private.scss'
  ]
})
export class CreateComponent implements OnInit {

  message: string = null;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null
  };

  ROUTES : ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null
  };
  SITE: any = {};
  SITE_NAME:string = '';

  SITE_IS_DEACTIVATED: boolean = false;
  DATA_IS_LOADING: boolean = false;

  newForm: FormGroup;
  submitted: boolean = false;
  isActivatedChecked: boolean;

  imageURL: string;
  file_to_display: Array<String> = [];
  file_to_export: Array<File> = [];

  public Editor = ClassicEditor;
  public editorConfig;

  constructor(
    private router: Router,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _surveysService: SurveysService,
    private _fileManagement: FileManagementService
  ) {
    this.titleService.setTitle("Elefight - Modify Form");
    this.editorConfig = EDITOR_CONFIG;
    
    /** Get routes information then get site information*/
    this._routesService.currentRoutes
    .subscribe(routes => {
      this.ROUTES = routes;
      console.log(routes);
      if(routes.PARAMS){
        console.log(routes.PARAMS);
        if(routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
      }
    });

    this._sitesService.getSite(this.SITE_NAME)
      .then(res => {
        this.SITE = JSON.parse(JSON.stringify(res));
        console.log(this.SITE);

        //Check if site exist and if it's activated
        if (Object.keys(this.SITE).length <= 0 || this.SITE.OPTIONS.ACTIVATED == 'false') {
          console.error("Site doesn't exist or has been deactivated");
          this.message = "This site/page is no longer  \n available or has been deactivated";
          this.SITE_IS_DEACTIVATED = true;
          return;
        }

      })
      .catch(err => this.callbackHTTPClient(err));
  }

  ngOnInit(): void {
    this.initForms();
  }

  /** Init different forms */
  initForms(){
    this.newForm = this.formBuilder.group({
      uuid: [generateUUID(), [Validators.required]],
      site: [this.SITE_NAME, [Validators.required]],
      title: ['', [Validators.required]],
      description: [''],
      end_message: ['', [Validators.required]],
      creation_date: [new Date().getTime(), [Validators.required]],
      questions: new FormArray([])
    });
    this.addQuestion();
  }

  get nF() { return this.newForm.controls; }

  /** ---- Question Management ---- */
  questions(): FormArray { return this.newForm.get("questions") as FormArray }
  addQuestion() { 
    const newQuestion: FormGroup = this.formBuilder.group({
      uuid: [generateUUID(), [Validators.required]],
      title: ['', [Validators.required]],
      image_file: ['', [maximumFileSize(`image_file_${this.questions().length}`, 2000000), extensionFile(`image_file_${this.questions().length}`, listOfImagesExtensions),fileName(`image_file_${this.questions().length}`)]],
      image: [''],
      status: ['ACTIVATED', [Validators.required]],
      status_toogle: [true, [Validators.required]],
      type: [null, [Validators.required]],
      answers_options: new FormArray([])
    });
    this.questions().push(newQuestion);
    this.addOption(this.questions().length - 1);
  }
  removeQuestion(i:number) {
    this.questions().removeAt(i);
  }
  imageFileAt(i: number): FormControl {
    return (this.questions().at(i) as FormGroup).controls.image_file as FormControl;
  }
  /** ---- Question Management ---- */
  /** ---- Options Management ---- */
  options(index: number): FormArray { return this.newForm.get("questions")['controls'][index].get("answers_options") as FormArray }
  addOption(index: number) {
    const newOption: FormGroup = this.formBuilder.group({
      uuid: [generateUUID(), [Validators.required]],
      label: ['', [Validators.required]]
    });
    this.options(index).push(newOption);
  }
  removeOption(index:number, i:number) {
    this.options(index).removeAt(i);
  }
  /** ---- Options Management ---- */

  /** onSubmit function */
  onSubmit() {
    this.submitted = true;
    console.log(this.newForm);

    // stop here if form is invalid
    if (this.newForm.invalid) {
      return;
    }

    
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "save";
    this.ALERT_MESSAGE.MESSAGE = 
    `<p>Are you sure you want to create this form ?</p><br/>`;


    
    console.log("Form is valid : ", this.newForm.value);
  }

  /** Callback to send value of form to API */
  callbackActionModal_save(){
    while(this.ALERT_MESSAGE.IS_DISPLAYED)
      this.callbackResetModal(this.ALERT_MESSAGE);

    //Set the variable submitted to true
    this.submitted = true;

    var newFormValue = this.newForm.value;
    console.log(this.newForm);
    console.log(newFormValue);

    //Set the variable submitted to true
    this.submitted = true;

    // stop here if form is invalid
    if (this.newForm.invalid) {
      return;
    }

    //We replace " characters by &quot;
    newFormValue.description = newFormValue.description.replace(/[\\"]/g, '&quot;').replace(/\u0000/g, '\\0');
    //We replace " characters by &quot;
    newFormValue.end_message = newFormValue.end_message.replace(/[\\"]/g, '&quot;').replace(/\u0000/g, '\\0');

    console.log("files to export :",this.file_to_export);
    //For each file in file_to_export, we upload the file in S3 bucket
    for(let i=0; i < this.file_to_export.length ; i++){
      if(this.file_to_export[i] != undefined){
        let extension = this.file_to_export[i].name.substring(this.file_to_export[i].name.lastIndexOf('.') + 1);
        this._fileManagement.uploadFile(this.file_to_export[i], null, extension, this.SITE_NAME,  this.file_to_export[i].name).then(result => {
          console.log(result);
        })
      }
    }

    //For each question we get value of Switch (boolean) and write ACTIVATED or DEACTIVATED instead
    for(let i=0; i<newFormValue.questions.length; i++){
      newFormValue.questions[i]['status_toogle'] ? newFormValue.questions[i]['status'] = "ACTIVATED" : newFormValue.questions[i]['status'] = "DEACTIVATED";
      console.log(newFormValue.questions[i]);
    }

    //We remove fields not necessary in API body
    delete newFormValue.creation_date;
    delete newFormValue.site;

    console.log(JSON.parse(JSON.stringify(newFormValue)));

    console.log('Creation of form starting');
    //We post form with body by calling API
    this._surveysService.postForm(this.SITE_NAME, newFormValue)
      .then(() => {
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
        this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
        this.ALERT_MESSAGE.MESSAGE = `Form generated successfully`;
        this.submitted = false;
      })
      .catch(err => this.callbackHTTPClient(err));
  }

  /**
   * move a question from a position on array to another
   * @param from position of object in array
   * @param to destination of object in array
   */
  moveItem(from, to) {
    var f = this.questions().controls.splice(from, 1)[0];
    this.questions().controls.splice(to, 0, f);

    //switch files
    var file1=this.file_to_display[from];
    this.file_to_display[from]=this.file_to_display[to];
    this.file_to_display[to]=file1; 
  }

  /**
   * event when a new file is put in input type file
   * @param event The file
   * @param index Index of the question
   */
  onFileChange(event, index) {
    if (event.target.files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.questions().at(index).patchValue({
        image: file.name
      });
      this.file_to_export[index] = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.file_to_display[index] = reader.result as string;
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Navigate through component
   * @param route route to reach
   * @param id id of the form (not mandatory)
   */
  navigate(route:string, id?: number){
    if(id) var route = `/admin/${this.SITE_NAME}/${route}/${id}`;
    else var route = `/admin/${this.SITE_NAME}/${route}`;
    console.log(route);
    this.router.navigate([route]);
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(error: string) {
    console.log(error);
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.MESSAGE = error['message'];
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE){
    if(ALERT_MESSAGE.TYPE_OF_MESSAGE === 'successModal'){
      this.navigate('survey');
    }
    this.resetModal();
  }

  resetModal(){
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }
}