import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QRCodePasswordService } from 'src/app/core/services/qrcode-password.service';

@Component({
    selector: 'password-modal',
    templateUrl: './password-modal.component.html',
    styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent implements OnInit {

    form: FormGroup;
    hidePassword: boolean = true;
    title: string;
    content: string;
    key: string;
    wrongPassword: boolean = false;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<PasswordModalComponent>,
        private _qrcodePasswordService: QRCodePasswordService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.title = data.title;
        this.content = data.content;
        this.key = data.key;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            password: ["", []]
        });
    }

    login() {
        this._qrcodePasswordService.validatePassword(this.form.value.password, this.key).then(res => {
            if (res) this.dialogRef.close({ "status": "LOGGEDIN" });
            else this.wrongPassword = true
        })
    }
}