import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/core/services/routes.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ALERT_MESSAGE } from 'src/app/core/classes/AlertMessage';
import { ROUTES } from 'src/app/core/classes/Routes';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs-compat/Observable';
import { SitesService } from 'src/app/core/services/sites.service';
import { map, startWith } from 'rxjs/operators';
import { SITE } from 'src/app/core/classes/Site';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Title } from '@angular/platform-browser';
import Auth from '@aws-amplify/auth';

@Component({
  selector: 'aac-user-modification',
  templateUrl: './user-modification.component.html',
  styleUrls: [
    './user-modification.component.scss',
    '../users-generator.scss',
    '../../global-back-office.scss',
    '../../../private.scss'
  ]
})
export class UserModificationComponent implements OnInit {

  DATA_IS_LOADED: boolean = false;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  siteCtrl = new FormControl();
  filteredSites: Observable<string[]>;
  sites: string[] = [];
  allSites: string[] = [];

  username: string;
  user: any = {
    username: '',
    email: '',
    group: '',
    sites: []
  };

  ROUTES: ROUTES;

  groups = [
    {
      "Label": "Site Admin",
      "Value": "SiteAdministrators"
    },
    {
      "Label": "Global Admin",
      "Value": "GlobalAdministrators"
    }
  ]

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null
  };

  inputType: string = "password"

  @ViewChild('siteInput') siteInput: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private titleService: Title,
    private _routesService: RoutesService,
    private _usersService: UsersService,
    private _sitesService: SitesService
  ) {
    this.titleService.setTitle("Elefight - User Management");
    this._routesService.currentRoutes.subscribe(routes => {
      this.ROUTES = routes;
      console.log(routes);
      if (routes.PARAMS) {
        console.log(routes.PARAMS);
        if (routes.PARAMS['user'] != undefined) var user = routes.PARAMS['user'];
        (user) ? this.username = user : this.username = '';
      }
    });
    this._sitesService.getSites().then((value) => {
      this.allSites = value['sites'].map((site: SITE) => site.SITE);
      //We remove sites ending by "-PREVIEW"
      this.allSites = this.allSites.filter((item) => {
        return !item.includes("-PREVIEW");
      });
    });
    this.filteredSites = this.siteCtrl.valueChanges.pipe(
      startWith(null),
      map((site: string | null) => (site ? this._filter(site) : this.allSites.slice())),
    );
  }

  ngOnInit(): void {
    this.DATA_IS_LOADED = false;
    this.getUser();
  }

  add(event: MatChipInputEvent, inputName): void {
    const value = (event.value || '').trim();

    // Add our site
    if (value) this.sites.push(value);

    // Clear the input value
    inputName.value = "";
    this.siteCtrl.setValue(null);
  }

  remove(site: string): void {
    const index = this.sites.indexOf(site);
    if (index >= 0) this.sites.splice(index, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.sites.push(event.option.viewValue);
    this.siteInput.nativeElement.value = '';
    this.siteCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allSites.filter(site => site.toLowerCase().includes(filterValue));
  }

  getUser() {
    this._usersService.getUser(this.username).then((user: any) => {
      this.user = JSON.parse(JSON.stringify(user));
      if (user.sites?.length > 0) {
        this.sites = [...user.sites.split(',')];
        this.user.sites = this.user.sites.split(',');
      } else this.user.sites = [];
      this.DATA_IS_LOADED = true;
    });
  }

  navigate(route: string, username?: string) {
    if (username) this.router.navigate([`/admin/${route}/${username}`])
    else this.router.navigate([`/admin/${route}`]);
  }

  /**
   * Remove current user
   * @param user Name of the user
   */
  removeUser() {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "remove";
    this.ALERT_MESSAGE.MESSAGE =
      `<p>Are you sure you want to remove user ${this.username} ?</p>`;
  }

  /**
   * Callback of removeUser() action modal
   */
  callbackActionModal_remove() {
    this._usersService.deleteUser(this.user.username).then((message) => {
      console.log(message);
      console.log("Delteing " + this.user.username);
      this.callbackResetModal(this.ALERT_MESSAGE);
      this.navigate("users-management/list-users");
    });
  }

  modifyUser() {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "modifyUser";
    this.ALERT_MESSAGE.MESSAGE =
      `<p>Are you sure you want to modify user ${this.username} ?</p>`;
  }

  callbackActionModal_modifyUser() {
    let body = {
      username: this.user.username,
      email: this.user.email,
      group: this.user.group,
      sites: this.sites.map(site => site.toUpperCase())
    }

    console.log(body);

    this._usersService.modifyUser(body).then(message => {
      console.log(message);
      this.DATA_IS_LOADED = false;
      this.callbackResetModal(this.ALERT_MESSAGE);
      this.getUser();
    }).catch(e => console.error(e)).then(e => console.log("OK"))

    this.navigate("users-management/user-modification", body.username)
  }

  passwordForget() {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "resetPassword";
    this.ALERT_MESSAGE.MESSAGE =
      `<p>Change the password for ${this.username} ?</p>
      <p>(this will generate a new temporary password)</p>`;
  }

  callbackActionModal_resetPassword() {
    //generate the temp password
    var randomstring = Math.random().toString(36).slice(-8);
    this.callbackResetModal(this.ALERT_MESSAGE);

    // this.DATA_IS_LOADED = false;
    this._usersService.passwordForget(this.username, randomstring).then(message => {
      console.log(message);
      this.displayPassword(randomstring);
      this.getUser();
    });
  }

  //informative Modal to show the password
  displayPassword(pass: string) {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "displaypassword";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = pass;
  }

  resendTemporaryPassword() {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "resendTemporaryPassword";
    this.ALERT_MESSAGE.MESSAGE =
      `<p>Resend the temporary password for ${this.username} ?</p>`;
  }

  callbackActionModal_resendTemporaryPassword() {
    console.log("callbackActionModal_resendTemporaryPassword");
    this._usersService.resendTemporaryPassword(this.username).then((value) => {
      console.log(value);
      this.callbackResetModal(this.ALERT_MESSAGE);
    });
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  signOut() {
    Auth.signOut();
  }
}