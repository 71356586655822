import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileManagementService } from 'src/app/core/services/file-management.service';
import { RoutesService } from 'src/app/core/services/routes.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'form-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
    './dashboard.component.scss',
    '../content-management.scss',
    '../../local-back-office.scss',
    '../../../private.scss'
  ]
})
export class DashboardComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;

  SITE_NAME: string = '';

  bsInlineRangeValue: Date[];
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private _fileManagement:FileManagementService,
    private _routesService: RoutesService,
    private _localeService: BsLocaleService,
  ) {
    /** Get routes information then get site information*/
    this._routesService.currentRoutes
    .subscribe(routes => {
      console.log(routes);
      if(routes.PARAMS){
        console.log(routes.PARAMS);
        if(routes.PARAMS['site'] != undefined) var site = routes.PARAMS['site'];
        (site) ? this.SITE_NAME = site.toUpperCase() : this.SITE_NAME = '';
      }
    });
    //Configuration for calendar
    this.bsConfig = Object.assign({}, {
      adaptivePosition: true,
      isAnimated: true,
      containerClass: 'theme-sanofi',
      dateInputFormat: 'DD/MM/YYYY'
    });
    this._localeService.use('fr');
  }

  ngOnInit(): void {
    this.bsInlineRangeValue = [this.parentForm.value.campaignDate_04_primary[0], this.parentForm.value.campaignDate_04_primary[1]];
    if (!this.parentForm.value.mapData_04_filename_primary) {
      this.parentForm.controls.mapData_04_filename_primary.setValue('Choose file');
    }
  }
  
  /**
   * on file change set value of the main Reactive Form
   * @param $event files send
   */
  onFileChange($event){
    this.parentForm.controls.mapData_04_filename_primary.setValue($event.target.files.item(0).name);
  }

  /**
   * When sending form, get and send files to S3 bucket
   * @param SITE_NAME name of the site
   * @returns returns a promise
   */
  getAndSendFiles(SITE_NAME: string){
    return new Promise(resolve => {
      let promises = [];

      if((<HTMLInputElement>document.getElementById('mapData_04_primary')).files[0])
        promises.push(this._fileManagement.uploadFile((<HTMLInputElement>document.getElementById('mapData_04_primary')).files[0], this.language, 'csv', SITE_NAME, `Real_Data_2020_France.csv`));
  
      Promise.all(promises).then((res) => {
        console.info(res);
        resolve(res);
      });
    });
  }

  /**
   * Download file
   * @param type type of the file
   */
  downloadFile(type){
    this._fileManagement.getDashboardData(this.SITE_NAME.toUpperCase());
  }
}