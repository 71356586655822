<div class="main">
    <!-- Navigation -->
    <LocalNavBar [COMPONENT]="'FormsComponent'"></LocalNavBar>

    <div *ngIf="DATA_IS_LOADING || SITE_IS_DEACTIVATED; else elseBlock" class="component text-center my-5">
        <Message [MESSAGE]="message"></Message>
    </div>

    <ng-template #elseBlock>
        <div class="container mt-4">
            <!-- Button to create new form -->
            <div role="group">
                <button type="button" class="btn btn-global-type btn-type3 d-flex align-items-center" (click)="navigate('survey/create')">
                    <span class="material-icons icons_button">
                        add_circle
                    </span>
                    <span class="ml-2">Create new form</span>
                </button>
            </div>
    
            <div *ngIf="!(SITE_FORMS.length > 0)" class="text-center my-5" >
                <Message MESSAGE="No form found"></Message>
            </div>

            <ng-container *ngIf="SITE_FORMS.length > 0">
                <!-- Title MyForms-->
                <div class="mt-3 d-flex align-items-end border-bottom">
                    <div class="bd-highlight">
                        <h2 class="main_title">My Forms</h2>
                    </div>
                    <div class="ml-auto bd-highlight">
                        <span>{{SITE_FORMS.length}} form<span *ngIf="SITE_FORMS.length > 1">s</span> created / {{numberMaxOfForms}}</span>
                    </div>
                </div>
        
                <div *ngIf="SITE_FORMS.length > 0">
                    <div *ngFor="let FORM of SITE_FORMS" class="my_form d-flex bd-highlight mb-3">
                        <!-- Form title -->
                        <div class="p-2 ml-5 bd-highlight d-flex align-items-center">
                            <span class="form_title  truncate" tooltip="{{FORM.TITLE}}" placement="left">
                                {{FORM.TITLE}}
                            </span>     
                        </div>
                        <!-- Creation date - Number of results -->
                        <div class="p-2 bd-highlight d-flex align-items-center">
                            <span class="form_information">created on {{ FORM.CREATION_DATE | date:'shortDate' }} - {{FORM.ANSWERS_COUNT}} answer<span *ngIf="FORM.ANSWERS_COUNT > 1">s</span></span>
                        </div>
                        <!-- Action button -->
                        <div class="ml-auto p-2 mr-2 bd-highlight d-flex align-items-center">
                            <div class="row bottom_row_buttons">
                                <div class="btn-toolbar ml-1" role="toolbar">
                                    <!-- Edit Form -->
                                    <div class="btn-group" role="group">
                                        <button type="button" class="btn" (click)="navigate('survey/modify', FORM.UUID)"><span class="material-icons blue">edit</span></button>
                                    </div>
                                    <!-- Remove Form -->
                                    <div class="btn-group" role="group">
                                        <button type="button" class="btn" (click)="removeForm(FORM.UUID)"><span class="material-icons blue">delete</span></button>
                                    </div>
                                    <!-- Analyse Form -->
                                    <div class="btn-group" role="group">
                                        <button type="button" class="btn" (click)="navigate('survey/results', FORM.UUID)"><span class="material-icons blue">analytics</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </ng-template>

</div>

<alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage>