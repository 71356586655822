<form [formGroup]="parentForm">
    <div [formGroupName]="'pages_' + type">
        <div [formGroupName]="id">
            <div formArrayName="pageCompositeFields" class="form-group">
                    <span *ngFor="let field of pageCompositeFields(id).controls; let i=index" [formGroupName]="i" class="col-12">
                        <div class="row my-2">
                            <p> <span class="labelLike">Image {{i+1}} </span> <span class="aspect-indication">(Aspect Ratio 1:1 - Size Max. 2MB)</span> </p>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-6 px-0">
                                <input type="file" class="custom-file-input" accept="image/*" [id]="'arrayPageCompositeFields_'+id+'_formCompositeField_imagePath_'+i" formControlName="imagePath" (change)="onChangeFile($event, i)">
                                <label class="custom-file-label" for="imagePath">Upload</label>
                                <div *ngIf="field.get('imagePath').invalid" class="alert alert-danger">
                                    <div *ngIf="field.get('imagePath').errors.invalidFileSize">
                                        This file is too large. It must be less than 2MB.
                                    </div>
                                    <div *ngIf="field.get('imagePath').errors.extension">
                                        File format not allowed. It must be an image.
                                    </div>
                                    <div *ngIf="field.get('imagePath').errors.unauthorizedFileName">
                                        File name not allowed. It must not contains any special characters other than "-" and "_" .
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex align-items-center">
                                <p *ngIf="field.value.imagePath; else no_value_image" class="labelLike">{{field.value.imagePath}}</p>
                                <ng-template #no_value_image>
                                    <p class="labelLike">No file</p>
                                </ng-template>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11 px-0">
                                <div class="form-group">
                                    <p class="labelLike">Text {{i+1}}</p>
                                    <ckeditor [editor]="Editor" [config]="editorConfig" id="text{{i}}" formControlName="text"></ckeditor>
                                </div>
                            </div>
                            <div class="col-md-1 align-self-center">
                                <span class="material-icons red clickable" (click)="removeLine(id, i)">
                                    remove_circle
                                </span>
                            </div>
                        </div>
                    </span>
                    <div class="d-flex justify-content-center">
                        <div class="col-6 justify-content-center add_line">
                            <hr />
                            <span class="material-icons blue add_line_button clickable" (click)="addLine(id, default_line)">
                                add_circle
                            </span>
                            <hr />
                        </div>
                    </div>
                </div>
        </div>
    </div>
</form>