<div class="main">
    <LocalNavBar [COMPONENT]="'FormsComponent'"></LocalNavBar>

    <div *ngIf="SITE_IS_DEACTIVATED || DATA_IS_LOADING; else elseBlock" class="component text-center my-5">
        <Message [MESSAGE]="message"></Message>
    </div>

    <ng-template #elseBlock>
        <div class="container mt-4" style="width: 75vw;">
            <!-- Form title -->
            <div class="d-flex border-bottom" style="align-items: flex-end;">
                <div class="mr-1">
                    <h2 class="mt-3 mb-0 form_title">{{RESULTS.FORM_NAME}}</h2>
                </div>
                <div class="ml-auto">
                    <span class="number_answers">{{RESULTS.PARTIAL_ANSWERS}}/{{RESULTS.TOTAL_ANSWERS}}</span>
                </div>
            </div>

            <!-- Filters -->
            <div class="d-flex mt-3">
                <div class="d-flex align-items-center">
                    <div class="row d-flex justify-content-start">
                        <div class="d-flex flex-column  mb-3 p-2">
                            <input type="text"
                                placeholder="Select a date range"
                                class="form-control"
                                bsDaterangepicker
                                [bsConfig]="bsConfig"
                                [maxDate]="maxDate"
                                [minDate]="minDate"
                                [(ngModel)]="daterangepickerModel"
                                (ngModelChange)="changeDateRange()"
                                style="height: 100%;"
                            >
                        </div>
                        <div class="d-flex flex-column mb-3">
                            <div class="p-2 ">
                                <select *ngIf="LOCATIONS.location1.length > 0" class="form-control" (change)="changeSearchDrilldown($event.target.value, 'location1')" placeholder="Select a location1">
                                    <option *ngFor="let source of LOCATIONS.location1" value="{{source}}">
                                        {{source === SELECT_VALUE ? 'Select a location level 1' : source}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex flex-column mb-3">
                            <div class="p-2 ">
                                <select *ngIf="LOCATIONS.location2.length > 0" class="form-control" (change)="changeSearchDrilldown($event.target.value, 'location2')" placeholder="Select a location2">
                                    <option *ngFor="let medium of LOCATIONS.location2" value="{{medium}}">
                                        {{medium === SELECT_VALUE ? 'Select a location level 2' : medium}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto mt-2 d-flex align-items-baseline">
                    <button type="button" class="btn btn-global-type btn-type2 d-flex align-items-center" (click)="exportFile()">
                        Export results
                        <span class="material-icons icons_button ml-1">
                            download
                        </span>
                    </button>
                </div>
            </div>
            <!-- Questions -->
            <div *ngIf="!DATA_IS_LOADING">
                <div *ngFor="let QUESTION of RESULTS.QUESTIONS; let index=index" class="mt-4">
                    <div class="d-flex ">
                        <div class=" d-flex align-items-center">
                            <span *ngIf="QUESTION.STATUS === 'ACTIVATED'" class="question_title mb-2">Question {{index+1}} - {{QUESTION.TITLE}}</span>
                            <div *ngIf="QUESTION.STATUS === 'DEACTIVATED'">
                                <div class="question_title">Question {{index+1}} - {{QUESTION.TITLE}}</div>
                                <div class="mb-2">DEACTIVATED</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="QUESTION.HAS_ANSWER; else no_answer_question" class="row">
                        <!-- Legend -->
                        <div class="col-3 d-flex align-items-center">
                            <results-legend [DATA]="QUESTION.ANSWERS_BY_OPTION"></results-legend>
                        </div>
                        <!-- Chart -->
                        <div class="col-5 d-flex align-items-center justify-content-center">
                            <results-chart style="width: 100%;" [RECEIVE]="QUESTION.ANSWERS_BY_WEEK" [INDEX]="index"></results-chart>
                        </div>
                        <!-- Table -->
                        <div class="col-4 d-flex align-items-center">
                            <results-table [DATA]="QUESTION.ANSWERS_BY_WEEK"></results-table>
                        </div>
                    </div>
                    <ng-template #no_answer_question>
                        <div class="row">
                            <span>
                                This question has no answer
                            </span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-template>

</div>

<!-- <alertmessage *ngIf="ALERT_MESSAGE.IS_DISPLAYED" [ALERT_MESSAGE]="ALERT_MESSAGE" [callback]="this"></alertmessage> -->