import { Component, OnDestroy, OnInit } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { RoutesService } from "src/app/core/services/routes.service";
import { GoogleAnalyticsService } from "src/app/core/services/google-analytics.service";
import { Subscription } from "rxjs-compat/Subscription";
import { ROUTES } from "src/app/core/classes/Routes";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { Router } from "@angular/router";
import { listOfImagesExtensions } from "src/app/core/classes/ImagesExtensions";
import { SitesService } from "src/app/core/services/sites.service";

@Component({
  selector: "aac-main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
})
export class MainPageComponent implements OnInit, OnDestroy {
  inputPath: string = environment.inputPath;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };

  SITE: any = {};
  SITE_NAME: string = "";
  PAGE_CONTENT: any;

  CTA_LABEL: string = "";
  CTA_TYPE: string = "";
  CTA_LINK: string = "";
  CTA_LIST = [];
  currentLanguage: string = "";
  colorHeader: any;

  isLoaded: boolean = false;
  errorMessage: string = null;

  private currentRoutesSubscribe: Subscription;
  private currentRoutesSubscribeOnLangChange: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    //Get routes objects in RoutesService
    this.currentRoutesSubscribe = this._routesService.currentRoutes.subscribe(
      (routes) => {
        this.ROUTES = routes;
        if (routes.PARAMS) {
          if (routes.PARAMS["site"] != undefined)
            var site = routes.PARAMS["site"];
          site ? (this.SITE_NAME = site.toUpperCase()) : (this.SITE_NAME = "");
          this.getTranslation();
        }
        this.currentRoutesSubscribeOnLangChange =
          this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslation();
          });
        this._sitesService.getSite(this.SITE_NAME).then((res) => {
          this.SITE = JSON.parse(JSON.stringify(res));
        });
      }
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    console.info("ngOnDestroy main-page is executing...");
    this.currentRoutesSubscribe.unsubscribe();
    this.currentRoutesSubscribeOnLangChange.unsubscribe();
  }

  getTranslation() {
    this.translate
      .get("language")
      .subscribe((val) => (this.currentLanguage = val));
    let promisesTranslate = [];
    promisesTranslate.push(this.translate.get("pages").toPromise()); //Get pages array

    Promise.all(promisesTranslate).then((value) => {
      console.log(value);
      this.PAGE_CONTENT = value[0].find((value) => value.id === "home");
      if (this.PAGE_CONTENT != undefined) {
        this.colorHeader = this.PAGE_CONTENT.color;
        console.log("colorHeader_main_page", this.colorHeader);

        if (this.PAGE_CONTENT.length < 1) this.navigate("home");
        this.isLoaded = true;

        this.CTA_TYPE = this.PAGE_CONTENT["callToAction"]["type"];
        this.CTA_LABEL = this.PAGE_CONTENT["callToAction"]["label"];
        switch (this.CTA_TYPE) {
          case "FILE":
            const fileName =
              this.PAGE_CONTENT["callToAction"]["callToActionDocument"]
                .documentPath;
            const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
            if (extension === "pdf" || extension === "ics")
              this.CTA_LINK = `${environment.inputPath}SITES/${this.SITE_NAME}/DATA/${extension}/${fileName}`;
            else if (listOfImagesExtensions.includes(extension))
              this.CTA_LINK = `${environment.inputPath}SITES/${this.SITE_NAME}/IMG/${fileName}`;
            break;
          case "EXTERNAL":
            this.CTA_LINK =
              this.PAGE_CONTENT["callToAction"]["callToActionExternalLink"].url;
            break;
          case "SURVEY":
            this.CTA_LINK = `/survey/${this.SITE_NAME}/${this.PAGE_CONTENT["callToAction"]["callToActionSurvey"].survey}`;
            break;
          case "MULTI_CTA":
            this.CTA_LIST =
              this.PAGE_CONTENT["callToAction"]["callToActionMultiCta"][
                "ctaList"
              ];
            break;
          default:
            console.error(`Error ! Type ${this.CTA_TYPE} is not allowed`);
        }
      }
    });
  }

  navigate(route: string) {
    this.router.navigate([`/${route}/${this.SITE_NAME.toLowerCase()}`]);
  }

  closeModal() {
    this.callbackResetModal(this.ALERT_MESSAGE);
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.resetModal();
  }

  resetModal() {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(error) {
    console.log(error);
    this.errorMessage;
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.MESSAGE = error["message"];
  }
}
